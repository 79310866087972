import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import {
    Box,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Grid,
} from "@mui/material";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ShowImage from '../../../shared/components/showImage';
import store from '../../../redux/reduxstore';
import './productview.scss';
export default function AddProductView() {
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.user.user);
    const name = useSelector(state => state.auth.name);
    const userData = store.getState().auth.userData


    const userDataRef = useRef(userData);
    useEffect(() => {
        // Update the ref whenever isExamStarted changes
        userDataRef.current = userData;
    }, [userData]);

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);



    const [formData, setFormData] = useState({
        orderProducts: [],
        orderupdatedby: '',
        yetscreen:false,


    });
    const [newNotification, setNewNotification] = useState([]);
    const [timelines, setTimelines] = useState([]);

    useEffect(() => {

        const getData = async () => {
            const requestBody = {
                "reference_type": "salesreturn",
                reference_id: id
            }

            const responsetimeline = await fetchData("timeline", requestBody);
            console.log(responsetimeline);
            if (responsetimeline?.data) {
                setTimelines(responsetimeline.data);
            }
        }

        getData()

    }, [newNotification])

    const handleSubmit = async (e, status, newStatus) => {
        e.preventDefault(); // Prevent default form submit behavior

        try {
            const allowedStatuses = ['Open', 'Close', 'Pending', 'Confirmed', 'Dispatched', 'Delivered', 'Approved', 'Rejected'];
            if (!allowedStatuses.includes(status)) {
                console.error('Invalid salesreturn status:', status);
                return;
            }

            const currentDateTime = dayjs().format('DD-MM-YYYY,  hh:mm:ss A');
            const updatedNotification = { date: currentDateTime, status: status, user: name }; // Store notification with the status

            // Update the new notification in the state
            setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);

            // Prepare the updated form data, including the status change
            const updatedFormData = {
                estimateStatus: status, // Use the new field name 'estimateStatus' for the status
                yetscreen: false,
            };

            // If an ID exists, update the record; otherwise, create a new salesreturn
            if (id) {
                const data = await patchData(`product/${id}`, updatedFormData);
                if (data?.data) {
                    setFormData({ ...formData, estimateStatus: status }); // Update form data with the new status
                    navigate('/estimat');
                }
            } else {
                const data = await postData('product', updatedFormData); // Create new salesreturn
                if (data) navigate(-1); // Navigate back if the creation is successful
            }
        } catch (error) {
            console.error('Error saving data:', error); // Handle any errors
        }
       
        e.preventDefault();
        setStatus(newStatus);
    };





    const getData = async () => {
        try {
            const rs = await fetchsingleData(`product`, id);
            if (rs?.data) setFormData(rs.data);
            if (rs?.data.ordernotification) setNewNotification(rs.data.ordernotification)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const [profile, setprofile] = useState([]);
    const [profileList, setProfileList] = useState([]);

    useEffect(() => {
        getPofile();
    }, []);

    const getPofile = async () => {
        try {
            const response = await fetchData('companyprofile');
            if (response?.data && response.data.length > 0) {
                setprofile(response.data[0]); // Set the first company's data to the state
                setProfileList(response.data); // If needed for another part of the app
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const appDataJSON = localStorage.getItem('users');
    const appData = JSON.parse(appDataJSON);
    const [status, setStatus] = useState(null);
    const pdfRef = useRef();
    const handleGeneratePdf = () => {
        const pdfWidth = 420;
        const pdfHeight = 370;
    
        const doc = new jsPDF({
          orientation: 'b5',
          unit: 'px',
          format: [pdfWidth, pdfHeight],
        });
        doc.html(pdfRef.current, {
          callback: (doc) => {
            const pdfBlob = doc.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            const newWindow = window.open(pdfUrl);
            if (newWindow) {
              newWindow.onload = () => {
                newWindow.print();
              };
            } else {
              console.error('Failed to open new window for printing');
            }
          },
          x: 20,
          y: 20,
          z: 20,
          width: pdfWidth - 20,
          windowWidth: 970,
        });
      };
      useEffect(() => {
        const saveYetscreen = async () => {
          const updatedFormData = {
            ...formData,
            yetscreen: false,
          };
    
          try {
            if (id) {
              await patchData(`product/${id}`, updatedFormData);
             
            } 
          } catch (error) {
            console.error("Error saving yetscreen:", error);
          }
        };
    
        saveYetscreen();
      }, []); 
    


    return (

        <Box p={4}>
            {/* Header Section */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                border="1px solid #ccc" // Border around the Box
                borderRadius="8px"      // Optional: Rounded corners for the Box
                p={2}                   // Padding inside the Box
            >

                <Typography variant="h6" onClick={() => navigate('/product')} style={{ cursor: 'pointer' }}>
                    {'<'} Product     {formData.name}
                </Typography>

                <Box>


                </Box>
            </Box>


            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }} ref={pdfRef}>
                <div style={{ width: '75%', display: 'flex', flexDirection: 'column', padding: '15px' }}>
                    <div className="new-white-box-design">
                        <h2>Basic Details</h2>
                        <Box mb={6}>
                            <Grid container >
                                <Grid item xs={3} >
                                    <Typography variant="subtitle1">
                                        Name
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Code
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.productCode || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Category
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {formData.categoryName || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        HSN Code
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.hsn_code || "-"}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container mt={3} mb={6} >
                                <Grid item xs={3} >
                                    <Typography variant="subtitle1">
                                        Remark
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.remarks}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                <Grid item xs={3}>
                                        <Typography variant="subtitle1">
                                            Status
                                        </Typography>
                                        <Box
                                            mt={1}
                                            px={2}
                                            marginRight={6}
                                            display="inline-block"
                                            borderRadius="16px"
                                            bgcolor={formData.status ? 'green' : '#f0ad4e'}
                                            color="white"
                                        >
                                            <Typography variant="subtitle1">
                                                {formData.status ? "Active" : "Inactive"}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Is New
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.isCheckedNew ? "Yes" : "No"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>


                        {/* <label>Name</label>
                            <strong>{formData.name}</strong> */}
                    </div>

                    <div className="new-white-box-design">
                        <h2>Price Details</h2>
                        <Box mb={6}>
                            <Grid container >
                                <Grid item xs={3} >
                                    <Typography variant="subtitle1">
                                    Price
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.price}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                    Price Tax
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.priceTax || "-"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                    Discount
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {formData.discount || "-"} {formData.discountType}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                    offerDiscountCount
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.offerDiscountCount || "-"} {formData.offerDiscountType}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container mt={3} mb={6} >
                                <Grid item xs={3} >
                                    <Typography variant="subtitle1">
                                    Apply Offer Discount On
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.applyOfferDiscount}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                    Additional Discount
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.additionalDiscount} {formData.additionalDiscountType}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                    Apply Additional Discount On
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.applyAdditionalDiscount}
                                    </Typography>
                                </Grid>

                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                    Tax(%)
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.tax }
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container mt={2} mb={6} >
                                <Grid item xs={3} >
                                    <Typography variant="subtitle1">
                                    MRP
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.mrpPrice}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>


                        {/* <label>Name</label>
                            <strong>{formData.name}</strong> */}
                    </div>

                    <div className="new-white-box-design">
                        <h2>Unit of measure</h2>
                        <Box mb={6}>

                            <Grid container mt={2} mb={6} >
                                <Grid item xs={3} >
                                    <Typography variant="subtitle1">
                                    Unit
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.unitName}
                                    </Typography>
                                </Grid>

                                <Grid item xs={3} >
                                    <Typography variant="subtitle1">
                                    Conversation rate
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.mrpPrice}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>


                        {/* <label>Name</label>
                            <strong>{formData.name}</strong> */}
                    </div>

                    <div className="new-white-box-design">
                        <h2>Other Details</h2>
                        <Box mb={6}>

                            <Grid container mt={2} mb={6} >
                                <Grid item xs={3} >
                                    <Typography variant="subtitle1">
                                    MOQ
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.moq}
                                    </Typography>
                                </Grid>

                                <Grid item xs={3} >
                                    <Typography variant="subtitle1">
                                    GST NUMBER
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.gstNumber}
                                    </Typography>
                                </Grid>

                                <Grid item xs={3} >
                                    <Typography variant="subtitle1">
                                    MRP
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.mrpPrice}
                                    </Typography>
                                </Grid>

                                <Grid item xs={3} >
                                    <Typography variant="subtitle1">
                                    location
                                    </Typography>
                                    <Typography mt={1} variant="subtitle1">
                                        {formData.location}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>


                        {/* <label>Name</label>
                            <strong>{formData.name}</strong> */}
                    </div>

                    <div className="new-white-box-design">
                        <h2>Product Images</h2>
                        <Box mb={6}>

                            <Grid container mt={2} mb={6} >
                                <Grid item xs={3} >
                                    <Typography mt={1} variant="subtitle1">
                                    {formData.productUrl ? (
                      <ShowImage value={formData.productUrl} />
                    ) : (
                      <i className="fas fa-image"></i>
                    )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>


                        {/* <label>Name</label>
                            <strong>{formData.name}</strong> */}
                    </div>

                    <div className="new-white-box-design">
                        <h2>Description List</h2>
                        <Box mb={6}>
                            <Grid container mt={2} mb={6}>
                                <Grid item xs={12}>
                                    <table className="styled-table">
                                        <thead>
                                            <tr>
                                                <th>Title</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formData?.descriptions?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.Title}</td>
                                                    <td>{item.Description}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>

                    <div className="new-white-box-design">
                        <h2>Price Group Details</h2>
                        <Box mb={6}>
                            <Grid container mt={2} mb={6}>
                                <Grid item xs={12}>
                                    <Table className="styled-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Default unit</TableCell>
                                                <TableCell>Price</TableCell>
                                                <TableCell>Price Tax</TableCell>
                                                <TableCell>Discount</TableCell>
                                                <TableCell>Offer Discount</TableCell>
                                                <TableCell>Additional Discount</TableCell>
                                                <TableCell>Status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {formData?.priceGroup?.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.unit}</TableCell>
                                                    <TableCell>{item.price}</TableCell>
                                                    <TableCell>{item.priceTax}</TableCell>
                                                    <TableCell>{item.discount}</TableCell>
                                                    <TableCell>{item.offerDiscount}%    {item.offerDiscountType}</TableCell>
                                                    <TableCell>{item.additionalDiscount}%  {item.additionalDiscountType}</TableCell>
                                                    <TableCell>{item.status}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>


                </div>

                <div style={{
                    marginTop: '1rem',
                    padding: '1.5rem',
                    backgroundColor: '#E5E5E5',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                    maxWidth: '400px',
                    marginLeft: '5px',
                    width: '400px',
                    scrollBehavior: 'smooth',
                    height: 'auto',
                    overflowY: 'auto',
                    flex: 1  // Ensures the second div stretches equally with the first
                }}>
                    <div style={{ marginTop: '-8px', paddingBottom: '15px', fontWeight: 'bold', fontSize: '20px' }}><strong>Timeline</strong></div>

                    <div

                        style={{
                            display: "flex",
                            paddingLeft: "3px",
                            paddingBottom: "15px",
                        }}
                    >
                        <div style={{ paddingRight: "5px" }}>➨</div>
                        <div style={{ fontSize: "14px" }}>

                        Product {formData.name} generated.
                        by {formData.created_by} on <strong> {dayjs(formData.created_at).format('DD-MM-YYYY, hh:mm:ss A')}</strong>

                        </div>
                    </div>

                </div>
            </div>




        </Box>

    );
};
