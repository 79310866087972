import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { postData } from "../../../../services/apiService";

export default function PaymentTableCustomerAreaTableComponent({ customerList, setCustomerList }) {
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [countryId, setCountryId] = useState(null); // State for selected country ID
    const [stateId, setStateId] = useState(19); // Default state ID
    const [cityId, setCityId] = useState(null);
    // Fetch data on component mount, currentPage, pageSize, or when countryId changes
    useEffect(() => {
        getData(currentPage, pageSize, countryId, stateId);
    }, [currentPage, pageSize, countryId]);

    // Function to fetch data from API
    const getData = async (page, pageSize, countryId, stateId) => {
        try {
            const requestBody = {
                page,
                limit: pageSize,
                ...(countryId ? { countryId } : { stateId }), // Use countryId if available, else stateId
            };

            const response = await postData("payment/area", requestBody);
            console.log("API Response:", response?.data);

            if (response?.data) {
                setCustomerList(response.data || []);
                setTotalItems(response?.totalDocuments || 0);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };


    return (
        <div className="account-table-page-alignment">
            <div className="account-table-design">
                {/* Country Selection Dropdown */}


                {/* Table */}
                <table>
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Confimed</th>
                            <th>Cancelled</th>
                            <th>Pending</th>
                            <th>Total amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {console.log(" customerList", customerList)}
                        {customerList && Array.isArray(customerList) && customerList.length > 0 ? (
                            customerList.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.stateName || "No data available"}</td>
                                    <td>{item?.confirmed || "N/A"}</td>
                                    <td>{item?.cancelled || "N/A"}</td>
                                    <td>{item?.pending || "N/A"}</td>
                                    <td>{item?.totalAmount || "N/A"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" style={{ textAlign: "center" }}>
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>

                {/* Pagination */}
                <Pagination
                    count={Math.ceil(totalItems / pageSize)} // Total pages
                    page={currentPage} // Current page
                    onChange={handlePageChange} // Page change handler
                    color="primary"
                    size="small"
                />
            </div>
        </div>
    );
}
