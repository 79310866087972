import React, { useState, useEffect } from "react";
import "./ordertable.scss";
import { Link } from "react-router-dom";
import { fetchData } from "../../../services/apiService";
import moment from 'moment';
import { useSelector } from 'react-redux';
import SelectBox from "../../../shared/components/select";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; 
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useDispatch } from "react-redux";
import { setTotalorder } from "../../../redux/auth";
import EditIcon from "../../../shared/icons/editIcon";
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from "../../../services/checkFeature";
import store from "../../../redux/reduxstore";
import debounce from 'lodash.debounce'; 
import SearchIcon from '../../../assets/icons/search.svg';
export default function Ordertable({ selectedIds, totalItems, setTotalItems, activityList, setSelectedIds, setActivityList }) {
  dayjs.extend(isSameOrAfter); 
  dayjs.extend(isSameOrBefore); 
  const [orderList, setOrderList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); 
  const [statusTerm, setStatusTerm] = useState(""); 
  const [dateTerm, setDateTerm] = useState("");
  const [createdByTerm, setCreatedByTerm] = useState(""); 
  const [customers, setCustomerOptions] = useState([]);
  const [orderTerm, setOrderTerm] = useState("");
  const [AmountTerm, setAmountTerm] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [ProductList, setProductList] = useState([]);
  const [productData, setProductData] = useState([]);
  const pageSize = 10;
  const user = useSelector(state => state.user.user);
  const userData = store.getState().auth.userData
  const userName = userData.name
  const userId = userData.id
  const [allactivityType, setallactivityTypeTerm] =useState('');
  const [formData, setFormData] = useState({
    orderNo: "",
    orderDate: moment(),
    customerName: "",
    customerId: "",
    customerAddress: "",
    customerContact: "",
    status: true,
    orderStatus: 'Pending',
    products: [],
    ordernotification: [],
    ordercreatedby: '',
    orderupdate: '',
    orderupdatedby: '',
  });

  useEffect(() => {
    getCustomer();
    const filters = {
      orderNo: orderTerm,
      customerName :searchTerm,
      orderStatus: statusTerm,
      userName: createdByTerm,
      totalAmount: AmountTerm,
      orderDate :selectedDates 
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList)
  }, [activityList])


  const { RangePicker } = DatePicker;


  const [errors, setErrors] = useState({});
  const getCustomer = async () => {
    try {
      const response = await fetchData(`customer`);
      if (response?.data) {
        setCustomerOptions(
          response?.data?.map((i) => {
            return {
              label: `[${i?.code}] ${i?.cName}`,
              key: "customer",
              value: {
                id: i?.customerId,
                name: `[${i?.code}] ${i?.cName}`,
                address: `${i?.address} - ${i?.pincode}.`,
                contact: i?.addressContact,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Close':
        return 'Closee';
      case 'Cancelled':
        return 'Cancelled';
      case 'Pending':
        return 'Pending';
      case 'Open':
        return 'Open';
      default:
        return '';
    }
  };
  const onRangeChange = (dates) => {
    setSelectedDates(dates); 
  };

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };

  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {

      const requestBody = checkAccess("access_their")
      ? { userId: userId }
      : {};

      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('order' ,
        removeEmptyFields(filters), 
        pagination,
        // requestBody,
        {},
        isSearching,
        globalSearch);

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }

      console.log('setProductData', responseProducts?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      orderNo: orderTerm,
      customerName: searchTerm,
      orderStatus: statusTerm,
      userName: createdByTerm,
      totalAmount: AmountTerm,
      // orderDate: selectedDates
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [orderTerm, searchTerm, statusTerm, createdByTerm, AmountTerm, selectedDates, currentPage, pageSize]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['orderNo', 'customerName', 'orderStatus', 'userName'],
      searchtext: allactivityType
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);
  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  };

  return (

    <div className='account-table-page-alignment'>
      <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className='account-table-design'>
        <table>
          <thead>
            <style>
              {`
      .search-input::placeholder {
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 2px;
        text-align: left;
        padding: 3px;
        border-bottom: 2px solid #edf2f9;
      }
    `}
            </style>
            <tr>
              <th><div style={{ width: '100%' }}></div></th>

              <th><div style={{ textAlign: 'center', width: '100%' }}><input
                type="text"
                placeholder=" Order#"
                value={orderTerm}
                onChange={(e) => setOrderTerm(e.target.value)}
                className="search-input input-height"
                style={{ border: 'none', width: '100%' }}

              /></div></th>

<th><div style={{ textAlign: 'center', width: '100%' }}><input
                type="text"
                placeholder="Customer"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input input-height"
                style={{ border: 'none', width: '100%' }}

              /></div></th>

              <th><div style={{ width: '100%' }}>
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={[' Start Date', ' End Date']} className="my-custom-class" />
                </Space></div> </th>               
              <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder=" Amount"
                value={AmountTerm}
                onChange={(e) => setAmountTerm(e.target.value)}
                className="search-input input-height"
                style={{ border: 'none', width: '100%' }}

              /></div></th>
              <th><div style={{ width: '100%' }}> <input
                type="text"
                placeholder=" Created By"
                value={createdByTerm}
                onChange={(e) => setCreatedByTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%' }}

              /></div></th>
              <th></th>
              <th></th>
              <th><div style={{
                width: '100%'
              }}><input
                  type="text"
                  placeholder=" Status"
                  value={statusTerm}
                  onChange={(e) => setStatusTerm(e.target.value)}
                  className="search-input"
                  style={{ border: 'none', width: '100%' }}

                /></div></th>
              <th><div style={{ width: '100%' }}></div></th>
            </tr>
            <tr>

              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === productData.length} /></th>

              <th>Order #</th>
              <th>Customer</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Created by</th>
              <th>Created at</th>
              <th>Billing area</th>
              <th>Status</th>
              {checkAccess('edit_order') && (<th>Action</th>)}
            </tr>
          </thead>
          <tbody>
            {productData?.length > 0 ? (
              productData.map((i, index) => {
                const orderDate = moment(i?.orderDate).format('DD-MM-YYYY');

                return (
                  <tr key={index} className={i?.yetscreen ? 'background' : ''}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(i.id)}
                        onChange={() => handleCheckboxChange(i.id)}
                      />
                    </td>
                    <td>
                    <Link className='font-color' to={`view/${i?.id}`}>
                      <span className={i?.yetscreen ? 'highlight' : ''}>
                        {i?.orderNo}
                      </span>
                      </Link>
                    </td>
                    <td>
                      <span className={i?.yetscreen ? 'highlight' : ''}>
                        {i?.customerName}
                      </span>
                    </td>
                    <td>
                      <span className={i?.yetscreen ? 'highlight' : ''}>
                        {orderDate}
                      </span>
                    </td>
                    <td>
                      <span className={i?.yetscreen ? 'highlight' : ''}>
                        {i?.totalAmount}
                      </span>
                    </td>
                    <td>
                      <span className={i?.yetscreen ? 'highlight' : ''}>
                        {i?.created_by}
                      </span>
                    </td>
                    <td>
                      <span className={i?.yetscreen ? 'highlight' : ''}>
                        {formatDateTime(i?.created_at)}
                      </span>
                    </td>
                    <td>
                      <span className={i?.yetscreen ? 'highlight' : ''}>
                        {i?.areaName}
                      </span>
                    </td>
                    <td>
                      {checkAccess("read_order") ? (
                        <Link to={`view/${i?.id}`}>
                          <button className={getStatusClass(i.orderStatus)}>
                            {i?.orderStatus}
                          </button>
                        </Link>
                      ) : (
                        <button
                          className={getStatusClass(i.orderStatus)}
                          style={{ pointerEvents: "none", cursor: "default" }}
                        >
                          {i?.orderStatus}
                        </button>
                      )}
                    </td>
                    {checkAccess('edit_order') && (
                      <td>
                        {i?.orderStatus !== "Close" ? (
                          <Link to={{ pathname: `add/${i?.id}` }}>
                            <EditIcon />
                          </Link>
                        ) : null}
                      </td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} style={{ textAlign: 'center', fontStyle: 'italic', color: '#666' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className='pagination-alignment'>
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems} 
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
