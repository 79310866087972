import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../../../services/apiService";
import moment from 'moment';
import { useSelector } from 'react-redux';
import SelectBox from "../../../shared/components/select";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import EditIcon from "../../../shared/icons/editIcon";
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from "../../../services/checkFeature";
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce';
export default function Quotetable({ selectedIds, totalItems, setTotalItems, setSelectedIds, activityList, setActivityList }) {
  dayjs.extend(isSameOrAfter); // Use the plugin
  dayjs.extend(isSameOrBefore); // Use the plugin
  const [orderList, setOrderList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Search term for customerName
  const [statusTerm, setStatusTerm] = useState(""); // Search term for status
  const [dateTerm, setDateTerm] = useState(""); // Search term for date
  const [createdByTerm, setCreatedByTerm] = useState(""); // Search term for created by
  const [customers, setCustomerOptions] = useState([]);
  const [orderTerm, setOrderTerm] = useState("");
  const [AmountTerm, setAmountTerm] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [ProductList, setProductList] = useState([]);
  const [productData, setProductData] = useState([]);
  const user = useSelector(state => state.user.user);
  const [allactivityType, setallactivityTypeTerm] = useState('');
  const [formData, setFormData] = useState({
    // quotation_no: "",
    orderDate: moment(),
    customerName: "",
    customerId: "",
    customerAddress: "",
    customerContact: "",
    status: true,
    quotationstatus: 'Pending',
    products: [],
    quotation_notification: [],
    created_by: '',
    orderupdate: '',
    orderupdatedby: '',




  });
  useEffect(() => {
    getCustomer();
    const filters = {
      linkname: searchTerm,
      quotationstatus: statusTerm,
      created_by: createdByTerm,
      totalamount: AmountTerm,
      orderDate: selectedDates,
      quotationNo: orderTerm
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList)
    // setTotalItems(activityList.length)
  }, [activityList])

  const { RangePicker } = DatePicker;

  const getCustomer = async () => {
    try {
      const [customerResponse, leadResponse] = await Promise.all([
        fetchData(`customer`),
        fetchData(`lead`),
      ]);

      const customerOptions = customerResponse?.data?.map((i) => ({
        label: `${i?.cName || "Unnamed Customer"} [Customer]`,  // Customer name first
        key: "customer",
        value: i?.cName,
      }));

      const leadOptions = leadResponse?.data?.map((i) => ({
        label: `${i?.name || "Unnamed Lead"} [Lead]`,  // Lead name first
        key: "lead",
        value: i?.name,
      }));

      setCustomerOptions([...customerOptions, ...leadOptions]);
    } catch (error) {
      console.error("Error fetching customer or lead data:", error);
    }
  };


  const [errors, setErrors] = useState({});


  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Close':
        return 'Closee';
      case 'Approved':
        return 'Approved';
      case 'Rejected':
        return 'Rejected';
      case 'Cancelled':
        return 'Cancelled';
      case 'Pending':
        return 'Pending';
      case 'Open':
        return 'Open';
      default:
        return '';
    }
  };
  const onRangeChange = (dates) => {
    setSelectedDates(dates); // Update the state with the selected date range
  };

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };
  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('quotation', removeEmptyFields(filters),
        pagination,
        {},
        isSearching,
        globalSearch
      );

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }

      console.log('setProductData', responseProducts?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      linkname: searchTerm,
      quotationstatus: statusTerm,
      created_by: createdByTerm,
      totalamount: AmountTerm,
      orderDate: selectedDates,
      quotationNo: orderTerm,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [searchTerm, statusTerm, createdByTerm, AmountTerm, selectedDates, orderTerm]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['linkname', 'quotationstatus', 'created_by', 'quotationNo'],
      searchtext: allactivityType,
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);

  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];

  return (
    <>
      <div className="table-v2-design">
        <table>
          <thead>
            <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>

            <th>Quotation #</th>
            <th>Sale Quotation For</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Created by</th>
            <th>Status</th>
            {checkAccess('edit_sale_quotation') && <th>Action</th>}
          </thead>
          <tbody>
            {productData && productData.length > 0 ? (
              productData.reverse().map((i, index) => {
                const currentDate = moment().format('DD-MM-YYYY');
                const orderDate = moment(i?.quotationDate).format('DD-MM-YYYY');

                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(i.id)}
                        onChange={() => handleCheckboxChange(i.id)}
                      />
                    </td>

                    <td
                      className="whitesapce-nowrap"
                      style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                    >
                      {i?.quotationNo || 'N/A'}
                    </td>
                    <td>
                      <span
                        className="price-group-text"
                        style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                      >
                        {i?.linkname || 'N/A'}
                      </span>
                    </td>
                    <td
                      className="whitesapce-nowrap"
                      style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                    >
                      <span className="whitesapce-nowrap">{orderDate || 'N/A'}</span>
                    </td>
                    <td
                      className="whitesapce-nowrap"
                      style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                    >
                      {i?.totalamount || 'N/A'}
                    </td>
                    <td
                      className="whitesapce-nowrap"
                      style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                    >
                      {i?.created_by || 'N/A'}
                    </td>
                    <td
                      className="whitesapce-nowrap"
                      style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                    >
                      {checkAccess('read_sale_quotation') ? (
                        <Link to={{ pathname: `view/${i?.id}` }}>
                          <button className={` ${getStatusClass(i?.quotationstatus)}`}>
                            {i?.quotationstatus || 'N/A'}
                          </button>
                        </Link>
                      ) : (
                        <button
                          className={` ${getStatusClass(i?.quotationstatus)}`}
                          style={{ pointerEvents: 'none', cursor: 'default' }}
                        >
                          {i?.quotationstatus || 'N/A'}
                        </button>
                      )}
                    </td>
                    {checkAccess('edit_sale_quotation') && (
                      <td>
                        <Link to={{ pathname: `add/${i?.id}` }}>
                          <EditIcon />
                        </Link>
                      </td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: 'center', fontStyle: 'italic', color: '#666' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
      {/* <div className='account-table-page-alignment'>
        <div className='account-table-header-alignment'>
          <div className='search-width'>
            <div className='searchbar-design'>
              <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
              <div className='icon-alignment'>
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
          </div>
        </div>

        <div className='account-table-design'>
          <table>
            <thead>

              <tr>
                <th><div style={{ width: '100%' }}></div></th>

                <th><div style={{ textAlign: 'center', width: '100%' }}><input
                  type="text"
                  placeholder=" Quote No#"
                  value={orderTerm}
                  onChange={(e) => setOrderTerm(e.target.value)}
                  className="w-100 border-0 table-input-background"
                  style={{ border: 'none', width: '100%', padding: "7px", borderRadius: "3px" }}

                /></div></th>

                <th><input type="text" placeholder="Name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>




                <th><div style={{ width: '100%' }}>
                  <Space direction="vertical" size={12}>
                    <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={[' Start Date', ' End Date']} className="w-100 border-0 table-input-background" />
                  </Space></div> </th>

                <th><div style={{ width: '100%' }}><input
                  type="text"
                  placeholder=" Amount"
                  value={AmountTerm}
                  onChange={(e) => setAmountTerm(e.target.value)}
                  className="w-100 border-0 table-input-background"
                  style={{ border: 'none', width: '100%', padding: "7px", borderRadius: "3px" }}

                /></div></th>

                <th><div style={{ width: '100%' }}> <input
                  type="text"
                  placeholder=" Created By"
                  value={createdByTerm}
                  onChange={(e) => setCreatedByTerm(e.target.value)}
                  className="w-100 border-0 table-input-background"
                  style={{ border: 'none', width: '100%', padding: "7px", borderRadius: "3px" }}

                /></div></th>
                <th><div style={{
                  width: '100%'
                }}><input
                    type="text"
                    placeholder=" Status"
                    value={statusTerm}
                    onChange={(e) => setStatusTerm(e.target.value)}
                    className="w-100 border-0 table-input-background"
                    style={{ border: 'none', width: '100%', padding: "7px", borderRadius: "3px" }}

                  /></div></th>
                <th><div style={{ width: '100%' }}></div></th>
              </tr>
              <tr>

                <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>

                <th>Quotation #</th>
                <th>Sale Quotation For</th>
                <th>Date</th>
                <th>Amount</th>
                <th>Created by</th>
                <th>Status</th>
                {checkAccess('edit_sale_quotation') && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {productData && productData.length > 0 ? (
                productData.reverse().map((i, index) => {
                  const currentDate = moment().format('DD-MM-YYYY');
                  const orderDate = moment(i?.quotationDate).format('DD-MM-YYYY');

                  return (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedIds.includes(i.id)}
                          onChange={() => handleCheckboxChange(i.id)}
                        />
                      </td>

                      <td
                        className="whitesapce-nowrap"
                        style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                      >
                        {i?.quotationNo || 'N/A'}
                      </td>
                      <td>
                        <span
                          className="price-group-text"
                          style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                        >
                          {i?.linkname || 'N/A'}
                        </span>
                      </td>
                      <td
                        className="whitesapce-nowrap"
                        style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                      >
                        <span className="whitesapce-nowrap">{orderDate || 'N/A'}</span>
                      </td>
                      <td
                        className="whitesapce-nowrap"
                        style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                      >
                        {i?.totalamount || 'N/A'}
                      </td>
                      <td
                        className="whitesapce-nowrap"
                        style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                      >
                        {i?.created_by || 'N/A'}
                      </td>
                      <td
                        className="whitesapce-nowrap"
                        style={{ fontWeight: orderDate === currentDate ? 'bold' : 'normal' }}
                      >
                        {checkAccess('read_sale_quotation') ? (
                          <Link to={{ pathname: `view/${i?.id}` }}>
                            <button className={` ${getStatusClass(i?.quotationstatus)}`}>
                              {i?.quotationstatus || 'N/A'}
                            </button>
                          </Link>
                        ) : (
                          <button
                            className={` ${getStatusClass(i?.quotationstatus)}`}
                            style={{ pointerEvents: 'none', cursor: 'default' }}
                          >
                            {i?.quotationstatus || 'N/A'}
                          </button>
                        )}
                      </td>
                      {checkAccess('edit_sale_quotation') && (
                        <td>
                          <Link to={{ pathname: `add/${i?.id}` }}>
                            <EditIcon />
                          </Link>
                        </td>
                      )}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} style={{ textAlign: 'center', fontStyle: 'italic', color: '#666' }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className='pagination-alignment'>
            <Pagination
              currentPage={currentPage}
              totalItems={totalItems}
              pageSize={pageSize}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div> */}
    </>
  );
}
