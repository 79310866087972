import React from 'react'

export default function RightArrow() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_69_1735)">
<path d="M15.5378 9.79472C15.7484 9.58378 15.8668 9.29785 15.8668 8.99972C15.8668 8.70159 15.7484 8.41566 15.5378 8.20472L11.2958 3.96047C11.0847 3.74942 10.7985 3.63086 10.5 3.63086C10.2015 3.63086 9.9153 3.74942 9.70425 3.96047C9.4932 4.17152 9.37464 4.45776 9.37464 4.75622C9.37464 5.05468 9.4932 5.34092 9.70425 5.55197L12.0262 7.87472H3.375C3.07663 7.87472 2.79048 7.99325 2.57951 8.20422C2.36853 8.4152 2.25 8.70135 2.25 8.99972C2.25 9.29809 2.36853 9.58424 2.57951 9.79522C2.79048 10.0062 3.07663 10.1247 3.375 10.1247H12.0262L9.70425 12.4467C9.59975 12.5512 9.51686 12.6753 9.4603 12.8118C9.40375 12.9483 9.37464 13.0947 9.37464 13.2425C9.37464 13.3903 9.40375 13.5366 9.4603 13.6731C9.51686 13.8097 9.59975 13.9337 9.70425 14.0382C9.80875 14.1427 9.93281 14.2256 10.0693 14.2822C10.2059 14.3387 10.3522 14.3678 10.5 14.3678C10.6478 14.3678 10.7941 14.3387 10.9307 14.2822C11.0672 14.2256 11.1913 14.1427 11.2958 14.0382L15.5378 9.79472Z" fill="#667085"/>
</g>
<defs>
<clipPath id="clip0_69_1735">
<rect width="18" height="18" fill="white" transform="matrix(0 1 -1 0 18 0)"/>
</clipPath>
</defs>
</svg>

  )
}
