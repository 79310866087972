import React, { useState, useEffect } from 'react';
import 'moment/locale/it.js';
import "./adddispatchorder.scss";
import Button from '../../../shared/components/button';
import InputCustom from "../../../shared/components/input";
import Input from '../../../shared/components/input';
import { fetchData, fetchsingleData, patchData, postData,editDispatch } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import Toggle from '../../../shared/components/switch';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import Pagination from '../../../shared/components/pagination';
import GlobalButton from '../../../shared/globalButton';
import { notify } from '../../toastr/Toastr';
import TextArea from 'antd/es/input/TextArea';
import store from '../../../redux/reduxstore';
import { checkAccess } from "../../../services/checkFeature";
export default function AddEditdispatchorder() {
  const navigate = useNavigate();
  const { id } = useParams();
  const name = useSelector((state) => state.auth.name);
  const isEditMode = window.location.pathname.includes('/add') || false;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [transportType, setTransportType] = useState([]);
  const [customers, setCustomerOptions] = useState([]);
  const [orderNo, setOrderOption] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  // const [productsForOrder, setProductsForOrder] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const location = useLocation();
  const [formDatadispatch, setformDatadispatch] = useState(location.state?.formData || {});
  console.log(formDatadispatch,"formDataoeder");
 
  const company_setting = store.getState()?.auth?.company_setting;
  const [formData, setFormData] = useState(location.state?.formData || {
    id: '',
    dispatchCode: '',
    prefix: "",
    dispatchNo: '',
    customerName: "",
    customerId: null,
    date: dayjs(),
    invoiceNo: '',
    invoiceDate: dayjs(),
    transport: '',
    lr_no: "",
    lr_date: dayjs(),
    orderproducts: [],
    total: 0,
    subTotal: 0,
    customerAddress: "",
    customerContact: "",
  });

  const [productsForOrder, setProductsForOrder] = useState(formData.orderproducts || []); // Use orderproducts from formData if available
  const AddProduct = () => {
    const newProduct = {
      remark: "",
      orderNo: "", 
      orderId: 0,
      productName: "",
      productId: "",
      unitId: "",
      unitName: "",
      quantity: 1,
      unitPrice: 0,
      discount: 0,
      tax: 0,
      total: 0,
      productUrl: '',
      productStatus: 'dispatchPending',
      productCode: '',
      offerDiscount: 0,
      additionalDiscount: 0,
      priceTax: '',
      taxcharges: 0,
    };

    setProductsForOrder(prevProducts => {
      const updatedProducts = [...prevProducts, newProduct];
      return updatedProducts;
    });

    setFormData(prev => ({
      ...prev,
      orderproducts: [...prev.orderproducts, newProduct],
    }));
  };

  useEffect(() => {
    if (location.state?.formData) {
      setFormData(prevState => ({
        ...prevState,
        ...location.state.formData,
        date: location.state.formData.date ? dayjs(location.state.formData.date) : prevState.date,
        invoiceDate: location.state.formData.invoiceDate ? dayjs(location.state.formData.invoiceDate) : prevState.invoiceDate,
        lr_date: location.state.formData.lr_date ? dayjs(location.state.formData.lr_date) : prevState.lr_date,
        orderproducts: location.state.formData.orderproducts || [], // Ensure orderproducts is initialized
      }));
      setProductsForOrder(location.state.formData.orderproducts || []); // Update local state too
    }
    console.log("location.state.formData", location.state?.formData);
  }, [location.state?.formData]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#f1f1f1',
      minHeight: '40px',
      height: '40px',
      fontSize: '14px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '40px',
      padding: '0 6px',
      fontSize: '14px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '40px',
    }),
  };

  const handleAddProductClick = () => {
    if (formData.customerId) {
      AddProduct();
    } else {
       notify.error('Please select customer first');
    }
  };
  
  const productionOptionsList = (orderId) => {
    
    if (formData?.orderproducts?.length > 0) {
      const usedProductIds = formData.orderproducts
        .filter((order) => order.orderId !== orderId && order.productId)
        .map((order) => order.productId);
      const availableOptions = productOptions.filter(
        (option) => !usedProductIds.includes(option.value)
      );
  
      return availableOptions;
    }
    return productOptions;
  };
  
  const productSelectChange = (index, event, type) => {
    if (type === "p") {
      const updatedOrderProducts = [...formData.orderproducts];
      const selectedProduct = event.value;
      const currentOrderNo = formData.orderproducts[index]?.orderNo;
      const isDuplicate = formData.orderproducts.some(
      (product, idx) =>
        idx !== index && 
        product.orderNo === currentOrderNo && 
        product.productId === selectedProduct.productId 
    );

    if (isDuplicate) {
      notify.error("This product has already been selected.");
     handleRemoveProduct(index);
      return; 
    }
    
      console.log(selectedProduct,"selectedProduct");
      updatedOrderProducts[index] = {
        ...updatedOrderProducts[index],
        productId: selectedProduct.productId,
        productName: selectedProduct.productName,
        unitName: selectedProduct.unitName,
        unitId: selectedProduct.unitId,
        quantity: selectedProduct.quantity || 1, 
        priceTax: selectedProduct.priceTax,
        discount: selectedProduct.discount || 0,
        offerDiscount: selectedProduct.offerDiscount || 0,
        additionalDiscount: selectedProduct.additionalDiscount || 0,
        productUrl: selectedProduct.productUrl,
        productCode: selectedProduct.productCode,
        moq: selectedProduct.moq || 1,
        remark: selectedProduct.remark || "",
        unitPrice: selectedProduct.unitPrice || 0,
        tax:selectedProduct.tax || 0,
        total: calculateSubTotal(selectedProduct),
        taxcharges: selectedProduct.taxcharges || 0,
        
      };
      const updatedProductOptions = productOptions.filter(
        (product) => product.value.productId !== selectedProduct.productId
      );
      setFormData((prevState) => {
        const updatedFormData = {
          ...prevState,
          orderproducts: updatedOrderProducts,
        };
        console.log("Updated formData.orderproducts:", updatedFormData.orderproducts); 
        setProductsForOrder([...updatedOrderProducts]);
        return updatedFormData;
      });
      setProductOptions(updatedProductOptions);
      calculateTotal(updatedOrderProducts);
    }
  };
  const handleInputChangeProduct = (e, index) => {
    const { name, value } = e.target;
    const updatedOrderProducts = [...formData.orderproducts];

    if (name === "quantity") {
        updatedOrderProducts[index][name] = Number(parseFloat(value).toFixed(2))
    }
    if (name === "unitPrice") {
      updatedOrderProducts[index][name] = Number(parseFloat(value).toFixed(2));
    }
    if (name === "discount") {
      updatedOrderProducts[index][name] = Number(parseFloat(value).toFixed(2));
    }
    if (name === "tax") {
      updatedOrderProducts[index][name] = Number(parseFloat(value).toFixed(2));
    }
    if (name === "offerDiscount") {
      updatedOrderProducts[index][name] = Number(parseFloat(value).toFixed(2));
    }
    if (name === "additionalDiscount") {
      updatedOrderProducts[index][name] = Number(parseFloat(value).toFixed(2));
    }
    if (name === "remark") {
      updatedOrderProducts[index][name] = value;
    }
    updatedOrderProducts[index].total = calculateSubTotal(updatedOrderProducts[index]);
    setFormData({ ...formData, orderproducts: updatedOrderProducts });
    setProductsForOrder([...updatedOrderProducts]);
    calculateTotal(updatedOrderProducts);
  };
  const calculateSubTotal = (product) => {

    let price = product.unitPrice * product.quantity;
    if (product.discount) {
      const discount = (price * product.discount) / 100;
      price -= discount;
    }

    if (product.offerDiscount) {
      const offerDiscount = (price * product.offerDiscount) / 100;
      price -= offerDiscount;
    }


    if (product.additionalDiscount) {
      const additionalDiscount = (price * product.additionalDiscount) / 100;
      price -= additionalDiscount;
    }
    const taxcharges = product.tax ? Number(((price * product.tax) / 100).toFixed(2)) : 0;


    if (product.tax) {
      const taxAmount = (price * product.tax) / 100;
      price += taxAmount;

    }
    product.taxcharges = taxcharges;


    return Number(price.toFixed(2));
  };
  const calculateTotal = (orderproducts) => {
    const total = orderproducts.reduce((acc, product) => acc + (product.total || 0), 0);
    setFormData({ ...formData, total });
   
  };
  const handleRemoveProduct = (indexToRemove) => {
    const updatedOrderProducts = [...formData.orderproducts];
    updatedOrderProducts.splice(indexToRemove, 1);
    setFormData({ ...formData, orderproducts: updatedOrderProducts });
    setProductsForOrder([...updatedOrderProducts]);
    calculateTotal(updatedOrderProducts);
  };
  const [errors, setErrors] = useState({});
  useEffect(() => {
    getData();
    getCustomer();
  }, []);
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      orderproducts: [...productsForOrder],
    }));
  }, [productsForOrder]);
  const getCustomer = async () => {
    try {
      const response = await fetchData(`customer`);
      if (response?.data) {
        setCustomerOptions(
          response?.data?.map((i) => {
            return {
              label: `[${i?.code}] ${i?.cName}`,
              key: "customer",
              value: {
                id: i?.id,
                name: `[${i?.code}] ${i?.cName}`,
                address: `${i?.address} - ${i?.pincode}.`,
                contact: i?.addressContact,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getData = async () => {
    try {
      const response = await fetchData('transport');

      if (response?.data) {
        const formattedTransporterNames = response.data.map(i => ({
          label: i?.name,
          key: 'transport',
          value: i?.name, // Directly use the name as the value
        }));
        setTransportType(formattedTransporterNames);
      }

      if (response?.data) {
        setUnitOptions(
          response?.data
            .filter((i) => i?.typeCategory === "unit")
            .map((i) => {
              return {
                label: i?.typeName,
                key: "unit",
                value: { id: i?.systemtypeId, name: i?.typeName },
              };
            })
        );
      }

      const res = await fetchData('order');
      if (res?.data) {
        setOrderOption(
          res?.data?.map((i) => {
            return {
              label: `${i?.orderNo}`,
              key: "orderNo",
              value: i?.orderNo,
              id: i?.id,
            };
          })
        );
      }
      if (id || location.state.formData.id) {
        const response = await fetchsingleData("dispatchorder",id ?? location.state.formData.id);
        if (response?.data) {
          const fetchedData = response.data;
          const updatedOrderProducts = fetchedData.dispatchqty.map((product) => ({
            ...product,
           
          }));
          const orderOptions = [
            ...new Set(fetchedData.dispatchqty.map((product) => product.orderId)),
          ].map((orderNo) => ({ value: orderNo, label: `${orderNo}` }));
          const productOptions = fetchedData.dispatchqty.map((product) => ({
            value: product.productId,
            label: `${product.productName}`,
          }));
      
          setFormData({
            customerId: fetchedData.customerId,
            customerName: fetchedData?.customerName,
            date: dayjs(fetchedData.date),
            dispatchNo: fetchedData.dispatchNo,
            invoiceNo: fetchedData.invoiceNo,
            date: dayjs(fetchedData.date),
            lr_no: fetchedData.lr_no,
            lr_date: dayjs(fetchedData.lr_date),
            transport: fetchedData.transport,
            orderproducts: updatedOrderProducts,
            // orderproducts: fetchedData.orderproducts,
            dispatchCode: fetchedData.dispatchCode,
            invoiceDate: dayjs(fetchedData.invoiceDate),
            customerName: fetchedData?.customerName,
            customerAddress: fetchedData.customerAddress,
            customerContact: fetchedData.customerContact,
            total: fetchedData.total,
          }); // Update state with fetched data
          setProductsForOrder(updatedOrderProducts); 
          setOrderOptions(orderOptions);
          console.log("orderOptions", orderOptions);
          setProductOptions(productOptions);
          console.log("productOptions", productOptions);
        }
      }
      if(location.state.formData.id){
        const productOptions = (location.state.formData?.orderproducts??[]).map((product) => ({
          value: product.productId,
          label: `${product.productName}`,
        }));
        setProductOptions(productOptions);
      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleSubmit = (e) => {
    const currentDateTime = dayjs().format('DD-MM-YYYY,HH:mm:ss');
    e.preventDefault();
    if (validationCheck(true)) {
      try {
        if (id) {
          patchData(`order/${id}`, formData).then(data => {
            navigate(-1);
            console.log('API response:', data);
          }).catch(error => {
            console.error('Error fetching data:', error);
          });
        } else {
          postData('order', formData).then(data => {
            navigate(-1);
            console.log('API response:', data);
          }).catch(error => {
            console.error('Error fetching data:', error);
          });
        }
      } catch (error) {
        console.error('Error saving data:', error);
        // Handle error
      }
    }
    if (validationCheck(true) && productsForOrder?.length > 0) {
      try {
        if (id) {
          patchData(`order/${id}`, { ...formData, orderproducts: [...productsForOrder], orderupdatedby: name, orderupdate: currentDateTime })
            .then((data) => {
              navigate(-1);
              console.log("API response:", data);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else {

          postData("order", { ...formData, orderproducts: [...productsForOrder], ordercreatedby: name, })
            .then((data) => {
              navigate(-1);
              console.log("API response:", data);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      } catch (error) {
        console.error("Error saving data:", error);
        // Handle error
      }
    }
  };
  const saveDispatchOrder = async (e) => {
    e.preventDefault();
    // Transform orderproducts to only include orderId and dispatchqty
    const currentDateTime = dayjs().format('DD-MM-YYYY,HH:mm:ss');
    if (validationCheck()) {
      try {
        const transformedOrderProducts = formData.orderproducts;
        const requestBody = {
          ...formData,
          orderproducts: transformedOrderProducts,
          created_by: name, 
        };
        // if (requestBody?.totalAmount)
          delete requestBody.totalAmount;
        delete requestBody.activityType;
        delete requestBody.yetscreen;
        delete requestBody.cancellationDateTime;
        delete requestBody.city;
        delete requestBody.cityId;
        delete requestBody.countryId;
        delete requestBody.countryName;
        delete requestBody.createdBy_Id;
        delete requestBody.delivery_date;
        delete requestBody.discount;
        delete requestBody.orderDate;
        // delete requestBody.ordercreatedby;
        delete requestBody.ordernotification;
        delete requestBody.orderupdate;
        delete requestBody.orderupdatedby;
        delete requestBody.salesperson_id;
        delete requestBody.stateId;
        delete requestBody.stateName;
        delete requestBody.totalTax;
        delete requestBody.userId;
        delete requestBody.userName;
        delete requestBody.status;
        delete requestBody.id;
 if (id) {
          
  editDispatch(`dispatchorder/${id}`, requestBody)
            .then((data) => {
              if (data) {
                notify.success(`Dispatch No ${data?.data.dispatchNo} has been updated successfully!`);

                  navigate(-1);                      
               }
            })
            .catch((error) => {
              notify.error(error?.response?.data?.message);
              console.error("Error fetching data:", error);
            });
        } else {
        await postData("dispatchorder", requestBody)
          .then((data) => {
            if(data){
        notify.success(`Dispatch No ${data?.data.dispatchNo} has been saved successfully!`);
            
            navigate(-1);
            console.log("API response:", data);
            console.log("Request Body:", requestBody);

         }else{
          notify.error(data?.message);
          console.log("API response:", data?.message);
         }})
          .catch((error) => {
            notify.error(error?.response.data.message);
          });
      } }catch (error) {
        // notify.error(data?.message);

        console.error("Error saving data:", error);
      }
    }
  };
  const selectChanged = async (selectedOption) => {
    if (selectedOption.key === "customer") {
      setFormData((prevState) => ({
        ...prevState,
        customerAddress: selectedOption.value?.address,
        customerContact: selectedOption.value?.contact,
        customerId: selectedOption.value?.id,
        customerName: selectedOption.value?.name,
        
      }));
      
    }
    if (errors.isSubmit) validationCheck();
    if (selectedOption.key === "transport") {
      setFormData((prevState) => ({
        ...prevState,
        transport: selectedOption.value,
      }));
  }
  };
  const toggleSwitch = (checked) => {
    setFormData({ ...formData, status: checked });
  };
  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
    if (errors.isSubmit) validationCheck();
  };
  const handleDateChange = (date) => {
    setFormData(prevState => ({
      ...prevState,
      date
    }));
    if (errors.isSubmit) validationCheck();
  };
  const handleInvoiceDateChange = (invoiceDate) => {
    setFormData(prevState => ({
      ...prevState,
      invoiceDate
    }));
    if (errors.isSubmit) validationCheck();
  };
  const handleLrDateChange = (lr_date) => {
    setFormData(prevState => ({
      ...prevState,
      lr_date
    }));
  };

  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!formData.customerId) {
      validationErrors.customerId = "Please select customer";
    }
    if(!formData.dispatchCode) {
      validationErrors.dispatchCode = "Please select DispatchCode"
    }
    if(!formData.invoiceNo) {
      validationErrors.invoiceNo = " Please select Invoice No"
    }
    if (!formData.date) {
      validationErrors.date = "Please select Date";
    }

    if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

    setErrors(validationErrors);
    return Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0;
  };
  const styles = {
    pageHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      backgroundColor: '#f0f0f0',
      borderBottom: '1px solid #d0d0d0',
      marginBottom: '20px',
    },
    formContainer: {
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)', // Updated to 4 columns
      gap: '20px',
      marginBottom: '20px',
    },
    inputWrapper: {
      marginBottom: '20px',
    },
    buttonAlignment: {
      display: 'flex',
      justifyContent: 'space-between',
    }
  };
 
  const [orderOptions, setOrderOptions] = useState([]);
  useEffect(() => {
    if (formData.customerId) {
      fetchOrdersForCustomer(formData.customerId);
    } else {
      setOrderOptions([]);
    }
  }, [formData.customerId]);

  const fetchOrdersForCustomer = async (customerId) => {
    try {
      const response = await fetchData("order");
      if (response?.data) {
        const filteredOrders = response.data
          .filter(
            (order) =>
              order.customerId === customerId &&
              (order.orderStatus === "Open" || order.orderStatus === "Pending")
          )
          .map((order) => ({
            label: `${order.orderNo}`,
            key: "orderNo",
            value: order.id,
        
          }));

        setOrderOptions(filteredOrders); 
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  const handleOrderNoChange = async (index, selectedOption) => {
    const selectedOrderId = selectedOption.value; 
    const orderId = selectedOption.value;
    setFormData((prev) => ({
      ...prev,
      orderproducts: prev.orderproducts.map((op, idx) =>
        idx === index ? { ...op, orderId, orderNo: selectedOption.label } : op
      ),
    }));
    if (selectedOrderId) {
      try {
        const response = await fetchsingleData("order", selectedOrderId); 
        if (response?.data) {
          const fetchedData = response.data;
          const filteredProducts = fetchedData.orderproducts
            .filter((product) => product.productStatus === "dispatchPending")
            .map((product) => ({
              label: `[${product.productCode}] ${product.productName}`,
              key: "product",
              value: {
              productId: product.id,
              productName: product.productName,
              unitId: product.unitId,
              unitName: product.unitName,
              tax: product.tax,
              quantity: Math.abs(Number(product.quantity) - Number(product.dispatchqty)),
              discount: Number(parseFloat(product.discount)),
              offerDiscount: Number(product.offerDiscount) || 0,
              additionalDiscount: Number(product.additionalDiscount) || 0,
              productUrl: product.productUrl,
              productCode: product.productCode,
              orderNo: fetchedData.orderNo, 
              moq: product.moq || 1, 
              priceTax: product.priceTax, 
              remark: product.remark || "", 
              unitPrice:
                product.priceTax === "Inclusive"
                  ? Number(
                      (
                        product.unitPrice +
                        product.unitPrice * (Number(product.tax) / 100)
                      ).toFixed(2)
                    )
                  : Number(parseFloat(product.unitPrice).toFixed(2)),
              },
            }));
          const updatedOrderProducts = [...formData.orderproducts];
          updatedOrderProducts[index].orderNo = fetchedData.orderNo;
          updatedOrderProducts[index].orderId = fetchedData.id;
          updatedOrderProducts[index].productId = null; 
          updatedOrderProducts[index].productName = ""; 
          // setFormData({ ...formData, orderproducts: updatedOrderProducts });
          const productValues = filteredProducts.map((product) => product.value);
          setProductOptions(filteredProducts);
          // setProductsForOrder(productValues); 
    
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
      }
    }

  };
 
  
  
  

  return (
    <div>
      <div className='page-header add-news-alignment'>
        <strong><span>Dispatch {`>`} Create</span></strong>
        <div className='two-button-alignment'>
          <div onClick={saveDispatchOrder}>
            <GlobalButton text={id ? 'Update' : 'Save'} />
          </div>
          <Link to={`/adddispatchorder`}>
            <Button text="Discard" outline />
          </Link>
        </div>
      </div>
      <div style={styles.formContainer}>
        {/* Grid container for four fields in a row */}
       
        <div style={styles.grid}>
        {
                            id && (
          <div style={styles.inputWrapper}>
            <Input
              label='Dispatch No'
              name='dispatchNo'
              value={formData.dispatchNo}
              onChange={handleInputChange}
              placeholder='Dispatch No'
              type='text'
              disabled
              errors={errors.dispatchNo}
              required
            />
          </div>
                            )}
          <div className="bottom-alignment select-change-design-change">
            <SelectBox
              label="Customer Name"
              styles={customStyles}
              name="customerName"
              value={customers?.find(option => option.value.id === formData.customerId)}
              options={customers}
              onChange={selectChanged}
              placeholder="Customer Name"
              type="text"
              required
              errors={errors.customerId}
            />
          </div>
          <div style={styles.inputWrapper}>
            <SelectBox
              label='Transport'
              name='transport'
              value={transportType?.find(i => i.value === formData.transport)}
              onChange={selectChanged}
              options={transportType}
              placeholder='Transport Type'
              errors={errors.transport}
              required
            />
          </div>
        </div>
    

        {/* Second grid row */}
        <div style={styles.grid}>
          <div style={styles.inputWrapper}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label={
                  <span>
                     Date<span className="required-star">*</span>
                  </span>}
                name='date'
                value={formData.date}
                onChange={handleDateChange}
                placeholder='Dispatch Date'
                errors={errors.date}
              />
            </LocalizationProvider>
          </div>
          <div style={styles.inputWrapper}>
            <Input
            label={
              <span>
                Dispatch Code  <span className="required-star">*</span>
              </span>
            }
              name='dispatchCode'
              value={formData.dispatchCode}
              onChange={handleInputChange}
              placeholder='Dispatch Code'
              type='text'
              errors={errors.dispatchCode}
              required
            />
          </div>
          <div style={styles.inputWrapper}>
            <Input
            label={
              <span>
                 Invoice No<span className="required-star">*</span>
              </span>}
              name='invoiceNo'
              value={formData.invoiceNo}
              onChange={handleInputChange}
              placeholder='Invoice No'
              type='text'
              errors={errors.invoiceNo}
              required
            />
          </div>
          <div style={styles.inputWrapper}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Invoice Date'
                name='invoiceDate'
                value={formData.invoiceDate}
                onChange={handleInvoiceDateChange}
                placeholder='Invoice Date'
              />
            </LocalizationProvider>
          </div>
        </div>

        <div className="col-md-4">
          <legend><span>Billing Details</span></legend>
          <div className="shipping_address">
            <span className="ng-binding">{formData?.customerAddress}</span>
            <span ng-if="dispatch.billing_contact_no || dispatch.billing_email" className="ng-binding ng-scope">
              {formData?.customerContact}
            </span>
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <legend><span>LR Details</span></legend>
          <div style={styles.grid}>
            <div style={styles.inputWrapper}>
              <Input
                label='LR No.'
                name='lr_no'
                value={formData.lr_no}
                onChange={handleInputChange}
                placeholder='LR No.'
                type='text'
                errors={errors.lr_no}
                required
              />
            </div>
            <div style={styles.inputWrapper}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label='LR Date'
                  name='lr_date'
                  value={formData.lr_date}
                  onChange={handleLrDateChange}
                  placeholder='Lr Date'
                />
              </LocalizationProvider>
            </div>
          </div>
          <legend><span>Product Details</span></legend>
          <div className="add-news-form-design">
            {/* <div className="product-details-header-alignment">
              <h2 style={{
                zIndex: 1111,
              }}>Product Details</h2>

            </div> */}
            <div className="order-table" style={{
              zIndex: -1,
              position: '',
              top: 'auto',
              //bottom: '100%',
            }}>
              <div className="table-design"
                style={{ overflow: "unset" }}
              >
                  <table>
        <thead>
          <tr style={{ border: '1px solid #eee' }}>
            <th className="width-80" style={{ border: 'none', background: '#f5f5f5' }}>Order No</th>
            <th className="width-100" style={{ zIndex: -1, border: 'none', background: '#f5f5f5' }}>Product</th>
            <th className="width-80" style={{ border: 'none', background: '#f5f5f5' }}>Unit</th>
            <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Quantity</th>
            <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Price (&#8377;)</th>
            <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Discount</th>
            <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Tax</th>
            <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Sub Total (&#8377;)</th>
            <th className="width-10" style={{ border: 'none', background: '#f5f5f5' }}></th>
          </tr>
        </thead>
                  <tbody>
                    {formData?.orderproducts?.map((i, INDEX) => {
                      const availableProductOptions = productionOptionsList(i?.orderId);
                      return (
                        <tr key={INDEX}>
                          <td className="width-auto">
                            <SelectBox
                              name="orderNo"
                              value={orderOptions.find(order=>i?.orderId=== order.value)}
                              options={orderOptions}
                              onChange={(e) => handleOrderNoChange(INDEX, e)} 
                              placeholder="Select Order No"
                              type="text"
                              styles={{
                                overflow: "initial",
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 99999,
                                  position: 'absolute',
                                }),
                                control: (provided) => ({
                                  ...provided,
                                  width: '100%',
                                }),
                              }}
                            />
                          </td>

                          <td className="width-auto">
                            
                            <SelectBox
                              name="product"
                              value={availableProductOptions.find((option) => option.value === i.productId)}
                              options={availableProductOptions}
                              onChange={(e) => productSelectChange(INDEX, e, "p")}
                              placeholder="Product Name"
                              type="text"
                              styles={{
                                overflow: "initial",
                                menu: (provided) => ({
                                  ...provided,
                                  zIndex: 99999,
                                  position: 'absolute'
                                }),
                                control: (provided) => ({
                                  ...provided,
                                  width: '100%'
                                })
                              }}
                            />
                            {company_setting?.extra_setting?.remarksInOrderProduct === "Yes" && (
                              <TextArea
                                name="remark"
                                value={i?.value?.remark}
                                onChange={(e) => handleInputChangeProduct(e, INDEX)}
                                placeholder="Remark"
                                type="text"
                                rows={2}
                                className="height-set mt-1"
                              ></TextArea>
                            )}
                          </td>

                          <td className="width-auto" style={{ textOverflow: 'ellipsis' }}>
                            <InputCustom
                              name="unit"
                              value={i?.unitName}
                              onChange={(e) => handleInputChangeProduct(e, INDEX)}
                              type="text"
                              readOnly={true}
                            />
                          </td>

                          <td className="whitespace-nowrap width-auto">
                            <InputCustom
                              name="quantity"
                              value={i?.quantity}
                              onChange={(e) => handleInputChangeProduct(e, INDEX)}
                              type="number"
                            />
                          </td>

                          <td className="width-auto">
                            <InputCustom
                              name="unitPrice"
                              value={i?.unitPrice}
                              onChange={(e) => handleInputChangeProduct(e, INDEX)}
                              type="number"
                              disabled={!checkAccess("edit_price")}
                            />
                            <div>{i?.priceTax}</div>
                          </td>

                          <td className="width-auto">
                            <InputCustom
                              name="discount"
                              value={i?.discount}
                              onChange={(e) => handleInputChangeProduct(e, INDEX)}
                              type="number"
                              disabled={!checkAccess("edit_discount")}
                            />
                            <div>
                              <label className="discount-label">Offer Discount</label>
                              <InputCustom
                                name="offerDiscount"
                                value={i?.offerDiscount}
                                onChange={(e) => handleInputChangeProduct(e, INDEX)}
                                type="number"
                                disabled={!checkAccess("edit_discount")}
                              />
                              <div className="base-price-text">(On Base Price)</div>
                            </div>
                            <div>
                              <label className="discount-label">Additional Discount</label>
                              <InputCustom
                                name="additionalDiscount"
                                value={i?.additionalDiscount}
                                onChange={(e) => handleInputChangeProduct(e, INDEX)}
                                type="number"
                                disabled={!checkAccess("edit_discount")}
                              />
                              <div className="base-price-text">(On Base Price)</div>
                            </div>
                          </td>

                          <td className="width-auto">
                            <InputCustom
                              name="tax"
                              value={i?.tax}
                              onChange={(e) => handleInputChangeProduct(e, INDEX)}
                              type="number"
                              disabled={!checkAccess("edit_tax")}
                            />
                          </td>

                          <td className="width-auto">
                            <InputCustom
                              name="total"
                              value={i?.total}
                              readOnly={true}
                              type="number"
                            />
                            {i.taxcharges > 0 && (
                              <div style={{ fontSize: "12px", color: "#888" }}>
                                Tax Applied: ₹{i.taxcharges} ({i.tax}%)
                              </div>
                            )}
                          </td>

                          <th className="width-auto vertical-center">
                            <div
                              onClick={() => handleRemoveProduct(INDEX)}
                              style={{
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </div>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
      </table>
              </div>
               
                  <Button onClick={handleAddProductClick} style={{marginTop:'15px'}} text="Add Product" />
              
              {/* <div> <table>
                <tr>
                  <th className="width-50">Sub Total</th>
                  <th></th>
                  <th className="width-50">Total</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Megha</td>
                  <td>19</td>
                  <td>Female</td>
                </tr>
                <tr>
                  <td>Subham</td>
                  <td>25</td>
                  <td>Male</td>
                </tr>
              </table></div> */}
              <div className="rounded-circl"> <table className="datatable_main alignTop rounded-circl " style={{
                marginLeft: '70%',
                maxWidth: '30%',
                background: '#f5f5f5',
                border: '2px #eee'
              }}>
                <tbody>

                  <tr></tr>
                  <tr>
                    <td style={{ textAlign: 'left', width: '1%', marginRight: '10px', padding: '5px' }}><b>Total</b></td>
                    <td></td>
                    <td style={{ textAlign: 'right', padding: '5px' }}><b> <i className="fa fa-inr"></i>{formData.total}</b></td>
                  </tr>
                </tbody>
              </table></div>
            </div>
          </div>
        </div>

      </div>
    </div>

  );
}