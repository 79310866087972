import React, { useEffect, useState } from "react";
import "./activeOrder.scss";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { fetchData } from "../../../services/apiService";
import ProgressBar from "react-bootstrap/ProgressBar";
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "../../../shared/components/pagination";

export default function ActiveOrder() {
  const [orderlist, setOrderList] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredPieData, setFilteredPieData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  
  const [tableFilter, setTableFilter] = useState("week");
  const [chartFilter, setChartFilter] = useState("week"); 

  const pageSize = 6; 

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    applyTableFilter();
  }, [orderlist, tableFilter]);

  useEffect(() => {
    applyChartFilter();
  }, [orderlist, chartFilter]);

  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
  }));

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }

  const getData = async () => {
    try {
      const response = await fetchData("order");
      if (response?.data) {
        setOrderList(response?.data);
        applyTableFilter(response?.data, "week"); 
        applyChartFilter(response?.data, "week"); 
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const applyTableFilter = (data = orderlist, filter = tableFilter) => {
    const now = new Date();
    let filteredData = data;

    if (filter !== "all") {
      filteredData = data.filter((order) => {
        const orderDate = new Date(order.orderDate);
        const diffInTime = now - orderDate;

        if (filter === "week") {
          return diffInTime <= 7 * 24 * 60 * 60 * 1000;
        } else if (filter === "month") {
          return now.getMonth() === orderDate.getMonth() && now.getFullYear() === orderDate.getFullYear();
        } else if (filter === "year") {
          return now.getFullYear() === orderDate.getFullYear();
        }
        return true;
      });
    }

    setFilteredOrders(filteredData);
    setCurrentPage(1); 
  };

  const applyChartFilter = (data = orderlist, filter = chartFilter) => {
    const now = new Date();
    let filteredData = data;

    if (filter !== "all") {
      filteredData = data.filter((order) => {
        const orderDate = new Date(order.orderDate);
        const diffInTime = now - orderDate;

        if (filter === "week") {
          return diffInTime <= 7 * 24 * 60 * 60 * 1000; 
        } else if (filter === "month") {
          return now.getMonth() === orderDate.getMonth() && now.getFullYear() === orderDate.getFullYear();
        } else if (filter === "year") {
          return now.getFullYear() === orderDate.getFullYear();
        }
        return true;
      });
    }

    const pendingOrders = filteredData.filter((order) => order.orderStatus === "Pending").length;
    const openOrders = filteredData.filter((order) => order.orderStatus === "Open").length;
    const cancelOrders = filteredData.filter((order) => order.orderStatus === "Cancelled").length;
    const closeOrders = filteredData.filter((order) => order.orderStatus === "Close").length;

    setFilteredPieData([
      { value: pendingOrders, label: "Pending Orders", color: "#C00900" },
      { value: openOrders, label: "Open Orders", color: "#1477e7" },
      { value: cancelOrders, label: "Cancelled Orders", color: "black" },
      { value: closeOrders, label: "Closed Orders", color: "gray" },
    ]);
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const calculateProgress = (order) => {
    if (order.orderStatus === "Pending") return 0;
    if (order.orderStatus === "Close") return 100;

    if (order.orderStatus === "Open" && order.productDetails?.length > 0) {
      const totalProducts = order.productDetails.length;
      const dispatchedCount = order.productDetails.filter(
        (product) => product.productStatus === "Dispatched"
      ).length;

      return Math.round((dispatchedCount / totalProducts) * 100);
    }

    return 0;
  };

  const paginateOrders = () => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredOrders.slice(startIndex, endIndex);
  };

  const totalorder = filteredPieData.reduce((acc, item) => acc + item.value, 0);

  return (
    <div className="active-order-grid">
      <div className="active-order-grid-items">
        <div className="card-header-alignment">
          <h2>Order List</h2>
          <div className="button-group-alignment">
            <button
              onClick={() => setTableFilter("week")}
              className={tableFilter === "week" ? "active-button" : ""}
            >
              Week
            </button>
            <button
              onClick={() => setTableFilter("month")}
              className={tableFilter === "month" ? "active-button" : ""}
            >
              Month
            </button>
            <button
              onClick={() => setTableFilter("year")}
              className={tableFilter === "year" ? "active-button" : ""}
            >
              Year
            </button>
            <button
              onClick={() => setTableFilter("all")}
              className={tableFilter === "all" ? "active-button" : ""}
            >
              All
            </button>
          </div>
        </div>
        <div className="table-design-change">
          <table>
            <thead>
              <tr>
                <th>Order No</th>
                <th>Customer Name</th>
                <th>Order Date</th>
                <th>Progress</th>
                <th>Total Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {paginateOrders().map((order, index) => {
                const progress = calculateProgress(order);
                return (
                  <tr key={index}>
                    <td>
                      <span>{order?.orderNo}</span>
                    </td>
                    <td>
                      <span>{order?.customerName}</span>
                    </td>
                    <td>
                      <span>{formatDate(order?.orderDate)}</span>
                    </td>
                    <td>
                      <ProgressBar now={progress} label={`${progress}%`} />
                    </td>
                    <td>
                      <span>₹{order?.totalAmount}</span>
                    </td>
                    <td>
                      <button
                        style={{
                          backgroundColor: order?.orderStatus === "Pending" ? "#C00900" :
                            order?.orderStatus === "Open" ? "#0D99FF" :
                              order?.orderStatus === "Completed" ? "green" : "gray",
                          color: "white",
                          padding: "5px 10px",
                          border: "none",
                          borderRadius: "5px",
                        }}
                      >
                        {order?.orderStatus}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          totalItems={filteredOrders.length}
          pageSize={pageSize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
      <div className="active-order-grid-items">
        <div className="card-header-alignment">
          <h2>Order Overview</h2>
          <div className="button-group-alignment">
            <button
              onClick={() => setChartFilter("week")}
              className={chartFilter === "week" ? "active-button" : ""}
            >
              Week
            </button>
            <button
              onClick={() => setChartFilter("month")}
              className={chartFilter === "month" ? "active-button" : ""}
            >
              Month
            </button>
            <button
              onClick={() => setChartFilter("year")}
              className={chartFilter === "year" ? "active-button" : ""}
            >
              Year
            </button>
            <button
              onClick={() => setChartFilter("all")}
              className={chartFilter === "all" ? "active-button" : ""}
            >
              All
            </button>
          </div>
        </div>
        <div className="chart-details">
          <div className="chart">
            <PieChart
              series={[
                {
                  data: filteredPieData,
                  innerRadius: 90,
                  label: true,
                  labelPosition: "outside",
                },
              ]}
              width={400}
              height={335}
            >
              <PieCenterLabel>{totalorder}</PieCenterLabel>
            </PieChart>
          </div>
          <div className='sub-grid-items'>
            <div className='icon-text-grid'>
              <div className='squre-icon pending'></div>
              <span>Pending Order</span>
            </div>
            <div className='icon-text-grid'>
              <div className='squre-icon open'></div>
              <span>Open Order</span>
            </div>
            <div className='icon-text-grid'>
              <div className='squre-icon close'></div>
              <span>Close Order</span>
            </div>
            <div className='icon-text-grid'>
              <div className='squre-icon cancel'></div>
              <span>Cancel Order</span>
            </div>
      
          </div>
        </div>
      </div>
    </div>
  );
}
