import React, { useState } from 'react'
import Button from '../../shared/components/button';
import './statetype.scss';
import { Link } from 'react-router-dom';
import StatetypeTable from './statetypetable';
import { deleteData, filterAll } from '../../services/apiService';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import AccountTableHeader from '../account/accountTableHeader';


export default function Statetype() {

  const [selectedIds, setSelectedIds] = useState([]);
  const [StateList, SetStateList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`state/${id}`)));
      SetStateList(StateList.filter(i => !selectedIds.includes(i?.id)));

      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);

  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleExport = () => {
    const headers = [
      ["Country Name", "State Name", "State Short Name", "Status"]

    ];

    filterAll("state")
      .then((data) => {
        const datas = (data.data ?? [])?.map((item) => [
          item.countryName,
          item.stateName,
          item.stateShortname,
          "Active"
        ]);
        exportToExcel('State', headers, datas, 'state')
      })
  };


  return (
    <div className='page-24'>
      {/* <div className='page-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>State Master </span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
          {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
          <Link to="addstatetype"><Button text='Add State'/></Link>
        </div>
      </div> */}
      <div className="">
        <div className="customer-header-alignment pb-4">
          <div>
            <h2>State </h2>
            <Breadcumbs activePath="State" pageName="State" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
              <Popconfirm
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                title="Are you sure to delete this record? "
                description="This will lead to delete all records related to this record only!"
                open={open}
                onConfirm={handleOk}
                okButtonProps={{ loading: confirmLoading }}
                onCancel={handleCancel}
              >
                <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
              </Popconfirm>
            )}
            <Link to="addstatetype">
              <GlobalButton text="Add State" Icon={PlusIcon} />
            </Link>
            <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />
          </div>
        </div>
      </div>

      <div className=''>
        <StatetypeTable
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          StateList={StateList}
          setProductList={SetStateList}
          totalItems={totalItems}
          setTotalItems={setTotalItems}
        />
      </div>
    </div>
  )
}
