import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

export default function PieareaComponent({ customerList }) {
    const [chartData, setChartData] = useState([]);
    const [visibleData, setVisibleData] = useState([]);
    const [dataType, setDataType] = useState("order");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        formatChartData(dataType);
    }, [dataType, customerList]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = Math.min(startIndex + itemsPerPage, chartData.length);
        setVisibleData(chartData.slice(startIndex, endIndex));
    }, [chartData, currentPage]);

    const formatChartData = (type) => {
        const fieldMapping = {
            order: "orders",
            SubAmount: "totalAmount",
            amount: "totalAmount",
            base_quantity: "totalQuantity",
            quantity: "totalQuantity",
            product: "totalProducts",
        };

        const selectedField = fieldMapping[type];
        const formattedData =
            customerList?.map((item) => ({
                name: item?.stateName || "Unknown Area",
                y: item[selectedField] || 0,
            })) || [];

        setChartData(formattedData);
        setCurrentPage(1); // Reset to the first page
    };

    const handleChange = (event) => {
        setDataType(event.target.value);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(chartData.length / itemsPerPage)));
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const options = {
        chart: {
            type: "pie",
        },
        title: {
            text: `Customer Data by ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`,
        },
        tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: {point.percentage:.1f}%",
                    style: {
                        fontSize: "12px",
                        fontFamily: "Verdana, sans-serif",
                    },
                },
            },
        },
        series: [
            {
                name: `${dataType.charAt(0).toUpperCase() + dataType.slice(1)} Count`,
                colorByPoint: true,
                data: visibleData,
            },
        ],
    };

    const totalPages = Math.ceil(chartData.length / itemsPerPage);
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(currentPage * itemsPerPage, chartData.length);

    return (
        <Box p={4}>
            <FormControl fullWidth margin="normal">
                <InputLabel id="data-type-label">Select Data Type</InputLabel>
                <Select
                    labelId="data-type-label"
                    value={dataType}
                    onChange={handleChange}
                >
                    <MenuItem value="order">Order</MenuItem>
                    <MenuItem value="SubAmount">SubAmount</MenuItem>
                    <MenuItem value="amount">Amount</MenuItem>
                    <MenuItem value="base_quantity">Base Quantity</MenuItem>
                    <MenuItem value="quantity">Quantity</MenuItem>
                    <MenuItem value="product">Product</MenuItem>
                </Select>
            </FormControl>
            <>
                <HighchartsReact
                    key={dataType}
                    highcharts={Highcharts}
                    options={options}
                />
                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <Typography>
                        Showing {rangeStart} - {rangeEnd} of {chartData.length}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </Box>
            </>
        </Box>
    );
}
