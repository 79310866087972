import React, { useState } from "react";
import "./signUp.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom';
import { message } from "antd"; 
import { fetchData, postData, fetchsingleData } from "../../services/apiService";
import { useDispatch } from "react-redux";
import { login as loginAction, setUsername } from '../../redux/auth';
import { notify } from '../toastr/Toastr';


export default function SignUp() {
    const [recaptchaVerified, setRecaptchaVerified] = useState(false);
    const dispatch = useDispatch();
    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const [isInactive, setIsInactive] = useState(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        companyName: "",
        contactNo: "",
        salesUsers: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    const [errors, setErrors] = useState({
        passwordMismatch: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === "password" || name === "confirmPassword") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordMismatch: false,
            }));
        }
    };




    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (formData.password !== formData.confirmPassword) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordMismatch: true,
            }));
            message.error("Passwords do not match!");
            return;
        }
    
        try {
            const data = await postData("auth/signup", formData);
            console.log("data31232131", data);

            message.success("Sign-up successful");
            const formatDate = (dateString) => {
                const date = new Date(dateString);
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0"); 
                const year = date.getFullYear();
                return `${day}--${month}--${year}`;
              };


            const expiryDate = formatDate(data?.subscriptionData?.expiresat);
            const currentDate = formatDate(data?.subscriptionData?.createdat);
            console.log(currentDate,"currentDate");
            console.log(currentDate > expiryDate,"currentDate > expiryDate");
            
            if (data?.subscriptionData?.subscriptiontype === "Free Trial" && currentDate > expiryDate) {
                message.warning("Your free trial has expired. Please subscribe to access all features.");
            } else {
                loginNow(formData?.email, formData?.password);
            }

        } catch (error) {
            console.error("Error during signup:", error);
            message.error(
                error.response?.data?.message || "An error occurred during signup."
            );
        }
    };
    

    const loginNow = async (username, password) => {
      
        const user = {
            email: username,
            password: password,
        };
    
        try {
            const data = await postData('auth/login', user);
            // console.log("datajhgdjhgcds", data);
    

            dispatch(loginAction(data));
    
            const response = await fetchsingleData("userrole", data?.user?.roleId);
            // console.log(response,"data.user.roleId");
            
            // const company_setting = await fetchsingleData("company_setting", data?.user?.orgId);
    
            dispatch(
                loginAction({
                    ...data,
                    feature: response.data.features,
                    // company_setting: company_setting?.data,
                })
            );
    

            const formatDate = (dateString) => {
                const date = new Date(dateString);
                const day = String(date.getDate()).padStart(2, "0");
                const month = String(date.getMonth() + 1).padStart(2, "0"); 
                const year = date.getFullYear();
                return `${day}--${month}--${year}`;
              };


            const expiryDate = formatDate(data?.subscriptionData?.expiresat);
            const currentDate = formatDate(data?.subscriptionData?.createdat);

    
            if (data?.subscriptionData?.subscriptiontype === "Free Trial" && currentDate > expiryDate) {
                notify.warning('Your free trial has expired. Please subscribe to access all features.');
                navigate('/subscription'); 
            }  else {
                notify.warning('Your account is inactive. Please activate your subscription.');
                navigate('/subscription'); 
            }
            
        } catch (error) {
            console.error('Error fetching data:', error);
            message.error('Please Enter Correct username and Password');
        }
    };
    
    
    const handleRecaptchaChange = (value) => {
        if (value) {
            setRecaptchaVerified(true);
        } else {
            setRecaptchaVerified(false);
        }
    };

    return (
        <div className="signup-container">
            <main className="main-section">
                <div className="welcome-section">
                    <span className="logaName">
                        <i className="fas fa-chevron-circle-left speace"></i> SALESEASY
                    </span>
                </div>

                <div className="middle-section">
                    <div className="devices-section">
                        <p>
                            WELCOME TO <span>SALESEASY</span>
                        </p>
                    </div>

                    <div className="devices-middle">
                        <div className="image"></div>
                    </div>

                    <div className="devices-end">
                        <p>
                            Powered by <span>Synoris Software</span>
                        </p>
                    </div>
                </div>

                <div className="signup-section">
                    <h2>SIGN UP</h2>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Your Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="companyName"
                            placeholder="Company Name"
                            value={formData.companyName}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="text"
                            name="contactNo"
                            placeholder="contact No"
                            value={formData.contactNo}
                            onChange={handleChange}
                            required
                        />
                        <select
                            name="salesUsers"
                            value={formData.salesUsers}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select No. of Users</option>
                            <option value="lessThan10">Less than 10</option>
                            <option value="10-25">10-25</option>
                            <option value="25-50">25-50</option>
                            <option value="50-100">50-100</option>
                            <option value="above100">Above 100</option>
                        </select>
                        <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                        <input
                            type="password"
                            name="confirmPassword"
                            placeholder="Re-enter Password"
                            value={formData.confirmPassword}
                            onChange={handleChange}
                            required
                        />
                        {errors.passwordMismatch && (
                            <p className="error-text">Passwords do not match!</p>
                        )}
                        <ReCAPTCHA
                            sitekey="6Ld7DacqAAAAAEa7hWcJW4U_Xuw0VcolWNPKBzw4" // Use your public key here
                            onChange={handleRecaptchaChange}
                        />
                        <div className="checkbox-section">
                            <input
                                className="checkbox"
                                type="checkbox"
                                id="terms"
                                required
                            />
                            <label htmlFor="terms">
                                I have read and agree to the{" "}
                                Terms & Conditions and Privacy Policy.
                            </label>
                        </div>
                        <button
                            type="submit"
                            className="signup-btn"
                            disabled={isInactive}
                        >
                            {isInactive ? "User Inactive" : "Sign up"}
                        </button>
                    </form>
                </div>
            </main>
        </div>
    );
}
