import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
    Box,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Button,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default function PaymentBarAreaComponent({ customerList }) {
    const [chartData, setChartData] = useState([]);
    const [visibleData, setVisibleData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataType, setDataType] = useState("order"); // Default data type
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        if (customerList.length) {
            setLoading(false);
            processData(dataType);
        }
    }, [dataType, customerList]);

    useEffect(() => {
        const start = (currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        setVisibleData(chartData.slice(start, end));
    }, [chartData, currentPage]);

    const processData = (type) => {
        const fieldMapping = {
            order: "orders",
            SubAmount: "totalAmount",
            amount: "totalAmount",
            base_quantity: "totalQuantity",
            quantity: "totalQuantity",
            product: "totalProducts",
        };

        const selectedField = fieldMapping[type];

        const formattedData = customerList.map((item) => [
             item?.stateName || "Unknown Customer",
            item[selectedField] || 0,
        ]);

        setChartData(formattedData);
        setCurrentPage(1);
    };

    const handleNextPage = () => setCurrentPage((prevPage) => prevPage + 1);
    const handlePrevPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));

    // Define capitalizeFirstLetter as a function declaration
    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const options = {
        chart: {
            type: "column",
        },
        title: {
            text: `Customer Data by ${capitalizeFirstLetter(dataType)}`,
        },
        xAxis: {
            type: "category",
            labels: {
                autoRotation: [-45, -90],
                style: {
                    fontSize: "13px",
                    fontFamily: "Verdana, sans-serif",
                },
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: `${capitalizeFirstLetter(dataType)} Count`,
            },
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            pointFormat: `${capitalizeFirstLetter(dataType)}: <b>{point.y}</b>`,
        },
        series: [
            {
                name: capitalizeFirstLetter(dataType),
                color: "#1976d2",
                groupPadding: 0,
                data: visibleData,
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: "#FFFFFF",
                    inside: true,
                    verticalAlign: "top",
                    format: "{point.y}",
                    y: 10,
                    style: {
                        fontSize: "13px",
                        fontFamily: "Verdana, sans-serif",
                    },
                },
            },
        ],
    };

    return (
        <Box p={4}>
            <FormControl fullWidth margin="normal">
                <InputLabel id="data-type-label">Select Data Type</InputLabel>
                <Select
                    labelId="data-type-label"
                    value={dataType}
                    onChange={(e) => setDataType(e.target.value)}
                >
                    <MenuItem value="order">Order</MenuItem>
                    <MenuItem value="SubAmount">SubAmount</MenuItem>
                    <MenuItem value="amount">Amount</MenuItem>
                    <MenuItem value="base_quantity">Base Quantity</MenuItem>
                    <MenuItem value="quantity">Quantity</MenuItem>
                    <MenuItem value="product">Product</MenuItem>
                </Select>
            </FormControl>

            {loading ? (
                <Typography variant="body1" textAlign="center">
                    Loading chart...
                </Typography>
            ) : (
                <>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                    <Box mt={2}>
                        <Typography variant="body2" textAlign="center">
                            Showing {((currentPage - 1) * itemsPerPage) + 1} -{" "}
                            {Math.min(currentPage * itemsPerPage, chartData.length)} of {chartData.length}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                            sx={{ marginRight: "10px" }}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNextPage}
                            disabled={currentPage * itemsPerPage >= chartData.length}
                        >
                            Next
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
}
