import React from "react";
import "./Modal.scss";
import GlobalButton from "../../globalButton";
import CancelIcon from '../../../assets/icons/Cancel.svg';

const Modal = ({ title, isOpen, onClose, children, onSave }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <div className="heading">
                    <div className="modal-header">
                        <h3>{title}</h3>
                    
                    </div>
                    <div className="right-contnet-alignment">
                           <button className='light-button' onClick={onClose}>
                            <img src={CancelIcon} alt="CancelIcon" />
                            Discard
                        </button>
                       
                            <button onClick={onSave}>
                                save
                            </button>
                       
                    </div>
                </div>
                <div className="modal-body">{children}</div>
            </div>
        </div>
    );
};

export default Modal;
