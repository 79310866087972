import {
    Box,
    Typography,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Select,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
} from "@mui/material";
import BarComponent from "./BarComponent";
import SelectBox from '../../../shared/components/select';
import PieComponent from "./PieComponentt";
import CustomerTableComponent from "./CustomerTable";
import { fetchData, postData, fetchsingleData } from "../../../services/apiService";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import GlobalButton from "../../../shared/globalButton";
import SalesCustomerTableComponent from "./CustomerTable";
import SalesBarComponent from "./BarComponent";
import SalesPieComponent from "./PieComponentt";
import DealPieComponent from "./PieComponentt";
import DealCustomerTableComponent from "./CustomerTable";
import DealBarComponent from "./BarComponent";
import DealCustomersatusTableComponent from "./CustomerTable";
import DealPiesatusComponent from "./PieComponentt";
import DealBarsatusComponent from "./BarComponent";
import SaleQuotationcustomerPieComponent from "./PieComponentt";
import SaleQuotationcustomerTableComponent from "./CustomerTable";
import SaleQuotationcustomerBarComponent from "./BarComponent";

export default function SaleQuotationcustomerComponent() {
    const [tabValue, setTabValue] = useState(0);
    const [userNameOptions, setUserNameOptions] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [formData, setFormData] = useState([]);
    const [dealpipelineOptions, setdealpipelineOptions] = useState([]);
    const [dealpipelinestageOptions, setdealpipelinestageOptions] = useState([]);
    const [leadNameOptions , setLeadNameOptions] = useState([]);
    const { id } = useParams();
    const [customers, setCustomerOptions] = useState([]);
    const [categoryOptions, setcategoryOptions] = useState([]);
    const [priceGroupOptions, setPriceGroupOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [accountcategoryOptions, setaccountcategoryOptions] = useState([]);
    const [segmentOptions, setsegmentOptions] = useState([]);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const [errors, setErrors] = useState({});
    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: '#ced4da',  // Add your custom border color
            borderRadius: '4px',      // Rounded corners
            padding: '0.375rem 0.75rem',  // Padding inside the control
            boxShadow: 'none',        // Remove any box shadow
            '&:hover': {
                borderColor: '#80bdff', // Border color on hover
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#495057',  // Custom color for the dropdown indicator (arrow)
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '4px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',  // Shadow for the dropdown menu
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#e9ecef' : null,
            color: state.isSelected ? 'white' : '#495057',
            padding: '10px 20px',
        }),
    };


    const [mainTabValue, setMainTabValue] = React.useState(0); // Tracks main tabs like Customer, User, Area, etc.

    const handleMainTabChange = (event, newValue) => {
        setMainTabValue(newValue);
    };
    useEffect(() => {
        getData();
    }, []);
    const [customerList, setCustomerList] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);


    const getData = async () => {
        try {
            const requestBody = {};
            const response = await postData("customersSaleQuotatio/customer", requestBody); // Assuming postData is defined
            console.log("====", response)
            if (response?.data) {
                setCustomerList(response.data || []);
                setTotalItems(response?.totalDocuments || 0);

                // Calculate total amount by summing up the total_amount values
                const totalAmount = response.data.reduce((sum, item) => sum + (item.total_amount || 0), 0);
                setTotalAmount(totalAmount);  // Assuming you have a state setter for totalAmount
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    // Function to render the corresponding component based on selected tab
    const renderTabContent = () => {
        switch (mainTabValue) {
            case 0:
                return <SaleQuotationcustomerTableComponent customerList={customerList} setCustomerList={setCustomerList} />;
            case 1:
                return <SaleQuotationcustomerBarComponent customerList={customerList} setCustomerList={setCustomerList} />;
            case 2:
                return <SaleQuotationcustomerPieComponent customerList={customerList} setCustomerList={setCustomerList} />;
            default:
                return <Typography variant="h6">No Data Available</Typography>;
        }
    };

    const [countryOptions, setCountryOptions] = useState([])
    const [sateOptions, sateCategory] = useState([])
    const [areaOptions, areCategory] = useState([])

    const fetchCountry = async () => {
        try {
            const response = await fetchData("country");

            if (response?.data) {
                setCountryOptions(response?.data.map(i => {
                    return {
                        label: i?.countryName, key: 'country', value: { countryId: i?.id, countryName: i?.countryName }
                    }
                }));
            }
            if (id) {
                const response = await fetchsingleData("country", id);
                if (response?.data)
                    setFormData(response?.data); // Update state with fetched data
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const fetchState = async (id) => {
        try {
            const response = await fetchData("state", { countryId: id });

            if (response?.data) {
                sateCategory(response?.data.map(i => {
                    return {
                        label: i?.stateName, key: 'state', value: { stateId: i?.id, stateName: i?.stateName }
                    }
                }));
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    

    const fetchArea = async (id) => {
        try {
            const response = await fetchData("area", { stateId: id });
            console.log('arra', response.data);
            if (response?.data) {

                areCategory(response?.data.map(i => {
                    return {
                        label: i?.cityName, key: 'area', value: { areaId: i?.id, areaName: i?.cityName }
                    }
                }));
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const fetchproduct = async (id) => {
        try {
            const responseProducts = await fetchData("product");
            console.log('arra', responseProducts.data);
            if (responseProducts?.data) {

                setProductOptions(
                    responseProducts?.data?.map((i) => {
                        return {
                            ...i,
                            label: `${i?.name}`,
                            key: "name",
                            value: i?.id,
                        };
                    })
                );
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {

        fetchCountry()
        fetchState()
        fetchGroup();
        fetchArea()
        getCustomer()
        fetchCategory();
        fetchproduct();
        fetchUser()
        fetchaccountcategory()
        fetchasegment()
        fetchDealPipeline()
        fetchLead()
        fetchDealPipelinestage()
        // handleApply()
    }, [])

    const selectChanged = (e) => {



        // If the selected key is "country", update formData and fetch states
        if (e.key === "country") {
            setFormData(prevState => ({
                ...prevState,
                ...e.value
            }));
            fetchState(e.value.countryId);
        } else if (e.key === "state") {
            setFormData(prevState => ({
                ...prevState,
                ...e.value
            }));
            fetchArea(e.value.stateId);
        } else {
            setFormData(prevState => ({
                ...prevState,
                ...e.value
            }));
        }

    };

    const selectChangedcustomer = (e) => {
        //  Assuming e is an array of selected options for a multi-select
        const selectedValues = e.map(option => option.value.id); // Get ids from selected options

        // Update formData with the selected customer ids
        setFormData(prevState => ({
            ...prevState,
            customerId: selectedValues,
        }));
    }


    const getCustomer = async () => {
        try {
            const response = await fetchData(`customer`);
            if (response?.data) {

                setCustomerOptions(

                    response?.data?.map((i) => {

                        return {
                            label: `${i?.cName}`,
                            key: "customer",
                            value: {
                                id: i?.id,
                                name: ` ${i?.cName}`,
                                address: `${i?.address} - ${i?.pincode}.`,
                                contact: i?.addressContact,
                            },
                        };

                    })
                );
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchCategory = async () => {
        try {
            const response = await fetchData("systemtype", {
                typeCategory: "category",
            });

            if (response?.data) {
                setcategoryOptions(
                    response?.data.map((i) => {
                        return {
                            label: i?.typeName,
                            key: "area",
                            value: { productCategoryId: i?.id, productCategory: i?.typeName },
                        };
                    })
                );
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const fetchGroup = async () => {
        try {
            const response = await fetchData("pricegroup");

            if (response?.data) {
                setPriceGroupOptions(
                    response?.data.map((i) => {
                        return {
                            label: i?.name,
                            key: "pricegroup",
                            value: { priceGroupId: i?.id, priceGroupName: i?.name },
                        };
                    })
                );
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const selectChangeddealpipe = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setFormData(prevData => ({
            ...prevData,
            dealpipeline: selectedValues,
        }));
    };

    const fetchDealPipeline = async () => {
        try {
            const response = await fetchData('dealpipeline');
            if (response?.data) {
                setdealpipelineOptions(
                    response.data.map((user) => ({
                        label: user.name,  // Display name of the option
                        value: user.id,    // The value that gets selected
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching deal pipelines:', error);
        }
    };

    const selectChangedLead = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setFormData(prevData => ({
            ...prevData,
            lead: selectedValues,  // Ensure lead is updated, not username
        }));
    };
    
    const fetchLead = async () => {
        try {
            const response = await fetchData('lead');
            if (response?.data) {
                setLeadNameOptions(
                    response.data.map((user) => ({
                        label: user.name,
                        value: user.name,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching lead data:', error);
        }
    };

    const selectChangeddealpipestage = (selectedOptions) => {
        setFormData(prevData => ({
            ...prevData,
            dealpipelinestage: selectedOptions,  // Store the full selected option objects
        }));
    };



    const fetchDealPipelinestage = async () => {
        try {
            const response = await fetchData('dealpipeline');
            if (response?.data) {
                setdealpipelineOptions(
                    response.data.flatMap((deal) =>
                        Array.isArray(deal.stages) ?
                            deal.stages.map((stage) => ({
                                label: stage.name,  // Name to display in the dropdown
                                value: stage.name   // Unique value (ID)
                            })) : []
                    )
                );
            }
        } catch (error) {
            console.error('Error fetching deal pipeline stages:', error);
        }
    };

    const fetchUser = async () => {
        try {
            const response = await fetchData('user');
            if (response?.data) {
                setUserNameOptions(
                    response.data.map((user) => ({
                        label: user.name,
                        value: user.name,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchaccountcategory = async () => {
        try {
            const response = await fetchData('accountcategory');
            if (response?.data) {
                setaccountcategoryOptions(
                    response.data.map((item) => ({
                        label: item.name, // The label to display in the dropdown
                        value: item.id,   // The unique identifier for the option
                        color: item.color, // Optional additional property
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching account categories:', error);
        }
    };

    const fetchasegment = async () => {
        try {
            const response = await fetchData('segment');
            if (response?.data) {
                setsegmentOptions(
                    response.data.map((item) => ({
                        label: item.name, // Text shown in the dropdown
                        value: item.id,   // Unique identifier
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };


    const selectChangeduser = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setFormData(prevData => ({
            ...prevData,
            username: selectedValues,
        }));
    };


    const selectaccountcategory = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value); // Map selected options to their values
        setFormData(prevData => ({
            ...prevData,
            name: selectedValues, // Update the `name` field in formData
        }));
    };


    const selectacsegment = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setFormData(prevData => ({
            ...prevData,
            name: selectedValues,
        }));
    };


    const selectChangedprice = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value.priceGroupId);
        setFormData(prevData => ({
            ...prevData,
            priceGroupId: selectedValues,
        }));
    };

    const selectChangedproduct = (selectedOptions) => {
        // Assuming selectedOptions is an array of selected product objects
        const selectedValues = selectedOptions.map(option => option.value);
        setFormData({ ...formData, productId: selectedValues });
    };

    const selectChangedcat = (selectedOptions) => {
        // Assuming selectedOptions is an array of selected category objects
        const selectedValues = selectedOptions.map(option => option.value);
        setFormData({ ...formData, productCategoryId: selectedValues });
    };



    const clearFilters = () => {
        const initialData = {
            period: "",
            fromDate: "", // Clear date fields
            toDate: "",
            countryId: "",
            stateId: "",
            areaId: "",
            customerId: [], // Clear multi-selection arrays
            customerName: [],
            username: "",
            priceGroupId: "",
            segment: "",
            productCategoryId: "",
            productId: "",
            countryName: "",
            stateName: "",
            areaName: "",
            name: "",
            dealpipeline: "",
            dealpipelinestage: "",
            address: "",
            contact: "",
            productCategory: "",
        };
        handleApply();
        // setFormData(defaultData);
        setFormData(initialData); // Reset the form data to initial state
        setErrors({}); // Clear any validation errors if present
    };
    const handleApply = async () => {
        // Ensure all fields are properly set or defaulted
        const formattedData = {
            period: formData.period || "", // Ensure period is set
            fromDate: formData.fromDate ? new Date(formData.fromDate).toISOString().split('T')[0] : "", // Format date if exists
            toDate: formData.toDate ? new Date(formData.toDate).toISOString().split('T')[0] : "", // Format date if exists
            countryId: formData.countryId || "", // Ensure countryId is set
            stateId: formData.stateId || "", // Ensure stateId is set
            areaId: formData.areaId || "", // Ensure areaId is set
            customerId: Array.isArray(formData.customerId)
                ? formData.customerId.map(customer => customer?.id || customer) // Safely map over customerId
                : [], // Default to an empty array
            username: Array.isArray(formData.username)
                ? formData.username.map(user => user?.id || user) // Safely map over username
                : [], // Default to an empty array
            priceGroupId: Array.isArray(formData.priceGroupId)
                ? formData.priceGroupId.map(priceGroup => priceGroup?.id || priceGroup) // Safely map over priceGroupId
                : [], // Default to an empty array
            segment: Array.isArray(formData.name)
                ? formData.name.map(segment => ({
                    segmentId: segment?.id || "",
                    segmentName: segment?.name || "",
                }))
                : [], // Default to an empty array
            customerCategory: Array.isArray(formData?.id) && formData.id.length > 0
                ? formData.id.map((category) => ({
                    customerCategoryId: category?.id || "",
                }))
                : [], // Default to an empty array
            productCategories: Array.isArray(formData.productCategoryId)
                ? formData.productCategoryId.map(category => ({
                    productCategoryId: category?.productCategoryId || category?.id || "",
                    productCategory: category?.productCategory || "",
                }))
                : [], // Default to an empty array
            productId: Array.isArray(formData.productId)
                ? formData.productId.map(product => product?.id || product) // Safely map over productId
                : [], // Default to an empty array
            countryName: formData.countryName || "", // Ensure countryName is set
            stateName: formData.stateName || "", // Ensure stateName is set
            areaName: formData.areaName || "", // Ensure areaName is set
            productCategory: formData.productCategory || "", // Ensure productCategory is set
        };

        console.log("Formatted Payload:", formattedData);

        // API call
        try {
            const response = await postData("customersSaleQuotatio/customer", formattedData);

            setCustomerList(response?.data);

            if (response?.data) {
                console.log("API Response:", response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }

    };

    return (
        <div className="">
            <div className="customer-page-main-grid">
                <div>
                    <div className=" new-white-box-design">
                        <h2>
                            Total amount: ₹ {new Intl.NumberFormat('en-IN').format(totalAmount)}
                        </h2>
                        <div>
                            <Tabs value={mainTabValue} onChange={handleMainTabChange}>
                                <Tab label="TABLE" />
                                <Tab label="BAR" />
                                <Tab label="PIE" />
                            </Tabs>
                            <div className="pt-3">{renderTabContent()}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className=" new-white-box-design">
                        <div className="pb-3">
                            <FormControl style={{ width: '100%' }}>
                                <TextField
                                    type="date"
                                    label="From"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    variant="outlined"
                                    name="fromDate"
                                />
                            </FormControl>
                        </div>
                        <div className="pb-3">
                            <FormControl style={{ width: '100%' }}>
                                <TextField
                                    type="date"
                                    label="To"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    variant="outlined"
                                    name="toDate"
                                />
                            </FormControl>
                        </div>
                        <div className="pb-3">
                            <SelectBox
                                label="Country"
                                name="countryId"
                                value={countryOptions.find(i => i.value.id === formData.countryId)}
                                options={countryOptions}
                                onChange={selectChanged}
                                placeholder="Select Country"
                                required
                            />
                        </div>
                        <div className="pb-3">
                            <SelectBox
                                label="State"
                                name="stateId"
                                value={sateOptions.find(i => i.value.id === formData.stateId)}
                                options={sateOptions}
                                onChange={selectChanged}
                                placeholder="Select State"
                                required
                            />
                        </div>
                        <div className="pb-3">
                            <SelectBox
                                label="City"
                                name="areaId"
                                value={areaOptions.find(i => i.value.id === formData.areaId)}
                                options={areaOptions}
                                onChange={selectChanged}
                                placeholder="Select City"
                                required
                                errors={errors.customerName}
                            />
                        </div>
                        

                        <div className="pb-3">
                            <SelectBox
                                label="Lead"
                                name="lead"
                                value={leadNameOptions?.filter(i =>
                                    Array.isArray(formData?.lead) && formData?.lead.includes(i?.value)  // Use lead instead of username
                                )}
                                options={leadNameOptions}
                                onChange={selectChangedLead}
                                placeholder="Lead"
                                required
                                isMulti
                                errors={errors.lead}  // Reflect correct error key if needed
                            />
                        </div>

                        <div className="pb-3">
                            <SelectBox
                                label="Customer"
                                name="customerId"
                                value={customers?.filter(option => (formData.customerId || []).includes(option.value.id))} // This ensures selected values are shown
                                options={customers}
                                onChange={selectChangedcustomer}  // Ensure this is correct
                                placeholder="Customer Name"
                                required
                                errors={errors.customerName}
                                isMulti={true} // Enables multi-selection
                            />
                        </div>

                        <div className="pb-3">
                            <SelectBox
                                label="Product Category"
                                name="productCategoryId"
                                value={categoryOptions.filter(i => Array.isArray(formData.productCategoryId) && formData.productCategoryId.includes(i.value))}
                                options={categoryOptions}
                                onChange={selectChangedcat}
                                placeholder="Select Category"
                                required
                                errors={errors.state}
                                isMulti // This makes
                            />
                        </div>
                        <div className="pb-3">
                            <SelectBox
                                label="Product"
                                name="productId"
                                value={productOptions.filter(option => Array.isArray(formData.productId) && formData.productId.includes(option.value))}
                                options={productOptions}
                                onChange={selectChangedproduct}
                                placeholder="Product Name"
                                required
                                errors={errors.customerName}
                                isMulti // This makes it a multi-select
                            />
                        </div>

                        <div className="pb-3">
                            <SelectBox
                                label="Quotation Status"
                                name="QuotationStatus"
                                options={[
                                    { value: 'approved', label: 'Approved' },
                                    { value: 'pending', label: 'Pending' },
                                    { value: 'rejected', label: 'Rejected' }
                                ]}
                                placeholder="Quotation Status"
                                required
                                errors={errors.state}
                                isMulti
                            />
                        </div>
                        
                        <div className="d-flex align-items-center gap-3 justify-content-center">
                            <div onClick={handleApply}>
                                <GlobalButton text="APPLY" />
                            </div>
                            <div onClick={clearFilters}>
                                <GlobalButton text="Clear Filters" />
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}
