import React, { useState, useEffect } from 'react';
import "./index.scss";
import PenIcon from '../../../assets/icons/pen.svg';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from '../../../shared/components/pagination';
import { fetchData } from '../../../services/apiService';
import { checkAccess } from '../../../services/checkFeature';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce';
export default function Usertable({ selectedIds, setSelectedIds, setActivityList }) {
  const [activityList, setUserRole] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userrole, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [useRrole, setUserRoleTerm] = useState(""); // Initialize as an empty string
  const [NameTerm, setnameTerm] = useState("");
  const [Status, setStatusTrem] = useState("");
  const [allactivityType, setallactivityTypeTerm] =useState('');
  const pageSize = 10;
  const navigate = useNavigate();

  const [productData, setProductData] = useState([]);
  useEffect(() => {
    const filters = {
      name: NameTerm,
      status : Status,
      role : useRrole
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(activityList.map((item) => item.userId));
    } else {
      setSelectedIds([]);
    }
  };
  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };

  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('user', removeEmptyFields(filters), 
      pagination,
      {},
      isSearching,
      globalSearch
    );

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      name: NameTerm,
      status: Status,
      role: useRrole,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [NameTerm, Status, useRrole, currentPage, pageSize]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['name', 'role'],
      searchtext: allactivityType,
    };

    const debouncedSearchFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedSearchFetch();

    return () => {
      debouncedSearchFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNavigation = (id) => {
    navigate(`/view/${id}`);
  };

  return (
    <div className='account-table-page-alignment'>
                      <div className='account-table-header-alignment'>
                    <div className='search-width'>
                        <div className='searchbar-design'>
                            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
                            <div className='icon-alignment'>
                                <img src={SearchIcon} alt="SearchIcon" />
                            </div>
                        </div>
                    </div>
                </div>
      <div className='account-table-design'>
        <table>
          <thead>
            <tr>
              <th><div className="tableSize"></div></th>
              <th><div className="tableSize"></div></th>
              <th><input type="text" className='table-input-background' placeholder="Name" value={NameTerm} onChange={(e) => setnameTerm(e.target.value)} /></th>
              <th><input type="text" className='table-input-background' placeholder="User" value={useRrole} onChange={(e) => setUserRoleTerm(e.target.value)} /></th>
              <th><input type="text" className='table-input-background' placeholder="Status" value={Status} onChange={(e) => setStatusTrem(e.target.value)} /></th>
            </tr>
            <tr>
              <th>
                <input
                  type='checkbox'
                  onChange={handleSelectAll}
                  checked={selectedIds.length === activityList.length}
                />
              </th>
              {checkAccess('modify_user') && <th>Action</th>}
              <th>Name</th>
              <th>User-Role</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {productData.length > 0 ? (
              productData.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? '#f2f4f4' : '#f8f9f9',
                    cursor: checkAccess('read_user') ? 'pointer' : 'default',
                  }}
                  onClick={() => {
                    if (checkAccess('read_user')) {
                      navigate(`/user/view/${item.id}`);
                    }
                  }}
                >
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(item.userId)}
                      onClick={(e) => e.stopPropagation()}
                      onChange={() => handleCheckboxChange(item.userId)}
                    />
                  </td>
                  {checkAccess('modify_user') && (
                    <td onClick={(e) => e.stopPropagation()}>
                      <Link to={{ pathname: `adduser/${item.id}` }}>
                        <img src={PenIcon} alt="Edit" />
                      </Link>
                    </td>
                  )}
                  <td>
                    <span className="text-dark" onClick={() => handleNavigation(item?.id)}>{item?.name}</span>
                  </td>
                  <td>{item.role}</td>
                  <td onClick={(e) => e.stopPropagation()}>
                    <button className={item.status === true ? 'Active' : 'Inactive'}>
                      {item.status ? 'Active' : 'Inactive'}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{ textAlign: 'center', fontStyle: 'italic' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
        <div className='pagination-alignment'>
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
