import React, { useState,useEffect } from 'react';
import { fetchData } from '../../../services/apiService';
import { Link } from 'react-router-dom';
import EditIcon from '../../../shared/icons/editIcon';
import Pagination from '../../../shared/components/pagination';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce'; 
export default function Countrytable({ selectedIds, setSelectedIds,  setActivityList ,activityList, setProductList, setTotalItems, totalItems }) {

  const [currentPage, setCurrentPage] = useState(1); // Current page state
  // const [activityList, setProductList] = useState([]);
  const [countryName, setCountryName] = useState("");
  const [status, setStatus] = useState(undefined);
  const [countryFlag, setCountryFlag] = useState("");
  const [countryShortname, setCountryShortname] = useState("");
  const [productData, setProductData] = useState([]);
  const pageSize = 10; // Items per page
  const [allactivityType, setallactivityTypeTerm] =useState('');
  useEffect(() => {
    const filters = {
      countryName: countryName,
      countryShortname :countryShortname ,
      countryFlag :countryFlag,
      status : status
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };

  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('country', removeEmptyFields(filters), 
      pagination,
      {},
      isSearching,
      globalSearch
    );

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      countryName: countryName,
      countryShortname: countryShortname,
      countryFlag: countryFlag,
      status: status
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [countryName, countryShortname, countryFlag, status]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['countryName', 'countryShortname', 'countryFlag'],
      searchtext: allactivityType
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);

    const handleCheckboxChange = (id) => {
      setSelectedIds((prevSelectedIds) => {
        if (prevSelectedIds.includes(id)) {
          return prevSelectedIds.filter((selectedId) => selectedId !== id);
        } else {
          return [...prevSelectedIds, id];
        }
      });
    };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(activityList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = activityList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  return (
    <div className='account-table-page-alignment'>
      <div className='account-table-header-alignment'>
                    <div className='search-width'>
                        <div className='searchbar-design'>
                            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
                            <div className='icon-alignment'>
                                <img src={SearchIcon} alt="SearchIcon" />
                            </div>
                        </div>
                    </div>
                </div>
      <div className='account-table-design'>
        <table>
          <thead>
            <tr>
              <th><div></div></th>

              <th><div><input
                type="text"
                placeholder="Country Name"
                value={countryName}
                onChange={(e) => setCountryName(e.target.value)}
                className="w-100 border-0 table-input-background"

              /></div></th>

              <th><div><input
                type="text"
                placeholder="Country Short Name"
                value={countryShortname}
                onChange={(e) => setCountryShortname(e.target.value)}
                className="w-100 border-0 table-input-background"

              /></div></th>

               <th><div><input
                type="text"
                placeholder="Country Flag"
                value={countryFlag}
                onChange={(e) => setCountryFlag(e.target.value)}
                className="w-100 border-0 table-input-background"

              /></div></th>

                <th>
                <div>
                  <select
                    value={status === undefined ? "" : status.toString()} 
                    onChange={(e) => {
                      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
                      setStatus(selectedStatus); 
                    }}
                    className="w-100 border-0 table-input-background"
                  >
                    <option value="">All</option>
                    <option value="true">Active</option> 
                    <option value="false">Inactive</option>
                  </select>
                </div>
                </th>
                              
                <th><div ></div></th>
            </tr>
            <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
            
              <th>Name</th>
              <th>Country ShortName</th>
              <th>Country Flag</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {(productData ?? []).length > 0 ? (
              productData.map((item) => (
                <tr key={item?.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                  <td>{item?.countryName}</td>
                  <td>{item?.countryShortname}</td>
                  <td>{item?.countryFlag}</td>
                  <td>
                    <button className={item?.status ? "Active" : "Inactive"}>
                      {item?.status ? "Active" : "Inactive"}
                    </button>
                  </td>
                  <td>
                    <Link to={{ pathname: `addEditcountry/${item?.id}` }}>
                      <EditIcon />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} style={{ textAlign: "center", fontStyle: "italic" }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
