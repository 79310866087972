import React from "react";

const LoaderComponent = ({loading}) => {
    return (
        loading ? 
        <div style={styles.overlay}>
            <div style={styles.loaderBox}>
                <div style={styles.spinner}></div>
            </div>
        </div> : <></>
    );
};

const styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        // bottom: 0,
        // backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
    },
    loaderBox: {
        width: "160px",
        height: "60px",
        backgroundColor: "#2e8fda",
        borderRadius: "0px 0px 8px 8px",  // Apply border radius only to bottom-left and bottom-right
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    },    
    spinner: {
        width: "20px",
        height: "20px",
        border: "2px solid #fff",
        borderTop: "2px solid transparent",
        borderRadius: "50%",
        animation: "spin 1s linear infinite",
    },
};

// Keyframes for spinner animation
const styleTag = document.createElement('style');
styleTag.type = 'text/css';
document.head.appendChild(styleTag);
styleTag.sheet.insertRule(
    "@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }",
    0
);
export default LoaderComponent;
