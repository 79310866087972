import { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchData } from '../../../services/apiService';
import './expensetable.scss';
import Button from '../../../shared/components/button';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';
import { checkAccess } from '../../../services/checkFeature';
import SelectBox from "../../../shared/components/select";
import { DatePicker, Space } from 'antd';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce';
export default function Expensetable({ selectedIds, setSelectedIds,totalItems,setTotalItems,  activityList,setActivityList }) {
  const [expenseList, setExpenseList] = useState([]);
  const navigate = useNavigate();
  const user = useSelector(state => state.user.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [ProductList, setProductList] = useState([]);
  const [dateTerm, setDateTerm] = useState(""); // Search term for date
  const [selectedDates, setSelectedDates] = useState([]);
  const [productData, setProductData] = useState([]);
  const [expenseTerm,setExpenseTerm]= useState("");
  const [userTerm, setuserTerm] = useState("");
  const [expensetypeTerm , setexpensetypeTerm] = useState("");
  const [claimAmountTerm, setclaimAmountTerm] = useState("");
  const [expensstatusTerm , setexpensestatusTerm] = useState("");
  const [allactivityType, setallactivityTypeTerm] =useState('');
  const pageSize = 10;
  useEffect(() => {
    const filters = {
      expenseno: expenseTerm,
      username: userTerm,
      expensetypename: expensetypeTerm,
      amount: claimAmountTerm,
      expensstatusTerm :expensstatusTerm  
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);


  useEffect(() => {
    setProductList(activityList)
    // setTotalItems(activityList.length)
  }, [activityList])

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = activityList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const getStatusClass = (status) => {
    console.log("status:", status)
    switch (status?.toLowerCase()) {
      case 'approvel':
        return 'open';
      case 'reject':
        return 'cancelled';
      case 'pending':
        return 'Pending';
      default:
        return '';
    }
  };
  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };
  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('expense', removeEmptyFields(filters),pagination,
      {},
      isSearching,
      globalSearch);

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }

      console.log('setProductData', responseProducts?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      expenseno: expenseTerm,
      username: userTerm,
      expensetypename: expensetypeTerm,
      amount: claimAmountTerm,
      expensestatus: expensstatusTerm,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [expenseTerm, userTerm, expensetypeTerm, expensstatusTerm, selectedDates]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['username', 'expensetypename', 'expensestatus'],
      searchtext: allactivityType,
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);

  const { RangePicker } = DatePicker;
  const [errors, setErrors] = useState({});

    const rangePresets = [
      {
        label: 'Last 7 Days',
        value: [dayjs().subtract(7, 'day'), dayjs()],
      },
      {
        label: 'Last 14 Days',
        value: [dayjs().subtract(14, 'day'), dayjs()],
      },
      {
        label: 'Last 30 Days',
        value: [dayjs().subtract(30, 'day'), dayjs()],
      },
      {
        label: 'Last 90 Days',
        value: [dayjs().subtract(90, 'day'), dayjs()],
      },
    ];
    const onRangeChange = (dates) => {
      setSelectedDates(dates); // Update the state with the selected date range
    };

  return (
    <div className='account-table-page-alignment'>
                  <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className='account-table-design'>
        <table>
          <thead>
         
            <tr>
              <th><div className="tableSize"></div></th>
              
              <th><div className="tableSize"></div></th>

              <th><div ><input
                type="text"
                placeholder="Expense No"
                value={expenseTerm}
                onChange={(e) => setExpenseTerm(e.target.value)}
               className="w-100 border-0 table-input-background"
              /></div></th>
              
              <th><div ><input
                type="text"
                placeholder="User"
                value={userTerm}
                onChange={(e) => setuserTerm(e.target.value)}
                className="w-100 border-0 table-input-background"
              /></div></th>
             
<th><div ><input
                type="text"
                placeholder="Expense type"
                value={expensetypeTerm}
                onChange={(e) => setexpensetypeTerm(e.target.value)}
                className="w-100 border-0 table-input-background"
              /></div></th>

              <th><div className="tableSize">
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={[' Start Date', ' End Date']} className="my-custom-class" />
                </Space></div> </th>


              <th><div className="tableSize"><input
                type="text"
                placeholder=" Claim Amount"
                value={claimAmountTerm}
                onChange={(e) => setclaimAmountTerm(e.target.value)}
                className="w-100 border-0 table-input-background"
              /></div></th>


              <th><div className="tableSize"> <input
                type="text"
                placeholder="Expense status"
                value={expensstatusTerm}
                onChange={(e) => setexpensestatusTerm(e.target.value)}
               className="w-100 border-0 table-input-background"
              /></div></th>


            </tr>
            <tr>
              
                <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
              {checkAccess('edit_expense')&&<th>Action</th>}
              <th>Expense No</th>
              <th>User</th>
              <th>Expense type</th>
              <th>Date</th>
              <th>Claim Amount</th>
              <th>Expense status</th>
            </tr>
          </thead>
          <tbody>
            {productData && productData.length > 0 ? (
              productData.map((i, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#f8f9f9' }}>
                  <td>
                    <input type="checkbox" checked={selectedIds.includes(i.id)} onChange={() => handleCheckboxChange(i.id)} />
                  </td>
                  {checkAccess('edit_expense') && (
                    <td align="center">
                      <Link to={`addexpense/${i?.id}`}>
                        <EditIcon />
                      </Link>
                    </td>
                  )}
                  <td style={i?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{i.expenseno}</td>
                  <td style={i?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{i.username}</td>
                  <td style={i?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{i.expensetypename}</td>
                  <td style={i?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{dayjs(i?.date).format('DD-MM-YYYY')}</td>
                  <td style={i?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{i.amount}</td>
                  <td>
                    {checkAccess('read_expense') ? (
                      <Link to={{ pathname: `/expense/expenseview/${i?.id}` }}>
                        <button className={`${getStatusClass(i.expensestatus)}`}>{i?.expensestatus}</button>
                      </Link>
                    ) : (
                      <button className={`${getStatusClass(i.expensestatus)}`} style={{ pointerEvents: 'none', cursor: 'default' }}>
                        {i?.expensestatus}
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: 'center', fontStyle: 'italic' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
