import React from 'react'
import './orderwisereport.scss';
import Button from '../../shared/components/button';
import Orderwisereportdetails from './orderwisereportdetails';
import Breadcumbs from '../../shared/components/breadcumbs';
export default function Orderwisereport() {
  return (
    <div className='page-24'>
      <div className="customer-header-alignment pb-4">
        <div>
          <h2>Orderwise Dispatch Report</h2>
          <Breadcumbs activePath="Dashboard" pageName="Orderwise Dispatch Report" />
        </div>
      </div>
      <Orderwisereportdetails />
    </div>
  )
}
