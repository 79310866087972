import React from 'react'
import './dashboardHeader.scss';
import PrimaryButton from '../../../shared/components/primaryButton';
import FilterIcon from '../../../assets/icons/filter-gray.svg';
import FillWhiteButton from '../../../shared/components/fillWhiteButton';
export default function DashboardHeader() {
  return (
    <div className='dashboard-header-design'>
      <div className='text-style'>
        <h3>
        Hello Admin,
        </h3>
        <p>Tracking Progress across 4,400 Orders</p>
      </div>
      <div className='dashboard-right-contnet-alignment'>
      <div className='btn-grp'>
        <button>All Date</button>
        <button>12 Months</button>
        <button>30 Days</button>
        <button className='active-button'>7 Days</button>
        <button>24 Hour</button>
      </div>
      
      </div>
    </div>
  )
}
