import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../../../services/apiService";
import moment from "moment";
import { useSelector } from "react-redux";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import Pagination from "../../../shared/components/pagination";
import EditIcon from "../../../shared/icons/editIcon";
import store from '../../../redux/reduxstore';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce';
export default function Estimatetable({
  selectedIds,
  totalItems,
  setTotalItems,
  setSelectedIds,
  activityList,
  setActivityList,
}) {
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);

  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [ProductList, setProductList] = useState([]);
  const [estimateNo, setSalesReturnTerm] = useState(""); // Search term for Sales Return
  const [estimateFor, setCustomerTerm] = useState(""); // Search term for estimateFor
  const [totalAmount, setReasonTerm] = useState("");
  const [Amount, setAmountTerm] = useState("");
  const [userName, setReturnByTerm] = useState("");
  const [estimateStatus, setSalesReturnStatusTerm] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [errors, setErrors] = useState({});
  const [productData, setProductData] = useState([]);
  const name = useSelector(state => state.auth.name);
  const userData = store.getState().auth.userData
  const [allactivityType, setallactivityTypeTerm] =useState('');
  console.log(userData, "---[-")

  const userDataRef = useRef(userData);
  useEffect(() => {
    userDataRef.current = userData;
  }, [userData]);

  const pageSize = 10;

  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    const filters = {
      salesReturnNo: estimateNo,
      customerName: estimateFor,
      totalamount: Amount,
      userName :userName ,
      estimateStatus:estimateStatus
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList);
  }, [activityList]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(activityList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handlePageChange = (page) => setCurrentPage(page);

  const getStatusClass = (status) => {
    switch (status) {
      case "Close":
        return "Close";
      case "Approved":
        return "Approved";
      case "Rejected":
        return "Rejected";
      case "Cancelled":
        return "Cancelled";
      case "Pending":
        return "Pending";
      case "Open":
        return "Open";
      default:
        return "";
    }
  };

  const onRangeChange = (dates) => setSelectedDates(dates);

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };
  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('estimate',removeEmptyFields(filters), 
      pagination,
      {},
      isSearching,
      globalSearch);

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }

      console.log('setProductData', responseProducts?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      estimateNo: estimateNo,
      estimateFor: estimateFor,
      totalAmount: Amount,
      userName: userName,
      estimateStatus: estimateStatus,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [Amount, totalAmount, estimateFor, estimateNo, selectedDates, userName, estimateStatus]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['estimateFor', 'userName', 'estimateStatus'],
      searchtext: allactivityType,
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);

  const { RangePicker } = DatePicker;
  const rangePresets = [
    { label: "Last 7 Days", value: [dayjs().subtract(7, "day"), dayjs()] },
    { label: "Last 14 Days", value: [dayjs().subtract(14, "day"), dayjs()] },
    { label: "Last 30 Days", value: [dayjs().subtract(30, "day"), dayjs()] },
  ];

  return (
    <div className="account-table-page-alignment">
            <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className="account-table-design">
        <table>
          <thead>
            <tr>
              <th><div style={{ width: '100%' }}></div></th>
              <th><div style={{ width: '100%' }}></div></th>

              <th><div style={{ textAlign: 'center', width: '100%' }}><input
                type="text"
                placeholder="Estimate"
                value={estimateNo}
                onChange={(e) => setSalesReturnTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%', padding: "7px", borderRadius: "3px" }}

              /></div></th>

              <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="estimateFor"
                value={estimateFor}
                onChange={(e) => setCustomerTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%', padding: "7px", borderRadius: "3px" }}

              /></div></th>

              <th><div style={{ width: '100%' }}>
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={[' Start Date', ' End Date']} className="my-custom-class" />
                </Space></div> </th>



              <th><div style={{ width: '100%' }}> <input
                type="text"
                placeholder="totalAmount"
                value={totalAmount}
                onChange={(e) => setReasonTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%', padding: "7px", borderRadius: "3px" }}

              /></div></th>


              <th><div style={{
                width: '100%'
              }}><input
                  type="text"
                  placeholder="	Created By"
                  value={userName}
                  onChange={(e) => setReturnByTerm(e.target.value)}
                  className="search-input"
                  style={{ border: 'none', width: '100%', padding: "7px", borderRadius: "3px" }}

                /></div></th>

              <th><div style={{
                width: '100%'
              }}><input
                  type="text"
                  placeholder="Estimate Status"
                  value={estimateStatus}
                  onChange={(e) => setSalesReturnStatusTerm(e.target.value)}
                  className="search-input"
                  style={{ border: 'none', width: '100%', padding: "7px", borderRadius: "3px" }}

                /></div></th>

            </tr>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedIds.length === activityList.length}
                />
              </th>
              <th>Action</th>
              <th>Estimate</th>
              <th>Estimate For</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Created By</th>
              <th>Estimate Status</th>

            </tr>
          </thead>
          <tbody>
            {productData && productData.length > 0 ? (
              productData.reverse().map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                  <td>
                    <Link to={`add/${item.id}`}>
                      <EditIcon />
                    </Link>
                  </td>
                  <td
                    style={
                      item?.yetscreen
                        ? { fontWeight: 'bold', fontSize: '1em', color: '#333' }
                        : {}
                    }
                  >
                    {item.estimateNo || "N/A"}
                  </td>
                  <td
                    style={
                      item?.yetscreen
                        ? { fontWeight: 'bold', fontSize: '1em', color: '#333' }
                        : {}
                    }
                  >
                    {item.estimateFor || "N/A"}
                  </td>
                  <td
                    style={
                      item?.yetscreen
                        ? { fontWeight: 'bold', fontSize: '1em', color: '#333' }
                        : {}
                    }
                  >
                    {dayjs(item.estimateDate).format("YYYY-MM-DD") || "N/A"}
                  </td>
                  <td
                    style={
                      item?.yetscreen
                        ? { fontWeight: 'bold', fontSize: '1em', color: '#333' }
                        : {}
                    }
                  >
                    {item.totalAmount || "N/A"}
                  </td>
                  <td
                    style={
                      item?.yetscreen
                        ? { fontWeight: 'bold', fontSize: '1em', color: '#333' }
                        : {}
                    }
                  >
                    {item.userName || "N/A"}
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    <Link to={{ pathname: `view/${item.id}` }}>
                      <button className={` ${getStatusClass(item.estimateStatus)}`}>
                        {item.estimateStatus}
                      </button>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: 'center', fontStyle: 'italic', color: '#666' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
