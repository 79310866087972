import React from 'react';
import dayjs from 'dayjs';

const ReportTemplate = ({ formData }) => {
   
    return (
        <div>
        <div style={{
            marginTop: '1rem',
            padding: '1.5rem',
            backgroundColor: '#fff',
            border: '1px solid rgba(30,46,80,.09)',
            borderRadius: '0.25rem',
            boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
            width: '900px',
            height:'555px',
        }}>
            {/* <h2 style={{ paddingBottom: '10px' }}>Payment from,</h2>

            <p style={{ fontSize: '0.85rem', }}>{formData.cName}</p> */}
            <div style={{ display: 'flex' }}>
                        <div><h4 style={{ paddingBottom: '10px' }}>Payment from,</h4>
                            <p style={{ fontSize: '0.85rem' }}>{formData.cName}</p></div>
                        <div style={{ flex: '1', textAlign: 'right' }}>
                            <h6>Payment update</h6>
                            {formData.updatedby?.length > 0 ? (<p style={{ fontSize: '0.85rem' }}>Payment is Updateby {formData.updatedby} At {formData.updatedpayment}</p>) :
                                (<p>--</p>)}

                        </div>
                    </div>

            <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                <div style={{ display: 'flex', marginBottom: '1rem' }}>
                    <div style={{ flex: '1', marginRight: '1rem' }}>
                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Payment No.</div>
                        <strong>{formData.paymentno}</strong>
                    </div>
                    <div style={{ flex: '1', marginRight: '1rem',textAlign:'center' }}>
                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Created by</div>
                        <strong>{formData.created_by}</strong>
                    </div>
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Payment Date</div>
                        <strong>{dayjs(formData.date).format('DD-MM-YYYY')}</strong>
                    </div>
                </div>
            </div>

            <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                <div style={{ display: 'flex', marginBottom: '1rem' }}>
                    <div style={{ flex: '1', marginRight: '1rem' }}>
                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Customer</div>
                        <strong>{formData.cName}</strong>
                        <p style={{ fontSize: '0.75rem', marginTop: '0.5rem' }}>
                           {formData.address}
                            <br />
                            <a href="mailto:{formData.email}" style={{ color: 'purple' }}>{formData.email}</a>
                        </p>
                    </div>
                    <div style={{ flex: '1', textAlign: 'center' }}>
                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Payment Type</div>
                        <strong>{formData.paymenttypeName}</strong>
                       
                    </div>
                    <div style={{ flex: '1', textAlign: 'right' }}>
                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Payment status</div>
                        <strong>{formData.paymentstatus}</strong>
                       
                    </div>
                </div>
            </div>

            <table style={{ width: '100%', marginTop: '1.5rem', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '0.5rem 0' }}><strong>Description:</strong></th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ padding: '0.5rem 0', borderBottom: '1px solid #edf2f9' }}>{formData.description}</td>
                    </tr>

                </tbody>
            </table>

            <div style={{ marginTop: '1.5rem' }}>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <h5 style={{ marginRight: '0.5rem' }}>Total:</h5>
                    <h5 style={{ color: '#28a745' }}>{formData.amount}</h5>
                   
                </div>
            </div>

           
            </div>
            </div>
    );
};

export default ReportTemplate;
