import React, { useEffect, useState } from 'react';
import './orderwisereportdetails.scss';
import Input from '../../../shared/components/input';
import Button from '../../../shared/components/button';
import { fetchData } from '../../../services/apiService';
import { Empty } from 'antd';
import moment from 'moment'; 
import { Link, useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EditIcon from '../../../shared/icons/editIcon';

export default function Orderwisereportdetails() {
  const [orderList, setOrderList] = useState([]);
  const [filterdata, setFileterData] = useState({
    customerName: '',
    orderStatus: '',
    fromDate: dayjs(),
    toDate: dayjs(),
  });
  

  const handleSearch = async () => {
    try {
      const { fromDate, toDate, customerName, orderStatus } = filterdata;
      
      const startDate = dayjs(fromDate).format('YYYY-MM-DD');
      const endDate = dayjs(toDate).format('YYYY-MM-DD');
      const response = await fetchData("order", {
        customerName,
        orderStatus
      });
      if (response?.data) {
        const filteredData = response.data.filter(order => {
          const orderDate = dayjs(order.orderDate, 'YYYY-MM-DD'); 
          return orderDate.isBetween(dayjs(startDate), dayjs(endDate), undefined, '[]'); 
        });
        setOrderList(filteredData); 
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFileterData((prev) => ({ ...prev, [name]: value }));
  };
  const getStatusClass = (status) => {
    console.log("status:", status)
    switch (status) {
      case 'Close':
        return 'closee';
      case 'cancelled':
        return 'cancelled';
      case 'Pending':
        return 'Pending';
      case 'open':
        return 'open';
      default:
        return '';
    }
  }
  const handleDateChange = (fromDate) => {
    setFileterData(prevState => ({
        ...prevState,
       fromDate
    }));
   
};
const handletoDateChange = (toDate) => {
  setFileterData(prevState => ({
      ...prevState,
     toDate
  }));
 
};


  return (
    <div className='new-white-box-design'>
      <div className='two-col-grid'>
        <Input placeholder='Enter customer name' label='Customer' name='customerName' onChange={handleInputChange} />
        {/* <Input placeholder='dd-mm-yyyy' label='From Order Date' name='fromDate' onChange={handleInputChange} /> */}
        <Input placeholder='status' label='Order Status' name='orderStatus' onChange={handleInputChange} />

        <div >
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker label='From Order Date' name='fromDate' value={filterdata.fromDate} onChange={handleDateChange}  format="DD-MM-YYYY" />
                    </LocalizationProvider>
                </div>
        {/* <Input placeholder='dd-mm-yyyy' label='To Order Date' name='toDate' onChange={handleInputChange} /> */}
        <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                        <DatePicker label='To Order Date' name='toDate' value={filterdata.toDate} onChange={handletoDateChange}  format="DD-MM-YYYY" />
                    </LocalizationProvider>
                </div>
      </div>
      <div className='btn-center pt-5 d-flex justify-content-center'>
        <Button text='Search' onClick={handleSearch} />
      </div>

      <div className="order-table">
      <div className="table-design">
        {orderList && orderList.length > 0 ? (<table>
          <thead>
            <tr>
              <th>Action</th>
              <th>Order #</th>
              <th>Customer</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Created by</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {orderList?.map((i) => {
              return (
                <tr key={i}> 
                  <td>
                   <Link to={`/order/edit/${i?.orderId}`} >
                   <EditIcon/>
                   </Link>
                  </td>
                  <td className="whitesapce-nowrap">{i?.orderNo}</td>
                  <td>
                    <span className="price-group-text">{i?.customerName}</span>
                  </td>
                  <td className="whitesapce-nowrap">{moment(i?.orderDate).format('DD-MM-YYYY')}</td>
                  <td>
                    {i?.products?.reduce((accumulator, currentValue) => {
                      return accumulator + Number(currentValue.subTotal);
                    }, 0).toFixed(2)}
                  </td>
                  <td>{i?.ordercreatedby}</td>
                  <td>
                   
                      <Link to={{ pathname: `/order/view/${i?.orderId}` }}>
                        <button className={` ${getStatusClass(i.orderStatus)}`}>
                          {i?.orderStatus}
                        </button>
                      </Link>
                  
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>):( <div style={{marginTop:"5rem"}}><Empty/></div>)}
      </div>
    </div>
    </div>
    


  );
}



