import React from 'react';
import './select.scss'; // assuming you have a CSS file for styling
import Select from 'react-select'
export default function SelectBox({ label, value,options, onCountryChange, icon, path, colorchange,required,errors, isDisabled, ...other }) {
  

  return (
    <div className={colorchange ? 'input color-change' : 'input'} >
      <label className={required ? 'input required' : 'input'}>{label}</label>
      <div className='relative'>
      <Select   className="basic-single" options={options} onChange={onCountryChange} {...other}  value={value} isDisabled={isDisabled}  />
        {
          icon && (
            <div className='icon'>
              <i className={path}></i>
            </div>
          )
        }

      </div>
      {errors && <label  style={{color:'red'}} >{errors}</label>}
    </div>
    
  );
}
