import { useState, useEffect } from 'react';
import './leadtable.scss';
import { fetchData } from "../../../services/apiService";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import EditIcon from '../../../shared/icons/editIcon';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce'; 
export default function Leadtable({ selectedIds, setSelectedIds, totalItems, setTotalItems, activityList, setActivityList }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [NameTerm, setNameTerm] = useState("");
  const [EmailTerm, setEmailTerm] = useState("");
  const [ContactNoTrem, setContactNoTrem] = useState("");
  const [Owner, setOwner] = useState("");
  const [Collaborators, setCollaboratorsTerm] = useState("");
  const [Source, setSourceTerm] = useState("");
  const [Industry, setIndustryTerm] = useState("");
  const [Status, setStatusTerm] = useState("");
  const [productData, setProductData] = useState([]);
  const pageSize = 10;
  const [allactivityType, setallactivityTypeTerm] =useState('');
  useEffect(() => {
    const filters = {
      name: NameTerm,
      email: EmailTerm,
      contactno: ContactNoTrem,
      ownername:Owner,
      leadsource :Source ,
      leadindustry:Industry,
      leadstatus :Status
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  useEffect(() => {
    setActivityList(activityList);
  }, [activityList]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(activityList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'Disqualified':
        return 'cancelled';
      case 'Not Interested':
        return 'closee';
      case 'New':
      case 'NEW':
        return 'open';
      case 'Qualified':
        return 'overdue';
      default:
        return '';
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };
  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('lead', removeEmptyFields(filters) ,
      pagination,
      {},
      isSearching,
      globalSearch
    );

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }

      console.log('setProductData', responseProducts?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

useEffect(() => {
    const filters = {
      name: NameTerm,
      email: EmailTerm,
      contactno: ContactNoTrem,
      ownername: Owner,
      leadsource: Source,
      leadindustry: Industry,
      leadstatus: Status,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [NameTerm, EmailTerm, ContactNoTrem, Owner, Source, Industry, Status]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['name', 'email', 'contactno', 'ownername', 'leadsource', 'leadindustry', 'leadstatus'],
      searchtext: allactivityType,
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);


  return (
    <div className='' >
      <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className='account-table-design'>
        <table>
          <thead>
          
            <tr>
              <th><div className="tableSize"></div></th>
              <th><input type="text" placeholder="Name" value={NameTerm} onChange={(e) => setNameTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Email" value={EmailTerm} onChange={(e) => setEmailTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Contact No" value={ContactNoTrem} onChange={(e) => setContactNoTrem(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Owner" value={Owner} onChange={(e) => setOwner(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Source" value={Source} onChange={(e) => setSourceTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Industry" value={Industry} onChange={(e) => setIndustryTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Status" value={Status} onChange={(e) => setStatusTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              {checkAccess('edit_lead') && <th>Action</th>}
            </tr>
            <tr>
            <th><input type="checkbox" onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
              <th>Name</th>
              <th>Email</th>
              <th>Contact No</th>
              <th>Owner</th>
              <th>Source</th>
              <th>Industry</th>
              <th>Created by</th>
              {checkAccess('edit_lead')&&<th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {productData && productData.length > 0 ? (
              productData.map((item, index) => (
                <tr key={index}>
                  <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>
                    <input type="checkbox" checked={selectedIds.includes(item.id)} onChange={() => handleCheckboxChange(item.id)} />
                  </td>
                  <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{item.name}</td>
                  <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{item.email}</td>
                  <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{item.contactno}</td>
                  <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{item.ownername}</td>
                  <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{item.leadsource}</td>
                  <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{item.leadindustry}</td>
                  <td>
                    {checkAccess('read_lead') ? (
                      <Link to={`view/${item.id}`}>
                        <button className={getStatusClass(item.leadstatus)}>{item.leadstatus}</button>
                      </Link>
                    ) : (
                      <button className={getStatusClass(item.leadstatus)} disabled>
                        {item.leadstatus}
                      </button>
                    )}
                  </td>
                  {checkAccess('edit_lead') && (
                    <td>
                      <Link to={`addlead/${item.id}`}>
                        <EditIcon />
                      </Link>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: 'center', fontStyle: 'italic' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
