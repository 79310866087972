import React, { useEffect, useState } from 'react'
import { fetchorderdisptchsummary } from '../../../services/apiService';
import './PaymentCommoncard.scss';
import ProcessingIcon from '../../../assets/icons/Processing.svg';
import dispetchpending from '../../../assets/icons/dipetchpending.svg';
import dispetched from '../../../assets/icons/dispetched.svg';
import cancelled from '../../../assets/icons/Cancel.svg';



import GreenIcon from '../../../assets/icons/green-up.svg';
export default function CommonCard({ startDate, endDate ,filterType}) {
    const [paymentdispeachsummary, setPaymentDispetchsummary] = useState([]);
    useEffect(() => {
        getData();
    }, [startDate, endDate]);

    const getData = async () => {
        try {
            const start_date = startDate
                ? startDate.toISOString().split('T')[0]
                : null;
            const end_date = endDate
                ? endDate.toISOString().split('T')[0]
                : null;



            const response = await fetchorderdisptchsummary('/payment/getpaymentcollectionsummary',{
                start_date,
                end_date,
              });
            if (response?.data) {
                setPaymentDispetchsummary(response.data || []);

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <div className='common-card-section-alignment'>
            <div className='order-card-grid'>

                <div className='order-card-grid-items'>
                    <div className='card-header-alignment'>
                        <div>
                            <span>Pending</span>
                            <h3>{paymentdispeachsummary?.totalPendingPayments}</h3>
                        </div>
                        <img src={ProcessingIcon} alt="ProcessingIcon" />
                    </div>
                    <div className='card-body-alignment'>
                        <div className='icon-text'>
                            <span>{`${paymentdispeachsummary?.pendingGrowthPercentage}%`}</span>
                            <img src={GreenIcon} alt="GreenIcon" />
                        </div>
                        <p>{`+${paymentdispeachsummary?.todayPendingPayments} ${filterType}`}</p>
                    </div>
                </div>

                <div className='order-card-grid-items'>
                    <div className='card-header-alignment'>
                        <div>
                            <span>Confirm</span>
                            <h3>{paymentdispeachsummary?.totalConfirmPayments}</h3>
                        </div>
                        <img src={dispetchpending} alt="dispetchpending" />
                    </div>
                    <div className='card-body-alignment'>
                        <div className='icon-text'>
                            <span>{`${paymentdispeachsummary?.confirmGrowthPercentage}%`}</span>
                            <img src={GreenIcon} alt="GreenIcon" />
                        </div>
                        <p>{`+${paymentdispeachsummary?.todayConfirmPayments} ${filterType}`}</p>
                    </div>
                </div>

                <div className='order-card-grid-items'>
                    <div className='card-header-alignment'>
                        <div>
                            <span>Received</span>
                            <h3>{paymentdispeachsummary?.totalReceivedPayments}</h3>
                        </div>
                        <img src={dispetched} alt="dispetched" />
                    </div>
                    <div className='card-body-alignment'>
                        <div className='icon-text'>
                            <span>{`${paymentdispeachsummary?.receivedGrowthPercentage}%`}</span>
                            <img src={GreenIcon} alt="GreenIcon" />
                        </div>
                        <p>{`+${paymentdispeachsummary?.todayReceivedPayments} ${filterType}`}</p>
                    </div>
                </div>

                <div className='order-card-grid-items'>
                    <div className='card-header-alignment'>
                        <div>
                            <span>Cancelled</span>
                            <h3>{paymentdispeachsummary?.totalCancelPayments}</h3>
                        </div>
                        <img src={cancelled} alt="cancelled" />
                    </div>
                    <div className='card-body-alignment'>
                        <div className='icon-text'>
                            <span>{`${paymentdispeachsummary?.cancelGrowthPercentage}%`}</span>
                            <img src={GreenIcon} alt="GreenIcon" />
                        </div>
                        <p>{`+${paymentdispeachsummary?.todayCancelPayments} ${filterType}`}</p>
                    </div>
                </div>

            </div>
        </div>
    )
}
