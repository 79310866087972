import { useEffect, useState, useRef } from 'react';
import { fetchCustomData, fetchData } from '../../../services/apiService';
import ViewIcon from '../../../assets/icons/view.svg';
import Pagination from '../../../shared/components/pagination';
import { Modal } from 'antd';
import mapboxgl from 'mapbox-gl';
import SelectBox from '../../../shared/components/select';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';


const MAPBOX_TOKEN = 'pk.eyJ1IjoicnV0dmlrbSIsImEiOiJjbHh2b3Zjcmkwd28zMm5zZ3BzYWJ4NXlkIn0.SnKNDTgcIO2Ar6dGfnXZbQ';

export default function CurrentLocationTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [currentLocationList, setCurrentLocationList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [searchuser, setSearchUser] = useState("");
  const [user, setUser] = useState([]);
  const [userrole, setUserrole] = useState([]);
  const [searchrole, setSearchrole] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [battery,setBattery]=useState("");
  const [status,setStatus]=useState("");
  const [filteredCurrentLocationList, setFilteredCurrentLocationList] = useState([]);


  
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    getData();
    getUser();
    getUserrole();
  }, [currentPage]);
  useEffect(() => {
    applyFilters(); 
  }, [searchuser, searchrole, battery, status, selectedDates, currentLocationList]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
     
      const response = await fetchCustomData('location_tracking/current', requestBody);
      if (response?.data) {
        setCurrentLocationList(response?.data);
        setFilteredCurrentLocationList(response?.data); 
        setTotalItems(response?.data.length); 
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const getUser = async () => {
    try {
      const response = await fetchData(`user`);
      if (response?.data) {
        setUser(
          response?.data?.map((i) => {
            return {
              label: `${i?.name}`,
              key: "user",
              value: {
                id: i?.id,
                name: `${i?.name}`,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getUserrole = async () => {
    try {
      const response = await fetchData(`userrole`);
      if (response?.data) {
        setUserrole(
          response?.data?.map((i) => {
            return {
              label: `${i?.role}`,
              key: "userrole",
              value: {
                id: i?.id,
                name: `${i?.role}`,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 

  const openMapModal = (latitude, longitude) => {
    setLat(latitude);
    setLong(longitude);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setLat(null);
    setLong(null);
    if (mapRef.current) {
      mapRef.current.remove(); 
      mapRef.current = null;
    }
  };

  const initializeMap = () => {
    if (!mapContainerRef.current || lat === null || long === null) return;
    if (mapRef.current) {
      mapRef.current.remove();
      mapRef.current = null;
    }


    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      center: [long, lat],
      zoom: 12,
      style: 'mapbox://styles/mapbox/streets-v11',
      accessToken: MAPBOX_TOKEN,
    });

    new mapboxgl.Marker()
      .setLngLat([long, lat])
      .addTo(mapRef.current);
    setTimeout(() => {
      if (mapRef.current) mapRef.current.resize();
    }, 0);
  };

  useEffect(() => {
    if (isModalOpen && lat !== null && long !== null) {
      initializeMap();
    }
  }, [isModalOpen, lat, long]);
  const onRangeChange = (dates) => {
    setSelectedDates(dates); 
  };
  const { RangePicker } = DatePicker;
  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];
 
  const applyFilters = () => {
    const hasFilters =
      searchuser || searchrole || battery || status || (selectedDates && selectedDates.length === 2);
  
    if (!hasFilters) {
      setFilteredCurrentLocationList(currentLocationList);
      setTotalItems(currentLocationList.length);
      return;
    }
  
    const filtered = currentLocationList.filter((item) => {
      const matchesUser = searchuser
        ? item.userName?.toLowerCase().includes(searchuser.toLowerCase())
        : true;
  
      const matchesRole = searchrole
        ? item.userRole?.toLowerCase().includes(searchrole.toLowerCase())
        : true;
  
      const matchesBattery = battery
        ? String(item.battery || '').includes(battery)
        : true;
  
      const matchesStatus = status
        ? item.locationType?.toLowerCase().includes(status.toLowerCase())
        : true;
  
      const matchesDateRange =
        selectedDates && selectedDates.length === 2
          ? dayjs(item.lastLocationDate).isSameOrAfter(selectedDates[0], 'day') &&
            dayjs(item.lastLocationDate).isSameOrBefore(selectedDates[1], 'day')
          : true;
  
      return matchesUser && matchesRole && matchesBattery && matchesStatus && matchesDateRange;
    });
  
    setFilteredCurrentLocationList(filtered);
    setTotalItems(filtered.length);
    const totalFilteredPages = Math.ceil(filtered.length / pageSize);
    if (currentPage > totalFilteredPages) {
      setCurrentPage(1); 
    }
  };
  

  const handlePageChange = (page) => {
    const totalPages = Math.ceil(totalItems / pageSize);
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Paginate the filtered data
  const paginatedData = filteredCurrentLocationList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div className="account-table-page-alignment">
      <div className="account-table-design">
        <table>
          <thead>
            <tr>
              <th><div >
                <SelectBox
                  value={
                    user?.find((option) => option.label === searchuser) || null
                  }
                  options={user || []}
                  onChange={(selectedOption) =>
                    setSearchUser(selectedOption?.label || "")
                  }
                  placeholder="User"
                  type="text"
                  required={true}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      maxHeight: '400px',
                      overflowY: 'auto',
                      border: 'none',
                    }),
                    control: (provided) => ({
                      ...provided,
                      minWidth: '100%',
                      border: 'none',
                      marginTop: '-8px',
                      maxHeight: '25px',
                      textAlign: 'left',
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      border: 'none',
                    }),
                  }}
                  menuPortalTarget={document.body}
                  isClearable={true}
                  components={{
                    ClearIndicator: (props) => (
                      <div
                        {...props.innerProps}
                        style={{
                          cursor: 'pointer',
                          padding: '5px',
                          color: 'gray',
                        }}
                      >
                        &#x2715;
                      </div>
                    ),
                  }}
                />
              </div></th>
              <th><div >
                <SelectBox
                  value={
                    userrole?.find((option) => option.label === searchrole) || null
                  }
                  options={userrole || []}
                  onChange={(selectedOption) =>
                    setSearchrole(selectedOption?.label || "")
                  }
                  placeholder="User"
                  type="text"
                  required={true}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      maxHeight: '400px',
                      overflowY: 'auto',
                      border: 'none',
                    }),
                    control: (provided) => ({
                      ...provided,
                      minWidth: '100%',
                      border: 'none',
                      marginTop: '-8px',
                      maxHeight: '25px',
                      textAlign: 'left',
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      border: 'none',
                    }),
                  }}
                  menuPortalTarget={document.body}
                  isClearable={true}
                  components={{
                    ClearIndicator: (props) => (
                      <div
                        {...props.innerProps}
                        style={{
                          cursor: 'pointer',
                          padding: '5px',
                          color: 'gray',
                        }}
                      >
                        &#x2715;
                      </div>
                    ),
                  }}
                />
              </div></th>
              <th>
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={[' Start Date', ' End Date']} className="my-custom-class" />
                </Space></th>
                <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Battery"
                value={battery}
                onChange={(e) => setBattery(e.target.value)}
                className="search-input input-height"
                style={{ border: 'none', width: '100%' }}

              /></div></th>
              <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="search-input input-height"
                style={{ border: 'none', width: '100%' }}

              /></div></th>
              <th><div style={{ width: '100%' }}></div></th>

            </tr>
            <tr>
              <th>User</th>
              <th>User Role</th>
              <th>Date</th>
              <th>Battery Level</th>
              <th>Status</th>
              <th>View Map</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.map((item, index) => (
              <tr key={index}>
                <td>{item?.userName ? item.userName : 'NA'}</td>
                <td>{item?.userRole ? item?.userRole : 'NA'}</td>
                <td>
                  {item?.lastLocationDate && item?.lastLocationTime
                    ? `${item.lastLocationDate} ${item.lastLocationTime}`
                    : 'NA'}
                </td>
                <td>{item?.battery ? `${item.battery}%` : 'NA'}</td>
                <td className="status">{item?.locationType ? item?.locationType : 'NA'}</td>
                <td>
                  <img
                    src={ViewIcon}
                    alt="View Map"
                    onClick={() => openMapModal(item.lastLocationLat, item.lastLocationLong)}
                    style={{ cursor: 'pointer' }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-alignment">
        <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <Modal
        title="Map Location"
        open={isModalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        width={800}
        style={{ top: 20 }}
        bodyStyle={{ padding: 0 }}
      >
        <div
          ref={mapContainerRef}
          style={{ width: '100%', height: '400px' }}
        />
      </Modal>
    </div>
  );
}
