import React, { useState } from "react";
import Button from "../../shared/components/button";
import { Link } from "react-router-dom";
import { deleteData, filterAll } from "../../services/apiService";
import CountryTable from "./countryTable";
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from "../../services/globalService";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import AccountTableHeader from "../account/accountTableHeader";

export default function CountryMaster() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`country/${id}`)));
      setActivityList(
        activityList.filter((item) => !selectedIds.includes(item?.id))
      );
      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  
  const handleExport = () => {
    const headers = [
      ["Name","Country ShortName","	Country Flag", "Status"]

    ];
 
    filterAll("country")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.countryName,
      item.countryShortname,
      item.countryFlag,
      item.status ? "Active" : "Inactive",  
      ]);
      exportToExcel('Country',headers,datas,'country')
    })
  };
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  

  return (
    <div className="page-24">
      <div className="">
        <div className="pb-4 customer-header-alignment">
          <div>
            <h2>Country</h2>
            <Breadcumbs activePath="Country" pageName="Country" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
              <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title="Are you sure to delete this record? "
              description="This will lead to delete all records related to this record only!"
              open={open}
              onConfirm={handleOk}
              okButtonProps={{ loading: confirmLoading }}
              onCancel={handleCancel}
            >
              <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
            </Popconfirm>
            )}
            <Link to="addEditcountry">
              <GlobalButton text="Add Country" Icon={PlusIcon} />
            </Link>
            <GlobalButton text="Export" lightbutton Icon={ExportIcon}  onClick={handleExport}/>
          </div>
        </div>
      </div>

      <CountryTable
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        activityList={activityList}
        setProductList={setActivityList}
        totalItems={totalItems}
        setTotalItems={setTotalItems}
      />
    </div>
  );
}
