import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import { postData } from "../../../../services/apiService";

export default function CustomerTimeComponent({ setCustomerList,customerList }) {
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        getData(currentPage, pageSize);
    }, [currentPage, pageSize]);

    const getData = async (page, pageSize) => {
        try {
            const requestBody = {
                page,
                limit: pageSize,
            };

            const response = await postData("order/time", requestBody);

            if (response?.data) {
                setCustomerList(response?.data );
                setTotalItems(response?.totalDocuments || 0);
            }
            console.log(response.data  ,"823e9082390e")
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div className="account-table-page-alignment">
            <div className="account-table-design">
                <table>
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Order</th>
                            <th>Customer</th>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Base Quantity</th>
                            <th>Sub Amount</th>
                        </tr>
                    </thead>
                    <tbody>

                        
                        {customerList && customerList.length > 0 ? (
                            customerList.map((item, index) => {
                           // Accessing the nested data for that key

                                return (
                                    <tr key={index}>
                                        <td>{item.month}</td>
                                        <td>{item.orders || "N/A"}</td>
                                        <td>{item.customers || "N/A"}</td>
                                        <td>{item.productsCount || "N/A"}</td>
                                        <td>{item.totalQuantity || "N/A"}</td>
                                        <td>{item.totalQuantity || "N/A"}</td>
                                        <td>{item.totalAmount || "N/A"}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="7" style={{ textAlign: "center" }}>
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Pagination
                    count={Math.ceil(totalItems / pageSize)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    size="small"
                />
            </div>
        </div>
    );
}
