import React from 'react'
import './assignedSection.scss';
import FilterIcon from '../../../assets/icons/filter-gray.svg';
import AssignIcon from '../../../assets/icons/assign.svg';
import FillWhiteButton from '../../../shared/components/fillWhiteButton';
import PrimaryButton from '../../../shared/components/primaryButton';
export default function AssignedSection() {
    return (
        <div className='assigned-section-alignment'>
            <FillWhiteButton iconpath={AssignIcon} text='Assigned to' />
            <div className='assigned-right-contnet-alignment'>
                <div className='btn-grp'>
                    <button>All Date</button>
                    <button>12 Months</button>
                    <button>30 Days</button>
                    <button className='active-button'>7 Days</button>
                    <button>24 Hour</button>
                </div>
                <FillWhiteButton iconpath={FilterIcon} text='Filters' />
                <PrimaryButton text="Add New Order" />
            </div>
        </div>
    )
}
