import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { fetchData } from '../../services/apiService';

export default function Calendar() {
  const [events, setEvents] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const fetchProduct = async () => {
    try {
      const responseProducts = await fetchData('myactivities');
      if (responseProducts?.data) {
        const transformedEvents = responseProducts.data.map((activity) => ({
          title: activity.activityname,
          start: activity.activitydate,
          end: activity.activitydate,
          description: activity.activityvalue,
          user: activity.username,
          linkname: activity.linkname,
          likeTo : activity.module
        }));

        setEvents(transformedEvents);
      }
      debugger
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, []);

  const openPopup = (info) => {
    setSelectedEvent({
      title: info.event.title,
      description: info.event.extendedProps.description,
      user: info.event.extendedProps.user,
      linkname: info.event.extendedProps.linkname,
      likeTo : info.event.extendedProps.likeTo
    });
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedEvent(null);
  };

  return (
    <div>
      <h1>Salesperson Activity Schedule</h1>
      <div style={{
        width: '100%',
        height: '70vh',
        maxHeight: '70%',
        overflow: 'auto',
      }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          events={events}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          selectable={true}
          editable={true}
          eventClick={(info) => openPopup(info)}
        />
      </div>

      {isPopupOpen && selectedEvent && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 999,
        }}>
          <div style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
            width: '400px',
            textAlign: 'center',
            maxHeight: '80vh',
            overflowY: 'auto',
          }}>
            <h2>{selectedEvent.title}</h2>
            <div style={{
              display: 'flex',
              gap: '4px',
              flexDirection: 'row',
              marginBottom: '10px',
              alignItems: 'center',
            }}>
              <p style={{ fontWeight: 'bold' }}>Description:</p>
              <p>{selectedEvent.description}</p>
            </div>
            <div style={{
              display: 'flex',
              gap: '4px',
              flexDirection: 'row',
              marginBottom: '10px',
              alignItems: 'center',
            }}>
              <p style={{ fontWeight: 'bold' }}>User:</p>
              <p>{selectedEvent.user}</p>
            </div>
            <div style={{
              display: 'flex',
              gap: '4px',
              flexDirection: 'row',
              marginBottom: '10px',
              alignItems: 'center',
            }}>
              <p style={{ fontWeight: 'bold' }}>Link To:</p>
              <p>{selectedEvent.likeTo}</p>
            </div>
            <div style={{
              display: 'flex',
              gap: '4px',
              flexDirection: 'row',
              marginBottom: '10px',
              alignItems: 'center',
            }}>
              <p style={{ fontWeight: 'bold' }}>Link Name:</p>
              <p>{selectedEvent.linkname}</p>
            </div>
            <button 
              onClick={closePopup} 
              style={{
                backgroundColor: '#007bff',
                color: 'white',
                padding: '7px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '12px',
                marginTop: '20px',
                display: 'flex',
                justifyContent : 'flex-end'
              }}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
