import React, { useState, useEffect } from 'react';
import './productunittable.scss';
import { fetchData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link } from 'react-router-dom';
import EditIcon from '../../../shared/icons/editIcon';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce'; 
export default function Productunittable({
  PriceList,
  totalItems,
  unitList = [], // Default empty array if undefined
  setProductList,
  setSelectedIds,
  setTotalItems,
  selectedIds = [], // Default empty array if undefined
  activityList = [] // Default empty array if undefined
}) {
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const pageSize = 10;
  const [UnitName, setUnitNameTerm] = useState("");
  const [productData, setProductData] = useState([]);
  const [DecimalPoint , setdecimalPointTerm] = useState("");
  const [Status, setStatusTrem] = useState("");
  const [allactivityType, setallactivityTypeTerm] =useState('');
  useEffect(() => {
    const filters = {
      typeName: UnitName,
      decimalPoint : DecimalPoint,
      status: Status
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(unitList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };
  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      // Define pagination settings
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const filterParams = {
        ...removeEmptyFields(filters), // Existing filters after cleaning
        typeCategory: 'unit',         // Ensure this filter is always included
      };
  
      // Fetch data with filters and pagination
      const responseProducts = await fetchData(
        'systemtype',
        filterParams, 
        pagination,
        {},
        isSearching,
        globalSearch
      );
  
      // Handle the response
      if (responseProducts?.data) {
        setProductData(responseProducts.data || []); // Update product data state
        setTotalItems(responseProducts?.totalDocuments || 0); // Update total items count
      } else {
        setProductData([]); 
      }
    } catch (error) {
      // Log any errors
      console.error('Error fetching data:', error);
    }
  };
  
  

  useEffect(() => {
    const filters = {
      typeName: UnitName,
      decimalPoint: DecimalPoint,
      status: Status,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [UnitName, DecimalPoint, Status, currentPage, pageSize]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['typeName'],
      searchtext: allactivityType,
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);

  return (
    <div className="">
      {/* <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div> */}
      <div className='table-v2-design'>
        <table>
          <thead>
          <tr>
              {checkAccess('manage_product_unit') && (
                <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedIds.length === activityList?.length} 
                  />
                </th>
              )}
              <th>Unit Name</th>
              <th>Decimal point</th>
              <th>Status</th>
              {checkAccess('manage_product_unit') && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {(productData ?? []).length > 0 ? (
              productData.map((i) => (
                <tr key={i.id}>
                  {checkAccess('manage_product_unit') && (
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(i.id)}
                        onChange={() => handleCheckboxChange(i.id)}
                      />
                    </td>
                  )}
                  <td>{i?.typeName || "N/A"}</td>
                  <td>{i?.decimalPoint || "N/A"}</td>
                  <td>
                    <button className={i?.status ? "active-btn" : "active-btn in-active-btn"}>
                      <div className='dot'></div>
                      {i?.status ? "Active" : "Inactive"}
                    </button>
                  </td>
                  {checkAccess('manage_product_unit') && (
                    <td>
                      <Link to={{ pathname: `edit/${i?.id}` }}>
                        <EditIcon />
                      </Link>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{ textAlign: "center", fontStyle: "italic", color: "#666" }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
      </div>
      {/* <div className="account-table-design">
        <table>
          <thead>
          <tr>
              <th className="table"><div></div></th>
             
              <th className="tablewidth"><div></div></th>
              <th><input type="text" placeholder="Unit Name" value={UnitName} onChange={(e) => setUnitNameTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th className="tablepoint"><input type="text" placeholder="Decimal point" value={DecimalPoint} onChange={(e) => setdecimalPointTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th style={{width:'10%'}}>
                <div>
                  <select
                    value={Status === undefined ? "" : Status.toString()} 
                    onChange={(e) => {
                      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
                      setStatusTrem(selectedStatus); 
                    }}
                    className="w-100 border-0 table-input-background"
                  >
                    <option value="">All</option>  
                    <option value="true">Active</option>    
                    <option value="false">Inactive</option>
                  </select>
                </div>
              </th>
            </tr>

            <tr>
              {checkAccess('manage_product_unit') && (
                <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={selectedIds.length === activityList?.length} 
                  />
                </th>
              )}
               {checkAccess('manage_product_unit') && <th>Action</th>}
              <th>Unit Name</th>
              <th>Decimal point</th>
              <th>Status</th>
             
            </tr>
          </thead>
          <tbody>
            {(productData ?? []).length > 0 ? (
              productData.map((i) => (
                <tr key={i.id}>
                  {checkAccess('manage_product_unit') && (
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(i.id)}
                        onChange={() => handleCheckboxChange(i.id)}
                      />
                    </td>
                  )}
                  {checkAccess('manage_product_unit') && (
                    <td>
                      <Link to={{ pathname: `edit/${i?.id}` }}>
                        <EditIcon />
                      </Link>
                    </td>
                  )}
                  <td>{i?.typeName || "N/A"}</td>
                  <td>{i?.decimalPoint || "N/A"}</td>
                  <td>
                    <button className={i?.status ? "Active" : "Inactive"}>
                      {i?.status ? "Active" : "Inactive"}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{ textAlign: "center", fontStyle: "italic", color: "#666" }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="pagination-alignment">
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div> */}
    </div>
  );
}
