import React from 'react'
import './PaymentTabFilterButton.scss';
import FilterIcon from '../../../assets/icons/filter.svg';
import Searchbar from '../../../shared/components/header/searchbar';
export default function TabFilterButton({ onFilterChange,activeFilter }) {
  return (
    <div>
       <div className='tab-filter-button-alignment'>
        <div className='tab-design'>
            <button className={activeFilter === 'Past Year' ? 'active-btn' : ''} onClick={() => onFilterChange('Past Year')}>12 Months</button>
            <button className={activeFilter === 'Past 30Days' ? 'active-btn' : ''} onClick={() => onFilterChange('Past 30Days')}>30 Days</button>
            <button className={activeFilter === 'Past 7Days' ? 'active-btn' : ''} onClick={() => onFilterChange('Past 7Days')}>7 Days</button>
            <button className={activeFilter === 'Past 24Hour' ? 'active-btn' : ''} onClick={() => onFilterChange('Past 24Hour')}>24 Hour</button>
        </div>
      <div className='right-contnet-alignment'>
         <input type='text' placeholder='Select Dates'/> 
        <button>
        <img src={FilterIcon} alt="FilterIcon"/>
        Filters
        </button> 
      </div>
    </div>
    </div>
  )
}
