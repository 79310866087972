import { React, useEffect, useState, useRef, useContext } from "react";
import "./account.scss";
import Button from "../../shared/components/button";
import Accounttable from "./accounttable";
import { Link } from "react-router-dom";
import { fetchData, deleteData, filterAll } from "../../services/apiService";
import { Modal } from "antd";
import { UserContext } from "../../contexts/UserContext";
import { useSelector } from "react-redux";
import Userrole from "../userrole";
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
// import Searchbar from "../../shared/components/header/searchbar";
import AccountTableHeader from "./accountTableHeader";
import * as XLSX from "xlsx";
import { exportToExcel } from "../../services/globalService";
import { checkAccess } from "../../services/checkFeature";
import CaretIcon from "../../shared/icons/caretIcon";
import FillWhiteButton from "../../shared/components/fillWhiteButton";
import ExportIconGray from '../../assets/icons/export-gray.svg';
import PrimaryButton from "../../shared/components/primaryButton";
import PageSubHeader from "../../shared/components/pageSubHeader";
export default function Account() {
  const [data, setData] = useState([]);

  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);

  const mapContainerRef = useRef(null);
  let map;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`customer/${id}`)));
      setActivityList(
        activityList.filter((item) => !selectedIds.includes(item.customerId))
      );
      setSelectedIds([]);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const initMap = () => {
    // Initialize the map
    map = new window.google.maps.Map(mapContainerRef.current, {
      center: { lat: lat, lng: long },
      zoom: 8,
    });

    const marker = new window.google.maps.Marker({
      position: { lat: lat, lng: long },
      map: map,
      icon: {
        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", // URL to the red pin icon
      },
    });
  };

  useEffect(() => {
    if (isModalOpen && lat && long) {
      // Load the Google Maps API script
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?callback=initMap&key=AIzaSyCU15g1Uxh5ZJU-X2LZh6HjhhQwMRzFGPU`;
      script.async = true;
      document.body.appendChild(script);
      script.onload = initMap;
    }
  }, [isModalOpen, lat, long]);

  // useEffect(() => {
  //   fetchData("customer")
  //     .then((data) => {
  //       setData(data.documents);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  const buttonClicked = (lat, long) => {
    showModal();
    setLat(lat);
    setLong(long);
  };

  const user = useSelector((state) => state.user.user);

  const handleExport = () => {
    const headers = [
      ["Customer Name", "Code", "Price Group", "Email", "Contact No", "Status", "Created By"]
    ];

    filterAll("customer")
      .then((data) => {
        const datas = (data.data ?? [])?.map((item) => [
          item.cName,
          item.code,
          item.priceGroup,
          item.addressEmail,
          item.addressContact,
          item.status,
          item.created_by
        ]);
        exportToExcel('Customer', headers, datas, 'customer')
      })
  };

  // 'true'===tf
  {
    return (
      <>
        <div className="design-v2-header">
          <div className="design-v2-left">
            <h2>
              Costumer
            </h2>
            <div className="design-breadcumb">
              <span>Customers</span>
              <CaretIcon />
            </div>
          </div>
          <div className="design-v2-right">
            {checkAccess('export_account') && <FillWhiteButton text="Export" iconpath={ExportIconGray} />}
            {checkAccess('add_account') && <Link to="addcustomer">
              <PrimaryButton text="Add Costumer" />
            </Link>}

          </div>
        </div>
        <PageSubHeader />
        <div className="design-v2-left-right-alignment">
          <Accounttable
            data={data}
            buttonClicked={buttonClicked}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            activityList={activityList}
            setActivityList={setActivityList}
          />
        </div>
        <div className="page-24">
          {/* <div className="customer-header-alignment pb-4">
            <div>
              <h2>Customers</h2>
              <Breadcumbs activePath="Customer" pageName="Customers" />
            </div>
            <div className="right-contnet-alignment">
              {checkAccess('add_account') && <Link to="addcustomer">
                <GlobalButton text="Add Customer" Icon={PlusIcon} />
              </Link>}
              {checkAccess('export_account') && <GlobalButton text="Export" lightbutton Icon={ExportIcon} />}
            </div>
          </div> */}


          <Modal
            title="Basic Modal"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div
              ref={mapContainerRef}
              style={{ width: "100%", height: "400px" }}
            />
          </Modal>
        </div>
      </>
    );
  }
}
