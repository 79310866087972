import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import {
    Box,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Grid,
} from "@mui/material";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ShowImage from '../../../shared/components/showImage';
import store from '../../../redux/reduxstore';
export default function AddselesReturnView() {
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.user.user);
    const name = useSelector(state => state.auth.name);
    const userData = store.getState().auth.userData


    const userDataRef = useRef(userData);
    useEffect(() => {
        // Update the ref whenever isExamStarted changes
        userDataRef.current = userData;
    }, [userData]);

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);



    const [formData, setFormData] = useState({
        productDetails: [],
        orderupdatedby: '',
        yetscreen:false,


    });
    const [newNotification, setNewNotification] = useState([]);
    const [timelines, setTimelines] = useState([]);

    useEffect(() => {

        const getData = async () => {
            const requestBody = {
                "reference_type": "salesreturn",
                reference_id: id
            }

            const responsetimeline = await fetchData("timeline", requestBody);
            console.log(responsetimeline);
            if (responsetimeline?.data) {
                setTimelines(responsetimeline.data);
            }
        }

        getData()

    }, [newNotification])

    const handleSubmit = async (e, status, newStatus) => {
        e.preventDefault(); // Prevent default form submit behavior

        try {
            const allowedStatuses = ['Open', 'Close', 'Pending', 'Confirmed', 'Dispatched', 'Delivered', 'Approved', 'Rejected'];
            if (!allowedStatuses.includes(status)) {
                console.error('Invalid salesreturn status:', status);
                return;
            }

            const currentDateTime = dayjs().format('DD-MM-YYYY,  hh:mm:ss A');
            const updatedNotification = { date: currentDateTime, status: status, user: name }; // Store notification with the status

            // Update the new notification in the state
            setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);

            // Prepare the updated form data, including the status change
            const updatedFormData = {
                salesReturnstatus: status, // Use the new field name 'salesReturnstatus' for the status
                yetscreen: false,
            };

            // If an ID exists, update the record; otherwise, create a new salesreturn
            if (id) {
                const data = await patchData(`salesreturn/${id}`, updatedFormData);
                if (data?.data) {
                    setFormData({ ...formData, salesReturnstatus: status }); // Update form data with the new status
                    navigate('/salesReturn');
                }
            } else {
                const data = await postData('salesreturn', updatedFormData); // Create new salesreturn
                if (data) navigate(-1); // Navigate back if the creation is successful
            }
        } catch (error) {
            console.error('Error saving data:', error); // Handle any errors
        }
       
        e.preventDefault();
        setStatus(newStatus);
    };





    const getData = async () => {
        try {
            const rs = await fetchsingleData(`salesreturn`, id);
            if (rs?.data) setFormData(rs.data);
            if (rs?.data.ordernotification) setNewNotification(rs.data.ordernotification)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const [profile, setprofile] = useState([]);
    const [profileList, setProfileList] = useState([]);

    useEffect(() => {
        getPofile();
    }, []);

    const getPofile = async () => {
        try {
            const response = await fetchData('companyprofile');
            if (response?.data && response.data.length > 0) {
                setprofile(response.data[0]); // Set the first company's data to the state
                setProfileList(response.data); // If needed for another part of the app
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const calculateTotalAmount = () => {
        return formData.productDetails && formData.productDetails.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.total);
        }, 0).toFixed(2);
    };
    const appDataJSON = localStorage.getItem('users');
    const appData = JSON.parse(appDataJSON);
    const [status, setStatus] = useState(null);
    const pdfRef = useRef();
    const handleGeneratePdf = () => {
        const pdfWidth = 420;
        const pdfHeight = 370;
    
        const doc = new jsPDF({
          orientation: 'b5',
          unit: 'px',
          format: [pdfWidth, pdfHeight],
        });
        doc.html(pdfRef.current, {
          callback: (doc) => {
            const pdfBlob = doc.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            const newWindow = window.open(pdfUrl);
            if (newWindow) {
              newWindow.onload = () => {
                newWindow.print();
              };
            } else {
              console.error('Failed to open new window for printing');
            }
          },
          x: 20,
          y: 20,
          z: 20,
          width: pdfWidth - 20,
          windowWidth: 970,
        });
      };
      useEffect(() => {
        const saveYetscreen = async () => {
          const updatedFormData = {
            ...formData,
            yetscreen: false,
          };
    
          try {
            if (id) {
              await patchData(`salesreturn/${id}`, updatedFormData);
             
            } 
          } catch (error) {
            console.error("Error saving yetscreen:", error);
          }
        };
    
        saveYetscreen();
      }, []); 
    


    return (

        <Box p={4}>
            {/* Header Section */}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                border="1px solid #ccc" // Border around the Box
                borderRadius="8px"      // Optional: Rounded corners for the Box
                p={2}                   // Padding inside the Box
            >

                <Typography variant="h5" onClick={() => navigate('/salesReturn')} style={{ cursor: 'pointer' }}>
                    {'<'} Sales Return  #{formData.salesReturnNo}
                </Typography>

                <Box>

                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{ mr: 1 }}
                    // onClick={(e) => handleSubmit(e, 'Pending')} // Example status
                    >
                        Make Unseen
                    </Button>
                    {formData.salesReturnstatus !== 'Approved' && (
                        <Button
                            variant="contained"
                            color="success"
                            sx={{ mr: 1 }}
                            onClick={(e) => handleSubmit(e, 'Approved')}
                        >
                            Approve
                        </Button>
                    )}

                    {formData.salesReturnstatus !== 'Rejected' && (
                        <Button
                            variant="contained"
                            color="error"
                            sx={{ mr: 1 }}
                            onClick={(e) => handleSubmit(e, 'Rejected')}
                        >
                            Reject
                        </Button>
                    )}

                    <Button
                        variant="contained"
                        sx={{
                            mr: 1,
                            padding: '8px 16px', // Adjust padding to control the button size
                            fontSize: '14px', // Adjust font size to fit the button content
                            height: '36px', // Adjust height to control button height
                            minWidth: '60px', // Set a minimum width for the button
                        }}
                        onClick={() => navigate(`/salesReturn/add/${formData.salesReturnNo}`)}
                    >
                        <i className="fa-solid fa-pencil" style={{ fontSize: '18px' }}></i> {/* Adjust icon size */}
                    </Button>

                    <Button>
                        <div onClick={handleGeneratePdf} style={{ cursor: 'pointer' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                                <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                            </svg>
                        </div>
                    </Button>

                </Box>
            </Box>


            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }} ref={pdfRef}>
                <div style={{ width: '75%', display: 'flex', flexDirection: 'column', padding: '15px' }}>
                    <Box mb={4}>
                        <Grid container >
                            <Grid item>
                                <div className="profile-info__avatar mr-4">
                                    <ShowImage value={profile?.profileUrl}></ShowImage>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    {profile.name}
                                </Typography>
                                <Typography>{profile.contactNo} | {profile.email}</Typography>
                                <Typography>GST No: {profile.GST}</Typography>
                            </Grid>

                            <Grid item xs={4} fontWeight="bold" sx={{ fontSize: '1.25rem' }}>
                                    Sales Return #{formData.salesReturnNo}
                            </Grid>
                        </Grid>
                    </Box>



                    <Box mb={4} display="flex" justifyContent="space-between">
                        <Box p={2}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Sales Return From
                            </Typography>
                            <Typography>
                                <strong>{formData.customerName}</strong>
                            </Typography>
                            <Typography>
                                {formData.customerAddress}
                            </Typography>
                            <Typography>{formData.customercontact}</Typography>
                        </Box>


                        <Box >
                            <Typography variant="body2">
                                Sales Return Date: <strong>{dayjs(formData.orderDate).format('DD-MM-YYYY')}</strong>
                            </Typography>
                            <Typography variant="body2">
                                Sales Return Status: <strong>Pending</strong>
                            </Typography>
                            <Typography variant="body2">
                                Created By: <strong>{userData.name}</strong>
                            </Typography>
                        </Box>
                    </Box>


                    <Typography sx={{ border: '1px solid black', color: 'black' ,mt: 10 }}>
                    </Typography>


                    <Typography variant="body2" fontWeight="bold" mb={2} sx={{ mt: 4 }}>
                        Reason: <span style={{ fontWeight: "normal" }}>{formData.reason}</span>
                    </Typography>

                    
                    <Table style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>#</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Item</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Category</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Quantity</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Price</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Discount</TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {formData.productDetails && formData.productDetails.length > 0 && formData.productDetails.map((product, index) => (
                                <TableRow key={product.id}>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>{index + 1}</TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>{product.name}</TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>{product.unitName}</TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>{product.qty}</TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>₹ {product.unitPrice ? product.unitPrice.toFixed(2) : '0.00'}</TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>{product.discount}%</TableCell>
                                    <TableCell style={{ border: '1px solid black', padding: '8px' }}>₹ {product.subTotal ? product.subTotal.toFixed(2) : '0.00'}</TableCell>
                                </TableRow>
                            ))}
                            {/* Subtotal Row */}
                            <TableRow>
                                <TableCell colSpan={6} style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}><strong>Subtotal</strong></TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                    ₹ {formData.productDetails.reduce((acc, product) => acc + (product.subTotal || 0), 0).toFixed(2)}
                                </TableCell>
                            </TableRow>
                            {/* Total Row */}
                            <TableRow>
                                <TableCell colSpan={6} style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}><strong>Total</strong></TableCell>
                                <TableCell style={{ border: '1px solid black', padding: '8px' }}>
                                    ₹ {formData.productDetails.reduce((acc, product) => acc + (product.subTotal || 0), 0).toFixed(2)}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </div>

                <div style={{
                    marginTop: '1rem',
                    padding: '1.5rem',
                    backgroundColor: '#E5E5E5',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                    maxWidth: '400px',
                    marginLeft: '5px',
                    width: '400px',
                    scrollBehavior: 'smooth',
                    height: 'auto',
                    overflowY: 'auto',
                    flex: 1  // Ensures the second div stretches equally with the first
                }}>
                    <div style={{ marginTop: '-8px', paddingBottom: '15px', fontWeight: 'bold', fontSize: '20px' }}><strong>Timeline</strong></div>

                    <div

                        style={{
                            display: "flex",
                            paddingLeft: "3px",
                            paddingBottom: "15px",
                        }}
                    >
                        <div style={{ paddingRight: "5px" }}>➨</div>
                        <div style={{ fontSize: "14px" }}>
                            Sales Return <strong>{formData.salesReturnNo}</strong> generated by
                            <strong>  {userData.name}</strong> on <strong> {dayjs(formData.selesreturnData).format('DD-MM-YYYY, hh:mm:ss A')}</strong>.
                        </div>
                    </div>
                    {/* ))} */}
                </div>
            </div>

            <Box mt={4}>
                <Typography variant="subtitle2" fontWeight="bold">
                    Terms & Conditions
                </Typography>
            </Box>


        </Box>

    );
};
