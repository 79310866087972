import { useState } from "react";
import Button from "../../shared/components/button";
import Vehicletable from "./vehicletable";
import { Link } from "react-router-dom";
import { deleteData, filterAll } from "../../services/apiService";
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import { checkAccess } from "../../services/checkFeature";
import * as XLSX from "xlsx";
import { exportToExcel } from "../../services/globalService";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import AccountTableHeader from "../account/accountTableHeader";
export default function Vehicle() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`vehicle/${id}`)));
      setVehicleList(vehicleList.filter((i) => !selectedIds.includes(i.id)));

      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  return (
    <div className="page-24">
      <div className="">
        <div className="pb-4 customer-header-alignment">
          <div>
            <h2>Vehicle</h2>
            <Breadcumbs activePath="Vehicle" pageName="Vehicle" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
             <Popconfirm
             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             title="Are you sure to delete this record? "
             description="This will lead to delete all records related to this record only!"
             open={open}
             onConfirm={handleOk}
             okButtonProps={{ loading: confirmLoading }}
             onCancel={handleCancel}
           >
             <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
           </Popconfirm>
            )}
           <Link to="addvehicle">
              <GlobalButton text="Add Vehicle" Icon={PlusIcon} />
            </Link>
           <GlobalButton text="Export" lightbutton Icon={ExportIcon} />
          </div>
        </div>
      </div>

      <Vehicletable
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        vehicleList={vehicleList}
        setProductList={setVehicleList}
        totalItems={totalItems}
        setTotalItems={setTotalItems}
      />
    </div>
  );
}
