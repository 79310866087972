import React from "react";
import {
  Box,
  Typography,
  Button,
  Tab,
  Tabs,
} from "@mui/material";
import CustomerComponent from "./ CustomerComponent";
import UserComponent from "./user";
import AreaComponent from "./AreaComponent";
import TimeComponent from "./Time";
import ProductComponent from "./Product";
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import { Link } from "react-router-dom";
import SaleComponent from "./user";
import SaleUserComponent from "./user";
import SalesTimeComponent from "./Time";
import SalesProductComponent from "./Product";
import SalesAreaComponent from "./AreaComponent";
import SalesCustomerComponent from "./ CustomerComponent";
import AccountTableHeader from "../account/accountTableHeader";
import PaymentCustomerComponent from "./ CustomerComponent";
import PaymentAreaComponent from "./AreaComponent";
import PaymentTimeComponent from "./Time";
import StatusComponent from "./Product";
import PaymentUserComponent from "./user";

// Main Component
export default function PaymaentReport() {
  const [mainTabValue, setMainTabValue] = React.useState(0); // Tracks main tabs like Customer, User, Area, etc.

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  // Function to render the corresponding component based on selected tab
  const renderTabContent = () => {
    switch (mainTabValue) {
      case 0:
        return <PaymentCustomerComponent />;
      case 1:
        return <PaymentUserComponent />;
      case 2:
        return <PaymentAreaComponent />;
      case 3:
        return <PaymentTimeComponent />;

      case 4:
        return <StatusComponent />;
      default:
        return <Typography variant="h6">No Data Available</Typography>;
    }
  };

  return (
    <div className="page-px-24">
      <div className="customer-header-alignment pb-4">
        <div>
          <h2>Payment Collection Report by </h2>
          <Breadcumbs activePath="Payment Collection Report" pageName="Payment Collection Report" />
        </div>
        <div className="right-contnet-alignment">
          <GlobalButton text="PRINT" />
          <GlobalButton text="EXPORT" lightbutton />
        </div>
      </div>
      <AccountTableHeader/>
        <Tabs value={mainTabValue} onChange={handleMainTabChange}>
          <Tab label="Customer" />
          <Tab label="User" />
          <Tab label="Area" />
          <Tab label="Time" />
          <Tab label="status" />
        </Tabs>

        {/* Content Rendering */}
        <div className="pt-4">{renderTabContent()}</div>
    </div>
  );
}
