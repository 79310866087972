import { useEffect, useState } from 'react';
import { fetchData, postData } from '../../../services/apiService';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import SelectBox from '../../../shared/components/select';
import GlobalButton from '../../../shared/globalButton';

const { RangePicker } = DatePicker;

export default function Attendacewithleave() {
  const [mergedList, setMergedList] = useState([]);
  const [formData, setFormData] = useState({});
  const [UserName, setUserName] = useState([]);
  const [selectedDates, setSelectedDates] = useState([
    dayjs().startOf('month'), // Default to current month's start
    dayjs(), // Default to today's date
  ]);

  const rangePresets = [
    {
      label: 'Current Month',
      value: [dayjs().startOf('month'), dayjs()],
    },
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Previous Month',
      value: [
        dayjs().subtract(1, 'month').startOf('month'),
        dayjs().subtract(1, 'month').endOf('month'),
      ],
    },
   
    {
      label: 'Last Year',
      value: [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    },
  ];

  useEffect(() => {
    // Trigger data fetch for default dates
    const [defaultFromDate, defaultToDate] = selectedDates;
    getData(null, defaultFromDate.format('YYYY-MM-DD'), defaultToDate.format('YYYY-MM-DD'));
  }, []);

  const getData = async (user_id = null, from_date = null, to_date = null) => {
    try {
      const response = await postData('attendance/userWiseLeave', {
        created_at: new Date().toISOString(),
        user_id,
        from_date,
        to_date,
      });

      setMergedList(response?.data || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      setMergedList([]);
    }

    try {
      const userResponse = await fetchData('user');
      if (userResponse?.data) {
        setUserName(
          userResponse.data.map((user) => ({
            label: user.name,
            key: 'userid',
            value: user.id,
            name: user.name,
          }))
        );
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const onRangeChange = (dates) => {
    setSelectedDates(dates); // Update the state with the selected date range
    setFormData((prevState) => ({
      ...prevState,
      fromdate: dates ? dates[0] : null,
      todate: dates ? dates[1] : null,
    }));
  };

  const selectChanged = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      user_id: selectedOption.value,
      username: selectedOption.name,
    }));
  };

  const handleApply = async (e) => {
    e.preventDefault();
    setMergedList([]);
    const { user_id, fromdate, todate } = formData;
    await getData(
      user_id,
      fromdate ? fromdate.format('YYYY-MM-DD') : null,
      todate ? todate.format('YYYY-MM-DD') : null
    );
  };

  return (
    <div className="attendance-page-container">
      <div className="customer-page-main-grid">
        <div>
          <div className="new-white-box-design">
            <div className="account-table-design">
              <table>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Total Days</th>
                    <th>Present Day</th>
                    <th>Absent Day</th>
                    <th>Leave Days</th>
                    <th>Approved Leaves</th>
                    <th>Pending Leaves</th>
                    <th>Presence(%)</th>
                  </tr>
                </thead>
                <tbody>
                  {mergedList.length > 0 ? (
                    mergedList.map((item, index) => (
                      <tr key={index}>
                        <td>{item.username}</td>
                        <td>{item.totalDays}</td>
                        <td>{item.totalPresentDays}</td>
                        <td>{item.totalAbsentDays}</td>
                        <td>{item.totalLeaves}</td>
                        <td>{item.approvedLeaves}</td>
                        <td>{item.pendingLeaves}</td>
                        <td>{item.workingPercentage}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" style={{ textAlign: 'center' }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div className="new-white-box-design">
            <form onSubmit={handleApply}>
              <div className="py-3">
                <Space direction="vertical" size={12}>
                  <RangePicker
                    presets={rangePresets}
                    value={selectedDates} // Always show selected dates
                    onChange={onRangeChange}
                    placeholder={['Start Date', 'End Date']}
                    className="my-custom-class"
                  />
                </Space>
              </div>
              <div className="py-3">
                <label className="m-0">User</label>
                <SelectBox
                  name="username"
                  value={UserName?.find((i) => i.name === formData.username)}
                  onChange={selectChanged}
                  options={UserName}
                  placeholder="User"
                  required
                />
              </div>
              <div className="d-flex justify-content-center">
                <GlobalButton text="Apply" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
