import React from 'react';
import './pagination.scss';
import LeftArrow from '../../icons/leftArrow';
import RightArrow from '../../icons/rightArrow';

export default function Pagination({ currentPage, totalItems, pageSize, onPageChange }) {
  const totalPages = Math.ceil(totalItems / pageSize);

  const handleClick = (page) => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const range = 4; // Number of pages to show before and after the current page

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 || // Always show the first page
        i === totalPages || // Always show the last page
        (i >= currentPage - range && i <= currentPage + range) // Show pages within range
      ) {
        pageNumbers.push(
          <div
            key={i}
            className={`pagination-box ${currentPage === i ? 'active' : ''}`}
            onClick={() => handleClick(i)}
            style={{ cursor: 'pointer' }}
          >
            {i}
          </div>
        );
      } else if (
        (i === currentPage - range - 1 && i !== 1) || // Ellipsis before the current range
        (i === currentPage + range + 1 && i !== totalPages) // Ellipsis after the current range
      ) {
        pageNumbers.push(
          <div key={i} className="pagination-ellipsis">
            ...
          </div>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div className='custom-pagination'>
      <span>
        Showing {(currentPage - 1) * pageSize + 1}- 
        {Math.min(currentPage * pageSize, totalItems)} of {totalItems}
      </span>
      <div className='right-alignment'>
        <div
          className='previous-btn'
          onClick={() => handleClick(currentPage - 1)}
          style={{ cursor: currentPage > 1 ? 'pointer' : 'not-allowed' }}
        >
          <LeftArrow/>
      Previous
        </div>
        {renderPageNumbers()}
        <div
           className='previous-btn'
          onClick={() => handleClick(currentPage + 1)}
          style={{ cursor: currentPage < totalPages ? 'pointer' : 'not-allowed' }}
        >
          Next
         <RightArrow/>
        </div>
      </div>
    </div>
  );
}
