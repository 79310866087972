import React, { useState, useEffect } from 'react';
import { fetchData, patchData, postData, fetchsingleData } from '../../../services/apiService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import Toggle from '../../../shared/components/switch';
import './adduserrole.scss';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';

export default function AddEditUserRole() {
    const navigate = useNavigate();
    const { id } = useParams();
    const isViewMode = window.location.pathname.includes('/view');
    const [formData, setFormData] = useState({
        role: '',
        status: true,
        permissions: {},
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const allFeaturesResponse = await fetchData('feature');
                console.log(allFeaturesResponse,"allfeatureresponse");
                let groupedPermissions = {};
                if (allFeaturesResponse?.data) {
                    groupedPermissions = allFeaturesResponse.data.reduce((acc, item) => {
                        const groupName = item.group_display_name || 'Others';
                        acc[groupName] = acc[groupName] || [];
                        acc[groupName].push({
                            id: item.id,
                            displayName: item.display_name,
                            name: item.name,
                            status: false,
                        });
                        return acc;
                    }, {});
                }
                let roletype = '';
                let roleStatus = '';

                if (id) {
                    const singleRoleResponse = await fetchsingleData('userrole', id);
                    if (singleRoleResponse?.data) {
                        const { role, status, features } = singleRoleResponse.data;

                        roletype = role;
                        roleStatus = status;
                    }

                    if (singleRoleResponse?.data?.features) {
                        const selectedFeatures = singleRoleResponse.data.features.map(
                            (feature) => feature.name
                        );

                        Object.entries(groupedPermissions).forEach(([groupName, permissions]) => {
                            groupedPermissions[groupName] = permissions.map((perm) => ({
                                ...perm,
                                status: selectedFeatures.includes(perm.displayName),
                            }));
                        });
                    }
                }

                setFormData((prev) => ({
                    ...prev,
                    role: roletype,
                    status: roleStatus,
                    permissions: groupedPermissions,
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchAllData();
    }, [id]);

    const handlePermissionChange = (groupName, permissionId, checked) => {
        if (isViewMode) return; 
        setFormData((prev) => ({
            ...prev,
            permissions: {
                ...prev.permissions,
                [groupName]: prev.permissions[groupName].map((perm) =>
                    perm.id === permissionId ? { ...perm, status: checked } : perm
                ),
            },
        }));
    };
    const validationCheck = (isSubmit) => {
        const validationErrors = {};
        if (!formData.role) {
            validationErrors.role = "Enter RoleType";
        }
        if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

        setErrors(validationErrors);
        return (
            Object.keys(validationErrors).filter((i) => i !== "isSubmit").length === 0
        );
    };

    const handleInputChange = (e) => {
        if (isViewMode) return; 
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isViewMode) return; 
        if (validationCheck()) {
            try {
                const selectedPermissions = Object.entries(formData.permissions).reduce(
                    (acc, [groupName, permissions]) => {
                        permissions.forEach((permission) => {
                            if (permission.status) {
                                acc.push({
                                    id: permission.id,
                                    name: permission.displayName,
                                    code: permission.name,
                                });
                            }
                        });
                        return acc;
                    },
                    []
                );

                const payload = {
                    role: formData.role,
                    status: formData.status,
                    features: selectedPermissions,
                };

                if (id) {
                    patchData(`userrole/${id}`, payload).then(() => {
                        navigate(-1);
                    });
                } else {
                    postData('userrole', payload).then(() => {
                        navigate(-1);
                    });
                }
            } catch (error) {
                console.error('Error submitting data:', error);
            }
        }
    };

    return (
        <div className='page-24'>
            <div className='add-header-design'>
                <div>
                    <h2>Add User Role</h2>
                    <Breadcumbs activePath="Dashboard" pageName="Add User Role" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/user-role">
                        <button className='light-button'>

                            Discard
                        </button>
                    </Link>
                    <button onClick={handleSubmit}>
                        Save
                    </button>
                </div>
            </div>
            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid'>
                            <Input
                                label={
                                    <span>
                                        RoleType<span className="required-star">*</span>
                                    </span>}
                                name="role"
                                value={formData.role}
                                onChange={handleInputChange}
                                placeholder="Enter RoleType.."
                                type="text"
                                required
                                disabled={isViewMode} // Disable in view mode
                                errors={errors.role}
                            />
                            <div className='d-flex align-align-items-end'>
                                <Toggle
                                    label="Status"
                                    checked={formData.status}
                                    onChange={(checked) =>
                                        !isViewMode &&
                                        setFormData((prev) => ({ ...prev, status: checked }))
                                    }
                                    disabled={isViewMode} // Disable in view mode
                                />
                            </div>
                        </div>
                    </div>
                    {Object.entries(formData.permissions || {}).map(([groupName, permissions]) => (
                        <div key={groupName} className='new-white-box-design'>
                            <div className="group-header" style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f5f5f5', padding: '10px 15px', }}>
                                <input
                                    type="checkbox"
                                    className="group-checkbox"
                                    checked={permissions.every((perm) => perm.status)}
                                    onChange={(e) => {
                                        if (isViewMode) return; // Prevent changes in view mode
                                        const checked = e.target.checked;
                                        const updatedPermissions = permissions.map((perm) => ({
                                            ...perm,
                                            status: checked,
                                        }));
                                        setFormData((prevState) => ({
                                            ...prevState,
                                            permissions: {
                                                ...prevState.permissions,
                                                [groupName]: updatedPermissions,
                                            },
                                        }));
                                    }}
                                    disabled={isViewMode} // Disable in view mode
                                />
                                <label
                                    htmlFor={groupName}
                                    className="group-name"
                                    style={{ marginLeft: '8px', fontWeight: 'bold' }}
                                >
                                    {groupName}
                                </label>
                            </div>
                            <hr style={{ margin: 0 }} />
                            <div
                                className="permissions-grid"
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(4, 1fr)',
                                    gap: '10px',
                                    padding: '15px',
                                }}
                            >
                                {permissions.map((permission) => (
                                    <div
                                        key={permission.id}
                                        className="form-check"
                                        style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="permission-checkbox"
                                            checked={permission.status}
                                            onChange={(e) =>
                                                handlePermissionChange(
                                                    groupName,
                                                    permission.id,
                                                    e.target.checked
                                                )
                                            }
                                            disabled={isViewMode} // Disable in view mode
                                        />
                                        <label
                                            htmlFor={permission.id}
                                            className="permission-label"
                                            style={{ marginLeft: '8px' }}
                                        >
                                            {permission.displayName}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
        
            </div>





        </div>
    );
}
