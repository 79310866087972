import React, { useEffect, useState } from "react";
import { Pagination } from "@mui/material"; // Assuming Pagination is an existing component
import { postData } from "../../../../services/apiService"; // Adjusted import based on your previous example

export default function EstimateUserComponentTableComponent({ customerList, setCustomerList }) {
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    // Fetch data on component mount and when currentPage or pageSize changes
    useEffect(() => {
        getData(currentPage, pageSize);
    }, [currentPage, pageSize]);

    // Function to fetch data from API
    const getData = async (page, pageSize) => {
        try {
            const requestBody = {
                page,
                limit: pageSize,
            };

            const response = await postData("estimate/user", requestBody);


            if (response?.data) {
                setCustomerList(response?.data || []);
                setTotalItems(response?.totalDocuments || 0);
            }
            
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    return (
        <div className="account-table-page-alignment">
            <div className="account-table-design">
                <table>
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Estimate</th>
                            <th>customer</th>
                            <th>lead</th>
                            <th> product</th>
                            <th>Quantity</th>
                            <th>Base Quantity</th>
                            <th>Sub Amount</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customerList && customerList.length > 0 ? (
                            customerList.map((item, index) => (
                                item?.data!==null &&
                                    <tr key={index}>
                                    <td>{item?.userName || "No data available"}</td>
                                    <td>{item?.totalEstimates || "N/A"}</td>
                                    <td>{item?.totalCustomers || "N/A"}</td>
                                    <td>{item?.totalLeads || "N/A"}</td>
                                    <td>{item?.totalProducts || "N/A"}</td>
                                    <td>{item?.totalQuantity || "N/A"}</td>
                                    <td>{item?.totalQuantity || "N/A"}</td>
                                    <td>{item?.totalAmount || "N/A"}</td>
                                    <td>{item?.totalAmount || "N/A"}</td>
                                </tr>
                                
                             
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" style={{ textAlign: 'center' }}>No data available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Pagination
                    count={Math.ceil(totalItems / pageSize)} // Total pages
                    page={currentPage} // Current page
                    onChange={handlePageChange} // Page change handler
                    color="primary"
                    size="small"
                />
            </div>
        </div>
    );
}
