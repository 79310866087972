import React from "react";
import {
  Box,
  Typography,
  Button,
  Tab,
  Tabs,
} from "@mui/material";
import CustomerComponent from "./saleQuotationcustomerComponent";
import UserComponent from "./saleQuotationUserComponent";
import AreaComponent from "./saleQuotationAreaComponent";
import TimeComponent from "./saleQuotationTimeComponent";
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import { Link } from "react-router-dom";
import SaleComponent from "./saleQuotationUserComponent";
import SaleUserComponent from "./saleQuotationUserComponent";
import SalesTimeComponent from "./saleQuotationTimeComponent";
import SalesAreaComponent from "./saleQuotationAreaComponent";
import SalesCustomerComponent from "./saleQuotationcustomerComponent";
import AccountTableHeader from "../account/accountTableHeader";
import DealLeadComponent from "./saleQuotationLeadComponent";
import DealUserComponent from "./saleQuotationUserComponent";
import DealCustomerComponent from "./saleQuotationcustomerComponent";
import DealAreaComponent from "./saleQuotationAreaComponent";
import DealTimeComponent from "./saleQuotationTimeComponent";
import DealpipelineComponent from "./saleQuotationProductCategoryComponent";
import DealpipelinestageComponent from "./saleQuotationProductComponent";
import DealLeadStatusComponent from "./saleQuotationLeadComponent";
import DealCustomerStatusComponent from "./saleQuotationcustomerComponent";
import DealUserStatusComponent from "./saleQuotationUserComponent";
import DealAreaStatusComponent from "./saleQuotationAreaComponent";
import DealTimeStatusComponent from "./saleQuotationTimeComponent";
import DealpipelineStatusComponent from "./saleQuotationProductCategoryComponent";
import DealpipelinestageStatusComponent from "./saleQuotationProductComponent";
import SaleQuotationLeadComponent from "./saleQuotationLeadComponent";
import SaleQuotationcustomerComponent from "./saleQuotationcustomerComponent";
import SaleQuotationUserComponent from "./saleQuotationUserComponent";
import SaleQuotationAreaComponent from "./saleQuotationAreaComponent";
import SaleQuotationTimeComponent from "./saleQuotationTimeComponent";
import SaleQuotationProductComponent from "./saleQuotationProductComponent";
import SaleQuotationProductCategoryComponent from "./saleQuotationProductCategoryComponent";

// Main Component
export default function SaleQuotationReportReport() {
  const [mainTabValue, setMainTabValue] = React.useState(0); // Tracks main tabs like Customer, User, Area, etc.

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  // Function to render the corresponding component based on selected tab
  const renderTabContent = () => {
    switch (mainTabValue) {
      case 0:
        return <SaleQuotationLeadComponent />;
      case 1:
        return <SaleQuotationcustomerComponent />;
      case 2:
        return <SaleQuotationUserComponent />;
      case 3:
        return <SaleQuotationAreaComponent />;
      case 4:
        return <SaleQuotationTimeComponent />;
      case 5:
        return <SaleQuotationProductComponent />;
      case 6:
        return <SaleQuotationProductCategoryComponent />;
      default:
        return <Typography variant="h6">No Data Available</Typography>;
    }
  };

  return (
    <div className="page-px-24">
      <div className="customer-header-alignment pb-4">
        <div>
          <h2>Deal Status Report by </h2>
          <Breadcumbs activePath="Deal Status Report" pageName="Deal Status Report" />
        </div>
        <div className="right-contnet-alignment">
          <GlobalButton text="PRINT" />
          <GlobalButton text="EXPORT" lightbutton />
        </div>
      </div>
      <AccountTableHeader/>
        <Tabs value={mainTabValue} onChange={handleMainTabChange}>
          <Tab label="Lead" />
          <Tab label="Customer" />
          <Tab label="User" />
          <Tab label="Area" />
          <Tab label="Time" />
          <Tab label="Product" />
          <Tab label="Product Category" />
        </Tabs>

        {/* Content Rendering */}
        <div className="pt-4">{renderTabContent()}</div>
    </div>
  );
}
