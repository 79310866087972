import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './producttable.scss';
import { fetchData, deleteData } from '../../../services/apiService'; // Import deleteData
import Toggle from '../../../shared/components/switch';
import ShowImage from '../../../shared/components/showImage';
import ViewIcon from '../../../assets/icons/view.svg';
import RemoveIcon from '../../../assets/icons/remove.svg';
import PenIcon from '../../../assets/icons/pen.svg';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import EditIcon from '../../../shared/icons/editIcon';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce';
export default function Producttable({ setSelectedIds, selectedIds }) {
  const [productList, setProductList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);
  const [ProductCode, setProductCodeTerm] = useState("");
  const [Category, setCategoryTrem] = useState("");
  const [Price, setPriceTerm] = useState(""); 
  const [Stock, setStockTerm] = useState("");
  const [Status, setStatusTrem] = useState("");
  const [allactivityType, setallactivityTypeTerm] =useState('');
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const filters = {
      productCode: ProductCode,
      name: Category,
      price: Price,
      moq: Stock,
      status: Status
    };


    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(productList.map((i) => i.id));
    } else {
      setSelectedIds([]);
    }
  };


  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== NaN && value !== "")
    );
  };
  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
  
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };

      const responseProducts = await fetchData(
        'product',
        removeEmptyFields(filters), 
        pagination,
        {},
        isSearching,
        globalSearch,
        true
      );

      if (responseProducts?.data) {
        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
 
    const filters = {
      productCode: ProductCode || '',
      name: Category || '',
      price: Price !== '' ? Number(Price) : '',
      moq: Stock !== '' && !isNaN(Number(Stock)) ? parseInt(Stock, 10) : '',
      status: Status || ''
    };
    fetchProduct(filters, true, currentPage, pageSize);
    }, [currentPage, ProductCode, Category, Price, Stock, Status]); 
  
    useEffect(() => {
      const filters = {
        "isGlobalSearch": true,
        "isSearching" : true,
        "searchfields":["name","productCode"],
        "searchtext": allactivityType
      };
    
    
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, [allactivityType]);  
 
  
  return (
    <>
    <div className='table-v2-design'>
      <div>
        <table>
          <thead>
          <tr>
              <th>
                <input type="checkbox" onChange={handleSelectAll} />
              </th>
              <th>Image</th>
              <th>Name</th>
              <th>Product Code</th>
              <th>Price</th>
              <th>MOQ</th>
              <th>Status</th>
              {checkAccess('edit_product') && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {productData?.length > 0 ? (
              productData.map((i) => (
                <tr key={i.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(i.id)}
                      onChange={() => handleCheckboxChange(i.id)}
                    />
                  </td>
                  
                  <td>
                    <div className="image-text">
                      {!i?.productUrl && <i className="fas fa-camera"></i>}
                      {i?.productUrl && (
                        <a href={i?.productUrl} target="_blank" rel="noopener noreferrer">
                          <ShowImage
                            className="thumbnail"
                            value={i?.productUrl}
                            style={{
                              width: '80px',
                              height: '60px',
                              borderRadius: '4px',
                              paddingBottom: '3px',
                            }}
                          />
                        </a>
                      )}
                    </div>
                  </td>
                  <td>
                    <span>{i?.name}</span>
                  </td>
                  <td>
                    <span className="gray-text">{i?.productCode}</span>
                  </td>
                  <td className="">
                    <p className="">{i?.price}</p>
                  </td>
                  <td>
                    <span>{i?.moq}</span>
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    <Link to={{ pathname: `view/${i?.id}` }}>
                      <button className={i?.status ? 'active-btn' : 'active-btn in-active-btn'}>
                        <div className='dot'></div>
                        {i?.status ? 'Active' : 'Inactive'}
                      </button>
                    </Link>
                  </td>
                  <td align="center">
                    <div className="three-icon-alignment">
                      <Link to={{ pathname: `edit/${i?.id}` }}>
                        <EditIcon />
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: 'center', fontStyle: 'italic', color: '#666' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
      </div>
    </div>
    <div className="">
      {/* <div className="account-table-header-alignment">
        <div className="search-width">
          <div className="searchbar-design">
            <input
              type="text"
              placeholder="Search"
              value={allactivityType}
              onChange={(e) => setallactivityTypeTerm(e.target.value)}
            />
            <div className="icon-alignment">
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="product-table-design">
        <table>
          <thead>
            <tr>
              <th style={{ width: '3%' }}></th>
              <th style={{ width: '0%' }}></th>
              <th style={{ width: '5%' }}></th>
              <th style={{ width: '55%' }}>
                <input
                  type="text"
                  placeholder="Name"
                  value={Category}
                  onChange={(e) => setCategoryTrem(e.target.value)}
                  className="w-100 border-0 table-input-background"
                />
              </th>
              <th>
                <input
                  type="text"
                  placeholder="Product code"
                  value={ProductCode}
                  onChange={(e) => setProductCodeTerm(e.target.value)}
                  className="w-100 border-0 table-input-background"
                />
              </th>
              <th>
                <input
                  type="text"
                  placeholder="Price"
                  value={Price}
                  onChange={(e) => setPriceTerm(e.target.value)}
                  className="w-100 border-0 table-input-background"
                />
              </th>
              <th>
                <input
                  type="text"
                  placeholder="MOQ"
                  value={Stock}
                  onChange={(e) => setStockTerm(e.target.value)}
                  className="w-100 border-0 table-input-background"
                />
              </th>
              <th style={{ width: '8%' }}>
                <select
                  value={
                    Status === true
                      ? 'true'
                      : Status === false
                      ? 'false'
                      : ''
                  }
                  onChange={(e) => {
                    const selectedStatus =
                      e.target.value === 'true'
                        ? true
                        : e.target.value === 'false'
                        ? false
                        : undefined;
                    setStatusTrem(selectedStatus);
                  }}
                  className="w-100 border-0 table-input-background"
                >
                  <option value="">All</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>
              </th>
            </tr>
            <tr>
              <th>
                <input type="checkbox" onChange={handleSelectAll} />
              </th>
              {checkAccess('edit_product') && <th>Action</th>}
              <th>Image</th>
              <th>Name</th>
              <th>Product Code</th>
              <th>Price</th>
              <th>MOQ</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {productData?.length > 0 ? (
              productData.map((i) => (
                <tr key={i.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(i.id)}
                      onChange={() => handleCheckboxChange(i.id)}
                    />
                  </td>
                  <td align="center">
                    <div className="three-icon-alignment">
                      <Link to={{ pathname: `edit/${i?.id}` }}>
                        <EditIcon />
                      </Link>
                    </div>
                  </td>
                  <td align="center">
                    <div className="image-text">
                      {!i?.productUrl && <i className="fas fa-camera"></i>}
                      {i?.productUrl && (
                        <a href={i?.productUrl} target="_blank" rel="noopener noreferrer">
                          <ShowImage
                            className="thumbnail"
                            value={i?.productUrl}
                            style={{
                              width: '80px',
                              height: '60px',
                              borderRadius: '4px',
                              paddingBottom: '3px',
                            }}
                          />
                        </a>
                      )}
                    </div>
                  </td>
                  <td>
                    <span>{i?.name}</span>
                  </td>
                  <td>
                    <span className="gray-text">{i?.productCode}</span>
                  </td>
                  <td className="text-align-right">
                    <p className="text-align-right">{i?.price}</p>
                  </td>
                  <td>
                    <span>{i?.moq}</span>
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    <Link to={{ pathname: `view/${i?.id}` }}>
                      <button className={i?.status ? 'Active' : 'Inactive'}>
                        {i?.status ? 'Active' : 'Inactive'}
                      </button>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: 'center', fontStyle: 'italic', color: '#666' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="pagination-alignment">
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div> */}
    </div>
    </>
  )
}
