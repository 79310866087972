// src/contexts/UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import { fetchData } from "../services/apiService";

export const UserContext = createContext();

export const UserProvider = ({ children , currentRoute}) => {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState({});

  let userrolename = 'admin';
  useEffect(() => {


    const getUserRole = async () => {
      if(currentRoute !== '/login' && currentRoute !== '/signUp'){
        try {
          const response = await fetchData('userrole');
          if (response?.data) {
            const foundRole = response.data.find(role => role.name === userrolename);
            if (foundRole) {
              setUserRole(foundRole);
            } else {
              console.warn(`Role with name "${userrolename}" not found.`);
            }
            console.log(response.data);
          }
        } catch (error) { 
          console.error("Error fetching data:", error);
        }
      }
    };
    // getuserrole();
    getUserRole();
  }, [userrolename]);


  return (
    <UserContext.Provider value={{ user, setUser, userRole,setUserRole }}>
      {children}
    </UserContext.Provider>
  );
};
