import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchData } from '../../../services/apiService';
import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce';
export default function MyActivitiesTable({
  myactivitiesids = [],
  setSelectedIds,
  setMyActivitiesList,
  myactivitiesList = [],
  setProductList,
  setTotalItems,
  totalItems,
}) {
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const pageSize = 10; // Numb
  const [activityType, setactivityTypeTerm] = useState(""); // Initialize as an empty string
  const [Value, setValueTerm] = useState("");
  const [Assign, setAssignTerm] = useState("");
  const [Linked, setLinkedTrem] = useState(""); // Initialize as an empty string
  const [LinkedName, setLinkedNameTerm] = useState("");
  const [Status, setStatusTrem] = useState("");
  const [allactivityType, setallactivityTypeTerm] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);
  const [productData, setProductData] = useState([]);
  const { RangePicker } = DatePicker;
  useEffect(() => {
    const filters = {
      activityname: activityType,
      activityvalue: Value,
      username: Assign,
      module: Linked,
      linkname: LinkedName,
      status: Status
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = (e) => {
    const pageItems = myactivitiesList;
    setSelectedIds(e.target.checked ? pageItems.map((i) => i.id) : []);
  };

  const paginatedData = () => {
    const startIndex = (currentPage - 1) * pageSize;
    return myactivitiesList.slice(startIndex, startIndex + pageSize);
  };

  const totalPages = Math.ceil(totalItems / pageSize);
  const onRangeChange = (dates) => {
    setSelectedDates(dates); // Update the state with the selected date range
  };
  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };

  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('myactivities', removeEmptyFields(filters), pagination,
        {},
        isSearching,
        globalSearch
      );

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      activityname: activityType,
      activityvalue: Value,
      username: Assign,
      module: Linked,
      linkname: LinkedName,
      status: Status,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [activityType, Value, Assign, Linked, LinkedName, Status, selectedDates]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['activityname', 'activityvalue', 'username', 'linkname', 'module'],
      searchtext: allactivityType,
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);

  return (
    <>
      <div className='table-v2-design'>
        <div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Activity Type	</th>
                <th>Activity Value	</th>
                <th>Date</th>
                <th>Assign To	</th>
                <th>Linked To	</th>
                <th>Linked Name	</th>
                <th>Status
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {productData.length > 0 ? (
                productData.map((i) => (
                  <tr key={i.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={myactivitiesids.includes(i.id)}
                        onChange={() => handleCheckboxChange(i.id)}
                      />
                    </td>

                    <td>{i?.activityname}</td>
                    <td>{i?.activityvalue}</td>
                    <td>{i?.activitydate ? moment(i.activitydate).format('YYYY-MM-DD') : 'N/A'}</td>
                    <td>{i?.username}</td>
                    <td>{i?.module}</td>
                    <td>{i?.linkname}</td>
                    <td>
                      <button className='active-btn'>
                        <div className='dot'></div>
                        {i?.status ? 'Active' : 'Deactive'}
                      </button>
                    </td>
                    <td>
                      <Link to={`addeditActivity/${i?.id}`}>
                        <EditIcon />
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9} style={{ textAlign: 'center', fontStyle: 'italic', color: '#666' }}>
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          totalItems={totalItems}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
      <div className="account-table-page-alignment">
        {/* <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType	} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div> */}
        {/* <div className="account-table-design">
        <table>
          <thead>
            <tr>
              <th><div className="tableSize"></div></th>
              <th><div className="tableSize"></div></th>
              <th><input type="text" placeholder="Activity Type	" value={activityType} onChange={(e) => setactivityTypeTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Activity Value" value={Value} onChange={(e) => setValueTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><div style={{ width: '100%' }}>
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={[' Start Date', ' End Date']} className="my-custom-class" />
                </Space></div> </th>

              <th><input type="text" placeholder="Assign To" value={Assign} onChange={(e) => setAssignTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Linked To" value={Linked} onChange={(e) => setLinkedTrem(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Linked Name" value={LinkedName} onChange={(e) => setLinkedNameTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Status" value={Status} onChange={(e) => setStatusTrem(e.target.value)} className="w-100 border-0 table-input-background" /></th>

            </tr>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={
                    myactivitiesList.length > 0 &&
                    myactivitiesids.length === myactivitiesList.length
                  }
                />
              </th>
              <th align="center">Action</th>
              <th>Activity Type</th>
              <th>Activity Value</th>
              <th>Date</th>
              <th>Assign To</th>
              <th>Linked To</th>
              <th>Linked Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {productData.length > 0 ? (
              productData.map((i) => (
                <tr key={i.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={myactivitiesids.includes(i.id)}
                      onChange={() => handleCheckboxChange(i.id)}
                    />
                  </td>
                  <td>
                    <Link to={`addeditActivity/${i?.id}`}>
                      <EditIcon />
                    </Link>
                  </td>
                  <td>{i?.activityname}</td>
                  <td>{i?.activityvalue}</td>
                  <td>{i?.activitydate ? moment(i.activitydate).format('YYYY-MM-DD') : 'N/A'}</td>
                  <td>{i?.username}</td>
                  <td>{i?.module}</td>
                  <td>{i?.linkname}</td>
                  <td>{i?.status ? 'Active' : 'Deactive'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} style={{ textAlign: 'center', fontStyle: 'italic', color: '#666' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
        <div className="pagination-alignment">
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div> */}
      </div>
    </>
  );
}
