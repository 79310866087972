import React from 'react'
import './productwisereport.scss';
import Productwisereportdetails from './productwisereportdetails';
import Breadcumbs from '../../shared/components/breadcumbs';
export default function Productwisereport() {
  return (
    <div className='page-24'>
      <div className="customer-header-alignment pb-4">
          <div>
            <h2>Productwise Dispatch Report</h2>
            <Breadcumbs activePath="Productwise Dispatch Report" pageName="Productwise Dispatch Report" />
          </div>
        </div>
      <Productwisereportdetails/>
    </div>
  )
}
