import React, { useEffect, useState } from 'react';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchsingleData, patchData, postData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate, useParams } from 'react-router-dom';
import GlobalButton from '../../../shared/globalButton';
import PlusIcon from '../../../assets/icons/plus.svg';
import CancelIcon from '../../../assets/icons/Cancel.svg';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';
import './addprice.scss'
export default function AddEditPriceData() {
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        // Fetch data when the component mounts if there is an id
        if (id) {
            getData();
        }
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        code: '',
        price: '',
        discount: '',
        offerDiscount: '',
        status: true,
    });

    const [errors, setErrors] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        try {
            if (id) {
                patchData(`pricegroup/${id}`, formData).then(data => {
                    navigate(-1);
                }).catch(error => {
                    console.error('Error updating data:', error);
                });
            } else {
                postData('pricegroup', formData).then(data => {
                    navigate(-1);
                }).catch(error => {
                    console.error('Error posting data:', error);
                });
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }

    };
    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.code) newErrors.code = 'Code is required';
        if (!formData.price) newErrors.price = 'Price is required';


        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    // const validationCheck = (isSubmit) => {
    //     const validationErrors = {};
    //     if (!formData?.name?.trim()) {
    //         validationErrors.name = 'Please enter Name.';
    //     }
    //     if (!formData?.code?.trim()) {
    //         validationErrors.code = 'Please enter Code.';
    //     }
    //     if (!formData?.price?.trim()) {
    //         validationErrors.price = 'Please enter Price.';
    //     }
    //     if (!formData?.discount?.trim()) {
    //         validationErrors.discount = 'Please enter Discount.';
    //     }
    //     if (!formData?.offerDiscount?.trim()) {
    //         validationErrors.offerDiscount = 'Please enter Offer Discount.';
    //     }
    //     if (isSubmit || errors.isSubmit) {
    //         validationErrors.isSubmit = true;
    //     }
    //     setErrors(validationErrors);
    //     return Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0;
    // };

    const toggleSwitch = (checked) => {
        setFormData({ ...formData, status: checked });
    };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));

    };

    const getData = async () => {
        try {
            const response = await fetchsingleData('pricegroup', id);
            if (response?.data) {
                setFormData(response.data); // Update state with fetched data
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    return (
        <div className='page-24'>
            {/* <div className='page-header add-news-alignment'>
                <span></span>
                <div className='two-button-alignment'>
                    <div onClick={handleSubmit}><GlobalButton  /></div>
                    <Link to="/pricegroup"><GlobalButton text='Discard' outline /></Link>
                </div>
            </div> */}

            <div className='add-header-design'>
                <div>
                    <h2>Price Group </h2>
                    {/* {`>`} {id ? 'Edit' : 'Create'} */}
                    <Breadcumbs activePath="Catalog" pageName="Add Price group" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/pricegroup">
                        <button className='light-button'>
                            Discard
                        </button>
                    </Link>
                    <button className={formData.name && formData.code && formData.price  ? "success-button" : ""} onClick={handleSubmit}>
                        {/* text={id ? 'Update' : 'Save'} */}
                        {id ? 'Update' : 'Save'}

                    </button>
                </div>
            </div>
            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <form onSubmit={handleSubmit}>
                            <div className='three-col-grid'>
                                <Input
                                    label={
                                        <span>
                                            Name<span className="required-star">*</span>
                                        </span>
                                    }
                                    name='name'
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder='Enter Name..'
                                    type='text'
                                    required
                                    errors={errors.name}
                                />
                                <Input
                                    label={
                                        <span>
                                            Code<span className="required-star">*</span>
                                        </span>
                                    }
                                    name='code'
                                    value={formData.code}
                                    onChange={handleInputChange}
                                    placeholder='Enter Code..'
                                    type='text'
                                    required
                                    errors={errors.code}
                                />
                                <Input
                                    label={
                                        <span>
                                            Price<span className="required-star">*</span>
                                        </span>
                                    }
                                    type="number"
                                    name='price'
                                    value={formData.price}
                                    onChange={handleInputChange}
                                    placeholder='Enter Price..'
                                    required
                                    errors={errors.price}
                                />
                                <Input
                                    label='Discount'
                                    name='discount'
                                    value={formData.discount}
                                    onChange={handleInputChange}
                                    placeholder='Enter Discount..'
                                    type='number'
                                    required
                                    errors={errors.discount}
                                />
                                <Input
                                    label='Offer Discount'
                                    name='offerDiscount'
                                    value={formData.offerDiscount}
                                    onChange={handleInputChange}
                                    placeholder='Enter Offer Discount..'
                                    type='number'
                                    required
                                    errors={errors.offerDiscount}
                                />
                                <div className='d-flex align-items-end'>
                                    <Toggle label='Status' checked={formData.status} onChange={toggleSwitch} />

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                {/* <div>
                    <StatusBox />
                </div> */}
            </div>

            <div>

            </div>
        </div>
    );
}
