import { useEffect, useState } from 'react';
import { fetchData, postData } from '../../../services/apiService';
import './attendancetable.scss';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import SelectBox from '../../../shared/components/select';
import Input from '../../../shared/components/input';
import GlobalButton from '../../../shared/globalButton';

export default function AttendanceTable() {
  const [mergedList, setMergedList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [formData, setFormData] = useState({}); // Initialize with an empty object
  const [error, setErrors] = useState([]);
  const [UserName, setUserName] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async (user_id = null, date = null) => {
    try {
      const response = await postData('attendance/userWiseAttendance', {
        created_at: new Date().toISOString(),
        user_id,
        date,
      });

      console.log(response?.data);
      setMergedList(response?.data || []); // Set response data or empty array if null
    } catch (error) {
      console.error('Error fetching data:', error);
      setMergedList([]); // Set to empty array in case of error
    }

    try {
      const userResponse = await fetchData('user');
      if (userResponse?.data) {
        setUserName(
          userResponse.data.map((user) => ({
            label: user.name,
            key: 'userid',
            value: user.id,
            name: user.name,
          }))
        );
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

 

  const selectChanged = (selectedOption) => {
    setFormData((prevState) => ({
      ...prevState,
      user_id: selectedOption.value,
      username: selectedOption.name,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevState) => ({
      ...prevState,
      date: date ? dayjs(date) : null,
    }));
  };

  const handleApply = async (e) => {
    e.preventDefault();
    setMergedList([]); // Clear the list before fetching new data
    const { user_id, date } = formData;
    await getData(user_id, date ? date.toISOString().split('T')[0] : null);
  };

  return (
    <div className="attendance-page-container">
      <div className="customer-page-main-grid">
        <div>
          <div className="new-white-box-design">
            <div className="account-table-design">
              <table>
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Email</th>
                    <th>Punch In</th>
                    <th>Punch Out</th>
                    <th>Work Duration</th>
                    <th>Present/Absent</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {mergedList.length > 0 ? (
                    mergedList.map((item, index) => (
                      <tr key={index}>
                        <td>{item.username}</td>
                        <td>{item.useremail}</td>
                        <td>{item?.firstPunchInTime}</td>
                        <td>{item?.latestPunchOutTime || 'Not Yet Punched Out'}</td>
                        <td>{item?.totalWorkDuration || 'N/A'}</td>
                        <td className={`status ${item.attendanceStatus.toLowerCase()}`}>
                          {item.attendanceStatus}
                        </td>
                       
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" style={{ textAlign: 'center' }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div>
          <div className="new-white-box-design">
            <form onSubmit={handleApply}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  name="date"
                  value={dayjs(formData.date)}
                  onChange={handleDateChange}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>

              <div className="py-3">
                <label className="m-0">User</label>
                <SelectBox
                  name="username"
                  value={UserName?.find((i) => i.name === formData.username)}
                  onChange={selectChanged}
                  options={UserName}
                  placeholder="User"
                  required
                />
              </div>
              <div className="d-flex justify-content-center">
                <GlobalButton text="Apply" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
