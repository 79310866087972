import React from 'react';

export default function Categorydetailstable({ productData }) {
    return (
        <div>
            <div className='account-table-design' style={{
                overflowY: 'scroll',
                height: '700px',
                display: 'block',
                marginLeft: '5px',
                marginTop: '0px',
                border: '1px solid #ddd',
                borderRadius: '4px',
                backgroundColor: '#fff'
            }}>
                <table style={{
                    width: '100%',
                    borderCollapse: 'collapse'
                }}>
                    <thead>
                        <tr style={{
                            backgroundColor: '#f2f2f2',
                            borderBottom: '2px solid #ddd'
                        }}>
                            <th style={{
                                padding: '10px',
                                textAlign: 'left',
                                borderBottom: '1px solid #ddd'
                            }}>Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            productData?.map((i) => (
                                <tr key={i.id} style={{
                                    borderBottom: '1px solid #ddd'
                                }}>
                                    <td style={{
                                        padding: '10px'
                                    }}>{i.categoryName}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
