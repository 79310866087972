import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

export default function EstimateTimePieComponent({ customerList }) {
    const [fullChartData, setFullChartData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataType, setDataType] = useState("orders");
    const itemsPerPage = 10;

    useEffect(() => {
        generateChartData(dataType);
    }, [customerList, dataType]);

    useEffect(() => {
        updateVisibleData();
    }, [currentPage, fullChartData]);

    const generateChartData = (type) => {
        const fieldMapping = {
            Estimates: "totalEstimates",
            customer: "totalCustomers",
            lead: "totalLeads",
            product: "totalProducts",
            Quantity: "totalQuantity",
            BaseQuantity: "totalQuantity",
            SubAmount: "totalAmount",
            Amount: "totalAmount"
        };
        const mappedField = fieldMapping[type];

        const data = customerList.map((item) => ({
            name: item?.month || "Unknown",
            y: item[mappedField] || 0,
        }));

        setFullChartData(data);
        setCurrentPage(1); // Reset to the first page
    };

    const updateVisibleData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const visible = fullChartData.slice(startIndex, startIndex + itemsPerPage);
        setChartData(visible);
    };

    const handlePageChange = (direction) => {
        setCurrentPage((prev) => Math.max(prev + direction, 1));
    };

    const options = {
        chart: { type: "pie" },
        title: { text: `Data by ${dataType.toUpperCase()}` },
        tooltip: { pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>" },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: {point.percentage:.1f}%",
                },
            },
        },
        series: [{ name: "Data", data: chartData }],
    };

    const totalPages = Math.ceil(fullChartData.length / itemsPerPage);

    return (
        <Box p={4}>
            <FormControl fullWidth margin="normal">
                <InputLabel>Select Data Type</InputLabel>
                <Select
                    value={dataType}
                    onChange={(e) => setDataType(e.target.value)}
                    >
<MenuItem value="Estimates">Estimates</MenuItem>
                    <MenuItem value="product">Product</MenuItem>
                    <MenuItem value="Quantity">Quantity</MenuItem>
                    <MenuItem value="BaseQuantity">Base Quantity</MenuItem>
                    <MenuItem value="SubAmount">Sub Amount</MenuItem>
                    <MenuItem value="Amount">Amount</MenuItem>
                </Select>
            </FormControl>

            <HighchartsReact highcharts={Highcharts} options={options} />

            <Box display="flex" justifyContent="space-between" mt={2}>
                <Button
                    variant="contained"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(-1)}
                >
                    Previous
                </Button>
                <Typography>
                    Page {currentPage} of {totalPages}
                </Typography>
                <Button
                    variant="contained"
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(1)}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}
