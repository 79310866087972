import React from 'react'
import './followCard.scss';
import CallIcon from '../../../assets/icons/call-light.svg'
import MessageIcon from '../../../assets/icons/message-light.svg'
import ProfileIcon from '../../../assets/icons/follow-profile.svg'
import LocationIcon from '../../../assets/icons/location-sm.svg'
import CallIconOutline from '../../../assets/icons/call-outline.svg'
import MessageOutline from '../../../assets/icons/message-outline.svg'
export default function FollowCard() {
    return (
        <>
        <div className='follow-card-main-box'>
            {
                Array(10)
                    .fill(null)
                    .map((_, index) => {
                        return (
                            <div className="follow-card-box" key={index}>
                                <div className="follow-card-header">
                                    <div className="follow-left">
                                        <div className="profile-img">
                                            <img src={ProfileIcon} alt="ProfileIcon" />
                                        </div>
                                        <div>
                                            <p>Jenny Wilson</p>
                                            <div className="icons-text-alignment">
                                                <img src={LocationIcon} alt="LocationIcon" />
                                                <span>Mota Varaccha</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="follow-right">
                                        <img src={CallIcon} alt="CallIcon" />
                                        <img src={MessageIcon} alt="MessageIcon" />
                                    </div>
                                </div>
                                <div className="icons-text-alignment bottom-alignment-space">
                                    <img src={CallIconOutline} alt="CallIconOutline" />
                                    <span>+91- 78654 12345</span>
                                </div>
                                <div className="icons-text-alignment">
                                    <img src={MessageOutline} alt="MessageOutline" />
                                    <span>wilsonjenny@gmail.com</span>
                                </div>
                            </div>
                        );
                    })
            }
</div>
        </>
    )
}
