import React, { useEffect, useState } from "react";
import Button from "../../../shared/components/button";
import Input from "../../../shared/components/input";
import Modal from "../../../shared/components/model";
import {
    fetchsingleData,
    fetchData,
    patchData,
    postData,
    deleteData,
} from "../../../services/apiService";
import Toggle from "../../../shared/components/switch";
import { Link, useNavigate, useParams } from "react-router-dom/dist";
import SelectBox from "../../../shared/components/select";
import GlobalButton from "../../../shared/globalButton";
import Breadcumbs from "../../../shared/components/breadcumbs";
import PlusIcon from '../../../assets/icons/plus.svg';
import CancelIcon from '../../../assets/icons/Cancel.svg';
import StatusBox from "../../addcustomers/statusBox";
import store from "../../../redux/reduxstore";
import { notify } from "../../toastr/Toastr";
import EditIcon from "../../../shared/icons/editIcon";
import './addworkprofile.scss';

export default function AddWorkprofile() {
    const navigate = useNavigate();
    const { id } = useParams();
    const company_setting = store.getState()?.auth?.company_setting;

    useEffect(() => {
        getexpensetype();
    }, []);

    useEffect(() => {
        if (id) {
            getData();
            getexpensetype();
        }
    }, []);

    const getexpensetype = async () => {
        try {
            const response = await fetchData(`expensetype`);
            if (response?.data) {
                setExpensetype(
                    response?.data?.map((i) => ({
                        label: `${i?.name}`,
                        key: "expensetype",
                        value: {
                            id: i?.id,
                            name: `${i?.name}`,
                            allowancetype: `${i?.allowancetype}`,
                            isperkm: i?.isperkm,
                            amount: i?.amount,
                            rate: i?.rate,
                        },
                    }))
                );
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const [Expensetype, setExpensetype] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        status: true,
        expenses: [],
    });

    const [errors, setErrors] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [expenseData, setExpenseData] = useState({
        expensetype: '',
        allowancetype: '',
        isperkm: false,
        amount: 0,
        rate: 0,
        totalkm: 0,
        description: '',
    });

    const handleOpenModal = () => setIsModalOpen(true);

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setExpenseData({
            expensetype: '',
            allowancetype: '',
            isperkm: false,
            amount: 0,
            rate: 0,
            totalkm: 0,
            description: '',

        });
    };
    const getData = async () => {
        try {
            const response = await fetchsingleData("workprofile", id);
            if (response?.data) setFormData(response?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleExpenseChange = (e) => {
        const { name, value } = e.target;
        setExpenseData((prev) => ({
            ...prev,
            [name]: value,
        }));
        if (name === "totalkm" && expenseData.allowancetype === "travelling") {
            setExpenseData((prev) => ({
                ...prev,
                amount: value * prev.rate,
            }));
        }
    };

    const handleSaveExpense = () => {
        setFormData((prev) => ({
            ...prev,
            expenses: [...prev.expenses, expenseData],
        }));
        handleCloseModal();
    };

    const SelectChanged = (e) => {
        if (e.key === "expensetype") {
            setExpenseData((prevState) => ({
                ...prevState,
                expensetype: e.value.name,
                allowancetype: e.value.allowancetype,
                isperkm: e.value.isperkm,
                rate: e.value.rate,
                amount: e.value.amount,
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validationCheck()) {
            try {
                if (id) {
                    patchData(`workprofile/${id}`, formData)
                        .then((data) => {
                            notify.success('Workprofile updated successfully!');
                            if (data) navigate(-1);
                        })
                        .catch((error) => {
                            notify.error('Error while saving Workprofile');
                            console.error("Error fetching data:", error);
                        });
                } else {
                    postData("workprofile", formData)
                        .then((data) => {
                            if (data) {
                                notify.success('Workprofile saved successfully!');
                            } else {
                                notify.error('Workprofile not saved');
                            }
                            if (data) navigate(-1);
                        })
                        .catch((error) => {
                            notify.error('Error while saving Workprofile');
                            console.error("Error fetching data:", error);
                        });
                }
            } catch (error) {
                console.error("Error saving data:", error);
            }
        }
    };

    const validationCheck = () => {
        const validationErrors = {};
        if (!formData.name) {
            validationErrors.name = "Please enter a Workprofile Name.";
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    return (
        <div className="page-24">
            <div className='add-header-design'>
                <div>
                    <h2>Add WorkProfile</h2>
                    <Breadcumbs activePath="WorkProfile" pageName="Add Workprofile" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/workprofile">
                        <button className='light-button'>
                            <img src={CancelIcon} alt="CancelIcon" />
                            Discard
                        </button>
                    </Link>
                    <button onClick={handleSubmit}>
                        {id ? "Update" : "Save"}
                    </button>
                </div>
            </div>

            <div className="page-main-grid">
                <div>
                    <div className="new-white-box-design">
                        <div className="two-col-grid">
                            <Input
                                label={
                                    <span>
                                        WorkProfile Name<span className="required-star">*</span>
                                    </span>
                                }
                                name="name"
                                value={formData.name}
                                onChange={(e) =>
                                    setFormData((prev) => ({ ...prev, name: e.target.value }))
                                }
                                placeholder="Enter WorkProfile Name"
                                type="text"
                                required
                                errors={errors.name}
                            />
                            <div className="d-flex align-items-end">
                                <Toggle
                                    label="Status"
                                    checked={formData.status}
                                    onChange={(checked) =>
                                        setFormData((prev) => ({ ...prev, status: checked }))
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="work-profile-rule">
                        <h3 style={{ borderBottom: '1px solid #000', paddingBottom: '5px' }}>
                            Work Profile Rule
                        </h3>
                        <div className='account-table-design'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Expense Name</th>
                                        <th>Amount</th>
                                        <th>isImageRequired</th>
                                        <th>Is changeable</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.expenses.map((expense, index) => (
                                        <tr key={index}>
                                            <td>{expense.expensetype}</td>
                                            <td>{expense.amount}</td>
                                            <td>{expense.isImageRequired}</td>
                                            <td>{expense.isChangeable}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ marginTop: '5px' }}>
                            <GlobalButton text="Add Expense" Icon={PlusIcon} onClick={handleOpenModal} />
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && (
                <Modal title="Add Expense Type" isOpen={isModalOpen} onClose={handleCloseModal} onSave={handleSaveExpense}>
                    <div className="mainBox">
                        <div style={{ alignItems: 'center', display: 'flex' }}>
                            <SelectBox
                            label="Expense Type"
                                name="expensetype"
                                value={Expensetype.find(
                                    (option) => option.value.name === expenseData.expensetype
                                )}
                                options={Expensetype}
                                onChange={SelectChanged}
                                placeholder="Expense Type"
                            />
                        </div>
                        {expenseData.allowancetype !== "travelling" && (<div style={{ alignItems: 'center', display: 'flex' }}>
                            <Input
                                label="Amount"
                                name="amount"
                                value={expenseData.amount}
                                onChange={handleExpenseChange}
                                placeholder="Amount"
                                type="text"
                                errors={errors.amount}
                            />
                        </div>)}
                        {expenseData.allowancetype === "travelling" && (
                            <div style={{ alignItems: 'center', display: 'flex' }}>
                                <div className="two-col-grid">
                                    <Input
                                        label="Amount"
                                        name="amount"
                                        value={expenseData.rate}
                                        onChange={handleExpenseChange}
                                        placeholder="Amount"
                                        type="text"
                                        required
                                    />
                                    <SelectBox
                                    label="Is Per Km"
                                        name="isperkm"
                                        value={{
                                            label: expenseData.isperkm,
                                            value: expenseData.isperkm,
                                        }}
                                        options={[
                                            { label: "No", value: "No" },
                                            { label: "On System", value: "On System" },
                                            { label: "On Counter", value: "On Counter" },
                                        ]}
                                        onChange={(selectedOption) =>
                                            setExpenseData((prev) => ({
                                                ...prev,
                                                isperkm: selectedOption.value,
                                            }))
                                        }
                                        placeholder="Is Per Km?"
                                    />
                                </div>
                            </div>
                        )}
                        <div style={{ alignItems: 'center', display: 'flex' }}>
                            {/* Is Changeable */}
                            <div>
                                <label>Is changeable</label>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="isChangeable"
                                            value="Yes"
                                            checked={expenseData.isChangeable === "Yes"}
                                            onChange={(e) =>
                                                setExpenseData((prev) => ({
                                                    ...prev,
                                                    isChangeable: e.target.value,
                                                }))
                                            }
                                        />{" "}
                                        Yes
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="isChangeable"
                                            value="No"
                                            checked={expenseData.isChangeable === "No"}
                                            onChange={(e) =>
                                                setExpenseData((prev) => ({
                                                    ...prev,
                                                    isChangeable: e.target.value,
                                                }))
                                            }
                                        />{" "}
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div style={{ alignItems: 'center', display: 'flex' }}>
                            <div style={{gap:'3px'}}>
                                <label>Is image required</label>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="isImageRequired"
                                            value="Yes"
                                            checked={expenseData.isImageRequired === "Yes"}
                                            onChange={(e) =>
                                                setExpenseData((prev) => ({
                                                    ...prev,
                                                    isImageRequired: e.target.value,
                                                }))
                                            }
                                        />{" "}
                                        Yes
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="isImageRequired"
                                            value="No"
                                            checked={expenseData.isImageRequired === "No"}
                                            onChange={(e) =>
                                                setExpenseData((prev) => ({
                                                    ...prev,
                                                    isImageRequired: e.target.value,
                                                }))
                                            }
                                          
                                        />{" "}
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}

