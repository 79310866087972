import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

export default function SaleQuotationcustomerPieComponent({ customerList }) {
    const [chartData, setChartData] = useState([]);
    const [visibleData, setVisibleData] = useState([]); // Data to display
    const [dataType, setDataType] = useState("deal"); // Default data type
    const [currentPage, setCurrentPage] = useState(1); // Page tracker
    const itemsPerPage = 10; // Number of items per page

    useEffect(() => {
        formatChartData(dataType); // Format data based on the selected type
    }, [dataType, customerList]);

    useEffect(() => {
        // Update visible data when chartData or pagination changes
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setVisibleData(chartData.slice(startIndex, endIndex));
    }, [chartData, currentPage]);

    const formatChartData = (type) => {
        // Map selected data type to corresponding fields
        const fieldMapping = {
            saleQuotation: "totalQuotations",
            product: "totalProducts",
            Quantity: "totalQuantity",
            BaseQuantity: "totalQuantity",
            SubTotal: "totalamount",
            Total: "totalamount",
        };


        const selectedField = fieldMapping[type];

        // Format data based on the selected field
        const formattedData = customerList.map((item) => ({
            name: item?.link_name || "Unknown Customer",
            y: item[selectedField] || 0,
        }));

        setChartData(formattedData);
        setCurrentPage(1); // Reset pagination
    };

    const handleChange = (event) => {
        setDataType(event.target.value); // Update the selected data type
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const options = {
        chart: {
            type: "pie",
        },
        title: {
            text: `Customer Data by ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`,
        },
        tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: {point.percentage:.1f}%",
                    style: {
                        fontSize: "12px",
                        fontFamily: "Verdana, sans-serif",
                    },
                },
            },
        },
        series: [
            {
                name: `${dataType.charAt(0).toUpperCase() + dataType.slice(1)} Count`,
                colorByPoint: true,
                data: visibleData,
            },
        ],
    };

    const totalPages = Math.ceil(chartData.length / itemsPerPage);
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(currentPage * itemsPerPage, chartData.length);

    return (
        <Box p={4}>
            <FormControl fullWidth margin="normal">
                <InputLabel id="data-type-label">Select Data Type</InputLabel>
                <Select
                    labelId="data-type-label"
                    value={dataType}
                    onChange={(e) => setDataType(e.target.value)}
                >
                <MenuItem value="saleQuotation">Sale Quotation</MenuItem>
                <MenuItem value="product">Product</MenuItem>
                <MenuItem value="Quantity">Quantity</MenuItem>
                <MenuItem value="BaseQuantity">Base Quantity</MenuItem>
                <MenuItem value="SubTotal">Sub Amount</MenuItem>
                <MenuItem value="Total">Amount</MenuItem>
                </Select>
            </FormControl>
            <>
                <HighchartsReact highcharts={Highcharts} options={options} />
                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <Typography>
                        Showing {rangeStart} - {rangeEnd} of {chartData.length}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </Box>
            </>
        </Box>
    );
}
