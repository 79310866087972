import React, { useEffect, useState } from 'react'
import './categorydetails.scss';
import { fetchData } from '../../../services/apiService';
import Categorydetailstable from '../categorydetailstable';

export default function Category() {
    const [productData, setProductData] = useState([]);
    const [sortOrder, setSortOrder] = useState('');

    useEffect(() => {
        fetchData('product')
          .then((response) => {
            console.log('Fetched response:', response); // Log full API response
            const data = Array.isArray(response.data) ? response.data : [];
            setProductData(data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
            setProductData([]);
          });
    }, []);

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
    };

    const sortProductData = () => {
        let sortedData = [...productData];

        if (sortOrder === 'A to Z') {
            sortedData.sort((a, b) => a.name.localeCompare(b.name));
        } else if (sortOrder === 'Z to A') {
            sortedData.sort((a, b) => b.name.localeCompare(a.name));
        } else if (sortOrder === 'Last updated first') {
            sortedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        } else if (sortOrder === 'Last inserted first') {
            sortedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        } else if (sortOrder === 'Category A to Z') {
            sortedData.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
        } else if (sortOrder === 'Category Z to A') {
            sortedData.sort((a, b) => b.categoryName.localeCompare(a.categoryName));
        }

        return sortedData;
    };

    const sortedProductData = sortProductData();

    return (    
        <div className='priority-details-all-contnet-alignment'>
            <div className='grid'>
                <div className='grid-items' style={{ borderRight: '1px solid #ddd', marginTop: 25 }}>
                    <h5>Sorting Options</h5>
                    <div className='radion-button'>
                        <input
                            type='radio'
                            id='sortCategoryAtoZ'
                            name='sortOrder'
                            value='Category A to Z'
                            checked={sortOrder === 'Category A to Z'}
                            onChange={handleSortOrderChange}
                        />
                        <div>
                            <label>Category A to Z</label>
                        </div>
                    </div>
                    <div className='radion-button'>
                        <input
                            type='radio'
                            id='sortCategoryZtoA'
                            name='sortOrder'
                            value='Category Z to A'
                            checked={sortOrder === 'Category Z to A'}
                            onChange={handleSortOrderChange}
                        />
                        <div>
                            <label>Category Z to A</label>
                        </div>
                    </div>
                    <div className='radion-button'>
                        <input
                            type='radio'
                            id='sortLastUpdated'
                            name='sortOrder'
                            value='Last updated first'
                            checked={sortOrder === 'Last updated first'}
                            onChange={handleSortOrderChange}
                        />
                        <div>
                            <label>Last updated first</label>
                        </div>
                    </div>
                    <div className='radion-button'>
                        <input
                            type='radio'
                            id='sortLastInserted'
                            name='sortOrder'
                            value='Last inserted first'
                            checked={sortOrder === 'Last inserted first'}
                            onChange={handleSortOrderChange}
                        />
                        <div>
                            <label>Last inserted first</label>
                        </div>
                    </div>

                </div>
                <div className='grid-items' style={{ width: '100%', padding: '10px', borderRadius: '5px' }}>
                    <Categorydetailstable productData={sortedProductData} />
                </div>
            </div>
        </div>
    )
}
