import React, { useEffect, useState, useContext } from 'react';
import Button from '../../shared/components/button';
import { deleteData, filterAll } from '../../services/apiService';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Quotetable from './quotetable';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { checkAccess } from '../../services/checkFeature';
import ExportIconGray from '../../assets/icons/export-gray.svg';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import PrimaryButton from '../../shared/components/primaryButton';
import FillWhiteButton from '../../shared/components/fillWhiteButton';
import PageSubHeader from '../../shared/components/pageSubHeader';
import CaretIcon from '../../shared/icons/caretIcon';
export default function Quotation() {
  const user = useSelector(state => state.user.user);
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`quotation/${id}`)));
      const tempActivityList = activityList.filter(item => !(selectedIds.includes(item.id)))
      setActivityList(tempActivityList);

      setTotalItems(totalItems - selectedIds.length)

      // setActivityList(activityList.filter(item => !selectedIds.includes(item.id)));
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleExport = () => {
    const headers = [
      ["Qutotation No", "Quotation For", "Quotation Date","Total Amount", "Created By", "Status"]
    ];
 
    filterAll("quotation")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.quotationNo ,
        item.linkname,
        item.quotationDate,
        item.totalamount,
        item.created_by,
        item.quotationstatus,
      ]);
      exportToExcel('quotation',headers,datas,'quotation')
    })
  };

  return (
    <>
          <div className="design-v2-header">
        <div className="design-v2-left">
          <h2>
          Quotation
          </h2>
          <div className="design-breadcumb">
            <span>Quotation</span>
            <CaretIcon />
            <span>Quotation</span>
          </div>
        </div>
        <div className="design-v2-right">
        {selectedIds.length > 0 && (
             <Popconfirm
             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             title="Are you sure to delete this record? "
             description="This will lead to delete all records related to this record only!"
             open={open}
             onConfirm={handleOk}
             okButtonProps={{ loading: confirmLoading }}
             onCancel={handleCancel}
           >
            <div onClick={showPopconfirm}>
              <FillWhiteButton text='Delete Selected'/>
            </div>
           </Popconfirm>
            )}
            {checkAccess('add_sale_quotation')&&<Link to="add">
              <PrimaryButton text="Add Quotation"/>
            </Link>}
            {checkAccess('export_sale_quotation')&&<FillWhiteButton text="Export" iconpath={ExportIconGray} />}
         
        </div>
      </div>
      <PageSubHeader />
    <div className='design-v2-left-right-alignment'>
      <div >
        <Quotetable
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          activityList={activityList}
          totalItems={totalItems} 
          setTotalItems={setTotalItems}
          setActivityList={setActivityList}
        />
      </div>
    </div>
    </>
  );

}