// import React,{useContext,useState} from 'react'
// import { deleteData } from '../../services/apiService';
// import Button from '../../shared/components/button';
// import Leadtable from './leadtable';
// import { Link } from 'react-router-dom';
// import { UserContext } from '../../contexts/UserContext';
// import { useSelector } from 'react-redux';
// export default function Lead() {

//   const user = useSelector(state => state.user.user);
//   const [selectedIds, setSelectedIds] = useState([]);
//   const [activityList, setActivityList] = useState([]);

//   const handleDelete = async () => {
//     try {
//       await Promise.all(selectedIds.map((id) => deleteData(`lead/${id}`)));
//       setActivityList(activityList.filter(item => !selectedIds.includes(item.leadId)));
//       setSelectedIds([]);
//     } catch (error) {
//       console.error('Error deleting data:', error);
//     }
//   };

//   {return (
//     <div>
//       <div className='page-header'>
//         <span>Lead</span>
//         <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
//         {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
//         <Button text='Expor Lead'/>
//         {(<Link to="/lead/addlead"><Button text='Add Lead'/></Link>)}
//       </div>
//       </div>
//       <Leadtable
//        selectedIds={selectedIds} 
//        setSelectedIds={setSelectedIds} 
//        activityList={activityList}
//        setActivityList={setActivityList}/>
//     </div>
//   ) 
// }}
import React, { useState } from 'react';
import { deleteData, filterAll, postData } from '../../services/apiService';
import Button from '../../shared/components/button';
import Leadtable from './leadtable';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as XLSX from 'xlsx';
import { Modal } from 'antd';
import Papa from 'papaparse';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import { checkAccess } from '../../services/checkFeature';
import AccountTableHeader from '../account/accountTableHeader';

export default function Lead({ }) {
  const user = useSelector(state => state.user.user);
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [importOption, setImportOption] = useState('insert');
  const [data, setData] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      const fileType = file.name.split('.').pop();

      reader.onload = (e) => {
        const fileContent = e.target.result;

        if (fileType === 'csv') {
          Papa.parse(fileContent, {
            header: true,
            complete: (result) => {
              setData(result.data);
              handleDataProcessing(result.data);
            },
          });
        } else if (fileType === 'xlsx') {
          const workbook = XLSX.read(fileContent, { type: 'binary' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const excelData = XLSX.utils.sheet_to_json(worksheet);
          setData(excelData);
          handleDataProcessing(excelData);
        } else {
          alert('Unsupported file format');
        }
      };

      if (fileType === 'csv') {
        reader.readAsText(file);
      } else if (fileType === 'xlsx') {
        reader.readAsBinaryString(file);
      }
    }
  };

  const handleDataProcessing = (importedData) => {
    if (importOption === 'insert') {
      const newActivityList = importedData??[];
      newActivityList?.filter((dd)=>{
        postData('lead', dd).then((data) => {
          console.log(data);
        })
      })
      // Handle insertion logic for new records
      console.log('Inserting data:', importedData);
    } else if (importOption === 'update') {
      // Handle updating logic for existing records
      console.log('Updating data:', importedData);
    }
  };
  // Handle delete operation
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`lead/${id}`)));
      const tempActivityList = activityList.filter(item => !(selectedIds.includes(item.id)))
      setActivityList(tempActivityList);

      setTotalItems(totalItems - selectedIds.length)

      // setActivityList(activityList.filter(item => !selectedIds.includes(item.id)));
      // setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const buttonClicked = (lat, long) => {

    showModal();

  }

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
 
  // Function to export the activity list to Excel
  // const exportToExcel = () => {
  //   const formattedData = activityList.map((lead) => ({
  //     // id: lead.id,
  //     name: lead.name,
  //     leadsource: lead.leadsource,
  //     leadindustry: lead.leadindustry,
  //     pricegroup: lead.pricegroup,
  //     address: lead.address,
  //     city: lead.city,
  //     state: lead.state,
  //     country: lead.country,
  //     contactno: lead.contactno,
  //     email: lead.email,
  //     ownername: lead.ownername,
  //     partnername: lead.partnername,
  //     leadstatus: lead.leadstatus,
  //     created_at: lead.created_at,
  //     turnover: lead.turnover,
  //   }));

  //   const ws = XLSX.utils.json_to_sheet(formattedData);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Leads');

  //   // Export the Excel file
  //   XLSX.writeFile(wb, 'Leads_Data.xlsx');
  // };

  const handleExport = () => {
    const headers = [
      ["Name"	,"Email"	,"Contact no",	"Owner",	"Collaborators"	,"Source",	"Industry"]
    ];
 
    filterAll("lead")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.name,
        item.email,
        item.contactno,
        item.ownername,
        "-",
        item.leadsource,
        item.leadindustry,
      ]);
      exportToExcel('Leads',headers,datas,'lead')
    })
  };

  return (
    <div className='page-24'>
      {/* <div className='page-header'>
        <span>Lead</span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
          {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
          <Button text='Export Lead' onClick={exportToExcel} />
          <Button text='Import Lead' onClick={buttonClicked} />
          <Link to="/lead/addlead">
            <Button text='Add Lead' />
          </Link>
        </div>
      </div> */}
      <div className="">
        <div className="customer-header-alignment pb-4">
          <div>
            <h2>Lead</h2>
            <Breadcumbs activePath="Lead" pageName="Lead" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
              <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title="Are you sure to delete this record? "
              description="This will lead to delete all records related to this record only!"
              open={open}
              onConfirm={handleOk}
              okButtonProps={{ loading: confirmLoading }}
              onCancel={handleCancel}
            >
              <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
            </Popconfirm>
            )}
            {checkAccess('add_lead')&&<Link to="/lead/addlead">
              <GlobalButton text="Add Lead" Icon={PlusIcon} />
            </Link>}
            {checkAccess('export_lead')&&<GlobalButton text="Export" onClick={exportToExcel} lightbutton Icon={ExportIcon} />}
            {checkAccess('import_lead')&&<GlobalButton text="Import" onClick={buttonClicked} lightbutton Icon={ExportIcon} />}
          </div>
        </div>
      </div>

      <Leadtable
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        activityList={activityList}
        totalItems={totalItems} 
        setTotalItems={setTotalItems}
        setActivityList={setActivityList}
      />
      <Modal title="Import Excel or CSV File" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div style={{ width: '10%', height: '40px' }} />
        <div>
          {/* <h2>Import Excel or CSV File</h2> */}
          <div>
            <label htmlFor="importOption">Import Option:</label>
            <select
              id="importOption"
              value={importOption}
              onChange={(e) => setImportOption(e.target.value)}
            >
              <option value="insert">Insert New Records</option>
              <option value="update">Update Existing Records</option>
            </select>
          </div>
          <div>
            <input type="file" accept=".csv, .xlsx" onChange={handleFileUpload} />
          </div>
          {/* <div>
            {data.length > 0 && (
              <table border="1">
                <thead>
                  <tr>
                    {Object.keys(data[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((cell, i) => (
                        <td key={i}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div> */}
        </div>
      </Modal>
    </div>
  );
}
