import React, { useEffect, useState, useRef } from 'react'
import './addcustomers.scss';
import Button from '../../shared/components/button';
import Input from '../../shared/components/input';
import Select from '../../shared/components/select';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import SelectBox from '../../shared/components/select';
import { fetchData, fetchsingleData, patchData, postData } from '../../services/apiService';
import axios, { toFormData } from 'axios';
import Breadcumbs from '../../shared/components/breadcumbs';
import PlusIcon from '../../assets/icons/plus.svg';
import CancelIcon from '../../assets/icons/Cancel.svg';
import StatusBox from './statusBox';
import Toggle from '../../shared/components/switch';
import store from '../../redux/reduxstore';
import { useSelector } from "react-redux";
import { red } from '@mui/material/colors';

export default function Addcustomers() {
  const navigate = useNavigate();
  const { id } = useParams();
  const company_setting = store.getState()?.auth?.company_setting;
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const name = useSelector(state => state.auth.name);
  const userData = store.getState().auth.userData
  const userName = userData.name
  const userId = userData.id


  const userDataRef = useRef(userData);
  useEffect(() => {
    userDataRef.current = userData;
  }, [userData]);

  const [gstTypes, setGstTypes] = useState([
    { label: 'Registered Business', value: 'registered' },
    { label: 'Unregistered Business', value: 'unregistered' },
    { label: 'Overseas', value: 'overseas' },
  ]);


  useEffect(() => {
    if (id) {
      setIsEdit(true);
      getProductData();
    }
    fetchCountry();
    fetchState();
    fetchCity();
    fetchArea();
    placeofsupply();
    if (leadFormData?.id && leadFormData?.name) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        customerId: leadFormData.id,
        customerName: leadFormData.name,
      }));
    }
  }, [id]);

  const getProductData = async () => {
    const response = await fetchsingleData("customer", id);
    if (response?.data)
      setFormData(response?.data);
    if (response?.data?.stateId) fetchArea(response?.data?.stateId)
    if (response?.data?.countryId) fetchState(response?.data?.countryId)

  }
  const toggleSwitch = (checked) => {
    setFormData({ ...formData, status: checked });
  };

  const [formData, setFormData] = useState({
    cName: '',
    code: '',
    status: true,
    priceGroupid: null,
    priceGroup: '',
    priceGroupcode: '',
    priority: '',
    outstandingAmount: null,
    overdueBillAmount: null,
    email: '',
    password: '',
    repassword: '',
    address: '',
    title: '',
    pincode: '',
    countryId: null,
    countryName: '',
    stateName: '',
    stateId: null,
    cityId: null,
    city: '',
    posId: null,
    pos: '',
    areaId: null,
    latitude: null,
    longitude: null,
    addressEmail: '',
    addressContact: '',
    pricegroupId: null,
    gstNo: '',
    shippingaddressContact: '',
    gsttype: '',
    customerCategory: "",
    userName: userData.name,
    userId: userData.id,
    segment: ''
  });
  const location = useLocation();
  const [posOptions, satPos] = useState([]);
  const leadFormData = location.state?.formData;
  const [UserNames, setUserName] = useState([]);
  const [sateOptions, sateCategory] = useState([])
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [place, setPlaceofSupply] = useState([]);
  const [isSameAsPrimary, setIsSameAsPrimary] = useState(false);



  const fetchCountry = async () => {
    try {
      const response = await fetchData("country");

      if (response?.data) {
        setCountryOptions(
          response?.data.map((i) => {
            return {
              label: i?.countryName,
              key: "country",
              value: { countryId: i?.id, countryName: i?.countryName },
            };
          })
        );
      }
      if (leadFormData?.id) {
        setFormData({ link: leadFormData?.id, cName: leadFormData?.name, address: leadFormData?.address, title: leadFormData?.title, countryName: leadFormData?.country, pincode: leadFormData?.pincode, latitude: leadFormData?.latitude, longitude: leadFormData?.longitude, email: leadFormData?.email, phone: leadFormData?.contactno, city: leadFormData?.city, stateName: leadFormData?.state });
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const placeofsupply = async () => {
    try {
      const response = await fetchData("state");

      if (response?.data) {
        setPlaceofSupply(
          response?.data.map((i) => {
            return {
              label: i?.stateName,
              key: "state",
              value: { posId: i?.id, stateName: i?.stateName },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchState = async (countyId) => {
    try {
      const response = await fetchData("state", { countryId: countyId });

      if (response?.data) {
        setStateOptions(
          response?.data.map((i) => {
            return {
              label: i?.stateName,
              key: "state",
              value: { stateId: i?.id, stateName: i?.stateName },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCity = async (stateId) => {
    try {
      const response = await fetchData("city", { stateId: stateId });

      if (response?.data) {
        setCityOptions(
          response?.data.map((i) => {
            return {
              label: i?.cityName,
              key: "city",
              value: { cityId: i?.id, city: i?.cityName },
            };
          })
        );
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchArea = async (cityId) => {
    try {
      const response = await fetchData("area", { cityId: cityId });

      if (response?.data) {
        setAreaOptions(
          response?.data.map((i) => {
            return {
              label: i?.areaName,
              key: "area",
              value: { cityId: i?.id, city: i?.areaName },
            };
          })
        );
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const [priceOptions, setPriceOptions] = useState([]);

  const fetchPrice = async () => {
    try {
      const response = await fetchData("pricegroup");
      if (response?.data) {
        setPriceOptions(
          response.data.map(i => ({
            label: i.name,
            key: 'pricegroup',
            value: { id: i.id, name: i.name, code: i.code },
          }))
        );
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const [isPriceGroupSelected, setIsPriceGroupSelected] = useState(false);

  const selectChangedPrice = (selectedOption) => {
    if (selectedOption) {
      setFormData(prev => ({
        ...prev,
        priceGroupid: selectedOption.value.id,
        priceGroup: selectedOption.value.name,
        priceGroupcode: selectedOption.value.code,
      }));
      setIsPriceGroupSelected(true);
    } else {
      setFormData(prev => ({
        ...prev,
        priceGroupid: '',
        priceGroup: '',
        priceGroupcode: '',
      }));
      setIsPriceGroupSelected(false);
    }
  };
  const [accountcategoryOptions, setAccountCategoryOptions] = useState([]);

  const fetchAccountCategory = async () => {
    try {
      const response = await fetchData('accountcategory');
      if (response?.data) {
        setAccountCategoryOptions(
          response.data.map(item => ({
            label: item.name,
            value: { id: item.id, color: item.color },
          }))
        );
      }
    } catch (error) {
      console.error('Error fetching account categories:', error);
    }
  };
  const handleSelectChangeAcoount = selectedOption => {
    setFormData(prevState => ({
      ...prevState,
      accountCategory: selectedOption?.value.id || '',
    }));
  };
  const [segmentOptions, setSegmentOptions] = useState([]);

  const fetchSegment = async () => {
    try {
      const response = await fetchData('segment');
      if (response?.data) {
        setSegmentOptions(
          response.data.map(item => ({
            label: item.name,
            value: item.name,
          }))
        );
      }
    } catch (error) {
      console.error('Error fetching segments:', error);
    }
  };

  const handleSelectChange = selectedOption => {
    setFormData(prevState => ({
      ...prevState,
      segment: selectedOption?.value || '', // Update the form data with selected segment
    }));
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {

          setFormData(prevState => ({
            ...prevState,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }));

        },
        error => {
          console.error('Error getting geolocation:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }


    fetchPrice()
    fetchSegment();
    fetchAccountCategory();

  }, [])
  const validateForm = () => {
    const newErrors = {};
    if (!formData.cName) newErrors.cName = 'Customer Name is required';
    if (!formData.code && !company_setting?.extra_setting?.autoGenerateCustomerCode === 'Yes') newErrors.code = 'Customer Code is required';
    if (!formData.address) newErrors.address = 'Address is required';
    if (!formData.countryId) newErrors.countryId = 'Country is required';
    if (!formData.stateId) newErrors.stateId = 'State is required';
    if (!formData.cityId) newErrors.cityId = 'City is required';
    if (!formData.shippingAddress) newErrors.shippingAddress = 'Shipping Address is required';
    if (!formData.shippingcountryId) newErrors.shippingcountryId = 'Shipping Country is required';
    if (!formData.shippingstateId) newErrors.shippingstateId = 'Shipping State is required';
    if (!formData.shippingcityId) newErrors.shippingcityId = 'Shipping City is required';
    if (!/^\d{10}$/.test(formData.shippingaddressContact)) {
      newErrors.shippingaddressContact = "Please enter a valid 10-digit phone number.";
    }


    if (!formData.gsttype) {
      newErrors.gsttype = 'GST Type is required';
    } else if (formData.gsttype === 'registered') {

      if (!formData.gstNo) newErrors.gstNo = 'GST No is required for registered businesses';
      if (!formData.pos) newErrors.pos = 'Place of Supply is required for registered businesses';
    } else if (formData.gsttype === 'unregistered') {
      if (!formData.pos) newErrors.pos = 'Place of Supply is required for unregistered businesses';
    }
    if (formData.addressContact.length !== 10) {
      newErrors.addressContact = 'Please enter a valid 10-digit phone number.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function generateCustomerCode(customerName) {
    if (!customerName || customerName.trim().length === 0) {
      throw new Error("Customer name cannot be empty");
    }
    let adjustedName = customerName.trim().toUpperCase();
    while (adjustedName.length < 3) {
      adjustedName += 'X';
    }
    const namePart = adjustedName.substring(0, 3);
    const timestamp = Date.now().toString().slice(-5);
    const randomPart = Math.floor(1000 + Math.random() * 9000);
    const customerCode = `${namePart}${timestamp}${randomPart}`;
    return customerCode;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    let payloadCustomer = formData;
    ['posId', 'latitude', 'longitude'].forEach((key) => {
      if (!payloadCustomer?.[key]) {
        delete payloadCustomer[key]
      }
    });
    try {
      if (id) {
        // const {id, ...otherData} = payloadCustomer;
        patchData(`customer/${id}`, payloadCustomer).then(data => {
          if (data) {
            navigate(-1);
          }
          console.log('API response:', data);
        })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
      } else {
        if (company_setting?.extra_setting?.autoGenerateCustomerCode === 'Yes') {
          const ccode = generateCustomerCode(formData?.cName);
          payloadCustomer = { ...payloadCustomer, "code": ccode }
        }
        const { id, ...otherData } = payloadCustomer;
        postData('customer', otherData)
          .then(data => {
            if (data) {
              navigate(-1);
            }
            console.log('API response:', data);
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });


      }

    } catch (error) {
      console.error('Error saving data:', error);

    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    if (errors.isSubmit) {
      const fieldError = validateField(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: fieldError,
      }));
    }
  };
  const validateField = (name, value) => {
    switch (name) {
      case "cName":
        return value.trim() === "" ? "Customer Name is required" : "";

      case "code":
        return value.trim() === "" ? "Address is required" : "";
      case "address":
        return value.trim() === "" ? "Customer Code is required" : "";
      case "shippingAddress":
        return value.trim() === "" ? "Shipping Address is required" : "";
      case "shippingaddressContact":
        return !/^\d{10}$/.test(value) ? "Please enter a valid 10-digit phone number." : "";
      case "gstNo":
        return value.trim() === "" ? "GST No is required for registered businesses" : "";
      case "addressContact":
        return value.trim() === "" ? "Please enter a valid 10-digit phone number." : "";


      default:
        return "";
    }
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsSameAsPrimary(isChecked);
  
    if (isChecked) {
      // Copy Primary Details to Shipping Details
      setFormData((prevState) => ({
        ...prevState,
        shippingAddress: prevState.address,
        shippingtitle: prevState.title,
        shippingpincode: prevState.pincode,
        shippingcountryId: prevState.countryId,
        shippingcountryName: prevState.countryName,
        shippingstateId: prevState.stateId,
        shippingstateName: prevState.stateName,
        shippingcityId: prevState.cityId,
        shippingcityName: prevState.city,
        shippingaddressEmail: prevState.addressEmail,
        shippingaddressContact: prevState.addressContact,
      }));
  
      // Clear errors for shipping fields (if needed)
      setErrors((prevErrors) => ({
        ...prevErrors,
        shippingAddress: "",
        shippingcountryId: "",
        shippingstateId: "",
        shippingcityId: "",
        shippingaddressContact: "",
      }));
    } else {
      // Optionally, clear the shipping details if unchecked
      setFormData((prevState) => ({
        ...prevState,
        shippingAddress: "",
        shippingtitle: "",
        shippingpincode: "",
        shippingcountryId: null,
        shippingcountryName: "",
        shippingstateId: null,
        shippingstateName: "",
        shippingcityId: null,
        shippingcityName: "",
        shippingaddressEmail: "",
        shippingaddressContact: "",
      }));
    }
  };
  const selectChanged = (e) => {
    if (e.key === "country") {
      setFormData(prevState => ({
        ...prevState,
        ...e.value
      }));
      fetchState(e.value.countryId)
    } else if (e.key === "state") {
      setFormData(prevState => ({
        ...prevState,
        ...e.value
      }));
      fetchArea(e.value.stateId)

    }
    else {
      setFormData(prevState => ({
        ...prevState,
        ...e.value
      }));
    }
  }
  const selectgsttype = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFormData((prevData) => ({
        ...prevData,
        gsttype: selectedOption.value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        gsttype: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        gsttype: "",
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        gsttype: "Invalid selection",
      }));
    }
  };
  const selectaccountcategory = (selectedOption) => {
    setFormData(prevData => ({
      ...prevData,
      name: selectedOption.value,
    }));
  };
  const [error, setError] = useState('');

  const handleInputChangePassowed = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [name]: value }));


    if (name === 'repassword' || name === 'password') {
      const newPassword = name === 'password' ? value : formData.password;
      const rePassword = name === 'repassword' ? value : formData.repassword;

      if (newPassword !== rePassword) {
        setError('Passwords do not match');
      } else {
        setError('');
      }
    }
  };


  const selectpostype = (selectedOption) => {
    if (selectedOption && selectedOption.value) {
      setFormData((prevData) => ({
        ...prevData,
        pos: selectedOption.value.stateName || "",
        posId: Number(selectedOption.value.posId) || 0,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        pos: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        pos: "",
        posId: 0,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        pos: "Invalid selection",
      }));
    }
  };

  const selectuser = (selectedOption) => {

    setFormData((prevData) => ({
      ...prevData,
      userName: selectedOption.value.userName,
      userId: Number(selectedOption?.value?.userId),
    }));

  };



  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(prev => !prev);
  };
  return (
    <div className='add-customer-page-design-alignment'>
      <div className='add-customer-header-alignment'>
        <div>
          <h2>Add Customers</h2>
          <Breadcumbs activePath="Customer" pageName="Add Customers" />
        </div>
        <div className='right-contnet-alignment'>
          <button className='light-button'>
            <img src={CancelIcon} alt="CancelIcon" />
            Cancel
          </button>
          <button onClick={handleSubmit}>
            {id ? 'Update Customer' : 'Add Customer'}
          </button>
        </div>
      </div>

      <div className='add-customer-grid'>
        <div className='add-customer-grid-items'>
          <div className='add-news-form-design-customer'>
            <h2>General Information</h2>
            <div className='two-col-grid'>
              <div className='bottom-alignment'>
                <Input
                  label={
                    <span>
                      Name <span className="required-star">*</span>
                    </span>
                  }
                  name="cName"
                  value={formData.cName}
                  onChange={handleInputChange}
                  placeholder="Customer Name"
                />
                {errors.cName && <p className="error">{errors.cName}</p>}
              </div>
              <div className='bottom-alignment'>
                <Input
                  label={
                    <span>
                      Code
                      {company_setting?.extra_setting?.autoGenerateCustomerCode === 'No' && (
                        <span className="required-star">*</span>
                      )}
                    </span>
                  }
                  name="code"
                  value={formData.code}
                  onChange={handleInputChange}
                  readOnly={company_setting?.extra_setting?.autoGenerateCustomerCode === 'Yes'}
                  placeholder={company_setting?.extra_setting?.autoGenerateCustomerCode === 'Yes' ? 'Customer code will be Auto-Generated' : "Customer Code"}
                />
                {errors.code && <p className="error">{errors.code}</p>}
              </div>
              <div className='bottom-alignment'>
                <SelectBox
                  label='Price group'
                  name='priceGroup'
                  value={priceOptions.find(i => i.value.id === formData.priceGroupid)}
                  options={priceOptions}
                  onChange={selectChangedPrice}
                  placeholder='Select Price Group'
                  required
                />
              </div>
              {company_setting?.customer_assignment_type === "Direct assign customer to user" && (

                <div className='bottom-alignment'>
                  <SelectBox label={
                    <span>
                      Users
                    </span>}
                    name='userName' value={UserNames.find(i => i?.value?.userId === formData?.userId)} onChange={(e) => selectuser(e)} options={UserNames} placeholder='User' />
                </div>
              )}
              <div className='bottom-alignment'>
                <Input label='Outstanding Amount' name='outstandingAmount' value={formData.outstandingAmount} onChange={handleInputChange} placeholder='Outstanding Amount' />
              </div>
              <div className='bottom-alignment'>
                <Input label='Overdue Bill Amount' name='overdueBillAmount' value={formData.overdueBillAmount} onChange={handleInputChange} placeholder='Overdue Bill Amount' />
              </div>
              {company_setting?.extra_setting?.priorityForCustomers === "Yes" && (
                <div className='bottom-alignment'>
                  <Input label='Priority' name='priority' value={formData.priority} onChange={handleInputChange} placeholder='Customer Priority' type="text" />
                </div>
              )}
              <div className="bottom-alignment">
                <SelectBox
                  size="small"
                  variant="outlined"
                  label="Account Category"
                  name="customerCategory"
                  value={accountcategoryOptions.find(option => option.value.id === formData.accountCategory) || null}
                  options={accountcategoryOptions}
                  onChange={handleSelectChangeAcoount}
                  errors={errors.customerCategory}
                />
                {errors.customerCategory && <p className="error-message">{errors.customerCategory}</p>}
              </div>


              <div className='bottom-alignment'>
                <Toggle label='Status' checked={formData.status} onChange={toggleSwitch} />
              </div>
            </div>
          </div>
          <div className='add-news-form-design-customer'>
            <h2>Level Details</h2>
            <div>
              <div>
                <label
                  style={{
                    display: 'block',
                    marginBottom: '8px',
                    fontWeight: 'bold',
                  }}
                >
                  Segment
                </label>
                <button
                  onClick={toggleDropdown}
                  style={{
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    padding: '8px 10px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  ADD SEGMENT
                </button>
                {showDropdown && (
                  <div className="bottom-alignment">
                    <SelectBox
                      size="small"
                      variant="outlined"
                      name="segment"
                      value={
                        segmentOptions.find(option => option.value === formData.segment) || null
                      }
                      options={segmentOptions}
                      onChange={handleSelectChange}
                      errors={errors.name}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='add-news-form-design-customer'>
            <h2>Login Details</h2>
            <div className='two-col-grid'>
              <div className='bottom-alignment'>
                <Input label='Email' name='email' value={formData.email} onChange={handleInputChange} placeholder='Email' type='text' />
              </div>
              <div className="bottom-alignment">
                <Input
                  label="Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChangePassowed}
                  placeholder="Password"
                  type="password"
                />
              </div>
              <div className="bottom-alignment">
                <Input
                  label="Re-password"
                  name="repassword"
                  value={formData.repassword}
                  onChange={handleInputChangePassowed}
                  placeholder="Confirm Password"
                  type="password"
                />
              </div>
              {error && <div className="error-message" style={{ color: "#d50000" }}>{error}</div>}
            </div>
          </div>

          <div className='add-news-form-design-customer'>
            <h2>GST Details</h2>
            <div className='two-col-grid'>
              <div className='bottom-alignment'>
                <SelectBox
                  label={
                    <span>
                      GST type<span className="required-star">*</span>
                    </span>
                  }

                  name='gsttype'
                  value={gstTypes.find((option) => option.value === formData.gsttype)} options={gstTypes} onChange={(e) => selectgsttype(e)}
                  placeholder='GST Type'
                />
                {errors.gsttype && <span className="error">{errors.gsttype}</span>}
              </div>
              <div className='bottom-alignment'>
                {formData.gsttype === 'registered' && (
                  <Input
                    label={
                      <span>
                        GST no <span className="required-star">*</span>
                      </span>
                    }

                    name='gstNo'
                    value={formData.gstNo}
                    onChange={handleInputChange}
                    placeholder='GST No.'
                    type='text'
                  />

                )}

                {errors.gstNo && <span className="error">{errors.gstNo}</span>}
              </div>
              <div className='Place of Supply'>
                {(formData.gsttype === 'registered' || formData.gsttype === 'unregistered') && (
                  <SelectBox

                    label={
                      <span>
                        Place of Supply <span className="required-star">*</span>
                      </span>
                    }
                    name='pos'
                    value={place.find(i => i?.value.posId === formData.posId)}
                    options={place}
                    onChange={(e) => selectpostype(e)}
                    placeholder='Place of Supply'
                  />
                )}
                {errors.pos && <span className="error">{errors.pos}</span>}
              </div>
            </div>
          </div>

          <div className='add-news-form-design-customer'>
            <h2>Primary Contact Details</h2>
            <div className='two-col-grid'>
              <div className='bottom-alignment'>
                <Input
                  label={
                    <span>
                      Address <span className="required-star">*</span>
                    </span>
                  }
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  placeholder="Address"
                />
                {errors.address && <p className="error">{errors.address}</p>}
              </div>
              <div className='bottom-alignment'>
                <Input label='Title' name='title' value={formData.title} onChange={handleInputChange} placeholder='Title' type='text' />
              </div>
              <div className='bottom-alignment'>
                <Input label='Pincode' name='pincode' value={formData.pincode} onChange={handleInputChange} placeholder='Pincode' type='text' />
              </div>
              <div className='bottom-alignment'>
  <SelectBox
    label={
      <span>
        Country <span className="required-star">*</span>
      </span>
    }
    name="countryName"
    value={countryOptions.find(
      (i) => i.value.countryId === formData.countryId
    )}
    options={countryOptions}
    placeholder="Select Country"
    onChange={(selectedOption) => {
      fetchState(selectedOption?.value?.countryId);

      setFormData((prevState) => ({
        ...prevState,
        countryId: Number(selectedOption?.value?.countryId),
        countryName: selectedOption?.value?.country,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        countryId: "",
      }));
    }}
  />
  {errors.countryId && <p className="error">{errors.countryId}</p>}
</div>
<div className='bottom-alignment'>
  <SelectBox
    label={
      <span>
        State <span className="required-star">*</span>
      </span>
    }
    name="stateName"
    value={stateOptions.find(
      (i) => i.value.stateId === formData.stateId
    )}
    options={stateOptions}
    placeholder="Select State"
    onChange={(selectedOption) => {
      fetchCity(selectedOption?.value?.stateId);

      setFormData((prevState) => ({
        ...prevState,
        stateId: Number(selectedOption?.value?.stateId),
        stateName: selectedOption?.value?.stateName,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        stateId: "",
      }));
    }}
  />
  {errors.stateId && <p className="error">{errors.stateId}</p>}
</div>
              <div className='bottom-alignment'>
                <SelectBox
                  label={
                    <span>
                      City <span className="required-star">*</span>
                    </span>
                  }
                  name="city"
                  value={cityOptions.find(
                    (i) => i.value.cityId === formData.cityId
                  )}
                  options={cityOptions}
                  placeholder="Select City"
                  onChange={(selectedOption) => {
                    fetchArea(selectedOption?.value?.cityId);

                    setFormData((prevState) => ({
                      ...prevState,
                      cityId: Number(selectedOption?.value?.cityId),
                      city: selectedOption?.value?.city,
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      cityId: "",
                    }));
                  }}

                />
                {errors.cityId && <p className="error">{errors.cityId}</p>}

              </div>

              {/* <div className='bottom-alignment'>
                <Input label='Latitude' name='latitude' value={formData.latitude} onChange={handleInputChange} placeholder='Latitude' />
              </div>
              <div className='bottom-alignment'>
                <Input label='Longitude' name='longitude' value={formData.longitude} onChange={handleInputChange} placeholder='Longitude' />
              </div> */}

              <div className='bottom-alignment'>
                <Input label='Email' name='addressEmail' value={formData.addressEmail} onChange={handleInputChange} placeholder='Email' type='text' />
              </div>
              <div className='bottom-alignment'>
                <Input label={
                  <span>
                    Contact no <span className="required-star">*</span>
                  </span>
                } name='addressContact' value={formData.addressContact} onChange={handleInputChange} placeholder='Contact No.' type='text' />

                {errors.addressContact && <div className="error">{errors.addressContact}</div>}</div>
            </div>
          </div>
          <div className="checkbox-container">
  <label className="adjust-checkbox">
    <input
      type="checkbox"
      // checked={isSameAsPrimary}
      onChange={handleCheckboxChange}
    />
    Same as Primary Details
  </label>
</div>

          <div className='add-news-form-design-customer'>
            <h2>Shipping Details</h2>
            <div className='two-col-grid'>
              <div className='bottom-alignment'>
                <Input
                  label={
                    <span>
                      Address <span className="required-star">*</span>
                    </span>
                  }
                  name="shippingAddress"
                  value={formData.shippingAddress}
                  onChange={handleInputChange}
                  placeholder="Address"
                />
                {errors.Address && <p className="error">{errors.shippingAddress}</p>}
              </div>
              <div className='bottom-alignment'>
                <Input label='Title' name='shippingtitle' value={formData.shippingtitle} onChange={handleInputChange} placeholder='Title' type='text' />
              </div>
              <div className='bottom-alignment'>
                <Input label='Pincode' name='shippingpincode' value={formData.shippingpincode} onChange={handleInputChange} placeholder='Pincode' type='text' />
              </div>

              <div className='bottom-alignment'>
                  <SelectBox
                    label={
                      <span>
                        Country <span className="required-star">*</span>
                      </span>
                    }
                    name="shippingcountryName"
                    value={countryOptions.find(
                      (i) => i.value.countryId === formData.shippingcountryId
                    )}
                    options={countryOptions}
                    placeholder="Select Country"
                    onChange={(selectedOption) => {
                      fetchState(selectedOption?.value?.countryId);

                      setFormData((prevState) => ({
                        ...prevState,
                        shippingcountryId: Number(selectedOption?.value?.countryId),
                        shippingcountryName: selectedOption?.value?.country,
                      }));
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        shippingcountryId: "",
                      }));
                    }}
                  />
                  {errors.shippingcountryId && <p className="error">{errors.shippingcountryId}</p>}
                </div>
                <div className='bottom-alignment'>
                  <SelectBox
                    label={
                      <span>
                        State <span className="required-star">*</span>
                      </span>
                    }
                    name="shippingstateName"
                    value={stateOptions.find(
                      (i) => i.value.stateId === formData.shippingstateId
                    )}
                    options={stateOptions}
                    placeholder="Select State"
                    onChange={(selectedOption) => {
                      fetchCity(selectedOption?.value?.stateId);

                      setFormData((prevState) => ({
                        ...prevState,
                        shippingstateId: Number(selectedOption?.value?.stateId),
                        shippingstateName: selectedOption?.value?.stateName,
                      }));
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        shippingstateId: "",
                      }));
                    }}
                  />
                  {errors.shippingstateId && <p className="error">{errors.shippingstateId}</p>}
              </div>
              <div className='bottom-alignment'>

              <SelectBox
                  label={
                    <span>
                      City <span className="required-star">*</span>
                    </span>
                  }
                  name="shippingcityName"
                  value={cityOptions.find(
                    (i) => i.value.cityId === formData.shippingcityId
                  )}
                  options={cityOptions}
                  placeholder="Select City"
                  onChange={(selectedOption) => {
                    fetchArea(selectedOption?.value?.cityId);

                    setFormData((prevState) => ({
                      ...prevState,
                      shippingcityId: Number(selectedOption?.value?.cityId),
                      shippingcityName: selectedOption?.value?.city,
                    }));
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      shippingcityId: "",
                    }));
                  }}

                />
                {errors.cityId && <p className="error">{errors.cityId}</p>}
              </div>

              <div className='bottom-alignment'>
                <Input label='Email' name='shippingaddressEmail' value={formData.shippingaddressEmail} onChange={handleInputChange} placeholder='Email' type='text' />
              </div>
              <div>
                <div className="bottom-alignment">
                  <Input
                    label={
                      <span>
                        Contact no <span className="required-star">*</span>
                      </span>
                    }
                    name="shippingaddressContact"
                    value={formData.shippingaddressContact}
                    onChange={handleInputChange}
                    placeholder="Contact No."
                    type="text"
                  />
                  {errors.shippingaddressContact && <div className="error">{errors.shippingaddressContact}</div>}
                </div>
              </div>


            </div>
          </div>
        </div>
        <div className='add-customer-grid-items'>
          <StatusBox />
        </div>

      </div>

    </div>
  )
}