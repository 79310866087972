import React, { useState, useEffect, useRef } from "react";
import "./addinvoice.scss";
import { Link } from "react-router-dom";
import Button from "../../../shared/components/button";
import InputCustom from "../../../shared/components/input";
import { fetchData, fetchsingleData, filterAll, patchData, postData, fetchorderforinvoice } from "../../../services/apiService";
import SelectBox from "../../../shared/components/select";
import Toggle from "../../../shared/components/switch";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Input } from "antd";
import moment from 'moment';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { control } from "leaflet";
import GlobalButton from "../../../shared/globalButton";
import TotalAmountDisplay from "../formateamount";
import { checkAccess } from "../../../services/checkFeature";
import { notify } from "../../toastr/Toastr";
import store from "../../../redux/reduxstore";
// import { getDat } from "react-datepicker";
const { TextArea } = Input;

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#f1f1f1',
        minHeight: '40px',
        height: '40px',
        fontSize: '14px',
        boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '40px',
        padding: '0 6px',
        fontSize: '14px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '40px',
    }),
};
export default function AddInvoice() {
    const navigate = useNavigate();
    const { id } = useParams();
    const company_setting = store.getState()?.auth?.company_setting;

    const isEditMode = window.location.pathname.includes('/add') || false;

    useEffect(() => {
        // Fetch data when the component mounts
        getData();
        getCustomer();
        setProductsForOrder([]);

    }, []);

    const [productOptions, setProductOptions] = useState([]);
    const [customers, setCustomerOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [transportOptions, setTransportOptions] = useState([]);
    const [newNotification, setNewNotification] = useState([]);
    const [newUpdate, setNewUpdate] = useState([]);
    const [productsForOrder, setProductsForOrder] = useState([]);
    const [orderNo, setOrderOption] = useState([]);
    const name = useSelector(state => state.auth.name);
    const userData = store.getState().auth.userData
    const userName = userData.name
    const userId = userData.id
    const [discountType, setDiscountType] = useState([
        { label: "%", value: "%", name: "%" },
        { label: "₹", value: "₹", name: "₹" },
    ]);


    const userDataRef = useRef(userData);
    useEffect(() => {
        // Update the ref whenever isExamStarted changes
        userDataRef.current = userData;
    }, [userData]);
    const [segmentOptions, setsegmentOptions] = useState([]);
    const [formData, setFormData] = useState({
        // invoiceNo: "",
        invoiceDate: new Date(),
        customerName: "",
        customerId: "",
        customerAddress: "",
        customerContact: "",
        orderNo: [],
        status: true,
        orderproducts: [],
        taxBreakdown: [],
        grandtotal: {},
        ordernotification: [],
        totalAmount: 0,
        created_by: '',
        updated_by: '',
        gstType: 'IGST',
        // placeOfSupply:'',
        placeOfSupplyId: '',
        userName: userData.name,
        userId: userData.id,
    });

    const [errors, setErrors] = useState({});
    const getData = async () => {
        if (id) {
            const response = await fetchsingleData("invoice", id);
            if (response?.data) {
                const updatedProductDetails = (response?.data ?? []).orderproducts.map((product) => {
                    return { ...product };
                });
                setFormData(response.data);
                setProductsForOrder([...updatedProductDetails]);
                calculateTotal();

                if (response.data?.customerId) {
                    const res = await fetchData("order", { customerId: response.data?.customerId });
                    if (res?.data) {
                        const filteredOrders = (res?.data ?? [])
                            .map((order) => ({
                                label: `${order?.orderNo ?? order?.id}`,
                                key: "orderNo",
                                value: order?.id,
                                id: order.id,
                                name: `${order?.orderNo ?? order?.id}`,
                            }));

                        setOrderOption(filteredOrders);
                        const company_setting = await fetchsingleData("company_setting", 1);
                        if (company_setting?.data) {
                            let gstType = 'IGST';
                            if (company_setting?.data?.gst_configuration?.enableGST === 'Yes') {
                                if (company_setting?.data?.gst_configuration?.placeOfSupply === String(response.data?.placeOfSupplyId)) {
                                    gstType = 'CGST_SGST';
                                }
                            } else if (company_setting?.data?.gst_configuration?.enableGST === 'No') {
                                gstType = 'Tax';
                            }
                            setFormData((prevState) => ({ ...prevState, gstType: gstType }));
                        }
                    }
                }
            }
        }
        getProductsList();

    }
    const getProductsList = async () => {
        const responseProducts = await fetchData(`product`);
        if (responseProducts?.data) {
            const data = (responseProducts?.data ?? []).map((d) => {
                return {
                    ...d,
                    value: d?.id,
                    label: d?.name,
                };
            })
            setProductOptions(data);
        }
    }
    const getCustomer = async () => {
        try {
            const response = await fetchData(`customer`);
            if (response?.data) {
                const filteredCustomers = response.data.filter((i) => i?.status ===true);
                setCustomerOptions(
                    filteredCustomers.map((i) => {
                        return {
                            label: `[${i?.code ?? ''}] ${i?.cName}`,
                            key: "customer",
                            value: {
                                id: i?.id,
                                name: `[${i?.code}] ${i?.cName}`,
                                address: `${i?.address} - ${i?.pincode}.`,
                                contact: i?.addressContact,
                                posId: i?.posId,
                            },
                        };
                    })
                );
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validationCheck()) {
            try {
                const updatedData = {
                    ...formData,
                    orderproducts: [...productsForOrder],
                };
                delete updatedData.textBreakdown;
                if (id) {
                    const data = await patchData(`invoice/${id}`, updatedData);
                    if (data) {
                        notify.success(data?.data.invoiceNo + ' Invoice updated Successfully!');
                        navigate(-1);
                    }
                    else {
                        throw new Error("Failed to save invoice.");
                    }
                } else {
                    const data = await postData("invoice", updatedData);
                    if (data) {
                        notify.success(data?.data.invoiceNo + ' Invoice saved Successfully!');
                        navigate(-1);
                    }
                    else {
                        throw new Error("Failed to save invoice.");
                    }
                }

            } catch (error) {
                notify.error(error?.error);
                console.error("Error saving data:", error);
            }
        }
    };

    const selectChanged = async (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [`${e.key}Name`]: e.value?.name,
            [`${e.key}Id`]: e.value?.id,

        }));

        if (e.key === "customer") {
            setFormData((prevState) => ({
                ...prevState,
                customerAddress: e.value?.address ?? '-',
                customerContact: e.value?.phone ?? '-',
                placeOfSupplyId: e?.value?.posId ? String(e?.value?.posId) : ''
            }));

            const customerId = e.value?.id;
            if (customerId) {
                const res = await fetchData("order", { customerId: customerId });
                if (res?.data) {
                    const filteredOrders = (res?.data ?? [])
                        .map((order) => ({
                            label: `${order?.orderNo ?? order?.id}`,
                            key: "orderNo",
                            value: order?.id,
                            id: order.id,
                            name: `${order?.orderNo ?? order?.id}`,
                        }));

                    setOrderOption(filteredOrders);
                }
            }
            const company_setting = await fetchsingleData("company_setting", 1);
            if (company_setting?.data) {
                let gstType = 'IGST';
                if (company_setting?.data?.gst_configuration?.enableGST === 'Yes') {
                    if (company_setting?.data?.gst_configuration?.placeOfSupply === String(e?.value?.posId)) {
                        gstType = 'CGST_SGST';
                    }
                } else if (company_setting?.data?.gst_configuration?.enableGST === 'No') {
                    gstType = 'Tax';
                }
                setFormData((prevState) => ({ ...prevState, gstType: gstType }));
            }

        }

        if (e?.target?.name === "orderNo") {
            setFormData((prevState) => ({
                ...prevState,
                orderNo: e?.target?.value,
            }));
            const selectedOrders = e?.target?.value?.map(d => d?.orderId)
            console.log(selectedOrders, "selectedOrders");
            if(selectedOrders.length === 0) {setProductsForOrder([]); return;}
            if (selectedOrders) {
                const response = await fetchorderforinvoice("invoice/customerId", { orderIds: [selectedOrders] });
                if (response?.data) {
                    const fetchedData = response.data.products;
                    console.log(fetchedData, "fetchedData");
                    const grandtotal = response.data.totals;
                    console.log(grandtotal, "grandtotal");
                    if (fetchedData && Array.isArray(fetchedData)) {
                        setProductsForOrder(() => fetchedData);
                    }

                    const taxBreakdown = fetchedData.reduce((acc, item) => {
                        if (Array.isArray(item.taxBreakdown)) {
                            acc.push(...item.taxBreakdown);
                        }
                        return acc;
                    }, []);
                    setFormData((prevState) => ({
                        ...prevState,
                        orderproducts: fetchedData,
                        taxBreakdown: taxBreakdown,
                        grandtotal: grandtotal,
                    }));
                }
            }
        }
        if (errors.isSubmit) validationCheck();
    };
    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
        if (errors.isSubmit) validationCheck();
    };

    const handleDateChange = (date) => {
        if (date) {
            setFormData((prevState) => ({
                ...prevState,
                invoiceDate: dayjs(date).format("DD/MM/YYYY"),
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                invoiceDate: null,
            }));
        }
    };
    const validationCheck = (isSubmit) => {
        const validationErrors = {};
        if (!formData.customerName.trim()) {
            validationErrors.customerName = "Please select customer";
        }
        if (!formData.invoiceDate) {
            validationErrors.invoiceDate = "Select Order Date";
        }
        if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

        setErrors(validationErrors);
        return Object.keys(validationErrors).filter((i) => i !== "isSubmit")
            .length === 0
            ? true
            : false;
    };
    // const handleProductChange = (index, field, value) => {
    //     setProductsForOrder((prevProducts) => {
    //         const updatedProducts = [...prevProducts];
    //         console.log(updatedProducts, "updatedProducts");
    //         setProductsForOrder(updatedProducts);
    //         const product = { ...updatedProducts[index] };
    //         console.log(product, "product");
    //         product[field] = value;
    //         // const quantity = parseFloat(product.quantity || 0);
    //         // const price = parseFloat(product.unitPrice || 0);
    //         // const discountPercentage = parseFloat(product.discount || 0);
    //         // const taxPercentage = parseFloat(product.tax || 0);
    //         // if(field ==='tax') product[field] = taxPercentage;
    //         // const grossAmount = quantity * price;
    //         // const discountAmount = (grossAmount * discountPercentage) / 100;
    //         // const taxableAmount = grossAmount - discountAmount;

    //         // const taxAmount = (taxableAmount * taxPercentage) / 100;
    //         // // product.subTotal = (taxableAmount + taxAmount).toFixed(2);
    //         // product.subTotal = (taxableAmount).toFixed(2);
    //         updatedProducts[index] = product;
    //         return updatedProducts;
    //     });
    //     console.log(productsForOrder, "productsForOrder");

    // };

    const handleProductChange = (index, field, value) => {
        setProductsForOrder((prevProducts) => {
            const updatedProducts = [...prevProducts];
            const product = { ...updatedProducts[index] };
            product[field] = value;
            const quantity = parseFloat(product.quantity || 0);
            const unitPrice = parseFloat(product.unitPrice || 0);
            const discountPercentage = parseFloat(product.discount || 0);
            const offerDiscountPercentage = parseFloat(product.offerDiscount || 0);
            const additionalDiscountPercentage = parseFloat(product.additionalDiscount || 0);
            const taxPercentage = parseFloat(product.tax || 0);
            const customerPlaceOfSupply = parseInt(product.placeOfSupplyIdOfCustomer, 10);
            const orgPlaceOfSupply = parseInt(product.companySettingsPlaceOfSupplyId, 10);
            const gstType = product.gstType;
            let grossAmount = quantity * unitPrice;
            const discountAmount =
                product.discounttype === "%"
                    ? (grossAmount * discountPercentage) / 100
                    : discountPercentage;
            grossAmount -= discountAmount;

            const offerDiscountAmount =
                product.offerDiscounttype === "%"
                    ? (grossAmount * offerDiscountPercentage) / 100
                    : offerDiscountPercentage;
            grossAmount -= offerDiscountAmount;

            const additionalDiscountAmount =
                product.additionalDiscounttype === "%"
                    ? (grossAmount * additionalDiscountPercentage) / 100
                    : additionalDiscountPercentage;
            grossAmount -= additionalDiscountAmount;
            let sgst = 0,
                cgst = 0,
                igst = 0,
                taxAmount = 0;

            if (gstType === "registered" && orgPlaceOfSupply === customerPlaceOfSupply) {
                sgst = (grossAmount * taxPercentage) / 200;
                cgst = sgst;
            } else if (gstType === "registered") {
                
                igst = (grossAmount * taxPercentage) / 100;
            }
            taxAmount = sgst + cgst + igst;
            let subtotal = grossAmount + taxAmount; 
            const total = subtotal; 
            product.total = total.toFixed(2);
            product.subtotal = subtotal.toFixed(2); 
            product.taxcharges = taxAmount.toFixed(2);
            product.taxBreakdown = [
                { label: "SGST", percentage: taxPercentage / 2, amount: sgst.toFixed(2) },
                { label: "CGST", percentage: taxPercentage / 2, amount: cgst.toFixed(2) },
                { label: "IGST", percentage: taxPercentage, amount: igst.toFixed(2) },
            ].filter((taxItem) => taxItem.amount > 0);
            product.totalTax = taxAmount.toFixed(2);
            updatedProducts[index] = product;
            const taxBreakdown = updatedProducts.reduce((acc, item) => {
                if (Array.isArray(item.taxBreakdown)) {
                    acc.push(...item.taxBreakdown);
                }
                return acc;
            }, []);

            const grandtotal = updatedProducts.reduce(
                (totals, item) => {
                    const subtotal = parseFloat(item.subtotal || 0);
                    const totalTax = parseFloat(item.totalTax || 0);
                    return {
                        subTotal: (totals.subTotal + subtotal) - totalTax,
                        totalTax: totals.totalTax + totalTax,
                        grandTotal: totals.grandTotal + subtotal,
                    };
                },
                { subTotal: 0, totalTax: 0, grandTotal: 0 }
            );

            setFormData((prevState) => ({
                ...prevState,
                orderproducts: updatedProducts,
                taxBreakdown,
                grandtotal,
            }));

            return updatedProducts;
        });
    };

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            orderproducts: productsForOrder,
        }));
    }, [productsForOrder]);
    const calculateTotal = () => {
        const total = productsForOrder?.reduce((acc, product) => acc + Number(product.subTotal || 0), 0);
        setFormData((prevState) => ({
            ...prevState,
            totalAmount: total.toFixed(2),
        }));
    };

    useEffect(() => {
        calculateTotal();
    }, [productsForOrder]);

    const AddProduct = () => {
        const productdetails = [...productsForOrder];
        productdetails.push({
            index: productdetails.length + 1,
            //   name: "",
            remark: "",
            productId: "",
            productName: "",
            unitId: "",
            unitName: "",
            quantity: 1,
            unitPrice: 0,
            discount: 0,
            tax: 0,
            subTotal: 0,
            productUrl: '',
            total: '',
            orderNo: "",
            orderId: 0,
            productUrl: '',
            offerDiscount: 0,
            additionalDiscount: 0,
            taxcharges: 0,
            additionalDiscounttype: "%",
            offerDiscounttype: "%",
            discounttype: "%",
            taxBreakdown: [],
            totalTax: 0,
            gstType: '',
            placeOfSupplyIdOfCustomer:null,
            companySettingsPlaceOfSupplyId:null,



        });
        setProductsForOrder([...productdetails]);
    };

    const productSelectChange = (index, event, type) => {
        if (type === 'p') {
            const product_data = productOptions?.find(d => d?.value == event?.value);
            console.log(product_data, "product_data");
            const productdetails = [...productsForOrder];
            console.log(productdetails, "productdetails");
            productdetails[index]['productId'] = product_data?.id;
            productdetails[index]['productName'] = product_data?.productName;
            productdetails[index]['unitName'] = product_data?.unitName;
            productdetails[index]['unitId'] = product_data?.unitId;
            productdetails[index]['quantity'] = 0;
            productdetails[index]['unitPrice'] = Number(Number(parseFloat(product_data?.price)).toFixed(2));
            productdetails[index]['tax'] = Number(product_data?.tax);
            productdetails[index]['discount'] = Number(parseFloat(product_data?.discount));
            productdetails[index]['subTotal'] = (Number(Number(product_data?.price) * Number(product_data?.moq).toFixed(2)));
            productdetails[index]['productUrl'] = product_data?.productUrl;
            productdetails[index]['gstType'] = productdetails[0]?.gstType;
            productdetails[index]['placeOfSupplyIdOfCustomer'] = productdetails[0]?.placeOfSupplyIdOfCustomer;
            productdetails[index]['companySettingsPlaceOfSupplyId'] = productdetails[0]?.companySettingsPlaceOfSupplyId;
            productdetails[index]['taxBreakdown'] = [];
            productdetails[index]['totalTax'] = 0;
            productdetails[index]['additionalDiscountType']=product_data?.additionalDiscountType;
            productdetails[index]['offerDiscountType']=product_data?.offerDiscountType;
            productdetails[index]['discountType']=product_data?.discountType;
            setProductsForOrder([...productdetails]);
           

        }
    }
    const handleRemoveProduct = (indexToRemove) => {
        setProductsForOrder((prevProducts) => {
            const updatedProducts = prevProducts.filter((_, index) => index !== indexToRemove);
            const taxBreakdown = updatedProducts.reduce((acc, item) => {
                if (Array.isArray(item.taxBreakdown)) {
                    acc.push(...item.taxBreakdown);
                }
                return acc;
            }, []);

            const grandtotal = updatedProducts.reduce(
                (totals, item) => {
                    const subtotal = parseFloat(item.subtotal || 0);
                    const totalTax = parseFloat(item.totalTax || 0);
                    return {
                        subTotal: (totals.subTotal + subtotal) - totalTax,
                        totalTax: totals.totalTax + totalTax,
                        grandTotal: totals.grandTotal + subtotal,
                    };
                },
                { subTotal: 0, totalTax: 0, grandTotal: 0 }
            );
            setFormData((prevState) => ({
                ...prevState,
                orderproducts: updatedProducts,
                taxBreakdown,
                grandtotal,
            }));

            return updatedProducts;
        });
    };
    const handleAddProductClick = () => {
        if (formData?.orderNo?.length) {
            AddProduct();
        }
    };

    return (
        <div className="add-order-page-design-change">
            {isEditMode && (
                <div className="page-header add-news-alignment">
                    <span>Invoice {`>`} {id ? 'Edit' : 'Create'}</span>
                    <div className="two-button-alignment">
                        <div onClick={handleSubmit}>
                            <GlobalButton text={id ? 'Update' : 'Save'} />
                        </div>
                        <Link to="/invoice">
                            <GlobalButton text="Discard" outline />
                        </Link>
                    </div>
                </div>
            )}
            <div>
                <div className='add-news-form-design' style={{ margin: '10px -5px 10px 3px' }}>
                    <h2 style={{ marginTop: '-30px', paddingBottom: '20px' }}>Customer Details</h2>
                    <div className='grid' style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                        {
                            id && (
                                <div className="bottom-alignment w-100">
                                    <InputCustom
                                        label="Invoice No"
                                        name="invoiceNo"
                                        value={formData.invoiceNo}
                                        onChange={handleInputChange}
                                        placeholder="#InvoiceNo"
                                        // type="text"
                                        disabled
                                    />
                                </div>
                            )
                        }

                        <div className="bottom-alignment select-change-design-change w-100">
                            <SelectBox
                                label={
                                    <span>
                                        Customer Name <span className="required-star">*</span>
                                    </span>}
                                styles={customStyles}
                                name="customer"
                                value={customers?.find(option => option.value.id === formData.customerId)}
                                options={customers}
                                onChange={selectChanged}
                                placeholder="Customer Name"
                                type="text"
                                required
                                errors={errors.customerName}
                            />
                        </div>
                        <div className="bottom-alignment select-change-design-change w-100">
                            <SelectBox
                                label="Order"
                                styles={customStyles}
                                name="orderNo"
                                // value={orderNo?.find((i) => i.value === formData?.orderNo)}
                                value={(formData?.orderNo ?? []).map((order) => (orderNo ?? [])?.find((i) => i?.value === order?.orderId)) || []}
                                options={orderNo}
                                // onChange={selectChanged}
                                onChange={(selectedOptions) => {
                                    const selectedValues = selectedOptions?.map((option) => {
                                        return {
                                            orderId: option?.value,
                                            orderNo: `${option?.label ?? option?.value}`
                                        }
                                    });
                                    selectChanged({ target: { name: 'orderNo', value: selectedValues } });
                                }}
                                placeholder="Order No"
                                type="text"
                                required
                                isMulti
                                errors={errors.orderNo}
                            />
                        </div>
                        <div style={{ marginTop: '1rem' }} className="w-100">
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker label={
                                    <span>
                                        Date<span className="required-star">*</span>
                                    </span>}
                                    name='invoiceDate' value={dayjs(formData.invoiceDate)} onChange={handleDateChange} format="DD/MM/YYYY" errors={errors.invoiceDate} />
                            </LocalizationProvider>
                        </div>


                    </div>
                    {formData?.customerAddress && (<div><div>
                        <h2 style={{ marginTop: '15px' }}>Billing Details</h2><hr />
                    </div>
                        <div>
                            <TextArea
                                rows={3}
                                value={`${formData?.customerAddress ?? '-'}\n${formData?.customerContact ?? '-'}`} // Use value to reflect updates
                                disabled
                                style={{ width: '50%' }}
                            />
                        </div>
                    </div>)}
                </div>



                <div className="add-news-form-design">
                    <div className="product-details-header-alignment">
                        <h2 style={{
                            
                        }}>Product Details</h2>

                    </div>
                    <div className="order-table" style={{
                        zIndex: -1,
                        position: '',
                        top: 'auto',
                        //bottom: '100%',
                    }}>
                        <div className="table-design"
                            style={{ overflow: "unset" }}
                        >
                            <table >
                                <thead>
                                    <tr style={{ border: '1px solid #eee' }}>
                                        <th className="width-80" style={{ border: 'none', background: '#f5f5f5' }}>Order No</th>
                                        <th className="width-100" style={{ zIndex: -1, border: 'none', background: '#f5f5f5' }}>Product</th>
                                        <th className="width-80" style={{ border: 'none', background: '#f5f5f5' }}>Unit</th>
                                        <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Quantity</th>
                                        <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Price (&#8377;)</th>
                                        <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Discount</th>
                                        <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Tax</th>
                                        <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Sub Total (&#8377;)</th>
                                        <th className="width-10" style={{ border: 'none', background: '#f5f5f5' }}></th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {productsForOrder?.map((i, INDEX) => {
                                        return (
                                            <tr key={INDEX}>
                                                <td className="width-auto" style={{ textOverflow: 'ellipsis', }}>
                                                    <InputCustom
                                                        name="orderNo"
                                                        value={i?.orderNo}
                                                        type="text"
                                                        onChange={(e) => handleProductChange(INDEX, 'unit', e.target.value)}
                                                        readOnly={true}
                                                    />
                                                </td>
                                                <td className="width-auto">
                                                    <SelectBox

                                                        name="product"
                                                        value={productOptions?.find(
                                                            (option) => option?.value === i?.productId
                                                        )}
                                                        options={productOptions}
                                                        onChange={(e) =>
                                                            productSelectChange(INDEX, e, "p")
                                                        }
                                                        placeholder="Product Name"
                                                        type="text"

                                                        styles={{
                                                            overflow: "initial",
                                                            ...customStyles,
                                                            menu: (provided) => ({
                                                                ...provided,
                                                                zIndex: 99999,
                                                                position: 'absolute'



                                                            }),
                                                            control: (provided) => ({
                                                                ...provided,
                                                                width: '100%'



                                                            })
                                                        }}
                                                    />
                                                    {company_setting?.extra_setting?.remarksInInvoice === "Yes" && (
                                                        <TextArea name='remark' value={i?.remark} onChange={(e) =>
                                                            handleProductChange(INDEX, 'remark', e.target.value)
                                                        } placeholder='Remark' type='text' rows={2} className="height-set mt-1"></TextArea>

                                                    )}
                                                </td>
                                                <td className="width-auto" style={{ textOverflow: 'ellipsis', }}>
                                                    <InputCustom
                                                        name="unit"
                                                        value={i?.unitName}
                                                        type="text"
                                                        onChange={(e) => handleProductChange(INDEX, 'unit', e.target.value)}
                                                        readOnly={true}
                                                    />
                                                </td>
                                                <td className="whitesapce-nowrap width-auto">
                                                    <InputCustom
                                                        name="quantity"
                                                        value={i?.quantity}
                                                        type="number"
                                                        onChange={(e) => handleProductChange(INDEX, 'quantity', e.target.value)}
                                                    />
                                                </td>
                                                <td className="width-auto">
                                                    <InputCustom
                                                        name="unitPrice"
                                                        value={i?.unitPrice}
                                                        type="number"
                                                        onChange={(e) => handleProductChange(INDEX, 'unitPrice', e.target.value)}
                                                        disabled={!checkAccess('edit_invoice_price')}
                                                    />
                                                </td>

                                                <td className="width-auto">
    <div style={{ display: "flex", flexDirection: "column" }}>
       
        <div>
            
            <div style={{ display: "flex", gap: "8px" }}>
                <InputCustom
                    name="discount"
                    value={i?.discount}
                    onChange={(e) => handleProductChange(INDEX, "discount", e.target.value)}
                    type="number"
                    disabled={!checkAccess("edit_discount")}
                />
                <SelectBox
                className='selectBox'
                    name="discounttype"
                    value={discountType.find(
                        (option) => option.value === i.discounttype
                    )}
                    options={discountType}
                    onChange={(e) =>
                        handleProductChange(INDEX, "discounttype", e.value)
                    }
                    placeholder="%"
                    type="text"
                />
            </div>
        </div>

        {/* Offer Discount Section */}
        <div>
            <label className="discount-label">Offer Discount</label>
            <div style={{ display: "flex", gap: "8px" }}>
                <InputCustom
                    name="offerDiscount"
                    value={i?.offerDiscount}
                    onChange={(e) => handleProductChange(INDEX, "offerDiscount", e.target.value)}
                    type="number"
                    disabled={!checkAccess("edit_discount")}
                />
                <SelectBox
                 className='selectBox'
                   
                    name="offerDiscounttype"
                    value={discountType.find(
                        (option) => option.value === i.offerDiscounttype
                    )}
                    options={discountType}
                    onChange={(e) =>
                        handleProductChange(INDEX, "offerDiscounttype", e.value)
                    }
                    placeholder="%"
                    type="text"
                />
            </div>
            <div className="base-price-text">(On Base Price)</div>
        </div>

        {/* Additional Discount Section */}
        <div>
            <label className="discount-label">Additional Discount</label>
            <div style={{ display: "flex", gap: "8px" }}>
                <InputCustom
                    name="additionalDiscount"
                    value={i?.additionalDiscount}
                    onChange={(e) =>
                        handleProductChange(INDEX, "additionalDiscount", e.target.value)
                    }
                    type="number"
                    disabled={!checkAccess("edit_discount")}
                />
                <SelectBox
                 className='selectBox'
                  
                    name="additionalDiscounttype"
                    value={discountType.find(
                        (option) => option.value === i.additionalDiscounttype
                    )}
                    options={discountType}
                    onChange={(e) =>
                        handleProductChange(INDEX, "additionalDiscounttype", e.value)
                    }
                    placeholder="%"
                    type="text"
                />
            </div>
            <div className="base-price-text">(On Base Price)</div>
        </div>
    </div>
</td>

                                                <td className="width-auto">
                                                    <InputCustom
                                                        name="tax"
                                                        value={i?.tax}
                                                        type="number"
                                                        onChange={(e) => handleProductChange(INDEX, 'tax', e.target.value)}
                                                    />
                                                </td>
                                                <td className="width-auto">
                                                    <InputCustom
                                                        name="subTotal"
                                                        value={i?.subtotal}
                                                        readOnly={true}
                                                        type="number"
                                                    />
                                                    {i.totalTax > 0 && (
                                                        <div style={{ fontSize: "12px", color: "#888" }}>
                                                            Tax Applied: ₹{i.totalTax} ({i.tax}%)
                                                        </div>
                                                    )}
                                                </td>
                                                <th className="width-auto">
                                                    <div
                                                        onClick={() => {
                                                            handleRemoveProduct(INDEX);
                                                        }}
                                                        style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                                                    >
                                                        <i className="fas fa-trash-alt" ></i>
                                                    </div>
                                                </th>

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            {formData?.orderproducts?.length > 0 && (
                                <div>
                                    <div style={{ paddingTop: '15px' }}>  {isEditMode && (
                                        <div >
                                            <GlobalButton text="Add Product" onClick={handleAddProductClick} />
                                        </div>
                                    )}</div>

                                    <div className="rounded-circl"> <table className="datatable_main alignTop rounded-circl " style={{
                                        marginLeft: '70%',
                                        maxWidth: '30%',
                                        background: '#f5f5f5',
                                        border: '2px #eee'
                                    }}>
                                        {/* <tbody>

                                            <tr></tr>
                                            <tr>
                                                <td style={{ textAlign: 'left', width: '1%', marginRight: '10px', padding: '5px' }}><b>Total</b></td>
                                                <td></td>
                                                <td style={{ textAlign: 'right', padding: '5px' }}><b> <i className="fa fa-inr"></i>{formData?.totalAmount}</b></td>
                                            </tr>
                                        </tbody> */}
                                    </table>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* {!formData?.orderproducts?.length && ( */}


                        <div style={{ marginTop: '1.5rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                <h5 style={{ color: '#28a745' }} className="w-50"><TotalAmountDisplay formData={formData} /></h5>
                            </div>
                        </div>
                        {/* )} */}
                    </div>
                </div>

            </div>

        </div>
    );
}
