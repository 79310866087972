import {
    Box,
    Typography,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Select,
    MenuItem,
    Button,
    FormControl,
    InputLabel,
} from "@mui/material";
import BarComponent from "./BarComponent";
import SelectBox from '../../../shared/components/select';
import PieComponent from "./PieComponentt";
import CustomerTableComponent from "./CustomerTable";
import { fetchData, postData, fetchsingleData } from "../../../services/apiService";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import GlobalButton from "../../../shared/globalButton";
import SalesCustomerTableComponent from "./CustomerTable";
import SalesBarComponent from "./BarComponent";
import SalesPieComponent from "./PieComponentt";

export default function SalesCustomerComponent() {
    const [tabValue, setTabValue] = useState(0);
    const [userNameOptions, setUserNameOptions] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const { id } = useParams();
    const [customers, setCustomerOptions] = useState([]);
    const [priceGroupOptions, setPriceGroupOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [accountcategoryOptions, setaccountcategoryOptions] = useState([]);
    const [segmentOptions, setsegmentOptions] = useState([]);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const [formData, setFormData] = useState({
        fromDate: "",
        toDate: "" ,
    });
    const [errors, setErrors] = useState({});
    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: '#ced4da',  // Add your custom border color
            borderRadius: '4px',      // Rounded corners
            padding: '0.375rem 0.75rem',  // Padding inside the control
            boxShadow: 'none',        // Remove any box shadow
            '&:hover': {
                borderColor: '#80bdff', // Border color on hover
            },
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: '#495057',  // Custom color for the dropdown indicator (arrow)
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '4px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',  // Shadow for the dropdown menu
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#e9ecef' : null,
            color: state.isSelected ? 'white' : '#495057',
            padding: '10px 20px',
        }),
    };


    const [mainTabValue, setMainTabValue] = React.useState(0); // Tracks main tabs like Customer, User, Area, etc.

    const handleMainTabChange = (event, newValue) => {
        setMainTabValue(newValue);
    };
    useEffect(() => {
        getData();
    }, []);
    const [customerList, setCustomerList] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);


    const getData = async () => {
        try {
            const requestBody = {};
            const response = await postData("sales/salesCustomers", requestBody); // Assuming postData is defined
            console.log("====", response)
            if (response?.data) {
                setCustomerList(response.data || []);
                setTotalItems(response?.totalDocuments || 0);

                // Calculate total amount by summing up the total_amount values
                const totalAmount = response.data.reduce((sum, item) => sum + (item.total_amount || 0), 0);
                setTotalAmount(totalAmount);  // Assuming you have a state setter for totalAmount
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    // Function to render the corresponding component based on selected tab
    const renderTabContent = () => {
        switch (mainTabValue) {
            case 0:
                return <SalesCustomerTableComponent customerList={customerList} setCustomerList={setCustomerList} />;
            case 1:
                return <SalesBarComponent customerList={customerList} setCustomerList={setCustomerList} />;
            case 2:
                return <SalesPieComponent customerList={customerList} setCustomerList={setCustomerList} />;
            default:
                return <Typography variant="h6">No Data Available</Typography>;
        }
    };

    const [countryOptions, setCountryOptions] = useState([])
    const [sateOptions, sateCategory] = useState([])
    const [areaOptions, areCategory] = useState([])

    const fetchCountry = async () => {
        try {
            const response = await fetchData("country");

            if (response?.data) {
                setCountryOptions(response?.data.map(i => {
                    return {
                        label: i?.countryName, key: 'country', value: { countryId: i?.id, countryName: i?.countryName }
                    }
                }));
            }
            if (id) {
                const response = await fetchsingleData("country", id);
                if (response?.data)
                    setFormData(response?.data); // Update state with fetched data
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const fetchState = async (id) => {
        try {
            const response = await fetchData("state", { countryId: id });

            if (response?.data) {
                sateCategory(response?.data.map(i => {
                    return {
                        label: i?.stateName, key: 'state', value: { stateId: i?.id, stateName: i?.stateName }
                    }
                }));
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const fetchArea = async (id) => {
        try {
            const response = await fetchData("area", { stateId: id });
            console.log('arra', response.data);
            if (response?.data) {

                areCategory(response?.data.map(i => {
                    return {
                        label: i?.cityName, key: 'area', value: { areaId: i?.id, areaName: i?.cityName }
                    }
                }));
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const fetchproduct = async () => {
        try {
            const responseProducts = await fetchData("product");
            console.log('Fetched products:', responseProducts.data);
            if (responseProducts?.data) {
                setProductOptions(
                    responseProducts.data.map((i) => ({
                        label: i?.name,
                        value: i?.id,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    };

    useEffect(() => {

        fetchCountry()
        fetchState()
        fetchGroup();
        fetchArea()
        getCustomer()
        fetchCategory();
        fetchproduct();
        fetchUser()
        fetchaccountcategory()
        fetchasegment()
        // handleApply()
    }, [])

    const selectChanged = (e) => {



        // If the selected key is "country", update formData and fetch states
        if (e.key === "country") {
            setFormData(prevState => ({
                ...prevState,
                ...e.value
            }));
            fetchState(e.value.countryId);
        } else if (e.key === "state") {
            setFormData(prevState => ({
                ...prevState,
                ...e.value
            }));
            fetchArea(e.value.stateId);
        } else {
            setFormData(prevState => ({
                ...prevState,
                ...e.value
            }));
        }

    };

    const selectChangedcustomer = (selectedOptions) => {
        // Extracting the customer IDs from the selected customer objects
        const selectedCustomerIds = selectedOptions.map(option => option.value.id);
        setFormData(prevData => ({
            ...prevData,
            customerId: selectedCustomerIds,  // Updating the customerId in formData
        }));
    };
    


    const getCustomer = async () => {
        try {
            const response = await fetchData(`customer`);
            if (response?.data) {

                setCustomerOptions(

                    response?.data?.map((i) => {

                        return {
                            label: `${i?.cName}`,
                            key: "customer",
                            value: {
                                id: i?.id,
                                name: ` ${i?.cName}`,
                                address: `${i?.address} - ${i?.pincode}.`,
                                contact: i?.addressContact,
                            },
                        };

                    })
                );
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const [categoryOptions, setCategoryOptions] = useState([]);
    const fetchCategory = async () => {
        try {
            const response = await fetchData("systemtype", {
                typeCategory: "category",
            });

            if (response?.data) {
                setCategoryOptions(
                    response.data.map(i => ({
                        label: i.typeName,
                        value: i.id, // We only need the value as the unique identifier
                    }))
                );
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    const fetchGroup = async () => {
        try {
            const response = await fetchData("pricegroup");
    
            if (response?.data) {
                setPriceGroupOptions(
                    response?.data.map((i) => ({
                        label: i?.name,  // This is what will be displayed
                        value: i?.id,    // Store the priceGroupId in value
                    }))
                );
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    

    const fetchUser = async () => {
        try {
            const response = await fetchData('user');
            if (response?.data) {
                setUserNameOptions(
                    response.data.map((user) => ({
                        label: user.name,    // Display name
                        value: user.id,      // Store user ID as value
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };    

    const fetchaccountcategory = async () => {
        try {
            const response = await fetchData('accountcategory');
            if (response?.data) {
                setaccountcategoryOptions(
                    response.data.map((item) => ({
                        label: item.name, // The label to display in the dropdown
                        value: item.id,   // The unique identifier for the option
                        color: item.color, // Optional additional property
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching account categories:', error);
        }
    };
    const fetchasegment = async () => {
        try {
            const response = await fetchData('segment');
            if (response?.data) {
                setsegmentOptions(
                    response.data.map((item) => ({
                        label: item.name, // Text shown in the dropdown
                        value: item.id,   // Unique identifier
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };


    const selectChangeduser = (selectedOptions) => {
        const selectedIds = selectedOptions.map(option => option.value);  // Map to user IDs
        setFormData(prevData => ({
            ...prevData,
            username: selectedIds,  // Store only IDs
        }));
    };
    
    


    const selectaccountcategory = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value); // Map selected options to their values
        setFormData(prevData => ({
            ...prevData,
            name: selectedValues, // Update the `name` field in formData
        }));
    };

    const selectacsegment = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setFormData(prevData => ({
            ...prevData,
            name: selectedValues,
        }));
    };


    const selectChangedprice = (selectedOptions) => {
        // Extract only the priceGroupId values from selected options
        const selectedValues = selectedOptions.map(option => option.value);  // This is the priceGroupId
        setFormData(prevData => ({
            ...prevData,
            priceGroupId: selectedValues,  // Save only the IDs in formData
        }));
    };
    

    const selectChangedproduct = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setFormData(prevData => ({
            ...prevData,
            productId: selectedValues,
        }));
    };

    const selectChangedcat = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setFormData(prevData => ({
            ...prevData,
            productCategoryId: selectedValues,
        }));
    };



    const clearFilters = () => {
        const initialData = {
            period: "",
            fromDate: "", // Clear date fields
            toDate: "",
            countryId: "",
            stateId: "",
            areaId: "",
            customerId: [], // Clear multi-selection arrays
            customerName: [],
            username: "",
            priceGroupId: "",
            segment: "",
            productCategoryId: "",
            productId: "",
            countryName: "",
            stateName: "",
            areaName: "",
            name: "",
            address: "",
            contact: "",
            productCategory: "",
        };
        handleApply();
        // setFormData(defaultData);
        setFormData(initialData); // Reset the form data to initial state
        setErrors({}); // Clear any validation errors if present
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleApply = async () => {
        // Ensure all fields are properly set or defaulted
        const formattedData = {
            period: formData.period || "", // Ensure period is set
            fromDate: formData.fromDate
                ? new Date(formData.fromDate).toISOString().split("T")[0]
                : "",
            toDate: formData.toDate
                ? new Date(formData.toDate).toISOString().split("T")[0]
                : "",
            countryId: formData.countryId || "", // Ensure countryId is set
            stateId: formData.stateId || "", // Ensure stateId is set
            areaId: formData.areaId || "", // Ensure areaId is set
            customerId: formData.customerId || [], 
            username: formData.username || [],
            priceGroupId: Array.isArray(formData.priceGroupId)
                ? formData.priceGroupId.map(priceGroup => priceGroup?.id || priceGroup)
                : [],
            segment: Array.isArray(formData.name)
                ? formData.name.map(segment => ({
                    segmentId: segment?.id || "",
                    segmentName: segment?.name || "",
                }))
                : [], 
                customerCategory  : Array.isArray(formData?.name) && formData.name.length > 0
                ? formData.name.map((category) => ({
                    customerCategoryId: category, // Using `category` which is the value (id) from selected options
                }))
                : [],
                productCategories : Array.isArray(formData.productCategoryId)
                ? formData.productCategoryId.map(category => ({
                    productCategoryId: category, // Assuming category is the ID now
                    productCategory: categoryOptions.find(option => option.value === category)?.label || "",
                }))
                : [],// Default to an empty array
                productId : Array.isArray(formData.productId)
                ? formData.productId // Mapping the product ID correctly without any extra mapping
                : [], // Default to an empty array
            countryName: formData.countryName || "", // Ensure countryName is set
            stateName: formData.stateName || "", // Ensure stateName is set
            areaName: formData.areaName || "", // Ensure areaName is set
            productCategory: formData.productCategory || "", // Ensure productCategory is set
        };

        console.log("Formatted Payload:", formattedData);

        // API call
        try {
            const response = await postData("sales/salesCustomers", formattedData);

            setCustomerList(response?.data);

            if (response?.data) {
                console.log("API Response:", response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }

    };

    return (
        <div className="">
            <div className="customer-page-main-grid">
                <div>
                    <div className=" new-white-box-design">
                           <h2>
                            Total amount: ₹ {new Intl.NumberFormat('en-IN').format(totalAmount)}
                           </h2>
                            <div>
                                <Tabs value={mainTabValue} onChange={handleMainTabChange}>
                                    <Tab label="TABLE" />
                                    <Tab label="BAR" />
                                    <Tab label="PIE" />
                                </Tabs>
                                <div className="pt-3">{renderTabContent()}</div>
                            </div>
                    </div>
                </div>
                <div>
                    <div className=" new-white-box-design">
                        <div className="pb-3">
                            <FormControl style={{ width: "100%" }}>
                                <TextField
                                    type="date"
                                    label="From"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    variant="outlined"
                                    name="fromDate"
                                    value={formData.fromDate}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </div>
                        <div className="pb-3">
                            <FormControl style={{ width: "100%" }}>
                                <TextField
                                    type="date"
                                    label="To"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    variant="outlined"
                                    name="toDate"
                                    value={formData.toDate}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </div>
                        <div className="pb-3">
                            <SelectBox
                                label="Country"
                                name="countryId"
                                value={countryOptions.find(i => i.value.id === formData.countryId)}
                                options={countryOptions}
                                onChange={selectChanged}
                                placeholder="Select Country"
                                required
                            />
                        </div>
                        <div className="pb-3">
                            <SelectBox
                                label="State"
                                name="stateId"
                                value={sateOptions.find(i => i.value.id === formData.stateId)}
                                options={sateOptions}
                                onChange={selectChanged}
                                placeholder="Select State"
                                required
                            />
                        </div>
                        <div className="pb-3">
                            <SelectBox
                                label="City"
                                name="areaId"
                                value={areaOptions.find(i => i.value.id === formData.areaId)}
                                options={areaOptions}
                                onChange={selectChanged}
                                placeholder="Select City"
                                required
                                errors={errors.customerName}
                            />
                        </div>
                        <div className="pb-3">
                            <SelectBox
                                label="Customer"
                                name="customerId"
                                value={customers?.filter(option => Array.isArray(formData.customerId) && formData.customerId.includes(option.value.id))}  // Ensure it's an array before using includes
                                options={customers}
                                onChange={selectChangedcustomer}
                                placeholder="Customer Name"
                                required
                                errors={errors.customerName}
                                isMulti={true}  // Enables multi-selection
                            />
                        </div>

                        <div className="pb-3">
                            <SelectBox
                                label="Ordered By"
                                name="username"
                                value={userNameOptions?.filter(i => Array.isArray(formData?.username) && formData?.username?.includes(i?.value))}  // Filter by IDs
                                options={userNameOptions}
                                onChange={selectChangeduser}
                                placeholder="Ordered By"
                                required
                                isMulti
                                errors={errors.username}
                            />
                        </div>


                        <div className="pb-3">
                            <SelectBox
                                label="Price Group"
                                name="priceGroupId"
                                value={priceGroupOptions.filter(i =>
                                    Array.isArray(formData.priceGroupId) && formData.priceGroupId?.includes(i.value)
                                )}
                                options={priceGroupOptions}
                                onChange={selectChangedprice}
                                placeholder="Select Price Group"
                                required
                                isMulti
                                errors={errors.countryName}
                            />
                        </div>

                        <div className="pb-3">
                            <SelectBox
                                size="small"
                                variant="outlined"
                                label="Customer Category"
                                name="Customer Category"
                                value={accountcategoryOptions?.filter(i => Array.isArray(formData?.name) && formData?.name?.includes(i?.value))}
                                options={accountcategoryOptions}
                                onChange={selectaccountcategory}
                                isMulti
                                errors={errors.name}
                            />
                        </div>
                        <div className="pb-3">
                            <SelectBox
                                label="Product Category"
                                name="productCategoryId"
                                value={categoryOptions.filter(i =>
                                    Array.isArray(formData.productCategoryId) &&
                                    formData.productCategoryId.includes(i.value)
                                )}
                                options={categoryOptions}
                                onChange={selectChangedcat}
                                placeholder="Select Category"
                                required
                                errors={errors.state}
                                isMulti
                            />
                        </div>
                        <div className="pb-3">
                            <SelectBox
                                label="Product"
                                name="productId"
                                value={productOptions.filter(option =>
                                    Array.isArray(formData.productId) &&
                                    formData.productId.includes(option.value)
                                )}
                                options={productOptions}
                                onChange={selectChangedproduct}
                                placeholder="Product Name"
                                required
                                errors={errors.customerName}
                                isMulti // Enables multi-select
                            />
                        </div>
                        <div className="pb-3">
                            <SelectBox
                                size="small"
                                variant="outlined"
                                label="segment"
                                name="segment"
                                value={segmentOptions?.filter(option =>
                                    Array.isArray(formData?.name) && formData?.name.includes(option.value)
                                )}
                                options={segmentOptions}
                                onChange={selectacsegment} // Correct handler
                                isMulti
                                errors={errors.name}
                            />
                        </div>
                        <div className="d-flex align-items-center gap-3 justify-content-center">
                            <div onClick={handleApply}>
                            <GlobalButton text="APPLY"/>
                            </div>
                            <div onClick={clearFilters}>
                            <GlobalButton text="Clear Filters"/>
                            </div>

                        </div>
                            {/* <InputLabel shrink>Period</InputLabel> */}
                            {/* <div className="col-md-12">
                                <FormControl>
                                    <InputLabel>Period</InputLabel>
                                    <Select
                                        name="period"
                                        value={formData.period}
                                        onChange={selectChanged}
                                    >
                                        <MenuItem value="Last 180 days">Last 180 days</MenuItem>
                                        <MenuItem value="Custom">Custom</MenuItem>
                                    </Select>
                                </FormControl>
                            </div> */}

                            {/* Buttons with display flex */}
                           
                    </div>
                </div>

            </div>
        </div>
    );
}
