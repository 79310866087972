import React from 'react'
import './fillWhiteButton.scss';
export default function FillWhiteButton({text , iconpath}) {
  return (
    <div className='fill-white-button-design'>
      <button>
        {
            iconpath && (
                <img src={iconpath} alt="icons"/>
            )
        }
        {text}
      </button>
    </div>
  )
}
