import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { deleteData } from '../../services/apiService';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import CustomerVisitTable from './customervisittable';
import AccountTableHeader from '../account/accountTableHeader';


export default function CustomerVisit() {

  const [customervisit, SetCustomerVisit] = useState([]);


  return (
    <div className='page-24'>
      <div className="">
        <div className="customer-header-alignment pb-4">
          <div>
            <h2>Customer Visit</h2>
            <Breadcumbs activePath="Customer Visit" pageName="Customer Visit" />
          </div>
        </div>
      </div>
      <AccountTableHeader/>
      <div className=''>
        <CustomerVisitTable
        customervisit={customervisit}
        SetCustomerVisit={SetCustomerVisit}/>
      </div>
    </div>
  )
}
