import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, MenuItem, Select, FormControl, InputLabel, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function PaymentBarTimeComponent({ customerList }) {
    const [chartData, setChartData] = useState([]);
    const [dataType, setDataType] = useState("orders"); // Default data type
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        if (customerList?.length > 0) {
            processData(dataType);
        } else {
            setChartData([]);
        }
    }, [dataType, customerList]);

    const processData = (type) => {
        const fieldMapping = {
            dispatch: "dispatches",
            invoice: "invoices",
            customer: "customers",
            products: "products",
            subAmount: "subAmount",
            amount: "amount",
            quantity: "quantity",
            base_quantity: "baseQuantity",
         
            
        };

        const selectedField = fieldMapping[type];

        if (!selectedField) {
            setChartData([]);
            return;
        }

        const formattedData = customerList.map((item) => [
            item?.month ||"Unknown", // X-axis label
            item[selectedField] || 0, // Y-axis value
        ]);

        setChartData(formattedData);
        setCurrentPage(1); // Reset to the first page
    };

    const handleChange = (event) => {
        setDataType(event.target.value);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const visibleData = chartData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const options = {
        chart: {
            type: "column",
        },
        title: {
            text: `Customer Data by ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`,
        },
        xAxis: {
            type: "category",
            labels: {
                autoRotation: [-45, -90],
                style: {
                    fontSize: "13px",
                    fontFamily: "Verdana, sans-serif",
                },
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: `${dataType.charAt(0).toUpperCase() + dataType.slice(1)} Count`,
            },
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            pointFormat: `${dataType.charAt(0).toUpperCase() + dataType.slice(1)}: <b>{point.y}</b>`,
        },
        series: [
            {
                name: dataType.charAt(0).toUpperCase() + dataType.slice(1),
                color: "#1976d2",
                data: visibleData,
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: "#FFFFFF",
                    inside: true,
                    format: "{point.y}",
                    style: {
                        fontSize: "13px",
                        fontFamily: "Verdana, sans-serif",
                    },
                },
            },
        ],
    };

    const totalPages = Math.ceil(chartData.length / itemsPerPage);

    return (
        <Box p={4}>
            <FormControl fullWidth margin="normal">
                <InputLabel id="data-type-label">Select Data Type</InputLabel>
                <Select
                    labelId="data-type-label"
                    value={dataType}
                    onChange={handleChange}
                >
                    <MenuItem value="orders">Orders</MenuItem>
                    <MenuItem value="subAmount">Sub Amount</MenuItem>
                    <MenuItem value="amount">Amount</MenuItem>
                    <MenuItem value="base_quantity">Base Quantity</MenuItem>
                    <MenuItem value="quantity">Quantity</MenuItem>
                    <MenuItem value="products">Products</MenuItem>
                </Select>
            </FormControl>

            {chartData.length === 0 ? (
                <Typography variant="body2" textAlign="center">
                    No data available.
                </Typography>
            ) : (
                <>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                    <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="body2">
                            Showing {((currentPage - 1) * itemsPerPage) + 1} - {Math.min(currentPage * itemsPerPage, chartData.length)} of {chartData.length}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                            sx={{ marginRight: "10px" }}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNextPage}
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
}
