import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import Button from '../../../shared/components/button';
import RightIcon from '../../../assets/icons/right-sm.svg';
import './leadview.scss';
import Input from '../../../shared/components/input';
import EditIcon from '../../../shared/icons/editIcon';


export default function AddEditviewleadtable() {
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getData();
    }
    fetchCountry();
    fetchState();
    fetchCity();
    fetchArea();
    fetchUsers();
  }, [id]);

  const [leadstatus, setLeadStatus] = useState([]);
  const [shippingDetails, setShippingDetails] = useState([]);

  const [timelines, setTimelines] = useState([]);
  const [editingDetailIndex, setEditingDetailIndex] = useState(null);
  const [userName, setUserName] = useState([]);
  const [newNotification, setNewNotification] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const user = useSelector(state => state.user.user);
  const name = useSelector((state) => state.auth.name);

  const [formData, setFormData] = useState({
    name: '',
    leadsource: '',
    leadindustry: '',
    pricegroup: '',
    address: '',
    otherAddress: '',
    collabrator: '',
    collabratorId: '',
    leadOwner: '',
    leadOwnerId: '',
    title: '',
    city: '',
    area: '',
    pincode: '',
    country: '',
    state: '',
    latitude: '',
    longtitude: '',
    email: '',
    contactno: '',
    ownername: '',
    partnername: '',
    ed: '',
    ewc: '',
    turnover: '',
    leadstatus: '',
    userName: '',
    leadnotification: [],
    yetscreen: false,

  });

  const fetchCountry = async () => {
    try {
      const response = await fetchData("country");

      if (response?.data) {
        setCountryOptions(
          response?.data.map((i) => {
            return {
              label: i?.countryName,
              key: "country",
              value: { countryId: i?.id, countryName: i?.countryName },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchState = async (countyId) => {
    try {
      const response = await fetchData("state", { countryId: countyId });

      if (response?.data) {
        setStateOptions(
          response?.data.map((i) => {
            return {
              label: i?.stateName,
              key: "state",
              value: { stateId: i?.id, stateName: i?.stateName },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchCity = async (stateId) => {
    try {
      const response = await fetchData("city", { stateId: stateId });

      if (response?.data) {
        setCityOptions(
          response?.data.map((i) => {
            return {
              label: i?.cityName,
              key: "city",
              value: { cityId: i?.id, city: i?.cityName },
            };
          })
        );
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchArea = async (cityId) => {
    try {
      const response = await fetchData("area", { cityId: cityId });

      if (response?.data) {
        setAreaOptions(
          response?.data.map((i) => {
            return {
              label: i?.areaName,
              key: "area",
              value: { cityId: i?.id, city: i?.areaName },
            };
          })
        );
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const newShippingDetail = {
      title: formData.title,
      otherAddress: formData.otherAddress,
      otherPincode: formData.otherPincode,
      countryId: formData.countryId,
      countryName: formData.countryName,
      stateId: formData.stateId,
      stateName: formData.stateName,
      cityId: formData.cityId,
      city: formData.city,
      email: formData.email,
      contactNo: formData.contactNo,
    };

    try {
      let updatedShippingDetails = [...shippingDetails];

      if (id) {
        const response = await fetchsingleData(`lead`, id);
        updatedShippingDetails = response?.data?.shippingDetails || [];

        if (editingDetailIndex !== null) {
          updatedShippingDetails[editingDetailIndex] = newShippingDetail;
        } else {
          updatedShippingDetails.push(newShippingDetail);
        }

        const data = await patchData(`lead/${id}`, { shippingDetails: updatedShippingDetails });
        if (data) {
          console.log('Data updated successfully:', data);
          setShippingDetails(updatedShippingDetails);
          setEditingDetailIndex(null);
          setDrawerOpen(false);
        }
      } else {
        updatedShippingDetails.push(newShippingDetail);
        const data = await postData('lead', { shippingDetails: updatedShippingDetails });
        if (data) {
          console.log('Data saved successfully:', data);
          setShippingDetails(updatedShippingDetails);
          setDrawerOpen(false);
          navigate(-1);
        }
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const resetFormData = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      title: '',
      otherAddress: '',
      otherPincode: '',
      countryId: '',
      countryName: '',
      stateId: '',
      stateName: '',
      cityId: '',
      city: '',
      email: '',
      contactNo: '',
    }));
    setEditingDetailIndex(null);
  };


  const handleAddClick = () => {
    resetFormData();
    setDrawerOpen(true);
  };
  const handleEditClick = (index) => {
    const detail = shippingDetails[index];
    setFormData({
      ...formData,
      title: detail.title,
      otherAddress: detail.otherAddress,
      otherPincode: detail.otherPincode,
      countryId: detail.countryId,
      countryName: detail.countryName,
      stateId: detail.stateId,
      stateName: detail.stateName,
      cityId: detail.cityId,
      city: detail.city,
      email: detail.email,
      contactNo: detail.contactNo,
    });
    setEditingDetailIndex(index);
    setDrawerOpen(true);
  };
  const [countryOptions, setCountryOptions] = useState([])
  const fetchUsers = async () => {
    try {
      const response = await fetchData("user");

      if (response?.data) {
        // Map the user names to create dropdown options
        const nameOptions = response.data.map(user => ({
          label: user.name,  // The label to display in the dropdown
          value: user.id     // The value associated with each option (you can use 'id' or any other unique property)
        }));

        setUserName(nameOptions); // Assuming this is the state to hold dropdown options
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {

    const getData = async () => {
      const requestBody = {
        "reference_type": "lead",
        reference_id: id
      }

      const responsetimeline = await fetchData("timeline", requestBody);
      console.log(responsetimeline);
      if (responsetimeline?.data) {
        setTimelines(responsetimeline.data);
      }
    }

    getData()

  }, [newNotification])

  const getData = async () => {
    try {
      // Fetch lead status data
      const responsee = await fetchData(`leadStatus`);
      if (responsee?.data) {
        const formattedleadstatus = responsee.data.map(i => ({
          label: i?.name,
          key: 'leadstatus',
          value: { name: i?.name },
        }));
        setLeadStatus(formattedleadstatus);
      }

      // Fetch single lead data
      const response = await fetchsingleData("lead", id);
      if (response?.data) {
        setFormData(response.data);

        // Extract shipping details
        const shippingDetails = response.data.shippingDetails || [];
        setShippingDetails(shippingDetails); // Assuming `setShippingDetails` is a state setter for your table data
      }

      if (response?.data?.leadnotification) {
        setNewNotification(response.data.leadnotification);
      }

      // Update lead status
      const updatedFormData = { yetscreen: false };
      await patchData(`lead/${id}`, updatedFormData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSelectChange = async (selectedValue) => {


    try {
      const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
      // const newNotification = { date: currentDateTime, status: selectedValue };
      // setNewNotification(newNotification =>({
      //     ...newNotification,
      //     date: currentDateTime, status: selectedValue

      // }))


      const updatedNotification = { date: currentDateTime, status: selectedValue, user: name };

      setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);
      // const updatedLeadNotification = [...formData.leadnotification, newNotification];

      if (id) {
        // const updatedData = { ...formData, leadstatus: selectedValue, collabrator:selectedValue, leadOwner:selectedValue, leadnotification: [...newNotification, updatedNotification] };
        // const data = await patchData(`lead/${id}`, updatedData);
        // if (data) {
        //     setFormData(updatedData);
        // }

        let tempForm = { ...formData }
        tempForm["leadnotification"] = [...newNotification, updatedNotification]
        // setFormData(tempForm)
      }
    } catch (error) {
      console.error('Error updating lead status:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const selectCustChanged = async (e) => {
    const selectedValue = e?.label;

    const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
    const updatedNotification = { date: currentDateTime, status: selectedValue, user: name };


    const updatedData = { collabrator: selectedValue, collabratorId: e.value, leadnotification: [...formData.leadnotification, updatedNotification] };

    const data = await patchData(`lead/${id}`, updatedData);
    if (data) {
      setFormData(data?.data);
    }

    handleSelectChange(selectedValue);
  };

  const getLocationsData = async () => {
    try {
      setDrawerOpen(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const selectLeadStatusChanged = async (e) => {
    const selectedValue = e?.value?.name;
    const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
    const updatedNotification = { date: currentDateTime, status: selectedValue, user: name };


    const updatedData = { leadstatus: selectedValue };
    const data = await patchData(`lead/${id}`, updatedData);
    if (data) {
      setFormData(data?.data);
    }
    handleSelectChange(selectedValue);
  };

  const selectLeadOwnerChanged = async (e) => {
    const selectedValue = e.label

    const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
    const updatedNotification = { date: currentDateTime, status: selectedValue, user: name };


    const updatedData = { leadOwner: selectedValue, leadOwnerId: e.value };
    const data = await patchData(`lead/${id}`, updatedData);
    if (data) {
      setFormData(data?.data);
    }
    handleSelectChange(selectedValue);
  };

  return (
    <div className='page-24'>
      <div>
        <div className='breadcumbs-alignment pb-3'>
          <span className='active-class'>Lead</span>
          <img src={RightIcon} alt="RightIcon" />
          <span>{formData.name}</span>
        </div>
        <div style={{
          marginTop: '20px',
          // background: '#D2E0DF',       
          // background:'#C0C0C0',     
          padding: '20px',
          maxWidth: '4000px',
          margin: '0 auto'
        }}>
          <div className="d-flex align-items-center" >
            <div className='mr-4' style={{
              display: 'flex',
              gap: '10px'
            }}>
              {(<div>
                <SelectBox name='userName' value={userName?.find(i => i.value === formData.leadOwnerId)} onChange={selectLeadOwnerChanged} options={userName} placeholder=' Lead Owner' required />
              </div>)}
            </div>
            <div style={{
              display: 'flex',
              gap: '10px'
            }}>
              {(<div>

                {/* {JSON.stringify(userName?.find(i => i.value === formData.collabratorId))} */}
                <SelectBox name='userName' value={userName?.find(i => i.value === formData.collabratorId)} onChange={selectCustChanged} options={userName} placeholder='Collaborators' required />
              </div>)}
            </div>

          </div>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            {/* <Link to={`/account/addcustomer`}><Button text='Convert To Customer' /></Link> */}
            <Link to="/account/addcustomer" state={{ formData: formData }}><Button text='Convert To Customer' /></Link>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '2500px', }}>
            <div style={{
              marginTop: '1rem',
              // padding: '1.5rem',
              backgroundColor: '#fff',
              border: '1px solid rgba(30,46,80,.09)',
              borderRadius: '0.25rem',
              boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
              width: '950px'
            }}>
              <>
                <div style={{ padding: '1.5rem' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <h2 style={{ justifyContent: '', paddingBottom: '15px' }}>{formData.name}</h2>
                    {/* <div style={{display:'flex',justifyContent:'end'}}> */}
                    <div style={{
                      display: 'flex',
                      gap: '10px'
                    }}>
                      {(<div>
                        <SelectBox
                          name="leadstatus"
                          value={leadstatus?.find(i => i.value.name === formData.leadstatus)}
                          onChange={selectLeadStatusChanged}
                          options={leadstatus}
                          placeholder="Select Lead Status"
                          required
                        />

                      </div>)}
                    </div>
                    {/* </div> */}
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: '2px' }}>✆</div>
                    <a href={`mailto:${formData.contactno}`} style={{ color: 'purple', paddingLeft: '1px' }}>{formData.contactno}</a>
                  </div>
                  <div style={{ fontSize: '0.85rem', marginTop: '0.5rem', marginBottom: '17px' }}>
                    🏠{formData.address},{formData.state}-{formData.pincode},{formData.country}
                  </div>
                  <div style={{ fontSize: '0.85rem', marginTop: '0.5rem', marginBottom: '17px', color: '#828282' }}>
                    Longitude{formData.longitude} ,

                    Latitude{formData.latitude}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    <div>
                      <div style={{ fontSize: '0.85rem', color: '#777777', }}>Source</div>
                      <div>{formData.leadsource}</div>
                    </div>
                    <div>
                      <div style={{ fontSize: '0.85rem', color: '#777777' }}>Industry</div>
                      <div>{formData.leadindustry}</div>
                    </div>
                    <div>
                      <div style={{ fontSize: '0.85rem', color: '#777777' }}>Price Group</div>
                      <div>{formData.pricegroup}</div>
                    </div>
                  </div>
                </div>
                <div style={{ padding: '1.5rem', fontSize: '0.85rem', borderTop: '1px solid #cccccc', paddingTop: '1rem', marginTop: '1rem' }}>
                  <div style={{ borderBottom: '1px solid #e5e5e5', fontWeight: 'bold' }}>Owner Details </div>

                  <div style={{ display: 'flex', marginTop: '7px', justifyContent: 'space-between', }}>
                    <div>
                      <div style={{ fontSize: '0.85rem', color: 'black' }}>Owner Name</div>
                      <div>{formData.ownername}</div>
                    </div>
                    <div>
                      <div style={{ fontSize: '0.85rem', color: 'black' }}>Partner Name</div>
                      <div>{formData.partnername}</div>
                    </div>
                    <div>
                      <div style={{ fontSize: '0.85rem', color: 'black' }}>Attachment</div>
                      <div>Not uploaded.</div>
                    </div>
                  </div>
                </div>
                <div style={{ padding: '1.5rem', fontSize: '0.85rem', paddingTop: '1rem', marginTop: '1rem', }}>
                  <div style={{ borderBottom: '1px solid #e5e5e5', fontWeight: 'bold' }}>Other Shipping Details </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button onClick={handleAddClick} text="ADD" />
                </div>
                <table style={{ width: '100%', marginTop: '0rem', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th style={{ fontSize: '0.75rem', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Address</strong></th>
                      <th style={{ fontSize: '0.75rem', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Area</strong></th>
                      <th style={{ fontSize: '0.75rem', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>City</strong></th>
                      <th style={{ fontSize: '0.75rem', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>State</strong></th>
                      <th style={{ fontSize: '0.75rem', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Country</strong></th>
                      <th style={{ fontSize: '0.75rem', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Pincode</strong></th>
                      <th style={{ fontSize: '0.75rem', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Contact No</strong></th>
                      <th style={{ fontSize: '0.75rem', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Email</strong></th>
                      <th style={{ fontSize: '0.75rem', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Action</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    {shippingDetails.map((detail, index) => (
                      <tr key={index} style={{ width: '100%' }}>
                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{detail.otherAddress}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{detail.area || '-'}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{detail.city}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{detail.stateName}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{detail.countryName || '-'}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{detail.otherPincode}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{detail.contactNo}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{detail.email}</td>
                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>
                          {/* <button style={{ padding: '5px 10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px' }} onClick={() => handleEditClick(index)}>Edit</button> */}
                          <b onClick={() => handleEditClick(index)}> <EditIcon /></b>
                        </td>
                        {/* <td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {
                  isDrawerOpen && (

                    <div className='map-locator-modal'></div>
                  )
                }
                <div className={isDrawerOpen ? 'map-locator-modal-md width1 show' : 'map-locator-modal-md width1 hide'}>
                  <div className='header-modal'>
                    {/* <h3>{selectedUser?.userName}</h3> */}
                    <div className='close' onClick={closeDrawer}>
                      &times;
                    </div>
                    <div>
                      <Button onClick={handleSubmit} text="Save" />
                    </div>
                  </div>
                  <div className='maplocator-body'>
                    <div className='all-card-box'>
                      <div className='bottom-alignment'>
                        <Input
                          label={
                            <span>
                              Title <span className="required-star">*</span>
                            </span>
                          }
                          name="title"
                          value={formData.title}
                          onChange={handleInputChange}
                          placeholder="Title"
                        />
                      </div>
                      <div className='bottom-alignment'>
                        <Input
                          label={
                            <span>
                              Address <span className="required-star">*</span>
                            </span>
                          }
                          name="otherAddress"
                          value={formData.otherAddress}
                          onChange={handleInputChange}
                          placeholder="Address"
                        />
                      </div>
                      <div className='bottom-alignment'>
                        <Input label='Pincode' name='otherPincode' value={formData.otherPincode} onChange={handleInputChange} placeholder='Pincode' type='text' />
                      </div>
                      <div className='bottom-alignment'>
                        <SelectBox
                          label={
                            <span>
                              Country <span className="required-star">*</span>
                            </span>
                          }
                          name="countryName"
                          value={countryOptions.find(
                            (i) => i.value.countryId === formData.countryId
                          )}
                          options={countryOptions}
                          placeholder="Select Country"
                          onChange={(selectedOption) => {
                            fetchState(selectedOption?.value?.countryId);

                            setFormData((prevState) => ({

                              ...prevState,
                              countryId: Number(selectedOption?.value?.countryId),
                              countryName: selectedOption?.value?.countryName,
                            }));
                          }}


                        />

                        {/* {errors.countryId && <p className="error">{errors.countryId}</p>} */}

                      </div>
                      <div className='bottom-alignment'>
                        <SelectBox
                          label={
                            <span>
                              State <span className="required-star">*</span>
                            </span>
                          }
                          name="stateName"
                          value={stateOptions.find(
                            (i) => i.value.stateId === formData.stateId
                          )}
                          options={stateOptions}
                          placeholder="Select State"
                          onChange={(selectedOption) => {
                            fetchCity(selectedOption?.value?.stateId);

                            setFormData((prevState) => ({
                              ...prevState,
                              stateId: Number(selectedOption?.value?.stateId),
                              stateName: selectedOption?.value?.stateName,
                            }));
                          }}

                        />

                        {/* {errors.stateId && <p className="error">{errors.stateId}</p>} */}
                      </div>
                      <div className='bottom-alignment'>
                        <SelectBox
                          label={
                            <span>
                              City <span className="required-star">*</span>
                            </span>
                          }
                          name="city"
                          value={cityOptions.find(
                            (i) => i.value.cityId === formData.cityId
                          )}
                          options={cityOptions}
                          placeholder="Select City"
                          onChange={(selectedOption) => {
                            fetchArea(selectedOption?.value?.cityId);

                            setFormData((prevState) => ({
                              ...prevState,
                              cityId: Number(selectedOption?.value?.cityId),
                              city: selectedOption?.value?.city,
                            }));
                          }}

                        />
                        {/* {errors.cityId && <p className="error">{errors.cityId}</p>} */}

                      </div>
                      <div className='bottom-alignment'>
                        <Input label='Email' name='email' value={formData.email} onChange={handleInputChange} placeholder='Email' type='text' />
                      </div>
                      <div className='bottom-alignment'>
                        <Input label={
                          <span>
                            Contact No <span className="required-star">*</span>
                          </span>
                        } name='contactNo' value={formData.contactNo} onChange={handleInputChange} placeholder='Contact No.' type='text' />

                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ padding: '1.5rem', fontSize: '0.85rem', paddingTop: '1rem', marginTop: '1rem', }}>
                  <div style={{ borderBottom: '1px solid #e5e5e5', fontWeight: 'bold' }}>Counter category </div>

                  <div style={{ fontSize: '0.85rem', display: 'flex', justifyContent: 'space-between', paddingTop: '7px' }}>
                    <div>
                      <div style={{ fontSize: '0.85rem', color: 'black' }}>Turn-Over</div>
                      <div>{formData.turnover}</div>
                    </div>
                    <div>
                      <div style={{ fontSize: '0.85rem', color: 'black' }}>Existing Distributor</div>
                      <div>{formData.ed}</div>
                    </div>
                    <div>
                      <div style={{ fontSize: '0.85rem', color: 'black' }}>Existing Working Company</div>
                      <div>{formData.ewc}</div>
                    </div>
                  </div>
                </div>

                <div style={{ padding: '1.5rem', fontSize: '0.85rem', paddingTop: '1rem', marginTop: '1rem', }}>
                  <div style={{ borderBottom: '1px solid #e5e5e5', fontWeight: 'bold' }}>Deal Details </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  <Link to="/deal/adddeal" state={{ formData: formData }}>
                    <Button text="Add Deal" />
                  </Link>
                </div>
                <table style={{ width: '100%', marginTop: '0rem', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Deal #</strong></th>
                      <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Date</strong></th>
                      <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Pipeline Name</strong></th>
                      <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Pipeline Status</strong></th>
                      <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Owner</strong></th>
                      <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Created By</strong></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ width: '100%' }}>
                      <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                      <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}></td>
                    </tr>
                  </tbody>
                </table>
              </>
            </div>
            <div style={{
              marginTop: '1rem',
              padding: '1.5rem',
              // backgroundColor: '#E5E5E5',
              border: '1px solid rgba(30,46,80,.09)',
              borderRadius: '0.25rem',
              boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
              maxWidth: '400px',
              marginLeft: '5px',
              width: '400px',
              scrollBehavior: 'smooth',
              height: '683px',
              overflowY: 'auto'
            }}>
              <div style={{ marginTop: '-8px', paddingBottom: '15px', fontWeight: 'bold', fontSize: '20px' }}><strong>Timeline</strong></div>
              {timelines?.slice().reverse().map((i, index) => (
                <div
                  key={i.id || index}
                  style={{
                    display: "flex",
                    paddingLeft: "3px",
                    paddingBottom: "15px",
                  }}
                >
                  <div style={{ paddingRight: "5px" }}>➨</div>
                  <div style={{ fontSize: "14px" }}>
                  <strong>{i.message}</strong> updated by <strong>{i.user_name}</strong>.   On<strong>{dayjs(i.timestamp).format('DD-MM-YYYY, ⏱︎HH:mm:ss')}.</strong>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
