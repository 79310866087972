import React from 'react'
import './pageSubHeader.scss';
import Searchbar from '../header/searchbar';
import FillWhiteButton from '../fillWhiteButton';
import FilterIcon from '../../../assets/icons/filter-sm.svg'
import DateIcon from '../../../assets/icons/date-sm.svg'
export default function PageSubHeader() {
    return (
        <div className='page-sub-header-design-v2'>
            <div className='search-width'>
                <Searchbar />
            </div>
            <div className='page-sub-header-right-alignment'>
                <FillWhiteButton text="Select Dates" iconpath={DateIcon} />
                <FillWhiteButton text="Filters" iconpath={FilterIcon} />
            </div>
        </div>
    )
}
