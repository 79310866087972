import React from "react";
import {
  Box,
  Typography,
  Button,
  Tab,
  Tabs,
} from "@mui/material";
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import AccountTableHeader from "../account/accountTableHeader";
import SaleQuotationLeadComponent from "./EstimateLeadComponent";
import SaleQuotationcustomerComponent from "./EstimateComponent";
import SaleQuotationUserComponent from "./EstimateUserComponent";
import SaleQuotationAreaComponent from "./EstimateAreaComponent";
import SaleQuotationTimeComponent from "./EstimateTimeComponent";
import SaleQuotationProductComponent from "./EstimateProductComponent";
import SaleQuotationProductCategoryComponent from "./EstimateProductCategoryComponent";
import EstimateProductCategoryComponent from "./EstimateProductCategoryComponent";
import EstimateProductComponent from "./EstimateProductComponent";
import EstimateTimeComponent from "./EstimateTimeComponent";
import EstimateAreaComponent from "./EstimateAreaComponent";
import EstimateUserComponent from "./EstimateUserComponent";
import EstimatecustomerComponent from "./EstimateComponent";
import EstimateLeadComponent from "./EstimateLeadComponent";

// Main Component
export default function EstimateReport() {
  const [mainTabValue, setMainTabValue] = React.useState(0); // Tracks main tabs like Customer, User, Area, etc.

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  };

  // Function to render the corresponding component based on selected tab
  const renderTabContent = () => {
    switch (mainTabValue) {
      case 0:
        return <EstimateLeadComponent />;
      case 1:
        return <EstimatecustomerComponent />;
      case 2:
        return <EstimateUserComponent />;
      case 3:
        return <EstimateAreaComponent />;
      case 4:
        return <EstimateTimeComponent />;
      case 5:
        return <EstimateProductComponent />;
      case 6:
        return <EstimateProductCategoryComponent />;
      default:
        return <Typography variant="h6">No Data Available</Typography>;
    }
  };

  return (
    <div className="page-px-24">
      <div className="customer-header-alignment pb-4">
        <div>
          <h2>Estimate Report by </h2>
          <Breadcumbs activePath="Estimate Report" pageName="Estimate Report" />
        </div>
        <div className="right-contnet-alignment">
          <GlobalButton text="PRINT" />
          <GlobalButton text="EXPORT" lightbutton />
        </div>
      </div>
      <AccountTableHeader/>
        <Tabs value={mainTabValue} onChange={handleMainTabChange}>
          <Tab label="Lead" />
          <Tab label="Customer" />
          <Tab label="User" />
          <Tab label="Area" />
          <Tab label="Time" />
          <Tab label="Product" />
          <Tab label="Product Category" />
        </Tabs>

        {/* Content Rendering */}
        <div className="pt-4">{renderTabContent()}</div>
    </div>
  );
}
