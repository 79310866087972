import axios from 'axios';
import { getToken,signOutUser } from './cognitoService';
// import { useLoader } from '../shared/components/loader/LoaderProvider';
const BASE_URL = process.env.REACT_APP_BASE_URL;
const apiService = axios.create({
    baseURL: BASE_URL,
});

const showLoader = () => {
    if (typeof window !== 'undefined') window.__showLoader && window.__showLoader();
};

const hideLoader = () => {
    if (typeof window !== 'undefined') window.__hideLoader && window.__hideLoader();
};
apiService.interceptors.request.use(async (config) => {
    showLoader();
    try {
        if(!config.url.includes('login')){
            const token = await getToken();
            config.headers.Authorization = `Bearer ${token}`;
        }
    
    } catch (error) {
      console.error('Error fetching Cognito token:', error);
    }
    return config;
  }, error => {
    hideLoader();
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  });
apiService.interceptors.response.use(
    (res) => {
        hideLoader();
        return res; 
    },
    async (err) => {
        hideLoader(); 
        if (err.response && err.response.status === 401) {
            await signOutUser();
        }
        return Promise.reject(err);
    }
  )
export async function fetchData(endpoint, data = {}, pagination ,order = {}, isSearching = false, globalSearch) {
    try {
        const token = await getToken();
        const response = await apiService.post(`search/${endpoint}`, {isSearching, ...globalSearch, query:data, pagination,"order":order}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        return response?.data;
    } catch (error) {
        console.error('Error posting data:', error);
        //throw error;
    }
}
export async function filterAll(endpoint, data = {}) {
    try {
        const response = await apiService.post(`search/${endpoint}`, {query:data}, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data;
    } catch (error) {
        console.error('Error posting data:', error);
        //throw error;
    }
}
export async function fetchCustomData(endpoint, data = {}, options = {}) {
    try {
        const response = await apiService.post(`${endpoint}`, {query:data}, {
            headers: {
                'Content-Type': 'application/json',
                ...options.headers
            },
            ...options
        });
        return response?.data;
    } catch (error) {
        console.error('Error posting data:', error);
        //throw error;
    }
}
export async function fetchLivelocation(endpoint,id, options={}) {
    try {
        const response = await apiService.get(`${endpoint}/${id}`,{
            headers: {
                'Content-Type': 'application/json',
                ...options.headers
            },
            ...options
        });
        return response?.data;
    } catch (error) {
        console.error('Error posting data:', error);
        //throw error;
    }
}

export async function fetchCustomDataId(endpoint, options={}) {
    try {
        const response = await apiService.post(`${endpoint}`,options,
            {
            headers: {
                'Content-Type': 'application/json',
                ...options.headers
            },
        });
        return response?.data;
    } catch (error) {
        console.error('Error posting data:', error);
        //throw error;
    }
}
export async function fetchorderdisptchsummary(endpoint, options={}) {
    try {
        const response = await apiService.post(`${endpoint}`,options,
            {
            headers: {
                'Content-Type': 'application/json',
                ...options.headers
            },
        });
        return response?.data;
    } catch (error) {
        console.error('Error posting data:', error);
        //throw error;
    }
}

export async function fetchorderforinvoice(endpoint, options={}) {
    try {
        const response = await apiService.post(`${endpoint}`,options,
            {
            headers: {
                'Content-Type': 'application/json',
                ...options.headers
            },
        });
        return response?.data;
    } catch (error) {
        console.error('Error posting data:', error);
       
    }
}

export async function fetchalluservisitdistance(endpoint,data={}, options = {},pagination) {
    try {
        const response = await apiService.get(`${endpoint}`, {query:data,pagination}, {
            headers: {
                'Content-Type': 'application/json',
                ...options.headers
            },
            ...options
        });
        return response?.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Re-throw the error for the caller to handle
    }
}

export async function fetchsingleData(endpoint, id, options = {}) {
    try {
        if(!id && !endpoint)
            return
        const response = await apiService.get(`${endpoint}/${id}`,{
            headers: {
                'Content-Type': 'application/json',
                ...options.headers
            },
            ...options
        });
        return response?.data;
    } catch (error) {
        console.error('Error posting data:', error);
        //throw error;
    }
}
export async function postData(endpoint, data = {}, options = {}) {
    try {      
        const token = await getToken();
        const response = await apiService.post(`${endpoint}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        return response?.data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
}
export async function editDispatch(endpoint, data = {}, options = {}) {
    try {      
        const token = await getToken();
        const response = await apiService.post(`${endpoint}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        return response?.data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
}
export async function uploadDocument(endpoint, data = {}, options = {}) {
    try {
        const response = await apiService.post(`${endpoint}`, data, {
            headers: {
                // 'Content-Type': 'application/json',
                ...options.headers
            },
            ...options
        });
        return response?.data;
    } catch (error) {
        console.error('Error posting data:', error);
        throw error;
    }
}
// export async function getuploadedfile(endpoint, data = {}, options = {}) {
//     try {
//         const response = await apiService.get(`${endpoint}`, {
//             headers: {
//                 responseType:'arraybuffer',
//                 ...options.headers
//             },
//             ...options
//         });
//         return response;
//     } catch (error) {
//         console.error('Error posting data:', error);
//         throw error;
//     }
// }

export async function getuploadedfile(endpoint, options = {}) {
    try {
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...options.headers
        },
        ...options
      });
      
      // Return the response blob directly
      if (!response.ok) throw new Error('Network response was not ok');
      return response.blob();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

export async function patchData(endpoint, data = {}, options = {}) {
    try {
        const token = await getToken();
        const response = await apiService.put(`${endpoint}`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            }
        });
        return response?.data;
    } catch (error) {
        console.error('Error patching data:', error);
        throw error;
    }
    
}
export async function deleteData(endpoint, options = {}) {
    try {
        const response = await apiService.delete(`${endpoint}`, {
            headers: {
                'Content-Type': 'application/json',
                ...options.headers
            },
            ...options
        });
        return response?.data;
    } catch (error) {
        console.error('Error deleting data:', error);
        throw error;
    }
}