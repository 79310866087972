import React, { useState, useEffect } from 'react';
import './product.scss';
import Producttable from './producttable';
import { Link } from 'react-router-dom';
import Breadcumbs from '../../shared/components/breadcumbs';
import PlusIcon from '../../assets/icons/plus.svg';
import ExportIcon from '../../assets/icons/export.svg';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { fetchData } from "../../services/apiService";
import * as XLSX from "xlsx";
import GlobalButton from '../../shared/globalButton';
import AccountTableHeader from '../account/accountTableHeader';
import { checkAccess } from '../../services/checkFeature';
import { exportToExcel } from '../../services/globalService';
import { deleteData, filterAll } from "../../services/apiService";
import { Popconfirm } from 'antd';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';  // Correct import for Button
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PageSubHeader from '../../shared/components/pageSubHeader';
import FillWhiteButton from '../../shared/components/fillWhiteButton';
import CaretIcon from '../../shared/icons/caretIcon';
import PrimaryButton from '../../shared/components/primaryButton';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

export default function Product({ }) {
  const [data, setData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [productList, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [unitList, setUnitList] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [open, setOpen] = useState(false);  // Add this line to define 'open' state
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getData();
  }, []); // Empty dependency array ensures no infinite loop

  const getData = async () => {
    try {
      const requestBody = {}; // Adjust this if your API requires any specific query parameters or body data
      const response = await fetchData('product', requestBody);
      if (response?.data) {
        setProductData(response.data || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCloseExport = () => {
    if (productData.length === 0) {
      alert("No data available to export.");
      return;
    }

    const preparedData = prepareDataForSingleSheet(productData);
    const worksheet = XLSX.utils.json_to_sheet(preparedData);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Product_Report");
    XLSX.writeFile(workbook, `product_report.xlsx`);
  };

  const prepareDataForSingleSheet = (data) => {
    const groupedData = [];

    // Add product data rows
    data.forEach((item) => {
      const { name, productCode, offerDiscountCount
        , categoryName, priceTax, offerDiscountType
        , discountType, additionalDiscount, created_at, gstNumber, location
        , unitName, moq, remarks, tax, mrpPrice, discount, isCheckedNew, applyAdditionalDiscount, additionalDiscountType, hsn_code, price, category, stock, description, applyOfferDiscount
      } = item;
      groupedData.push({
        "Name": name,
        "Code": productCode,
        "Product category": categoryName,
        "Product unit": unitName,
        "Stock": stock,
        "Is new ": isCheckedNew,
        "HSN Code": hsn_code,

        "Price": price,
        "Price Tax {Exclusive | Inclusive}": priceTax,
        "Discount": discount,
        "Discount Type {Percentage | Amount}": discountType,
        "Offer discount": offerDiscountCount,
        "Offer Discount Type {Percentage | Amount}": offerDiscountType
        ,

        "Apply Offer Discount On {Base price | Discounted price}": applyOfferDiscount
        ,
        "Additional discount}": additionalDiscount,
        "Additional Discount Type {Percentage | Amount}": additionalDiscountType,
        "Apply Additional Discount On {Base price | Discounted price}": applyAdditionalDiscount,
        "Tax": tax,
        "MRP": mrpPrice,

        "Remark": remarks,
        "Bundle Quantity": moq,
        "Created At": created_at,
        "MOQ": moq,
        "GST NUMBER": gstNumber,
        "Loaction": location
      });
    });

    return groupedData;
  };
  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`product/${id}`)));
      setProductList(productList.filter(i => !selectedIds.includes(i?.id)));
      setTotalItems(totalItems - selectedIds.length);
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const handleExport = () => {
    const headers = [["Product", "Code", "Category", "Price", "Stock", "Status"]];
    filterAll("product")
      .then((data) => {
        const datas = (data.data ?? [])?.map((item) => [
          item.name,
          item.hsn_code,
          item.categoryName,
          item.price,
          (item.stock + " " + item.unitName),
          item.status ? "Active" : "Inactive",
        ]);
        exportToExcel('Product', headers, datas, 'product');
      });
  };

  useEffect(() => {
    filterAll('product')
      .then((response) => {
        if (response.data) {
          setProductList(response.data);
          setTotalItems(response.totalCount);
        }
      })
      .catch((error) => {
        console.error('Error fetching product data:', error);
      });
  }, []);

  return (
    <>
      <div className="design-v2-header">
        <div className="design-v2-left">
          <h2>
            Product
          </h2>
          <div className="design-breadcumb">
            <span>Catalog</span>
            <CaretIcon />
            <span>product</span>
          </div>
        </div>
        <div className="design-v2-right">
          {selectedIds.length > 0 && (
            <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title="Are you sure to delete this record? "
              description="This will lead to delete all records related to this record only!"
              open={open}
              onConfirm={handleOk}
              okButtonProps={{ loading: confirmLoading }}
              onCancel={handleCancel}
            >
              <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
            </Popconfirm>
          )}
          <div>
            <Button
              id="demo-customized-button"
              aria-controls={openMenu ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              variant="contained"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              <i class="fa fa-bars" aria-hidden="true"></i>
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} disableRipple>
                Import Products
              </MenuItem>

              <MenuItem onClick={handleCloseExport} disableRipple>
                Export Products
              </MenuItem>

              <MenuItem onClick={handleClose} disableRipple>
                Import Products Unit
              </MenuItem>

              <MenuItem onClick={handleClose} disableRipple>
                Export Products Unit
              </MenuItem>

              <MenuItem onClick={handleClose} disableRipple>
                Import Products Description
              </MenuItem>

              <MenuItem onClick={handleClose} disableRipple>
                Export Products Description
              </MenuItem>

            </StyledMenu>
          </div>
          {checkAccess('add_product') && (
            <Link to="add">
              <PrimaryButton text="Add Product" />
            </Link>
          )}
        </div>
      </div>
      <PageSubHeader />
      <div className='design-v2-left-right-alignment'>
        <Producttable
          productList={productList}
          setSelectedIds={setSelectedIds}
          setProductList={setProductList}
          setUnitList={setUnitList}
          totalItems={setTotalItems}
          selectedIds={selectedIds}
        />
      </div>
    </>
  );
}
