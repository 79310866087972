import React, { useEffect, useState } from 'react'
import { fetchorderdisptchsummary } from '../../../services/apiService';
import './commonCard.scss';
import ProcessingIcon from '../../../assets/icons/Processing.svg';
import dispetchpending from '../../../assets/icons/dipetchpending.svg';
import dispetched from '../../../assets/icons/dispetched.svg';
import cancelled from '../../../assets/icons/Cancel.svg';

import GreenIcon from '../../../assets/icons/green-up.svg';
export default function CommonCard({ startDate, endDate ,filterType}) {
    const [orderdispeachsummary, setOrderDispetchsummary] = useState([]);
    useEffect(() => {
        getData();
    }, [startDate, endDate]);

    const getData = async () => {
        try {
            const start_date = startDate
                ? startDate.toISOString().split('T')[0]
                : null;
            const end_date = endDate
                ? endDate.toISOString().split('T')[0]
                : null;



            const response = await fetchorderdisptchsummary('/order/orderanddispatchsummary',{
                start_date,
                end_date,
              });
            if (response?.data) {
                setOrderDispetchsummary(response.data || []);

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    return (
        <div className='common-card-section-alignment'>
            <div className='order-card-grid'>

                <div className='order-card-grid-items'>
                    <div className='card-header-alignment'>
                        <div>
                            <span>Pending</span>
                            <h3>{orderdispeachsummary?.totalPendingOrders}</h3>
                        </div>
                        <img src={ProcessingIcon} alt="ProcessingIcon" />
                    </div>
                    <div className='card-body-alignment'>
                        <div className='icon-text'>
                            <span>{`${orderdispeachsummary?.pendingOrderIncreasePercentage}%`}</span>
                            <img src={GreenIcon} alt="GreenIcon" />
                        </div>
                        <p>{`+${orderdispeachsummary?.todayPendingOrders} ${filterType}`}</p>
                    </div>
                </div>

                <div className='order-card-grid-items'>
                    <div className='card-header-alignment'>
                        <div>
                            <span>Dispatch Progress</span>
                            <h3>{orderdispeachsummary?.totalDispatchPending}</h3>
                        </div>
                        <img src={dispetchpending} alt="dispetchpending" />
                    </div>
                    <div className='card-body-alignment'>
                        <div className='icon-text'>
                            <span>{`${orderdispeachsummary?.dispatchPendingGrowthPercentage}%`}</span>
                            <img src={GreenIcon} alt="GreenIcon" />
                        </div>
                        <p>{`+${orderdispeachsummary?.todayDispatchPending} ${filterType}`}</p>
                    </div>
                </div>

                <div className='order-card-grid-items'>
                    <div className='card-header-alignment'>
                        <div>
                            <span>Dispatched</span>
                            <h3>{orderdispeachsummary?.totalDispatched}</h3>
                        </div>
                        <img src={dispetched} alt="dispetched" />
                    </div>
                    <div className='card-body-alignment'>
                        <div className='icon-text'>
                            <span>{`${orderdispeachsummary?.dispatchedGrowthPercentage}%`}</span>
                            <img src={GreenIcon} alt="GreenIcon" />
                        </div>
                        <p>{`+${orderdispeachsummary?.todayDispatched} ${filterType}`}</p>
                    </div>
                </div>

                <div className='order-card-grid-items'>
                    <div className='card-header-alignment'>
                        <div>
                            <span>Cancelled</span>
                            <h3>{orderdispeachsummary?.totalCanceledOrders}</h3>
                        </div>
                        <img src={cancelled} alt="cancelled" />
                    </div>
                    <div className='card-body-alignment'>
                        <div className='icon-text'>
                            <span>{`${orderdispeachsummary?.canceledOrderIncreasePercentage}%`}</span>
                            <img src={GreenIcon} alt="GreenIcon" />
                        </div>
                        <p>{`+${orderdispeachsummary?.todayCanceledOrders} ${filterType}`}</p>
                    </div>
                </div>

            </div>
        </div>
    )
}
