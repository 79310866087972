import React, { useContext, useState } from 'react'

import Button from '../../shared/components/button';
import { deleteData, filterAll } from '../../services/apiService';
import Dealtable from './dealtable';
import { Link } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { useSelector } from 'react-redux';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import { checkAccess } from '../../services/checkFeature';
import AccountTableHeader from '../account/accountTableHeader';

export default function Deal() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const user = useSelector(state => state.user.user);

  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`deal/${id}`)));
      const tempActivityList = activityList.filter(item => !(selectedIds.includes(item.id)))
      setActivityList(tempActivityList);

      setTotalItems(totalItems - selectedIds.length)

      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const [open, setOpen] = useState(false);
const [confirmLoading, setConfirmLoading] = useState(false);

const showPopconfirm = () => {
  setOpen(true);
};

const handleOk = async () => {
  setConfirmLoading(true);

  await handleDelete();
  setOpen(false);
  setConfirmLoading(false);
  
};

const handleCancel = () => {
  console.log('Clicked cancel button');
  setOpen(false);
};

  const handleExport = () => {
    const headers = [
      ["Deal No","Deal for", "Date", "Owner","Collaborators by", "Deal stage","Amount", "Status","Lost reason"]
    ];
 
    filterAll("deal")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.dealno,
        item.customerName,
        item.dealdate,
        "Admin",
        '_',
        item.dealstage,
         item.totalAmount,
        item.dealStatus,
        item.lossReason
      ]);
      exportToExcel('Deal',headers,datas,'deal')
    })
  };


  
    return (
      <div className='page-24'>
        {/* <div className='page-header'>
          <span>Deal</span>
          <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
            {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}

       <Link to="/deal/adddeal"><Button text='Add Deal' /></Link>
          </div>
        </div> */}
        <div className="">
        <div className="customer-header-alignment pb-4">
          <div>
            <h2>Deal</h2>
            <Breadcumbs activePath="Deal" pageName="Deal" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
             <Popconfirm
             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             title="Are you sure to delete this record? "
             description="This will lead to delete all records related to this record only!"
             open={open}
             onConfirm={handleOk}
             okButtonProps={{ loading: confirmLoading }}
             onCancel={handleCancel}
           >
             <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
           </Popconfirm>
            )}
            {checkAccess('add_deal')&&<Link to="/deal/adddeal">
              <GlobalButton text="Add Deal" Icon={PlusIcon} />
            </Link>}
            {checkAccess('export_deal')&&<GlobalButton text="Export" lightbutton Icon={ExportIcon} />}
          </div>
        </div>
      </div>

        <Dealtable
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          activityList={activityList}
          totalItems={totalItems} 
          setTotalItems={setTotalItems}
          setActivityList={setActivityList}
        />
      </div>
    )
  }

