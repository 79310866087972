import React, { useState, useEffect } from "react";
import "./invoicetable.scss";
import { Link,useNavigate } from "react-router-dom";
import { fetchData } from "../../../services/apiService";
import moment from 'moment';
import { useSelector } from 'react-redux';
import SelectBox from "../../../shared/components/select";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // Import plugin
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; // Import plugin
import { useDispatch } from "react-redux";
import { setTotalorder } from "../../../redux/auth";
import Pagination from '../../../shared/components/pagination';
import EditIcon from "../../../shared/icons/editIcon";
import { checkAccess } from "../../../services/checkFeature";
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce';
export default function Invoicetable({ selectedIds, setSelectedIds, activityList,setActivityList }) {
  dayjs.extend(isSameOrAfter); // Use the plugin
  dayjs.extend(isSameOrBefore); // Use the plugin
  const navigate = useNavigate(); 
  const [invoiceList, setInvoiceList] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Search term for customerName
  const [statusTerm, setStatusTerm] = useState(""); // Search term for status
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [ProductList, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10;
  const [createdByTerm, setCreatedByTerm] = useState(""); // Search term for created by
  const [customers, setCustomerOptions] = useState([]);
  const [orderTerm, setOrderTerm] = useState("");
  const [AmountTerm, setAmountTerm] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [invoiceTerm, setinvoiceTerm] = useState("");
  const [productData, setProductData] = useState([]);
  const user = useSelector(state => state.user.user);
  const [allactivityType, setallactivityTypeTerm] =useState('');
  useEffect(() => {
    const filters = {
      linkname: searchTerm,
      quotationstatus: statusTerm,
      quotation_no: createdByTerm,
      orderproducts: AmountTerm,
      orderDate :selectedDates 
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList)
    setTotalItems(activityList.length)
  }, [activityList])

  const { RangePicker } = DatePicker;

  const [errors, setErrors] = useState({});


  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

 
  const onRangeChange = (dates) => {
    setSelectedDates(dates); // Update the state with the selected date range
  };
 
  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };
  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('invoice',removeEmptyFields(filters), 
      pagination,
      {},
      isSearching,
      globalSearch
    );

      if (responseProducts?.data) {
        setProductData(responseProducts.data);
        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }
      console.log(responseProducts)

     
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      customerName: searchTerm,
      status: statusTerm,
      invoiceNo: invoiceTerm,
      totalAmount: AmountTerm,
      orderDate: selectedDates,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [searchTerm, statusTerm, invoiceTerm, AmountTerm, selectedDates]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['customerName', 'invoiceNo'],
      searchtext: allactivityType,
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);


  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];

  return (

    <div className='account-table-page-alignment'>
            <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className='account-table-design'>
        <table>
          <thead>
          
            <tr>
              <th><div className="tableSize"></div></th>
              <th><div className="tableSize"></div></th>
              <th><div style={{ textAlign: 'center', width: '100%' }}><input
                type="text"
                placeholder="Invoice"
                value={invoiceTerm}
                onChange={(e) => setinvoiceTerm(e.target.value)}
              className="w-100 border-0 table-input-background"
              /></div></th>

<th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Customer"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                 className="w-100 border-0 table-input-background"
                style={{ border: 'none', width: '100%',padding:"7px", borderRadius:"3px"  }}

              /></div></th>

              <th><div className="tableSize">
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange}  placeholder={[' Start Date', ' End Date']}  className="my-custom-class " />
                </Space></div> </th>

              <th><div className="tableSize"><input
                type="text"
                placeholder=" Amount"
                value={AmountTerm}
                onChange={(e) => setAmountTerm(e.target.value)}
               className="w-100 border-0 table-input-background"
              /></div></th>

<th style={{width:'10%'}}>
                <div>
                  <select
                    value={statusTerm === undefined ? "" : statusTerm.toString()} // Default to empty string when status is undefined
                    onChange={(e) => {
                      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
                      setStatusTerm(selectedStatus); // Updates the state with true, false, or undefined (for all)
                    }}
                    className="w-100 border-0 table-input-background"
                  >
                    <option value="">All</option>  {/* Default option to show all */}
                    <option value="true">Active</option>     {/* Option for true */}
                    <option value="false">Inactive</option> {/* Option for false */}
                  </select>
                </div>
              </th>
            </tr>
            <tr>
             
                <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
                 {checkAccess('edit_invoice')&&<th>Action</th>}
              <th>Invoice #</th>
              <th>Customer</th>
              <th>Date</th>
              <th>Amount</th>
             <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {productData && productData.length > 0 ? (
              productData.reverse().map((i, index) => {
                const currentDate = moment().format('DD-MM-YYYY');
                const invoiceDate = moment(i?.invoiceDate).format('DD-MM-YYYY');

                return (
                  <tr
                    key={i.id}
                    style={{
                      backgroundColor: index % 2 === 0 ? '#f2f4f4' : '#f8f9f9',
                      cursor: checkAccess('read_invoice') ? 'pointer' : 'default',
                    }}
                    onClick={() => {
                      if (checkAccess('read_invoice')) {
                        navigate(`/invoice/view/${i.id}`);
                      }
                    }}
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedIds.includes(i.id)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => handleCheckboxChange(i.id)}
                      />
                    </td>

                    {checkAccess('edit_invoice') && (
                      <td onClick={(e) => e.stopPropagation()}>
                        <Link to={`/invoice/add/${i.id}`}>
                          <EditIcon />
                        </Link>
                      </td>
                    )}

                    <td
                      className="whitesapce-nowrap"
                      style={{ fontWeight: invoiceDate === currentDate ? 'bold' : 'normal' }}
                    >
                      {i?.invoiceNo}
                    </td>

                    <td>
                      <span
                        className="price-group-text"
                        style={{ fontWeight: invoiceDate === currentDate ? 'bold' : 'normal' }}
                      >
                        {i?.customerName}
                      </span>
                    </td>

                    <td
                      className="whitesapce-nowrap"
                      style={{ fontWeight: invoiceDate === currentDate ? 'bold' : 'normal' }}
                    >
                      {invoiceDate}
                    </td>

                    <td
                      className="whitesapce-nowrap"
                      style={{ fontWeight: invoiceDate === currentDate ? 'bold' : 'normal' }}
                    >
                      {i?.grandtotal?.grandTotal}
                    </td>

                    <td
                      style={{ fontWeight: invoiceDate === currentDate ? 'bold' : 'normal' }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <button className={i?.status ? "Active" : "Inactive"}>
                        {i?.status ? "Active" : "Inactive"}
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7} style={{ textAlign: 'center', fontStyle: 'italic', color: '#666' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
