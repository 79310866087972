import React, { useEffect, useRef, useState } from 'react';
import './newHeader.scss';
import SearchIcon from '../../../assets/icons/search-gray.svg';
import DarteIcon from '../../../assets/icons/date.svg';
import BellIcon from '../../../assets/icons/bell.svg';
import { Button, List, Skeleton } from 'antd';
import { fetchData, patchData } from '../../../services/apiService';
import { LoadingOutlined } from '@ant-design/icons';
import { clearuser } from '../../../redux/rba';
import { notify } from '../../../routes/toastr/Toastr';
import { useDispatch, useSelector } from 'react-redux'; // Add this import
import store from '../../../redux/reduxstore';
import { persistor } from '../../../redux/reduxstore';
import { useNavigate } from 'react-router-dom';
import { logout as logoutinAction, } from '../../../redux/auth';
import ProfileIcon from '../../../assets/icons/profile.webp';
import { addEventListener, removeEventListener } from '../../../services/socketService';
import DownIcon from '../../../assets/icons/down-arrow.svg';
import { signOutUser } from '../../../services/cognitoService';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import Calendar from '../../../routes/calendar';


export default function NewHeader() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popupRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationEditingId, setNotificationEditingId] = useState();
  const [toogle, setToogle] = useState(false);
  const [mobileheader, setMobileheader] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const name = useSelector(state => state.user.user); // This is now valid
  const userData = store.getState().auth.userData;
  const userDataRef = useRef(userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    userDataRef.current = userData;
  }, [userData]);

  const togglePopup = (e) => {
    e.stopPropagation();
    setIsPopupOpen(!isPopupOpen);
  };

  const signout = async () => {
    await signOutUser();
    dispatch(logoutinAction());
    dispatch(clearuser());
    notify.info('You have successfully logged out.');
    persistor.purge().then(() => {
      navigate('/login');
    });
  }

  const handleIconClick = () => {
    setShowCalendar((prev) => !prev);
  };

  const dashboard = async () => {
    navigate('/dashboard');
  }

  const readButtonClicked = (e, notificationId) => {
    setNotificationEditingId(notificationId);

    patchData(`notification/${notificationId}`, { readStatus: true })
      .then(data => {
        const updatedNotifications = notifications
          .map(notification =>
            notification.id === notificationId ? data.data : notification
          )
          .filter(notification => !notification.readStatus);

        setNotifications(updatedNotifications);
        setNotificationEditingId("");
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        setNotificationEditingId("");
      });
  };

  const getNotitifications = async () => {
    try {
      const response = await fetchData("notification", { userId: userData.id });
      if (response?.data) {
        const filteredNotifications = response.data
          .filter(notification => !notification.readStatus)
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        setNotifications(filteredNotifications);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  useEffect(() => {
    getNotitifications();
    return () => { };
  }, []);

  useEffect(() => {
    const handleFullscreenChange = async (body) => {
      if (body?.userId === userDataRef.current?.id) {
        getNotitifications();
      }
    };
    addEventListener('inAppNotification', handleFullscreenChange);
    return () => {
      removeEventListener('inAppNotification', handleFullscreenChange);
    };
  }, []);

  return (
    <div className='new-header'>
      <div className='text'>
        <h2>
          Dashboard - Orders
        </h2>
      </div>

      <div className='all-icons-alignment'>
        <div className='header-search'>
          <input type='text' placeholder='Search..' />
          <div className='search-icon-alignment'>
            <img src={SearchIcon} alt='SearchIcon' />
          </div>
        </div>

        <div>

          <div
            className="icons"
            onClick={handleIconClick}
            style={{ cursor: 'pointer' }}
          >
            <img src={DarteIcon} alt="DarteIcon" />
          </div>


          {showCalendar && (
            <div className="calendar-modal">
              <div className="calendar-content">
                <Calendar />
                <button className="close-btn" onClick={() => setShowCalendar(false)}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>

        <div>
          <div className="icons">
            <img src={BellIcon} alt="BellIcon" onClick={togglePopup} />
            {notifications.filter((item) => !item.readStatus).length > 0 && (
              <span className="notification-count">
                {notifications.filter((item) => !item.readStatus).length}
              </span>
            )}
          </div>

          {isPopupOpen && (
            <div
              ref={popupRef}
              className={`notification-popup ${isPopupOpen ? 'open' : ''}`}
            >
              <div className="popup-header">
                <h4>Notifications</h4>
                <a href="#" onClick={togglePopup}>X</a>
              </div>
              <List
                dataSource={notifications}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        icon={notificationEditingId === item.id ? <LoadingOutlined /> : null}
                        variant="link"
                        onClick={(e) => readButtonClicked(e, item.id)}
                        disabled={item.readStatus}
                      >
                        Mark as read
                      </Button>,
                    ]}
                  >
                    <Skeleton avatar title={false} loading={item?.loading} active>
                      <div>{item.notificationText}</div>
                    </Skeleton>
                  </List.Item>
                )}
              />
            </div>
          )}
        </div>

        <div >
          <div className='profile-all-contnet-alignment'>
            <div className='profile'>

              <div className='profile-contnet-alignment'>
                <div className='icons'>
                  <img className='img' src={ProfileIcon} alt="ProfileIcon" />
                </div>
                <div className='right-content'>
                  <div>
                    <p>
                      {userData?.name || "Guest"}
                    </p>
                    <span>{userData?.role || "User"}</span>
                  </div>
                  <div onClick={() => setToogle(!toogle)}>
                    <img src={DownIcon} alt="DownIcon" />
                  </div>
                </div>
              </div>
              <div className={toogle ? 'profile-design show' : 'profile-design hide'}>
                <span>Profile</span>
                <span onClick={dashboard}>Dashboard</span>
                <span>Post & Activity</span>
                <span>Setting & Privacy</span>
                <span>Help Center</span>
                <span onClick={signout}>Logout</span>
              </div>
            </div>
            <i className="fa-solid fa-bars" onClick={() => setMobileheader(!mobileheader)}></i>
          </div>
        </div>

      </div>
    </div>
  );
}
