import { useEffect, useState, useRef } from 'react';
import { fetchCustomData, fetchData } from '../../../services/apiService';
import ViewIcon from '../../../assets/icons/view.svg';
import Pagination from '../../../shared/components/pagination';
import { Modal } from 'antd';
import mapboxgl from 'mapbox-gl';
import SelectBox from '../../../shared/components/select';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';


const MAPBOX_TOKEN = 'pk.eyJ1IjoicnV0dmlrbSIsImEiOiJjbHh2b3Zjcmkwd28zMm5zZ3BzYWJ4NXlkIn0.SnKNDTgcIO2Ar6dGfnXZbQ';

export default function CustomerVisitTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [customervisit, SetCustomerVisit] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [customers, setCustomerOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [duration, setDuration] = useState("");
  const [feedback, setFeedback] = useState("");
  const [user, setUser] = useState([]);
  const [searchuser, setSearchUser] = useState("");
  const [selectedcheckout, setSelectedcheckout] = useState([]);
  const [address,setAddress]=useState([]);
  const [filteredCustomerVisit, setFilteredCustomerVisit] = useState([]);


  const pageSize = 10;
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    getData();
    getCustomer();
    getUser();

  }, []);
  const getCustomer = async () => {
    try {
      const response = await fetchData(`customer`);
      if (response?.data) {
        setCustomerOptions(
          response?.data?.map((i) => {
            return {
              label: `${i?.cName}`,
              key: "customer",
              value: {
                id: i?.customerId,
                name: `${i?.cName}`,
                address: `${i?.address} - ${i?.pincode}.`,
                contact: i?.addressContact,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getUser = async () => {
    try {
      const response = await fetchData(`user`);
      if (response?.data) {
        setUser(
          response?.data?.map((i) => {
            return {
              label: `${i?.name}`,
              key: "user",
              value: {
                id: i?.id,
                name: `${i?.name}`,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getData = async () => {
    try {
      const requestBody = {};

      const response = await fetchCustomData('location_tracking/Customervisit', requestBody);
      if (response?.data) {
        SetCustomerVisit(response.data || []);
        setTotalItems(response?.totalDocuments || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openMapModal = (latitude, longitude, customerName, date, time) => {
    setLat(latitude);
    setLong(longitude);
    setModalTitle(`${customerName} - ${date} ${time}`);
    setIsModalOpen(true);
  };


  const handleModalClose = () => {
    setIsModalOpen(false);
    setLat(null);
    setLong(null);
    if (mapRef.current) {
      mapRef.current.remove(); // Clean up the map instance
      mapRef.current = null;
    }
  };

  const initializeMap = () => {
    if (!mapContainerRef.current || lat === null || long === null) return;
    if (mapRef.current) {
      mapRef.current.remove();
      mapRef.current = null;
    }


    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      center: [long, lat],
      zoom: 12,
      style: 'mapbox://styles/mapbox/streets-v11',
      accessToken: MAPBOX_TOKEN,
    });

    new mapboxgl.Marker()
      .setLngLat([long, lat])
      .addTo(mapRef.current);
    setTimeout(() => {
      if (mapRef.current) mapRef.current.resize();
    }, 0);
  };

  useEffect(() => {
    if (isModalOpen && lat !== null && long !== null) {
      initializeMap();
    }
  }, [isModalOpen, lat, long]);
  console.log(customervisit);

  const formatDateAndTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  const calculateWorkDuration = (punchIn, punchOut) => {
    const punchInDate = new Date(punchIn);
    const punchOutDate = new Date(punchOut);

    const duration = Math.abs(punchOutDate - punchInDate);

    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((duration % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
  };
  const onRangeChange = (dates) => {
    setSelectedDates(dates); // Update the state with the selected date range
  };
  const onRangecheckoutChange = (dates) => {
    setSelectedcheckout(dates);
  };
  const { RangePicker } = DatePicker;
  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];
 
  
  const filterCustomerVisit = () => {
    const filtered = customervisit.filter((item) => {
      const matchesCustomerName = searchTerm
        ? item.customername?.toLowerCase().includes(searchTerm.toLowerCase())
        : true;
  
      const matchesUser = searchuser
        ? item.userName?.toLowerCase().includes(searchuser.toLowerCase())
        : true;
  
      const matchesDuration = duration
        ? calculateWorkDuration(item.vist_start, item.visit_end)
            ?.toLowerCase()
            .includes(duration.toLowerCase())
        : true;
  
      const matchesFeedback = feedback
        ? item.feedback?.toLowerCase().includes(feedback.toLowerCase())
        : true;
  
      const matchesDateRange =
        selectedDates && selectedDates.length === 2
          ? dayjs(item.vist_start).isSameOrAfter(selectedDates[0], 'day') &&
            dayjs(item.vist_start).isSameOrBefore(selectedDates[1], 'day')
          : true;
  
      const matchesCheckoutRange =
        selectedcheckout && selectedcheckout.length === 2
          ? dayjs(item.visit_end).isSameOrAfter(selectedcheckout[0], 'day') &&
            dayjs(item.visit_end).isSameOrBefore(selectedcheckout[1], 'day')
          : true;
  
      return (
        matchesCustomerName &&
        matchesUser &&
        matchesDuration &&
        matchesFeedback &&
        matchesDateRange &&
        matchesCheckoutRange
      );
    });
  
    setFilteredCustomerVisit(filtered);
    const totalFilteredPages = Math.ceil(filtered.length / pageSize);
    if (currentPage > totalFilteredPages) {
      setCurrentPage(1);
    }
    setTotalItems(filtered.length);
  };

  useEffect(() => {
    filterCustomerVisit();
  }, [searchTerm, searchuser, duration, feedback, selectedDates, selectedcheckout, customervisit]);
  
  const paginatedData = filteredCustomerVisit.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  


  return (
    <div className="account-table-page-alignment">
      <div className="account-table-design">
        <table>
          <thead>
            <style>
              {`
      .search-input::placeholder {
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 2px;
        text-align: left;
        padding: 3px;
        border-bottom: 2px solid #edf2f9;
      }
    `}
            </style>
            <tr>
              <th><div >
                <SelectBox
                  value={
                    customers?.find((option) => option.label === searchTerm) || null
                  } // Safely access customers
                  options={customers || []} // Default to an empty array if customers is null/undefined
                  onChange={(selectedOption) =>
                    setSearchTerm(selectedOption?.label || "") // Handle null selectedOption
                  }
                  placeholder="Customer"
                  type="text"
                  required={true}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999, // Ensure dropdown is on top of other elements
                      maxHeight: '400px',
                      overflowY: 'auto',
                      border: 'none', // Enable scrolling if the dropdown is too large
                    }),
                    control: (provided) => ({
                      ...provided,
                      minWidth: '100%',
                      border: 'none',
                      marginTop: '-8px',
                      maxHeight: '25px',
                      textAlign: 'left',
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      border: 'none', // Render the dropdown with a very high z-index
                    }),
                  }}
                  menuPortalTarget={document.body}
                  isClearable={true} // Add clear button
                  components={{
                    ClearIndicator: (props) => (
                      <div
                        {...props.innerProps}
                        style={{
                          cursor: 'pointer',
                          padding: '5px',
                          color: 'gray',
                        }}
                      >
                        &#x2715; {/* Clear button (cross symbol) */}
                      </div>
                    ),
                  }}
                />
              </div></th>
              <th><div style={{ width: '100%' }}>
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={[' Start Date', ' End Date']} className="my-custom-class" />
                </Space></div> </th>
              <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                className="search-input input-height"
                style={{ border: 'none', width: '100%' }}

              /></div></th>
              <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Feedback"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                className="search-input input-height"
                style={{ border: 'none', width: '100%' }}

              /></div></th>
              <th><div >
                <SelectBox
                  value={
                    user?.find((option) => option.label === searchuser) || null
                  }
                  options={user || []}
                  onChange={(selectedOption) =>
                    setSearchUser(selectedOption?.label || "")
                  }
                  placeholder="User"
                  type="text"
                  required={true}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      maxHeight: '400px',
                      overflowY: 'auto',
                      border: 'none',
                    }),
                    control: (provided) => ({
                      ...provided,
                      minWidth: '100%',
                      border: 'none',
                      marginTop: '-8px',
                      maxHeight: '25px',
                      textAlign: 'left',
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      border: 'none',
                    }),
                  }}
                  menuPortalTarget={document.body}
                  isClearable={true}
                  components={{
                    ClearIndicator: (props) => (
                      <div
                        {...props.innerProps}
                        style={{
                          cursor: 'pointer',
                          padding: '5px',
                          color: 'gray',
                        }}
                      >
                        &#x2715;
                      </div>
                    ),
                  }}
                />
              </div></th>
              <th><div style={{ width: '100%' }}>
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangecheckoutChange} placeholder={[' Start Date', ' End Date']} className="my-custom-class" />
                </Space></div> </th>
                <th><div style={{ width: '100%' }}></div></th>
               <th><div style={{ width: '100%' }}></div></th>
              



            </tr>
            <tr>
              <th>Name</th>
              <th>Check In Time</th>
              <th>Duration</th>
              <th>Feedback Type</th>
              <th>Visited By</th>
              <th>Check Out Time</th>
              <th>Address</th>
              <th>View Map</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.slice().reverse().map((item, index) => (
              <tr key={index}>
                <td>{item?.customername}</td>
                <td>{formatDateAndTime(item?.vist_start)}</td>
                <td>
                  {calculateWorkDuration(item?.vist_start, item?.visit_end)}
                </td>
                <td>Average</td>
                <td>{item?.userName}</td>
                <td>{formatDateAndTime(item?.visit_end)}</td>

                <td className="status">{item?.address}</td>
                <td>
                  <img
                    src={ViewIcon}
                    alt="View Map"
                    onClick={() => openMapModal(item.latitude, item.longitude, item.customername, item.address, item.date, item.time)}
                    style={{ cursor: 'pointer' }}
                  />
                </td>
              </tr>
            ))}
            {paginatedData?.length === 0 && (
              <tr>
                <td colSpan="8" style={{ textAlign: 'center', padding: '10px', color: '#999' }}>
                  No records found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="pagination-alignment">
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        width={800}
        style={{ top: 20 }}
        bodyStyle={{ padding: 0 }}
      >
        <div
          ref={mapContainerRef}
          style={{ width: '100%', height: '400px' }}
        />
      </Modal>
    </div>
  );
}
