import { RouterProvider, } from "react-router-dom";
import "./App.css";
import router from "./routes/routes";
import './styles/mixins/index.scss';
import ToastProvider from "./routes/toastr/Toastr";
import { useLoader } from "./shared/components/loader/LoaderProvider";
import LoaderComponent from "./shared/components/loader/loader";
import { useEffect } from "react";

function App() {

  // const { loading } = useLoader();
  
  const { loading, showLoader, hideLoader } = useLoader();

    // Set loader functions globally
    useEffect(() => {
        window.__showLoader = showLoader;
        window.__hideLoader = hideLoader;

        return () => {
            delete window.__showLoader;
            delete window.__hideLoader;
        };
    }, [showLoader, hideLoader]);
    

  return (
    <>
      
      <ToastProvider/>
      <RouterProvider router={router} />
      <LoaderComponent loading={loading}></LoaderComponent>
    </>
  );
}

export default App;
