import React from 'react'
import './accountTableHeader.scss';
import FilterIcon from '../../../assets/icons/filter.svg';
import Searchbar from '../../../shared/components/header/searchbar';
export default function AccountTableHeader() {
  return (
    <div className='account-table-header-alignment'>
      <div className='search-width'>
      <Searchbar/>
      </div>
      <div className='right-contnet-alignment'>
         <input type='text' placeholder='Select Dates'/> 
        <button>
        <img src={FilterIcon} alt="FilterIcon"/>
        Filters
        </button> 
      </div>
    </div>
  )
}
