import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
// import dayjs from 'dayjs';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import Button from '../../../shared/components/button';
import ShowImage from '../../../shared/components/showImage';
import RightIcon from '../../../assets/icons/right-sm.svg';
import dayjs from 'dayjs';
import { useRef } from 'react';
import cameraIcon from '../../../assets/images/cameraicon.png';




import jsPDF from 'jspdf';


export default function ViewDispatchOrder() {
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);
const pdfRef = useRef();
    const [leadstatus, setLeadStatus] = useState([]);
    const [userName, setUserName] = useState([]);
    const [newNotification, setNewNotification] = useState([]);
    const user = useSelector(state => state.user.user);
    const name = useSelector((state) => state.auth.name);
    const [dispatchOrder, setDispatchOrder] = useState([])
    const [timelines, setTimelines] = useState([]);


    const [formData, setFormData] = useState({
        name: '',
        leadsource: '',
        customerName: '',
        lr_no: '',
        date: '',
        transport: '',
        lr_date: '',
        invoiceNo: '',
        orderStatus: '',



    });
      
        useEffect(() => {
            getTimeline(); // Fetch timeline data
        }, [newNotification]);
        const getTimeline = async () => {
            const requestBody = {
                reference_type: "dispatch",
                reference_id: id,
            };
    
            try {
                const responsetimeline = await fetchData("timeline", requestBody);
                console.log(responsetimeline);
                if (responsetimeline?.data) {
                    setTimelines(responsetimeline.data);
                }
            } catch (error) {
                console.error("Error fetching timeline data:", error);
            }
        };
    

     const handleGeneratePdf = () => {
            const pdfWidth = 595.28; // A4 width in px (portrait)
            const pdfHeight = 841.89; // A4 height in px (portrait)
        
            const doc = new jsPDF({
                orientation: 'portrait', // Portrait orientation for A4
                unit: 'px', // Use pixels for dimensions
                format: [pdfWidth, pdfHeight], // Custom A4 format
            });
        
            // doc.addFileToVFS('ITFRuppee.ttf', ITFRuppee);
            // doc.addFont('ITFRuppee.ttf', 'ITFRuppee', 'normal');
            // doc.setFont('ITFRuppee'); // Set the custom font as the default
            doc.setFont("helvetica");
    
    
            // Convert the content of the div to PDF
            doc.html(pdfRef.current, {
                callback: (doc) => {
                    const fileName = `${formData.dispatchNo || 'dispatch_details'}.pdf`; // Use orderNo or fallback to default
                    doc.save(fileName); // Save the PDF file
                },
                x: 20, // X offset for margins
                y: 20, // Y offset for margins
                width: pdfWidth - 0, // Adjust the width for margins (20px on each side)
                windowWidth: 970, // The width of the browser window
            });
        };

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };



    const getData = async () => {
        try {
            const responsee = await fetchData(`dispatchorder`);
            // if (responsee?.data) {
            //     const formattedleadstatus = responsee.data.map(i => ({
            //         label: i?.name,
            //         key: 'leadstatus',
            //         value: { name: i?.name }
            //     }));
            //     setLeadStatus(formattedleadstatus);
            // }


            const response = await fetchsingleData("dispatchorder", id);
            setDispatchOrder(response?.data)
            if (response?.data) setFormData(response.data);
            // if (response?.leadnotification) setNewNotification(response.data.leadnotification)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // const handleSelectChange = async (selectedValue) => {


    //     try {
    //         const currentDateTime = dayjs().format('DD-MM-YYYY,    ⏱︎HH:mm:ss');
    //         // const newNotification = { date: currentDateTime, status: selectedValue };
    //         // setNewNotification(newNotification =>({
    //         //     ...newNotification,
    //         //     date: currentDateTime, status: selectedValue

    //         // }))


    //         const updatedNotification = { date: currentDateTime, status: selectedValue, user: name };

    //         setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);
    //         // const updatedLeadNotification = [...formData.leadnotification, newNotification];

    //         if (id) {
    //             // const updatedData = { ...formData, leadstatus: selectedValue, collabrator:selectedValue, leadOwner:selectedValue, leadnotification: [...newNotification, updatedNotification] };
    //             // const data = await patchData(`lead/${id}`, updatedData);
    //             // if (data) {
    //             //     setFormData(updatedData);
    //             // }

    //             let tempForm = { ...formData }
    //             tempForm["leadnotification"] = [...newNotification, updatedNotification]
    //             // setFormData(tempForm)
    //         }
    //     } catch (error) {
    //         console.error('Error updating lead status:', error);
    //     }
    // };



    return (
        <div className='page-24'> 
        <div>
        <div className='breadcumbs-alignment pb-3'>
                    {/* <span className='active-class'>Order</span> */}
                    <span className='active-class'>Customer</span>
                    <img src={RightIcon} alt="RightIcon" />
                    <span>{formData.customerName}</span>
                    {/* <img src={RightIcon} alt="RightIcon" /> */}
                    <span>List</span>
                </div>
        <div style={{
            marginTop: '20px',
            // background: '#C0C0C0',
            padding: '20px',
            maxWidth: '2500px',
            margin: '0 auto'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
            }}>
                  {/* <div style={{
                    display: 'flex',
                    gap: '10px'
                }}>
                </div> */}
                    <div style={{
                                    display: 'flex',
                                    gap: '10px'
                                }}>
                                 <div onClick={handleGeneratePdf}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                                        </svg>
                                    </div>
                                    {formData.orderStatus === 'Pending' ? (
                  <>
                    <div onClick={handleGeneratePdf}>
                      {/* PDF generation content */}
                    </div>
                  </>
                ) : null}
                                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '2500px', }}>
                <div ref={pdfRef} style={{
                marginTop: '1rem',
                padding: '1.5rem',
                backgroundColor: '#fff',
                border: '1px solid rgba(30,46,80,.09)',
                borderRadius: '0.25rem',
                boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                width: '900px',
                scrollBehavior: 'smooth',
                height: 'auto',
                overflowY: 'scroll',

            }}>
            
                    <><div style={{ display: 'flex' }}>
                        <div><h4 style={{ paddingBottom: '0px' }}>Dispatch To,</h4>
                        <strong> <p style={{ fontSize: '0.85rem' }}>{formData.customerName}</p></strong>
                        <strong>  <p style={{ fontSize: '0.85rem' }}>{formData.customerAddress}</p></strong>
                            {/* <br /> */}
                                        <a href={`mailto:${formData.customerContact}`} style={{ color: 'purple' }}>{formData.customerContact}</a></div>
                            
                        <div style={{ flex: '1', textAlign: 'right' }}>
                            <h6>Dispatch No-<strong>{formData.dispatchNo}</strong></h6>
                            {/* <h6>Order No - <strong>{formData.dispatchqty && formData.dispatchqty.length > 0 ? formData.dispatchqty[0].orderNo : "N/A"}</strong></h6> */}
                                
                            <div style={{ fontSize: '0.75rem', marginBottom: '0rem' }}>Invoice No</div>
                            <strong>{formData.invoiceNo}</strong>
                            <div style={{ fontSize: '0.75rem', marginBottom: '0rem' }}>Invoice Date</div>
                            <strong>{dayjs(formData.invoiceDate).format('DD-MM-YYYY')}</strong>
                            {/* <div style={{ fontSize: '0.75rem', marginBottom: '0rem' }}>Transport</div>
                            <strong>{formData.transport}</strong> */}

                            {formData?.orderupdatedby && formData.orderupdatedby?.length > 0 ? (<p style={{ fontSize: '0.85rem' }}>Order is Updateby {formData.orderupdatedby} At {formData.orderupdate}</p>) :
                                (<p></p>)
                                }

                        </div>
                    </div>

                        <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '0rem', marginTop: '0rem' }}>
                            <div style={{ display: 'flex', marginBottom: '0rem' }}>
                                <div style={{ flex: '1', marginRight: '1rem' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0rem' }}>Order No.</div>
                                    <strong>{formData.dispatchqty && formData.dispatchqty.length > 0 ? formData.dispatchqty[0].orderNo : "N/A"}</strong>                                </div>
                                <div style={{ flex: '1', textAlign: 'center' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0rem' }}>Created By</div>
                                    <strong>{formData.created_by}</strong>
                                </div>
                                <div style={{ flex: '1', textAlign: 'right' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0rem' }}>Order Date</div>
                                    <strong>{dayjs(formData.orderDate).format('DD-MM-YYYY')}</strong>
                                </div>
                            </div>
                        </div>

                        <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '0rem', marginTop: '0rem' }}>
                            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                                <div style={{ flex: '1', marginRight: '1rem' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0rem' }}>LR No.</div>
                                    <strong>{formData.lr_no}</strong>
                                </div>
                                <div style={{ flex: '1', textAlign: 'center' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0rem' }}>Dispatch Status</div>
                                    <strong>{formData.dispatchqty && formData.dispatchqty.length > 0 ? formData.dispatchqty[0].productStatus : "N/A"}</strong>  
                                </div>
                                <div style={{ flex: '1', textAlign: 'right' }}>
                                    <div style={{ fontSize: '0.75rem', marginBottom: '0rem' }}>LR Date</div>
                                    <p style={{ fontSize: '0.75rem', marginTop: '0rem' }}>
                                    <strong>   {dayjs(formData.lr_date).format('DD-MM-YYYY')}</strong>
                                      
                                    </p>
                                </div>
                            </div>
                        </div>

                        <table style={{ width: '100%', marginTop: '0rem', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>#</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Order No</strong></th>
                                    {/* <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Image</strong></th> */}
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Item</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Category</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Quantity</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Price</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Discount</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Tax Details</strong></th>
                                    <th style={{ fontSize: '0.75rem', textTransform: 'uppercase', fontWeight: '500', letterSpacing: '2px', textAlign: 'left', padding: '10px', borderBottom: '2px solid #edf2f9' }}><strong>Sub Total</strong></th>
                                </tr>
                            </thead>
                            <tbody>
                            {dispatchOrder && Array.isArray(dispatchOrder?.dispatchqty) && dispatchOrder?.dispatchqty.map((item, index) => (
                                    <tr key={index}
                                        style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff', }}>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{index + 1}</td>

                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{formData.dispatchqty && formData.dispatchqty.length > 0 ? formData.dispatchqty[0].orderNo : "N/A"}</td>

                                        {/* <td > <a href={product.photoUrl} target="_blank" rel="noopener noreferrer">
                                            <ShowImage
                                                className="thumbnail"
                                                value={product?.productUrl}
                                                style={{ width: '80px', height: '60px', borderRadius: '4px', paddingBottom: '3px' }}
                                            />
                                        </a></td> */}

                                        {/* <td style={{   textAlign:'center',   width: '80px', height: '40px', borderRadius: '4px', paddingBottom: '3px', borderBottom: '1px solid #edf2f9' }}>
                                               <div className="image-text">
                                                               {!product?.productUrl &&  <img src={cameraIcon} />}
                                                               {product?.productUrl && (
                                                                 <a href={product?.productUrl} target="_blank" rel="noopener noreferrer">
                                                                   <ShowImage
                                                                     className="thumbnail"
                                                                     value={product?.productUrl}
                                                                     style={{ width: '50px', height: '40px', borderRadius: '4px', paddingBottom: '3px' }}
                                                                   />
                                                                 </a>
                                                               )}
                                                             </div>
                                        </td> */}
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>[{item.productCode}] {item.productName}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{item.unitName}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{item.quantity}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{item.unitPrice}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{item.discount}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{item.tax}% {item.taxcharges}</td>
                                        <td style={{ padding: '10px', borderBottom: '1px solid #edf2f9' }}>{item.subTotal}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div style={{ marginTop: '1.5rem' }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <h5 style={{ marginRight: '1rem' }}>Total: {formData.total}</h5>
                                <h5 style={{ color: '#28a745' }}> </h5>
                            </div>
                        </div>
                    </>
                
            </div>

            <div style={{
    marginTop: '1rem',
    padding: '1.5rem',
    backgroundColor: '#ffffff',
    border: '1px solid rgba(30,46,80,.09)',
    borderRadius: '0.25rem',
    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
    maxWidth: '400px',
    marginLeft: '5px',
    width: '400px',
    scrollBehavior: 'smooth',
    height: '540px',
    overflowY: 'auto'
}}>
    <div style={{marginTop:'-8px', paddingBottom:'15px', fontWeight:'bold', fontSize:'20px',}}>
        <strong>Timeline</strong>
    </div>
    <div style={{
        overflowY: 'auto',   // Enable vertical scrolling
    }}>
        {timelines?.slice().reverse().map((i, index) => (
            <div
                key={i.id || index}
                style={{
                    display: "flex",
                    paddingLeft: "3px",
                    paddingBottom: "15px",
                }}
            >
                <div style={{ paddingRight: "5px" }}>➨</div>
                <div style={{ fontSize: "14px" }}>
                    <strong> {i.message}</strong> by
                    <strong> {i.user_name}</strong> on <strong>  {dayjs(i.timestamp).format('DD-MM-YYYY, ⏱︎hh:mm:ss A')}</strong>.
                </div> 
            </div>
        ))}
    </div>
</div>

            </div>
        </div>
        </div>
        </div>

    );
}
