import React, { useState, useEffect, useContext } from "react";
import "./dealtable.scss";
import { Link } from "react-router-dom";
import { fetchData } from "../../../services/apiService";
import Toggle from "../../../shared/components/switch";
import { UserContext } from "../../../contexts/UserContext";
import { useSelector } from 'react-redux';
import Pagination from '../../../shared/components/pagination';
import SelectBox from '../../../shared/components/select';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import EditIcon from "../../../shared/icons/editIcon";
import { checkAccess } from "../../../services/checkFeature";
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce'; 
export default function Dealtable({ selectedIds, setSelectedIds,totalItems,setTotalItems,activityList, setActivityList }) {
  const [ProductList, setDealList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dealList, setProductList] = useState([]);
  const [DealnoTerm, setdealNoTerm] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);
  const [OwnerTerm, setOwnerTerm] = useState('');
  const [DealTerm, setdealTerm] = useState('');
  const [Collaborators, setCollaborators] = useState('');
  const [Dealstage, setDealstageTerm] = useState('');
  const [AmountTerm, setAmountTerm] = useState('');
  const [Status, setStatusTerm] = useState('');
  const [Lostreason, setLostreasonTerm] = useState('');
  const [productData, setProductData] = useState([]);
  const [allactivityType, setallactivityTypeTerm] =useState('');
  const pageSize = 10;
  const user = useSelector(state => state.user.user);
  useEffect(() => {
    const filters = {
      customerName  : DealnoTerm,
      dealno: DealTerm,
      quotation_no: OwnerTerm,
      totalAmount: AmountTerm,
      orderDate :Collaborators ,
      dealstage :Dealstage,
      dealStatus :Status,
      lossReason :Lostreason
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);


  useEffect(() => {
    setProductList(activityList)
    // setTotalItems(activityList.length)
  }, [activityList])

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const onRangeChange = (dates) => {
    setSelectedDates(dates); // Update the state with the selected date range
  };
  const { RangePicker } = DatePicker;
  const [errors, setErrors] = useState({});
  const getStatusClass = (status) => {
    console.log("status:", status)
    switch (status) {
      case 'won':
        return 'open';
      case 'lost':
        return 'cancelled';
      case 'in progress':
        return 'Pending';
      case 'overdue':
        return 'overdue';
      default:
        return '';
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };
  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('deal', removeEmptyFields(filters), 
      pagination,
      {},
      isSearching,
      globalSearch
    );

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }

      console.log('setProductData', responseProducts?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      customerName: DealnoTerm,
      dealno: DealTerm,
      quotation_no: OwnerTerm,
      totalAmount: AmountTerm,
      orderDate: Collaborators,
      dealstage: Dealstage,
      dealStatus: Status,
      lossReason: Lostreason,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [DealnoTerm, DealTerm, OwnerTerm, AmountTerm, selectedDates, Collaborators, Dealstage, Status, Lostreason]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['customerName', 'dealStatus'],
      searchtext: allactivityType,
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);

  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];

  return (
    <div className='account-table-page-alignment'>
                  <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className='account-table-design'>
        <table>
          <thead>
         
            <tr>
              <th><div className="tableSize"></div></th>

              <th><div><input
                  type="text"
                placeholder="Deal No"
                value={DealTerm}
                onChange={(e) => setdealTerm(e.target.value)}
                 className="w-100 border-0 table-input-background"

              /></div></th>

              <th><div ><input
              type="text"
                placeholder="Deal For"
                value={DealnoTerm}
                onChange={(e) => setdealNoTerm(e.target.value)}
                 className="w-100 border-0 table-input-background"
              /></div></th>

              <th><div className="tableSize">
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={['Start Date', 'End Date']} className="my-custom-class" />
                </Space></div> </th>

              <th><div className="tableSize"><input
                type="text"
                placeholder="Owner"
                value={OwnerTerm}
                onChange={(e) => setOwnerTerm(e.target.value)}
                className="w-100 border-0 table-input-background"
                

              /> </div></th>
              <th><div className="tableSize">
              <input
                type="text"
                placeholder="Collaborators "
                value={Collaborators }
                onChange={(e) => setCollaborators(e.target.value)}
             className="w-100 border-0 table-input-background"

              />
               </div></th>
              <th><div className="tableSize"> <input
                type="text"
                placeholder="Deal stage"
                value={Dealstage}
                onChange={(e) => setDealstageTerm(e.target.value)}
               className="w-100 border-0 table-input-background"
                

              /></div></th>

              <th><div className="tableSize"><input
                  type="text"
                  placeholder="Amount"
                  value={AmountTerm}
                  onChange={(e) => setAmountTerm(e.target.value)}
                  className="w-100 border-0 table-input-background"
                  

                /></div></th>

<th><div className="tableSize"><input
                  type="text"
                  placeholder="Status"
                  value={Status}
                  onChange={(e) => setStatusTerm(e.target.value)}
                 className="w-100 border-0 table-input-background"
                /></div></th>

<th><div className="tableSize"><input
                  type="text"
                  placeholder="Lost reason"
                  value={Lostreason}
                  onChange={(e) => setLostreasonTerm(e.target.value)}
                  className="w-100 border-0 table-input-background"
                /></div></th>

                              <th><div className="tableSize"></div></th>
            </tr>
            <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
            
              <th> #</th>
              <th>Deal for</th>
              <th>Date</th>
              <th>Owner</th>
              <th>Collaborators by</th>
              <th>Deal stage</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Lost reason</th>
              {checkAccess('edit_deal')&&<th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {productData && productData.length > 0 ? (
              productData.map((i, index) => {
                return (
                  <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#f8f9f9' }}>
                    <td>
                      <input type="checkbox" checked={selectedIds.includes(i.id)} onChange={() => handleCheckboxChange(i.id)} />
                    </td>
                    <td className="whitesapce-nowrap">{i?.dealno}</td>
                    <td>
                      <span className="price-group-text">{i?.customerName}</span>
                    </td>
                    <td className="whitesapce-nowrap">{moment(i?.dealdate).format('DD-MM-YYYY')}</td>
                    <td className="whitesapce-nowrap">Admin{i?.owner}</td>
                    <td className="whitesapce-nowrap">-</td>
                    <td className="whitesapce-nowrap">{i?.dealstage}</td>
                    <td>
                      {Array.isArray(i?.products)
                        ? i.products.reduce((accumulator, currentValue) => {
                          return accumulator + Number(currentValue.subTotal || 0);
                        }, 0).toFixed(2)
                        : '0.00'}
                    </td>
                    <td>
                      {checkAccess('read_deal') ? (
                        <Link to={{ pathname: `view/${i?.id}` }}>
                          <button className={` ${getStatusClass(i.dealStatus)}`}>
                            {i?.dealStatus}
                          </button>
                        </Link>
                      ) : (
                        <button className={` ${getStatusClass(i.dealStatus)}`} style={{ pointerEvents: 'none', cursor: 'default' }}>
                          {i?.dealStatus}
                        </button>
                      )}
                    </td>
                    <td className="whitesapce-nowrap">{i?.dealSatatus === 'lost' ? i?.lossReason : '-'}</td>
                    {checkAccess('edit_deal') && (
                      <td>
                        <Link to={`adddeal/${i?.id}`}>
                          <EditIcon />
                        </Link>
                      </td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={10} style={{ textAlign: 'center', fontStyle: 'italic' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
