import React, { useEffect, useState } from 'react'
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchData, patchData, postData, fetchsingleData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate, useParams } from 'react-router-dom/dist';
import GlobalButton from '../../../shared/globalButton';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';

export default function AddEditcategory() {
    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(() => {
        // Fetch data when the component mounts
        getData();
    }, []);
    const [formData, setFormData] = useState({
        typeCategory: 'category',
        typeName: '',
        orgId: '1',
        status: true
    });
    const [errors, setErrors] = useState({});
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        try {
            if (id) {
                patchData(`systemtype/${id}`, formData).then(data => {
                    navigate(-1);
                }).catch(error => {
                    console.error('Error fetching data:', error);
                });
            } else {
                postData('systemtype', formData)
                    .then(data => {
                        navigate(-1);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });

            }
        } catch (error) {
            console.error('Error saving data:', error);
        }

    };
    const validateForm = () => {
        const newErrors = {};
        if (!formData.typeName) newErrors.typeName = 'Category Name is required';



        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const toggleSwitch = (checked) => {
        setFormData({ ...formData, status: checked });
    };
    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (errors.isSubmit)
            validateForm()
    };
    const getData = async () => {
        try {
            if (id) {
                const response = await fetchsingleData(`systemtype`, `${id}`);
                if (response?.data)
                    setFormData(response?.data); // Update state with fetched data
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    return (
        <div className='page-24'>

            <div className='add-header-design'>
                <div>
                    <h2>Add Category</h2>
                    <Breadcumbs activePath="Catalog" pageName="Add Category" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/activitytype">
                        <button className='light-button'>
                            Discard
                        </button>
                    </Link>
                    <button onClick={handleSubmit}>
                        Save
                    </button>
                </div>
            </div>
            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid'>
                            <Input label={
                                <span>
                                    Name <span className="required-star">*</span>
                              </span>

                            }


                                name='typeName' value={formData.typeName} onChange={handleInputChange} placeholder='Enter Category Name..' type='text' required errors={errors.typeName} />
                            <div className='d-flex align-items-end'>
                                <Toggle label='Status' checked={formData.status} onChange={toggleSwitch} />

                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
    )
}
