import React, { useState, useEffect } from 'react';
import 'moment/locale/it.js';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchData, fetchsingleData, patchData, postData,fetchorderdisptchsummary } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import GlobalButton from '../../../shared/globalButton';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';
import store from '../../../redux/reduxstore';
import { notify } from '../../toastr/Toastr';

export default function AddVansale() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [UserName, setUserName] = useState([]);
    const [vehiclelist,setVehicleList]=useState([]);
    const [arealist,setArialist]=useState([]);
    const userData = useSelector((state) => state.auth.userData);
    const [formData, setFormData] = useState({
        username: '',
        deliveryvehicle:'',
        workprofile:'',
        date: dayjs(),
        created_by: userData?.name,
        status: true,
        user_id: null,
        areaName:'',
        areaId:null,
        stateId:null,
        countryId:null,
       
    });
    const LeaveWeightOptions = [
        { label: "Full Day", value: "Full Day" },
        { label: "Half Day", value: "Half Day" },
        { label: "Quarter Day", value: "Quarter Day" },
    ];
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getData();
       
    }, []);
    useEffect(() => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          created_by: userData?.name || '',
        }));
      }, [userData]);
    const getData = async () => {
        try {
            // Fetch users
            const userResponse = await fetchData(`user`);
            if (userResponse?.data) {
                setUserName(
                    userResponse.data.map((user) => ({
                        label: user.name, 
                        key: "userid",
                        value: user.id, 
                        name: user.name, 
                    }))
                );
            }
            const vehicleresponse = await fetchorderdisptchsummary(`vehicle/getvehiclelist`);
            if (vehicleresponse?.data) {
                setVehicleList(
                    vehicleresponse.data
                );
            }
            const arealist = await fetchorderdisptchsummary(`vehicle/getAreaDetailsWithIds`);
            if (arealist?.data) {
                setArialist(
                    arealist.data
                );
            }
            
            if (id) {
                const response = await fetchsingleData("vansale", id);
                if (response?.data) {
                    const fetchedData = response.data;
                    fetchedData.date = dayjs(fetchedData.date);
                    setFormData(fetchedData);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validationCheck()) {
            try {
                if (id) {
                    patchData(`vansale/${id}`, formData).then(data => {
                         if(data){
                                      notify.success('VanSales saved successfully!');}
                                      else{
                                        notify.error('VanSales not saved');
                                      }
                        if(data){
                            navigate(-1);
                            console.log('API response:', data);}
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    });
                } else {
                    postData('vansale', formData ).then(data => {
                         if(data){
                                      notify.success('VanSales saved successfully!');}
                                      else{
                                        notify.error('VanSales not saved');
                                      }
                        if(data){
                            navigate(-1);
                            console.log('API response:', data);}
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    });
                }
            } catch (error) {
                console.error('Error saving data:', error);
                // Handle error
            }
        }
    };
   
    const selectChanged = (selectedOption) => {
        setFormData((prevState) => ({
            ...prevState,
            user_id: selectedOption.value,
            username: selectedOption.name,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            username: "", 
        }));
        if (errors.isSubmit) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                username: FormData.username ? "" : "Please Select User", 
            }));
        }
    };
    const handleDateChange = (date) => {
        setFormData(prevState => ({
            ...prevState,
            date: date ? dayjs(date) : null,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            date: "", 
        }));
        if (errors.isSubmit) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                date: date ? "" : "Date is required.", 
            }));
        }
        
    };

    const validationCheck = (isSubmit) => {
        const validationErrors = {};

        if (!(formData.username)) {
            validationErrors.username = 'Please Select User';
        }
        if (!(formData.date)) {
            validationErrors.date = 'Please Select Date';
        }
        if (!formData.deliveryvehicle) {
            validationErrors.deliveryvehicle = "Please select delivery vehicle";
        }
    
        if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

        setErrors(validationErrors);
        return Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0;
    };
    const styles = {
        pageHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
            top: 'auto',
        },
        twoButtonAlignment: {
            display: 'flex',
            gap: '10px'
        },
        formContainer: {
            marginBottom: '20px'
        },
        grid: {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '20px',

        },
        inputWrapper: {
            flex: '1 1 calc(33.33% - 20px)',
            minWidth: '300px'
        },
        input: {
            width: '100%',
            padding: '10px',
            boxSizing: 'border-box'
        }


    };

    return (
        <div className='page-24'>
            <div className='add-header-design'>
                <div>
                    <h2>Add VanSale</h2>
                    <Breadcumbs activePath="VanSale" pageName="Add VanSale" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/vansales">
                        <button className='light-button'>
                            Discard
                        </button>
                    </Link>
                    <button onClick={handleSubmit}>
                        Save
                    </button>
                </div>
            </div>


            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid'>
                            <SelectBox label={
                                <span>
                                    Van Delivery User <span className="required-star">*</span>
                                </span>}
                                name='username' value={UserName?.find(i => i.name === formData.username)} onChange={selectChanged} options={UserName} placeholder='User' errors={errors.username} required />
                            <LocalizationProvider dateAdapter={AdapterDayjs}  >
                                <DatePicker label={
                                    <span>
                                        Date<span className="required-star">*</span>
                                    </span>}
                                    name='date' value={formData.date} onChange={handleDateChange} />
                            </LocalizationProvider>
                            <SelectBox
                                label={
                                    <span>
                                        Delivery Vehicle<span className="required-star">*</span>
                                    </span>
                                }
                                name="deliveryvehicle"
                                value={vehiclelist.find((option) => option.value === formData.deliveryvehicle)}
                                onChange={(e) => {
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        deliveryvehicle: e.value, 
                                    }));
                                    setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        deliveryvehicle: "", 
                                    }));
                                }}
                                options={vehiclelist}
                                placeholder="Select Delivery Vehicle"
                                errors={errors.deliveryvehicle}
                                required
                            />
                             {formData?.username&&(<SelectBox
                                
                                name="areaName"
                                value={arealist.find((i) => i.value === formData.areaName)}
                                onChange={(e) =>
                                    setFormData((prevState) => ({
                                        ...prevState,
                                        areaName: e.value,
                                        areaId:e.areaId,
                                        stateId:e.stateId,
                                        cityId:e.cityId,
                                        countryId:e.countryId,
                                    }))
                                }
                                options={arealist}
                                placeholder="Select area"
                                required
                            />)}

                        </div>
                    </div>
                   
                </div>
            </div>
        </div>

    );
}
