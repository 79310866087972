import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Breadcumbs from "../../../shared/components/breadcumbs";
import { fetchData } from "../../../services/apiService";



export default function UserDailyReport({setProductList,setTotalItems}) {
  const [deviceData, setDeviceData] = useState([]);

useEffect(() => {
  getData();
}, []); // Empty dependency array ensures no infinite loop

const getData = async () => {
  try {
    const requestBody = {};
    const response = await fetchData('devicelogin', requestBody);
    if (response?.data) {
      setDeviceData(response.data || []); 
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const exportToExcel = () => {
  if (deviceData.length === 0) {
    alert("No data available to export.");
    return;
  }

  const preparedData = prepareDataForSingleSheet(deviceData);
  const worksheet = XLSX.utils.json_to_sheet(preparedData);
  const workbook = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(workbook, worksheet, "Device_Report");
  XLSX.writeFile(workbook, `user_device_report.xlsx`);
};



const prepareDataForSingleSheet = (data) => {
  const groupedData = [];
  let previousUserId = null;

  data.forEach((item) => {
    const { userId, userRole, ...deviceData } = item;

    // When encountering a new userId, add their User Name and Role rows
    if (userId !== previousUserId) {
      if (previousUserId !== null) {
        // Add 3 blank rows after the previous userId's data
        groupedData.push({}, {}, {});
      }

      // Add User Name and Role
      groupedData.push({ "User Nam": userId, "User Role": userRole });
 
      previousUserId = userId;
    }

    // Add device data rows
    groupedData.push(deviceData);
  });

  return groupedData;
};


  return (
    <div className="page-px-24">
      <div className="customer-header-alignment pb-4">
        <div>
          <h2>Device Report </h2>
          <Breadcumbs activePath="Device Report" pageName="Device Report" />
        </div>
      </div>
      <div className="reports-container">
      <div className="report-card">
        <div className="icon">
          <span>&#8595;</span>
        </div>
        <a href="#"  onClick={exportToExcel}>
          Click here to download Customer device report.
        </a>
        <p className="description">
          (By clicking above link you can download Customer Device report in excel file.)
        </p>
      </div>
      <div className="report-card">
        <div className="icon">
          <span>&#8595;</span>
        </div>
        <a href="#"  onClick={exportToExcel} >
          Click here to download User device report.
        </a>
        <p className="description">
          (By clicking above link you can download User Device report in excel file.)
        </p>
      </div>
    </div>
      
    </div>
  );
}
