import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import './userroletable.scss';
import { fetchData } from '../../../services/apiService';
import EditIcon from '../../../shared/icons/editIcon';
import { checkAccess } from '../../../services/checkFeature';
import SearchIcon from '../../../assets/icons/search.svg';
import Pagination from '../../../shared/components/pagination';
import debounce from 'lodash.debounce';
export default function Userroletable({ selectedIds, setSelectedIds, activityList, setActivityList ,totalItems }) {
  const [userRole, setUserRoleTerm] = useState("");
  const [userrole, setUserRole] = useState([]);
  const [NameTerm, setNameTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [Status, setStatusTrem] = useState("");
  const pageSize = 10;
  const [allactivityType, setallactivityTypeTerm] =useState('');
  const [productData, setProductData] = useState([]);
  useEffect(() => {
    const filters = {
      role: userRole,
      status : Status
    };

    fetchProduct(filters, true,currentPage, pageSize)
  }, [currentPage]);


  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(activityList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
};
  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };

  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('userrole', removeEmptyFields(filters), 
      pagination,
      {},
      isSearching,
      globalSearch
    );

      if (typeof responseProducts?.data === 'object') {
        setActivityList(responseProducts?.data); 
      }
      if (responseProducts?.data) {
        setProductData(responseProducts.data);
        setProductData(responseProducts.data || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      role: userRole,
      status: Status,
    };

    const debouncedFetch = debounce(() => {
      fetchProduct(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [userRole, Status, currentPage, pageSize]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['role'],
      searchtext: allactivityType,
    };

    const debouncedSearchFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedSearchFetch();

    return () => {
      debouncedSearchFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);
  return (
    <div className=''>
      <div className='account-table-header-alignment'>
                    <div className='search-width'>
                        <div className='searchbar-design'>
                            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
                            <div className='icon-alignment'>
                                <img src={SearchIcon} alt="SearchIcon" />
                            </div>
                        </div>
                    </div>
                </div>
      <div className='account-table-design'>
        <table>
          <thead>
          
            <tr>
              <th><div className="tableSize"></div></th>
              <th><div className="tableSize"></div></th>
              <th><input className='w-100 border-0 table-input-background' type="text" placeholder="User" value={userRole} onChange={(e) => setUserRoleTerm(e.target.value)} /></th>
              <th><input className='w-100 border-0 table-input-background' type="text" placeholder="Status" value={Status} onChange={(e) => setStatusTrem(e.target.value)} /></th>
            </tr>
            <tr>
            <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
            {checkAccess('edit_user_role')&&<th>Action</th>}
              <th>RoleType</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {productData && productData.length > 0 ? (
              productData.map((i, index) => (
                <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f4f4' : '#f8f9f9' }}>
                  <td>
                    <input type="checkbox" checked={selectedIds.includes(i.id)} onChange={() => handleCheckboxChange(i.id)} />
                  </td>
                  {checkAccess('edit_user_role') && (
                    <td>
                      <Link to={{ pathname: `/user-role/adduserrole/${i?.id}` }}>
                        <EditIcon />
                      </Link>
                    </td>
                  )}
                  <td>{i?.role}</td>
                  <td>
                    {checkAccess('read_user_role') ? (
                      <Link to={{ pathname: `/user-role/adduserrole/view/${i?.id}` }}>
                        <button className={i?.status ? 'Active' : 'Inactive'}>{i?.status ? 'Active' : 'Inactive'}</button>
                      </Link>
                    ) : (
                      <button className="status">{i?.status ? 'Active' : 'Inactive'}</button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: 'center', fontStyle: 'italic' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
        <div className='pagination-alignment'>
                        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} 
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
                    </div>
      </div>
    </div>
  )
}
