import React, { useState } from 'react';
import './activity.scss';
import { Link } from 'react-router-dom';
import GlobalButton from '../../shared/globalButton';
import PlusIcon from '../../assets/icons/plus.svg';
import ExportIcon from '../../assets/icons/export.svg';
import MyactivitiesTable from './myActivitiestable';
import { deleteData, filterAll } from '../../services/apiService';
import Breadcumbs from "../../shared/components/breadcumbs";
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import AccountTableHeader from '../account/accountTableHeader';
import CaretIcon from '../../shared/icons/caretIcon';
import ExportIconGray from '../../assets/icons/export-gray.svg';
import FillWhiteButton from '../../shared/components/fillWhiteButton';
import PrimaryButton from '../../shared/components/primaryButton';
import PageSubHeader from '../../shared/components/pageSubHeader';

export default function Activity() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [myactivitiesList, SetmyActivitiesList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const handleDelete = async () => {
    try {
      // Delete selected activities
      await Promise.all(selectedIds.map((id) => deleteData(`myactivities/${id}`)));

      // Update the list after deletion
      SetmyActivitiesList(myactivitiesList.filter((i) => !selectedIds.includes(i?.id)));

      // Update the total item count
      setTotalItems(totalItems - selectedIds.length);

      // Clear the selected IDs
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);

  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleExport = () => {
    const headers = [
      ["Activity Type", "Activity Value", "Date", "Assign To", "Linked To", "Linked Name", "Status"]
    ];

    filterAll("myactivities")
      .then((data) => {
        const datas = (data.data ?? [])?.map((item) => [
          item.activityname,
          item.activityvalue,
          item.activitydate,
          item.username,
          item.module,
          item.linkname,
          "Active"
        ]);
        exportToExcel('Myactivities', headers, datas, 'myactivities')
      })
  };

  return (
    <>
      <div className="design-v2-header">
        <div className="design-v2-left">
          <h2>
            My Activity
          </h2>
          <div className="design-breadcumb">
            <span>My Activity</span>
            <CaretIcon />
          </div>
        </div>
        <div className="design-v2-right">
          <div onClick={handleExport}>
            <FillWhiteButton text="Export" iconpath={ExportIconGray} />
          </div>
          <Link to="addActivity">
            <PrimaryButton text="Add Activity" />
          </Link>
          {/* <Link to="addActivity">
            <GlobalButton text="Add Activity" Icon={PlusIcon} />
          </Link>
          <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} /> */}
        </div>
      </div>
      <PageSubHeader />
      <div className='design-v2-left-right-alignment'>
        <MyactivitiesTable
          myactivitiesids={selectedIds} // Pass selectedIds as myactivitiesids
          setSelectedIds={setSelectedIds}
          myactivitiesList={myactivitiesList}
          setProductList={SetmyActivitiesList}
          totalItems={totalItems}
          setTotalItems={setTotalItems}
        />
      </div>
      <div className="page-24">
        {/* <div className="">
          <div className="pb-4 customer-header-alignment">
            <div>
              <h2>My Activity</h2>
              <Breadcumbs activePath="My Activity" pageName="My Activity" />
            </div>
            <div className="right-contnet-alignment">
              {selectedIds.length > 0 && (
                <Popconfirm
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  title="Are you sure to delete this record? "
                  description="This will lead to delete all records related to this record only!"
                  open={open}
                  onConfirm={handleOk}
                  okButtonProps={{ loading: confirmLoading }}
                  onCancel={handleCancel}
                >
                  <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
                </Popconfirm>
              )}
              <Link to="addActivity">
                <GlobalButton text="Add Activity" Icon={PlusIcon} />
              </Link>
              <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />
            </div>
          </div>
        </div> */}
        {/* <AccountTableHeader/> */}
        {/* <div className="">
          <MyactivitiesTable
            myactivitiesids={selectedIds} // Pass selectedIds as myactivitiesids
            setSelectedIds={setSelectedIds}
            myactivitiesList={myactivitiesList}
            setProductList={SetmyActivitiesList}
            totalItems={totalItems}
            setTotalItems={setTotalItems}
          />
        </div> */}
      </div>
    </>
  );
}
