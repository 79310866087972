import { useState, useEffect, useRef, useContext } from 'react';
import * as htmlToImage from 'html-to-image';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import { useNavigate, useNavigation, useParams } from 'react-router-dom';
import 'moment/locale/it.js';
import './paymentview.scss';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import ReportTemplate from './ReportTemplate';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';


const createFileName = (extension = "", ...names) => {
    if (!extension) {
        return "";
    }
    return `${names.join("")}.${extension}`;
};

export const downloadFile = (image, { name = "payment-report", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
};

const AddEditviewPaymentCollection = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const user = useSelector(state => state.user.user);
    const name = useSelector((state) => state.auth.name);

    const pdfRef = useRef();

    const handleGeneratePdf = () => {
        const pdfWidth = 420; // Full HD width
        const pdfHeight = 370; // Full HD height

        const doc = new jsPDF({
            orientation: 'b5', // Landscape orientation for wider content
            unit: 'px', // Use pixels for dimensions
            format: [pdfWidth, pdfHeight], // Custom format for full HD
        });
        
        // Convert the content of the div to PDF
        doc.html(pdfRef.current, {
            callback: (doc) => {
                doc.save('payment_details.pdf'); // Save the PDF file
            },
            x: 20, // X offset for margins
            y: 20,
            z: 20, // Y offset for margins

            width: pdfWidth - 20, // Adjust the width for margins
            windowWidth: 970, // The width of the browser window

        });
    };

   
    const handleedit=()=>{
        navigate(`/payment-collection/addpaymentcollection/${formData.id}`);

    }


    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const [formData, setFormData] = useState({
        paymentstatus: 'pending',
        paymentnotification: [],
        yetscreen:true,
    });

    const reportTemplateRef = useRef(null);

    // const handleGeneratePdf = () => {
    //     const doc = new jsPDF({
    //         format: 'a4',
    //         unit: 'px',
    //     });
    //     doc.setFont('Inter-Regular', 'normal');

    //     doc.html(reportTemplateRef.current, {
    //         callback: function (doc) {
    //             doc.save('payment-report.pdf');
    //         },
    //     });
    // };

    const handleCaptureImage = async () => {
        if (!reportTemplateRef.current) return;

        try {
            const image = await htmlToImage.toPng(reportTemplateRef.current);
            downloadFile(image, { name: 'payment-report', extension: 'png' });
            alert('Payment report captured and downloaded.');
        } catch (error) {
            console.error('Error capturing image:', error);
        }
    };
    const [newNotification, setNewNotification] = useState([]);
    const handleSubmit = async (e, status) => {
        e.preventDefault();
        try {
            const currentDateTime = dayjs().format('DD-MM-YYYY,    HH:mm:ss');
            const updatedNotification = { date: currentDateTime, status: status, user: name };
            setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);
            const updatedFormData = {
                ...formData,
                paymentstatus: status,
                paymentnotification: [...newNotification, updatedNotification],
                // updatedpayment:currentDateTime,
                yetscreen:false
            };
            if (id) {
                const data = await patchData(`paymentcollection/${id}`, updatedFormData);
                if (data) setFormData(updatedFormData);
            } else {
                const data = await postData('paymentcollection', updatedFormData);
                if (data) navigate(-1);
            }
            navigate(`/payment-collection`);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    const getData = async () => {
        try {
            const response = await fetchsingleData("paymentcollection",id);
            if (response?.data) setFormData(response.data);
           
            if (response?.data.paymentnotification) setNewNotification(response.data.paymentnotification)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        const saveYetscreen = async () => {
          const updatedFormData = {
            ...formData,
            yetscreen: false, 
            amount:formData.amount,
          };
    
          try {
            if (id) {
              
            await patchData(`paymentcollection/${id}`, updatedFormData);
            
            } 
          } catch (error) {
            console.error("Error saving yetscreen:", error);
          }
        };
    
        saveYetscreen();
      }, []); 

    return (
        <div style={{
            marginTop: '20px',
            background: '#C0C0C0',
            padding: '20px',
            maxWidth: '2500px',
            margin: '0 auto'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
            }}>
                <span style={{
                    fontSize: '18px',
                    fontWeight: 'bold'
                }}>Payment Collection {'>'} View</span>
                <div style={{
                    display: 'flex',
                    gap: '10px'
                }}> <div onClick={handleedit} style={{cursor:'pointer'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" fill="currentColor" className="bi bi-pen" viewBox="0 0 16 16">
                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z" />
                        </svg>
                    </div>
                    <div onClick={handleGeneratePdf}style={{cursor:'pointer'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
                            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                            <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
                        </svg>
                    </div>



                        <button
                            onClick={(e) => handleSubmit(e, 'confirm')}
                            style={{
                                backgroundColor: '#27AE60',
                                color: 'white',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                border: 'none',
                                cursor: 'pointer',
                                height: '30px',
                                fontSize: '10px',
                                fontWeight: 'bold',

                            }}
                        >
                            Confirm
                        </button>
<button
                        onClick={(e) => handleSubmit(e, 'cancel')}
                        style={{
                            backgroundColor: '#D44545',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={(e) => handleSubmit(e, 'received')}
                        style={{
                            backgroundColor: '#2BB2FE',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        Received
                    </button>
                    <a href="/payment-collection" style={{ textDecoration: 'none' }}>
                        <button style={{
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: '1px solid #1e2e50',
                            backgroundColor: 'transparent',
                            color: '#1e2e50',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}>
                            Back
                        </button>
                    </a>
                </div>
            </div>


            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '2500px' }}>  <div ref={reportTemplateRef}>
                <div ref={pdfRef}> <ReportTemplate formData={formData} /></div>
            </div>
                <div style={{
                    marginTop: '1rem',
                    padding: '1.5rem',
                    backgroundColor: '#E5E5E5',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                    maxWidth: '400px',
                    marginLeft: '5px',
                    width: '400px',
                    scrollBehavior: 'smooth',
                    height: '555px',
                    overflowY: 'auto'
                }}>
                    <div style={{ marginTop: '-8px', paddingBottom: '15px', fontWeight: 'bold', fontSize: '20px' }}><strong>Remark</strong></div>
                    {formData.paymentnotification?.slice().reverse().map((i, index) => (

                        < div style={{ display: 'flex', paddingLeft: '3px', paddingBottom: '15px' }}> <div style={{ paddingRight: '5px' }}>➨</div><div style={{ fontSize: '14px' }}>On <strong>{i.date}</strong> the payment status was updated to <strong>{i.status}</strong> by the <strong>{i.user}</strong>.</div></div>
                    ))}
                </div>
            </div>
            {/* <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1.5rem'
            }}>
                <button
                    onClick={handleGeneratePdf}
                    style={{
                        backgroundColor: '#1e2e50',
                        color: '#fff',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        border: 'none',
                        cursor: 'pointer',
                        marginRight: '10px'
                    }}
                >
                    Generate PDF
                </button>
                <button
                    onClick={handleCaptureImage}
                    style={{
                        backgroundColor: '#1e2e50',
                        color: '#fff',
                        padding: '10px 20px',
                        borderRadius: '5px',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    Capture as Image
                </button>
            </div> */}
        </div>
    );
};

export default AddEditviewPaymentCollection;
