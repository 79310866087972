import React from 'react'
import './dashboardCard.scss';
import GrothIcon from '../../../assets/icons/growth.svg';
export default function DashboardCard() {
    return (
        <div className='dashboard-card-grid'>
            <div className='items'>
                <div className='items-header'>
                    <p>Active Leads</p>
                </div>
                <div className='items-box'>
                    <div className='text-alignment'>
                        <h3>3.150</h3>
                        <div className='icon-text'>
                            <img src={GrothIcon} alt="GrothIcon" />
                            <span>15%</span>
                        </div>
                        <p>vs last month</p>
                    </div>
                </div>
            </div>
            <div className='items'>
                <div className='items-header'>
                    <p>Active Leads</p>
                </div>
                <div className='items-box'>
                    <div className='text-alignment'>
                        <h3>3.150</h3>
                        <div className='icon-text'>
                            <img src={GrothIcon} alt="GrothIcon" />
                            <span>15%</span>
                        </div>
                        <p>vs last month</p>
                    </div>
                </div>
            </div>
            <div className='items'>
                <div className='items-header'>
                    <p>Active Leads</p>
                </div>
                <div className='items-box'>
                    <div className='text-alignment'>
                        <h3>3.150</h3>
                        <div className='icon-text'>
                            <img src={GrothIcon} alt="GrothIcon" />
                            <span>15%</span>
                        </div>
                        <p>vs last month</p>
                    </div>
                </div>
            </div>
            <div className='items'>
                <div className='items-header'>
                    <p>Active Leads</p>
                </div>
                <div className='items-box'>
                    <div className='text-alignment'>
                        <h3>3.150</h3>
                        <div className='icon-text'>
                            <img src={GrothIcon} alt="GrothIcon" />
                            <span>15%</span>
                        </div>
                        <p>vs last month</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
