import React,{useState}from 'react'
import Usertable from './usertable';
import { deleteData, filterAll } from '../../services/apiService';
import Button from '../../shared/components/button';
import { Link } from 'react-router-dom';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';

import { checkAccess } from '../../services/checkFeature';
import AccountTableHeader from '../account/accountTableHeader';
export default function User() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`user/${id}`)));
      setActivityList(activityList.filter(item => !selectedIds.includes(item.userId)));
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const handleExport = () => {
    const headers = [
      ["Name","	User-Role", "Status"]

    ];
 
    filterAll("user")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.name,
      item.userrole,
      item.status ? "Active" : "Inactive",
      ]);
      exportToExcel('User',headers,datas,'user')
    })
  };

  return (
    <div className='page-24'>
      {/* <div className='page-header'>
        <span>User</span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
        {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}

        <Link to='/user/adduser'><Button text='Add User'/></Link>
      </div>
      </div> */}
      <div className="">
        <div className="customer-header-alignment pb-4">
          <div>
            <h2>User</h2>
            <Breadcumbs activePath="User" pageName="User" />
          </div>
          <div className="right-contnet-alignment">
            {/* {selectedIds.length > 0 && (
              <GlobalButton text="Delete Selected" onClick={handleDelete} />
            )}  */}
           {checkAccess('add_user')&& <Link to='/user/adduser'>
              <GlobalButton text="Add User" Icon={PlusIcon} />
            </Link>}
            {checkAccess('export_user')&&<GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />}
          </div>
        </div>
      </div>

      <div className=''>
     <Usertable 
     selectedIds={selectedIds} 
     setSelectedIds={setSelectedIds} 
     activityList={activityList}
     setActivityList={setActivityList}></Usertable>
    </div>
    </div>
  )
}
