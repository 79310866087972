import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, MenuItem, Select, FormControl, InputLabel, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function DealpiplinestageBarComponent({ customerList }) {
    const [chartData, setChartData] = useState([]);
    const [visibleData, setVisibleData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataType, setDataType] = useState("order"); // Default data type
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        if (customerList.length) {
            setLoading(false);
            processData(dataType); // Process data based on the selected type
        }
    }, [dataType, customerList]);

    useEffect(() => {
        // Update visible data when chartData changes or pagination changes
        const start = (currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        setVisibleData(chartData.slice(start, end));
    }, [chartData, currentPage]);

    const processData = (type) => {
        // Map selected data type to corresponding fields
        const fieldMapping = {
            order: "totalUniqueOrders",
            SubAmount: "totalSubAmount",
            amount: "totalAmount",
            base_quantity: "baseQuantity",
            quantity: "totalQuantity",
            product: "totalUniqueProducts",
        };

        const selectedField = fieldMapping[type];

        // Format data based on the selected field
        const formattedData = customerList.map((item) => [
             item?.productName || "Unknown Customer", // X-axis: Customer name
            item[selectedField] || 0, // Y-axis: Selected field value
        ]);

        setChartData(formattedData);
    };

    const handleChange = (event) => {
        setDataType(event.target.value); // Update the selected data type
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const options = {
        chart: {
            type: "column",
        },
        title: {
            text: `Customer Data by ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`,
        },
        xAxis: {
            type: "category",
            labels: {
                autoRotation: [-45, -90],
                style: {
                    fontSize: "13px",
                    fontFamily: "Verdana, sans-serif",
                },
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: `${dataType.charAt(0).toUpperCase() + dataType.slice(1)} Count`,
            },
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            pointFormat: `${dataType.charAt(0).toUpperCase() + dataType.slice(1)}: <b>{point.y}</b>`,
        },
        series: [
            {
                name: dataType.charAt(0).toUpperCase() + dataType.slice(1),
                color: "#1976d2", // Set the bar color here
                groupPadding: 0,
                data: visibleData,
                dataLabels: {
                    enabled: true,
                    rotation: -90,
                    color: "#FFFFFF",
                    inside: true,
                    verticalAlign: "top",
                    format: "{point.y}", // No decimal
                    y: 10, // 10 pixels down from the top
                    style: {
                        fontSize: "13px",
                        fontFamily: "Verdana, sans-serif",
                    },
                },
            },
        ],
    };

    return (
        <Box p={4}>
            <FormControl fullWidth margin="normal">
                <InputLabel id="data-type-label">Select Data Type</InputLabel>
                <Select
                    labelId="data-type-label"
                    value={dataType}
                    onChange={handleChange}
                >
                    <MenuItem value="order">Order</MenuItem>
                    <MenuItem value="SubAmount">SubAmount</MenuItem>
                    <MenuItem value="amount">Amount</MenuItem>
                    <MenuItem value="base_quantity">Base Quantity</MenuItem>
                    <MenuItem value="quantity">Quantity</MenuItem>
                    <MenuItem value="product">Product</MenuItem>
                </Select>
            </FormControl>

            {loading ? (
                <p>Loading chart...</p>
            ) : (
                <>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                    <Box mt={2}>
                        <Typography variant="body2">
                            Showing {((currentPage - 1) * itemsPerPage) + 1} - {Math.min(currentPage * itemsPerPage, chartData.length)} of {chartData.length}
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePrevPage}
                            disabled={currentPage === 1}
                            sx={{ marginRight: "10px" }}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNextPage}
                            disabled={currentPage * itemsPerPage >= chartData.length}
                        >
                            Next
                        </Button>
                    </Box>
                </>
            )}
        </Box>
    );
}
