import React from 'react'

export default function LeftArrow() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_69_1714)">
<path d="M2.46225 9.79472C2.25157 9.58378 2.13324 9.29785 2.13324 8.99972C2.13324 8.70159 2.25157 8.41566 2.46225 8.20472L6.70425 3.96047C6.9153 3.74942 7.20154 3.63086 7.5 3.63086C7.79846 3.63086 8.0847 3.74942 8.29575 3.96047C8.5068 4.17152 8.62536 4.45776 8.62536 4.75622C8.62536 5.05468 8.5068 5.34092 8.29575 5.55197L5.97375 7.87472H14.625C14.9234 7.87472 15.2095 7.99325 15.4205 8.20422C15.6315 8.4152 15.75 8.70135 15.75 8.99972C15.75 9.29809 15.6315 9.58424 15.4205 9.79522C15.2095 10.0062 14.9234 10.1247 14.625 10.1247H5.97375L8.29575 12.4467C8.40025 12.5512 8.48314 12.6753 8.5397 12.8118C8.59625 12.9483 8.62536 13.0947 8.62536 13.2425C8.62536 13.3903 8.59625 13.5366 8.5397 13.6731C8.48314 13.8097 8.40025 13.9337 8.29575 14.0382C8.19125 14.1427 8.06719 14.2256 7.93066 14.2822C7.79412 14.3387 7.64778 14.3678 7.5 14.3678C7.35222 14.3678 7.20588 14.3387 7.06934 14.2822C6.93281 14.2256 6.80875 14.1427 6.70425 14.0382L2.46225 9.79472Z" fill="#667085"/>
</g>
<defs>
<clipPath id="clip0_69_1714">
<rect width="18" height="18" fill="white" transform="matrix(0 1 1 0 0 0)"/>
</clipPath>
</defs>
</svg>

  )
}
