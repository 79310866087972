import React, { useState, useEffect, useContext } from 'react';
import './paymentcollectiontable.scss';
import { fetchData } from '../../../services/apiService';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import SelectBox from '../../../shared/components/select';
import { DatePicker, Space } from 'antd';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'; // Import plugin
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; // Import plugin
import { useDispatch } from "react-redux";
import { setTotalorder } from "../../../redux/auth";
import EditIcon from '../../../shared/icons/editIcon';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce';
export default function Paymentcollectiontable({ selectedIds, setSelectedIds, activityList, setActivityList}) {
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [paymentTerm, setPaymentTerm] = useState("");
  const [customers, setCustomerOptions] = useState([]);
  const [dateTerm, setDateTerm] = useState(""); // Search term for date
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState("");
  const [paymentType, setPaymentType] = useState([]);
  const [AmountTerm, setAmountTerm] = useState("");
  const [createdByTerm, setCreatedByTerm] = useState("");
  const [description,   setDescription] = useState("");
  const [productData, setProductData] = useState([]);
  const [allactivityType, setallactivityTypeTerm] =useState('');
  const [statusTerm, setStatusTerm] = useState(""); // Search term for status
  dayjs.extend(isSameOrAfter); // Use the plugin
  dayjs.extend(isSameOrBefore); // Use the plugin

  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [ProductList, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10;


  const [searchTerm, setSearchTerm] = useState("");
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    getCustomer();
    fetchPaymentTypes();
    const filters = {
      cName: searchTerm,
      paymentstatus: statusTerm,
      created_by: createdByTerm,
      description :description,
      paymentno : paymentTerm,
      amount :AmountTerm,
      paymenttypeName: selectedPaymentType
    };

    fetchpaymentcollection(filters, true, currentPage, pageSize)
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList)
    // setTotalItems(activityList.length)
  }, [activityList])
  const [errors, setErrors] = useState({});
  const getCustomer = async () => {
    try {
      const response = await fetchData(`customer`);
      if (response?.data) {
        setCustomerOptions(
          response?.data?.map((i) => {
            return {
              label: `[${i?.code}] ${i?.cName}`,
              key: "customer",
              value: {
                id: i?.customerId,
                name: `[${i?.code}] ${i?.cName}`,
                address: `${i?.address} - ${i?.pincode}.`,
                contact: i?.addressContact,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const fetchPaymentTypes = async () => {
    const response = await fetchData(`paymenttype`);
    const paymentttype = response.data.map(i => ({
      label: i?.name,
      key: 'paymenttype',
      value: { id: i?.paymentTypeId, name: i?.name }
    }));
    setPaymentType(paymentttype);
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };


  const getStatusClass = (status) => {
    switch (status) {
      case 'pending':
        return 'Pending';
      case 'cancel':
        return 'cancelled';
      case 'confirm':
        return 'open';
      default:
        return '';
    }
  };
  const { RangePicker } = DatePicker;

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };
  const fetchpaymentcollection = async (filters, isSearching = false, page = 1, pageSize = 10 , globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('paymentcollection', removeEmptyFields(filters), pagination,
      {},
      isSearching,
      globalSearch);

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }

      console.log('setProductData', responseProducts?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      cName: searchTerm,
      paymentstatus: statusTerm,
      created_by: createdByTerm,
      description: description,
      paymentno: paymentTerm,
      amount: AmountTerm,
      paymenttypeName: selectedPaymentType,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchpaymentcollection(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [searchTerm, statusTerm, createdByTerm, AmountTerm, paymentTerm, selectedDates, description, selectedPaymentType]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['cName', 'paymentstatus', 'created_by', 'description', 'paymenttypeName'],
      searchtext: allactivityType,
    };

    const debouncedFetch = debounce(() => {
      fetchpaymentcollection({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);


  const onRangeChange = (dates) => {
    setSelectedDates(dates); 
  };
  const rangePresets = [
    {
      label: 'Last 7 Days',
      value: [dayjs().subtract(7, 'day'), dayjs()],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().subtract(14, 'day'), dayjs()],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().subtract(30, 'day'), dayjs()],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().subtract(90, 'day'), dayjs()],
    },
  ];



  return (
    <div className=''>
                  <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className='account-table-design'>
        <table > 
          <thead>
            <tr>
              <th><div style={{ width: '100%' }}></div></th>

              <th><div style={{ textAlign: 'center', width: '100%' }}><input
                type="text"
                placeholder="Payment"
                value={paymentTerm}
                onChange={(e) => setPaymentTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%' }}

              /></div></th>

              <th><div style={{ textAlign: 'center', width: '100%' }}><input
                type="text"
                placeholder="Custmoer"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%', padding: "7px", borderRadius: "3px" }}

              /></div></th>
            
              <th><div style={{ width: '100%' }}>
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={['Start Date', 'End Date']} className="my-custom-class" />
                </Space></div> </th>

                <th><div style={{ textAlign: 'center', width: '100%' }}><input
                type="text"
                placeholder="Payment"
                value={selectedPaymentType}
                onChange={(e) => setSelectedPaymentType(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%' }}

              /></div></th>

             
              <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Amount"
                value={AmountTerm}
                onChange={(e) => setAmountTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%' }}

              /> </div></th>
              <th><div style={{ width: '100%' }}>
              <input
                type="text"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%' }}

              />
               </div></th>
              <th><div style={{ width: '100%' }}> <input
                type="text"
                placeholder="Created By"
                value={createdByTerm}
                onChange={(e) => setCreatedByTerm(e.target.value)}
                className="search-input"
                style={{ border: 'none', width: '100%' }}

              /></div></th>

              <th><div style={{
                width: '100%'
              }}><input
                  type="text"
                  placeholder="Status"
                  value={statusTerm}
                  onChange={(e) => setStatusTerm(e.target.value)}
                  className="search-input"
                  style={{ border: 'none', width: '100%' }}

                /></div></th>
                              <th><div style={{ width: '100%' }}></div></th>
            </tr>
            <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
              
           
              <th>Payment #</th>
              <th>Customer</th>
              <th>Date</th>
              <th>Payment type</th>
              <th>Amount</th>
              <th>Description</th>
              <th>Created by</th>
              <th>Status</th>
              {checkAccess('edit_payment_collection')&&<th>Action</th>}
            </tr>
          </thead>
          <tbody>
  {productData && productData.length > 0 ? (
    productData.reverse().map((item, index) => (
      <tr key={index}>
        <td>
          <input type="checkbox" checked={selectedIds.includes(item.id)} onChange={() => handleCheckboxChange(item.id)} />
        </td>
        <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{item?.paymentno}</td>
        <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{item?.cName}</td>
        <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{dayjs(item?.date).format('DD-MM-YYYY')}</td>
        <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{item?.paymenttypeName}</td>
        <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{item?.amount}</td>
        <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{item?.description}</td>
        <td style={item?.yetscreen ? { fontWeight: 'bold', fontSize: '1em', color: '#333' } : {}}>{item?.created_by}</td>
        <td>
          {checkAccess("read_payment_collection") ? (
            <Link to={{ pathname: `view/${item?.id}` }}>
              <button className={`${getStatusClass(item?.paymentstatus)}`}>
                {item?.paymentstatus}
              </button>
            </Link>
          ) : (
            <button className={`${getStatusClass(item?.paymentstatus)}`} style={{ pointerEvents: "none", cursor: "default" }}>
              {item?.paymentstatus}
            </button>
          )}
        </td>
        {checkAccess('edit_payment_collection') && (
          <td>
            <Link to={{ pathname: `addpaymentcollection/${item?.id}` }}>
              <EditIcon />
            </Link>
          </td>
        )}
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={9} style={{ textAlign: 'center', fontStyle: 'italic' }}>
        No data available
      </td>
    </tr>
  )}
</tbody>

        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
