import React from 'react'
import './newDashboard.scss';
import DashboardHeader from './dashboardHeader';
import AssignedSection from './assignedSection';
import DashboardCard from './dashboardCard';
import ThreeColumnSection from './threeColumnSection';
export default function NewDashboard() {
  return (
    <div>
      <DashboardHeader/>
      <div className='child-page-alignment'>
        <AssignedSection/>
        <DashboardCard/>
        <ThreeColumnSection/>
      </div>
    </div>
  )
}
