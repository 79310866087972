import React, { useState } from "react";
import "./prioritytable.scss";

export default function Prioritytable({ productData }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [showTotalCount, setShowTotalCount] = useState(false); // State to toggle total count display

  const totalRecords = productData?.length || 0;
  const totalPages = Math.ceil(totalRecords / recordsPerPage);
  const startIndex = (currentPage - 1) * recordsPerPage;
  const currentRecords = productData?.slice(startIndex, startIndex + recordsPerPage);

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleRowsPerPageChange = (e) => {
    const newRecordsPerPage = Number(e.target.value);
    setRecordsPerPage(newRecordsPerPage);
    setCurrentPage(1); // Reset to first page
  };

  // Function to toggle Total Count display
  const handleTotalCountClick = () => {
    setShowTotalCount(true);
  };

  return (
    <div>
      <div className="account-table-design">
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>Code</th>
              <th>Category</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords?.map((i) => (
              <tr key={i.id}>
                <td>{i.name}</td>
                <td>{i.productCode}</td>
                <td>{i.categoryName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-controls">
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* Total Count Section */}
          <span
            className="total-count"
            onClick={handleTotalCountClick}
            style={{ cursor: "pointer", color: "#428bca" }}
          >
            Total Count
          </span>
          {showTotalCount && (
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              {totalRecords}
            </span>
          )}
          <span className="separator">|</span>
          <span className="rows-per-page">
            Show{" "}
            <select
              value={recordsPerPage}
              onChange={handleRowsPerPageChange}
              className="rows-dropdown"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            Rows per page
          </span>
        </div>

        <div>
          <button
            onClick={handlePrevious}
            disabled={currentPage === 1}
            className="pagination-button previous"
          >
            &lt;
          </button>
          <span className="range-display">
            {`${startIndex + 1}-${Math.min(startIndex + recordsPerPage, totalRecords)}`}
          </span>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className="pagination-button next"
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
}
