import React, { useState, useEffect } from 'react';
import 'moment/locale/it.js';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import PlusIcon from '../../../assets/icons/plus.svg';
import CancelIcon from '../../../assets/icons/Cancel.svg';
import './lead.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Leadstatus from '../../leadstatus';
import dayjs from 'dayjs';
import GlobalButton from '../../../shared/globalButton';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';
import { checkAccess } from '../../../services/checkFeature';



export default function AddEditLead() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [leadSource, setLeadSource] = useState([]);
    const [LeadIndustry, setLeadIndustry] = useState([]);
    const [pricegroup, setPriceGroup] = useState([]);
    const [newNotification, setNewNotification] = useState([]); 
    const [place,setPlaceofSupply]=useState([]);

    const [formData, setFormData] = useState({
        name: '',
        leadsource: '',
        leadindustry: '',
        pricegroup: '',
        nameId:null,
        address: '',
        title: '',
        city: '',
         countryId: null,
         countryName: '',
         stateName: '',
        stateId: null,
        cityId: null,
        area: '',
        pincode: '',
        latitude:null,
        longitude:null,
        email: '',
        contactno: '',
        ownername: '',
        partnername: '',
        ed: '',
        ewc: '',
        turnover: '',
        leadstatus: 'NEW',
        leadnotification: [],
        gstNo:'',
        gsttype:'',
        pos:'',
        posId:null,
        yetscreen:true,
    });
    const [TurnOver, setTurnOver] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [cityOptions, setCityOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const options = [
        { value: '5 Lakh+', label: '5 Lakh+' },
        { value: '3 to 5 Lakh', label: '3 to 5 Lakh' },
        { value: '1.5 to 3 Lakh', label: '1.5 to 3 Lakh' },
        { value: '1.5 Lakh and above', label: '1.5 Lakh and above' },

    ];
    const [gstTypes, setGstTypes] = useState([
        { label: 'Registered Business', value: 'registered' },
        { label: 'Unregistered Business', value: 'unregistered' },
        { label: 'Overseas', value: 'overseas' },
      ]);


    useEffect(() => {
        getData();
        setTurnOver(options);
        fetchCountry();
        fetchState();
        fetchCity();
        placeofsupply();

    }, []);

    const getData = async () => {
        try {
            const response = await fetchData(`leadSource`);
            if (response?.data) {
                const formattedleadsource = response.data.map(i => ({
                    label: i?.name,
                    key: 'name',
                    value: { name: i?.name, }
                }));
                setLeadSource(formattedleadsource);
            }
            const responsee = await fetchData(`leadIndustry`);
            if (responsee?.data) {
                const leadindustry = responsee.data.map(i => ({
                    label: i?.name,
                    key: 'leadindustry',
                    value: { name: i?.name }
                }));
                setLeadIndustry(leadindustry);
            }
            const responseee = await fetchData(`pricegroup`);
            if (responseee?.data) {
                const pricegroup = responseee.data.map(i => ({
                    label: i?.name,
                    key: 'pricegroup',
                    value: { name: i?.name }
                }));
                setPriceGroup(pricegroup);
            }

            if (id) {
                const response = await fetchsingleData("lead", id);
                if (response?.data) {
                    const fetchedData = response.data;

                    setFormData(fetchedData); // Update state with fetched data
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const placeofsupply = async () => {
        try {
          const response = await fetchData("state");
    
          if (response?.data) {
            setPlaceofSupply(
              response?.data.map((i) => {
                return {
                  label: i?.stateName,
                  key: "state",
                  value: { Id: i?.id, stateName: i?.stateName },
                };
              })
            );
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    

    const fetchCountry = async () => {
        try {
          const response = await fetchData("country");
    
          if (response?.data) {
            setCountryOptions(
              response?.data.map((i) => {
                return {
                  label: i?.countryName,
                  key: "country",
                  value: { countryId: i?.id, countryName: i?.countryName },
                };
              })
            );
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      const fetchState = async (countyId) => {
        try {
          const response = await fetchData("state", { countryId: countyId });
    
          if (response?.data) {
            setStateOptions(
              response?.data.map((i) => {
                return {
                  label: i?.stateName,
                  key: "state",
                  value: { stateId: i?.id, stateName: i?.stateName },
                };
              })
            );
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      const fetchCity = async (stateId) => {
        try {
          const response = await fetchData("city", { stateId: stateId });
    
          if (response?.data) {
            setCityOptions(
              response?.data.map((i) => {
                return {
                  label: i?.cityName,
                  key: "city",
                  value: { cityId: i?.id, city: i?.cityName },
                };
              })
            );
          }
    
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validationCheck(true)) {
            try {
                if (id) {
                    patchData(`lead/${id}`, formData).then(data => {
                        navigate(-1);
                        console.log('API response:', data);
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    });
                } else {
                    postData('lead', formData).then(data => {
                        navigate(-1);
                        console.log('API response:', data);
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    });

                }
            } catch (error) {
                console.error('Error saving data:', error);
                // Handle error
            }
        }
    };

    const selectChanged = (e) => {
        setFormData(prevState => ({
            ...prevState,

            ['turnover']: e.value,
        }));
        if (errors.isSubmit) validationCheck();
    };
    const selectindustryChanged = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [`leadindustry`]: e.value?.name,


        }));
        if (errors.isSubmit) validationCheck();
    };

    const selectCustChanged = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [`leadsource`]: e?.value?.name,

        }));
        if (errors.isSubmit) validationCheck();
    };
    const SelectChanged = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [`pricegroup`]: e?.value?.name,

        }));
        if (errors.isSubmit) validationCheck();
    };


    const toggleSwitch = (checked) => {
        setFormData({ ...formData, status: checked });
    };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (errors.isSubmit) validationCheck();
    };

    const validationCheck = (isSubmit) => {
        const validationErrors = {};

        if (!formData.gsttype) {
            validationErrors.gsttype = 'GST Type is required';
        } else if (formData.gsttype === 'registered') {
            if (!formData.gstNo) validationErrors.gstNo = 'GST No is required for registered businesses';
            if (!formData.pos) validationErrors.pos = 'Place of Supply is required for registered businesses';
        } else if (formData.gsttype === 'unregistered') {
            if (!formData.pos) validationErrors.pos = 'Place of Supply is required for unregistered businesses';
        }
        setErrors(validationErrors);

        if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

        setErrors(validationErrors);
        return Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0;
    };

const selectgsttype = (selectedOption) => {
   
    setFormData((prevData) => ({
      ...prevData,
      gsttype: selectedOption.value, 
    }));
  };
  const selectpostype = (selectedOption) => {
   
    setFormData((prevData) => ({
      ...prevData,
      pos: selectedOption.value.stateName,
      posId:selectedOption.value.Id, 
    }));
  };
  

    return (
        <div className='page-24'>
            {/* <div className='page-header add-news-alignment'>
                <strong> <span>Lead {'>'} Create</span></strong>
                <div className='two-button-alignment'>
                    <div onClick={handleSubmit} ><GlobalButton text="Save" /></div>
                    <Link to="/lead"> <GlobalButton text="Discard" outline /></Link>
                </div>
            </div> */}

            <div className='add-header-design'>
                <div>
                    <h2>Add Lead</h2>
                    <Breadcumbs activePath="Dashboard" pageName="Add Lead" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/lead">
                        <button className='light-button'>
                            <img src={CancelIcon} alt="CancelIcon" />
                            Discard
                        </button>
                    </Link>
                    <button onClick={handleSubmit}>
                        Save
                    </button>
                </div>
            </div>

            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <h2>
                        Profile Info
                        </h2>
                        <div className='three-col-grid'>
                        <Input label='Name' name='name' value={formData.name} onChange={handleInputChange} placeholder='Lead Name' type='text' errors={errors.name} required />
                        <SelectBox label='Lead Source' name='leadsource' value={leadSource?.find(i => i.value.name === formData.leadsource)} onChange={selectCustChanged} options={leadSource} placeholder='Select Lead Source' errors={errors.unit} required />
                        <SelectBox label='Lead Industry' name='leadindustry' value={LeadIndustry?.find(i => i.value.name === formData.leadindustry)} onChange={selectindustryChanged} options={LeadIndustry} placeholder='Select Lead Industry' errors={errors.unit} required />
                        <SelectBox label='Price Group' name='pricegroup' value={pricegroup?.find(i => i.value.name === formData.pricegroup)} onChange={SelectChanged} options={pricegroup} placeholder='Select Price Group' errors={errors.unit} required />
                        </div>
                    </div>
                    <div className='add-news-form-design-customer'>
            <h2>GST Details</h2>
            <div className='two-col-grid'>
              <div className='bottom-alignment'>
                <SelectBox
                  label={
                    <span>
                      GST type<span className="required-star">*</span>
                    </span>
                  }

                  name='gsttype'
                  value={gstTypes.find((option) => option.value === formData.gsttype)}  options={gstTypes}  onChange={(e) => selectgsttype(e)}
                  placeholder='GST Type'
                />
                {errors.gsttype && <span className="error">{errors.gsttype}</span>}
              </div>
              <div className='bottom-alignment'>
                {formData.gsttype === 'registered' && (
                  <Input
                    label={
                      <span>
                        GST no <span className="required-star">*</span>
                      </span>
                    }

                    name='gstNo'
                    value={formData.gstNo}
                    onChange={handleInputChange}
                    placeholder='GST No.'
                    type='text'
                  />

                )}

                {errors.gstNo && <span className="error">{errors.gstNo}</span>}
              </div>
              <div className='Place of Supply'>
                {(formData.gsttype === 'registered' || formData.gsttype === 'unregistered') && (
                  <SelectBox

                    label={
                      <span>
                        Place of Supply <span className="required-star">*</span>
                      </span>
                    }
                    name='pos'
                    value={place.find(i => i?.value.Id === formData.posId)}
                    options={place}
                    onChange={(e) => selectpostype(e)}
                    placeholder='Place of Supply'
                  />
                )}
                {errors.pos && <span className="error">{errors.pos}</span>}
              </div>
            </div>
          </div>

                    <div className='new-white-box-design'>
                        <h2>
                        Primary Contact Details
                        </h2>
                        <div className=''>
                            <div className='pb-3'>
                                <label className='input-label'>Address</label>
                                <textarea label='Address' name='address' value={formData.address} onChange={handleInputChange} placeholder='Address' type='text' errors={errors.address} required ></textarea>
                            </div>
                        </div>
                        <div className='three-col-grid'>
                        <Input label='Title' name='title' value={formData.title} onChange={handleInputChange} placeholder='Title' type='text' errors={errors.title} required />
                        <Input label='Pincode' name='pincode' value={formData.pincode} onChange={handleInputChange} placeholder='Pincode' type='text' errors={errors.pincode} required />
                        {/* <Input label='Country' name='country' value={formData.country} onChange={handleInputChange} placeholder='Country' type='text' errors={errors.country} required /> */}
                        <div className='bottom-alignment'>
                <SelectBox
                  label={
                    <span>
                      Country <span className="required-star">*</span>
                    </span>
                  }
                  name="countryName"
                  value={countryOptions.find(
                    (i) => i.value.countryId === formData.countryId
                  )}
                  options={countryOptions}
                  placeholder="Select Country"
                  onChange={(selectedOption) => {
                    fetchState(selectedOption?.value?.countryId);

                    setFormData((prevState) => ({

                      ...prevState,
                      countryId: Number(selectedOption?.value?.countryId),
                      countryName: selectedOption?.value?.countryName,
                    }));
                  }}


                />

                {errors.countryId && <p className="error">{errors.countryId}</p>}

              </div>
                        {/* <Input label='State' name='state' value={formData.state} onChange={handleInputChange} placeholder='State' type='text' errors={errors.state} required /> */}
                        <div className='bottom-alignment'>
                <SelectBox
                  label={
                    <span>
                      State <span className="required-star">*</span>
                    </span>
                  }
                  name="stateName"
                  value={stateOptions.find(
                    (i) => i.value.stateId === formData.stateId
                  )}
                  options={stateOptions}
                  placeholder="Select State"
                  onChange={(selectedOption) => {
                    fetchCity(selectedOption?.value?.stateId);

                    setFormData((prevState) => ({
                      ...prevState,
                      stateId: Number(selectedOption?.value?.stateId),
                      stateName: selectedOption?.value?.stateName,
                    }));
                  }}

                />

                {errors.stateId && <p className="error">{errors.stateId}</p>}
              </div>

              <div className='bottom-alignment'>
                <SelectBox
                  label={
                    <span>
                      City <span className="required-star">*</span>
                    </span>
                  }
                  name="city"
                  value={cityOptions.find(
                    (i) => i.value.cityId === formData.cityId
                  )}
                  options={cityOptions}
                  placeholder="Select City"
                  onChange={(selectedOption) => {
                    // fetchArea(selectedOption?.value?.cityId);

                    setFormData((prevState) => ({
                      ...prevState,
                      cityId: Number(selectedOption?.value?.cityId),
                      city: selectedOption?.value?.city,
                    }));
                  }}

                />
                {errors.cityId && <p className="error">{errors.cityId}</p>}

              </div>
                        {/* <Input label='City' name='city' value={formData.city} onChange={handleInputChange} placeholder='City' type='text' errors={errors.city} required /> */}
                        <Input label='Area' name='area' value={formData.area} onChange={handleInputChange} placeholder='Area' type='text' errors={errors.area} required />
                        <Input label='Latitude' name='latitude' value={formData.latitude} onChange={handleInputChange} placeholder='Latitude' type='text' errors={errors.latitude} required />
                        <Input label='Longitude' name='longitude' value={formData.longitude} onChange={handleInputChange} placeholder='Longitude' type='text' errors={errors.longitude} required />
                        <Input label='Email' name='email' value={formData.email} onChange={handleInputChange} placeholder='example@examole.com' type='text' errors={errors.email} required />
                        <Input label='Contact No.' name='contactno' value={formData.contactno} onChange={handleInputChange} placeholder='Contact No' type='text' errors={errors.contactno} required />

                        </div>
                    </div>
                    <div className='new-white-box-design'>
                        <h2>
                        Primary Contact Details
                        </h2>
                        <div className='two-col-grid'>
                        <Input label='Owner Name' name='ownername' value={formData.ownername} onChange={handleInputChange} placeholder='Owner Name' type='text' errors={errors.ownername} required  disabled={!checkAccess("can_change_lead_owner")}/>
                        <Input label='Partner Name' name='partnername' value={formData.partnername} onChange={handleInputChange} placeholder='Partner Name' type='text' errors={errors.partnername} required />

                        </div>
                    </div>
                    <div className='new-white-box-design'>
                        <h2>
                        Counter category
                        </h2>
                        <div className='pb-3'>
                            <label className='input-label'>Existing Distributor</label>
                            <textarea name='ed' value={formData.ed} onChange={handleInputChange} placeholder='Enter Existing Distributor' type='text' errors={errors.ed} required  ></textarea>
                        </div>
                        <div className=''>
                            <label className='input-label'>Existing Working Company</label>
                            <textarea name='ewc' value={formData.ewc} onChange={handleInputChange} placeholder='Enter Existing Working Company' type='text' errors={errors.ewc} required  ></textarea>
                         
                        </div>
                    </div>
                    <div className='new-white-box-design'>
                    <SelectBox label='Turn Over' name='turnover' value={TurnOver?.find(i => i.value === formData.turnover)} onChange={selectChanged} options={TurnOver} placeholder='Select Turn Over' errors={errors.unit} required />

                    </div>
                </div>
                <div>
                    <StatusBox/>
                </div>
            </div>
        </div >


    );
}
