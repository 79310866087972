import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

export default function DealPiePiplinetagesatusComponent({ customerList }) {
    const [chartData, setChartData] = useState([]);
    const [visibleData, setVisibleData] = useState([]); // Data to display in the chart for the current page
    const [dataType, setDataType] = useState("order"); // Default data type
    const [currentPage, setCurrentPage] = useState(1); // Page tracker
    const itemsPerPage = 10; // Number of items per page

    // Update chart data based on the selected data type and customer list
    useEffect(() => {
        formatChartData(dataType);
    }, [dataType, customerList]);

    // Update visible data when pagination or chartData changes
    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setVisibleData(chartData.slice(startIndex, endIndex));
    }, [chartData, currentPage]);

    // Format chart data based on the selected data type
    const formatChartData = (type) => {
        const fieldMapping = {
            deal: "dealCount",
            won: "WON",
            lost: "LOST",
            inprogress: "InProgress",
            ratio: "Ration",
        };

        const selectedField = fieldMapping[type];

        const formattedData = customerList.map((item) => ({
            name: item?.productName || "Unknown Product",
            y: item[selectedField] || 0,
        }));

        setChartData(formattedData);
        setCurrentPage(1); // Reset to the first page when data type changes
    };

    // Handle data type selection
    const handleChange = (event) => {
        setDataType(event.target.value);
    };

    // Pagination handlers
    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    // Highcharts configuration
    const options = {
        chart: {
            type: "pie",
        },
        title: {
            text: `Customer Data by ${dataType.charAt(0).toUpperCase() + dataType.slice(1)}`,
        },
        tooltip: {
            pointFormat: "<b>{point.y}</b> ({point.percentage:.1f}%)",
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: {point.y} ({point.percentage:.1f}%)",
                    style: {
                        fontSize: "12px",
                        fontFamily: "Verdana, sans-serif",
                    },
                },
            },
        },
        series: [
            {
                name: `${dataType.charAt(0).toUpperCase() + dataType.slice(1)} Count`,
                colorByPoint: true,
                data: visibleData,
            },
        ],
    };

    // Calculate pagination details
    const totalPages = Math.ceil(chartData.length / itemsPerPage);
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(currentPage * itemsPerPage, chartData.length);

    return (
        <Box p={4}>
            <FormControl fullWidth margin="normal">
                <InputLabel id="data-type-label">Select Data Type</InputLabel>
                <Select
                    labelId="data-type-label"
                    value={dataType}
                    onChange={(e) => setDataType(e.target.value)}
                    >
                        <MenuItem value="deal">Deal</MenuItem>
                        <MenuItem value="ratio">Ratio</MenuItem>
                        <MenuItem value="won">Won Deals</MenuItem>
                        <MenuItem value="lost">Lost Deals</MenuItem>
                        <MenuItem value="inprogress">In Progress</MenuItem>
                </Select>
            </FormControl>
            <>
                <HighchartsReact highcharts={Highcharts} options={options} />
                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </Button>
                    <Typography>
                        Showing {rangeStart} - {rangeEnd} of {chartData.length}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </Button>
                </Box>
            </>
        </Box>
    );
}
