import React from 'react'

export default function ViewIconFill() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.99997 11.9987C10.6562 11.9987 11.9988 10.6561 11.9988 8.99985C11.9988 7.34362 10.6562 6.00098 8.99997 6.00098C7.34374 6.00098 6.0011 7.34362 6.0011 8.99985C6.0011 10.6561 7.34374 11.9987 8.99997 11.9987Z" fill="#888888"/>
<path d="M17.4502 7.06527C16.2873 5.17147 13.6423 1.99414 9.00004 1.99414C4.35777 1.99414 1.71275 5.17147 0.549929 7.06527C-0.183228 8.25111 -0.183228 9.74947 0.549929 10.9353C1.71275 12.8291 4.35777 16.0065 9.00004 16.0065C13.6423 16.0065 16.2873 12.8291 17.4502 10.9353C18.1833 9.74947 18.1833 8.25111 17.4502 7.06527ZM9.00004 13.4986C6.5157 13.4986 4.50171 11.4846 4.50171 9.00029C4.50171 6.51595 6.5157 4.50196 9.00004 4.50196C11.4844 4.50196 13.4984 6.51595 13.4984 9.00029C13.4959 11.4836 11.4834 13.4961 9.00004 13.4986Z" fill="#888888"/>
</svg>

  )
}
