import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './contexts/UserContext';
import store from './redux/reduxstore';
import { Provider } from 'react-redux';
import { useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import  { persistor } from './redux/reduxstore';

import { init } from '@elastic/apm-rum';
import { LoaderProvider } from './shared/components/loader/LoaderProvider';


const apm = init({
  serviceName: 'saleseasy-web', // Replace with your app's name
  serverUrl: process.env.REACT_APP_APM_URL, // URL of your APM Server
  environment: process.env.REACT_APP_APM_ENV, // Or 'development' based on your environment
  // Optional: additional configuration (e.g., logging, sample rate)
});
apm.startTransaction();
const root = ReactDOM.createRoot(document.getElementById('root'));
const currentRoute = window.location.pathname;
root.render(

  // <React.StrictMode>
  <UserProvider currentRoute={currentRoute}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LoaderProvider>
          <App />
        </LoaderProvider>
      </PersistGate>
    </Provider>
  </UserProvider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
