import { useEffect, useState, useContext } from 'react';
import './dashboard.scss';
import { fetchData,fetchorderdisptchsummary } from '../../services/apiService';
import { UserContext } from '../../contexts/UserContext';
import { useSelector } from 'react-redux';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import VisitTable from './visitTable';
import ActiveOrder from './activeOrder';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { totalopenorder, totalpendingorder, totalcancelorder, totalcloseorder, setTotalorder } from '../../redux/auth';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';

export default function Dashboard() {
  const [orders, setOrders] = useState([]);
  const [monthlyOrderCounts, setMonthlyOrderCounts] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [monthlyApprovedCounts, setMonthlyApprovedCounts] = useState([]);
  const [payment, setPayment] = useState([]);
  const [monthlyPendingCount, setMonthlyPendingCount] = useState([]);
  const [openorder, setOpenOrder] = useState([]);
  const [monthlyOpenOrder, setMonthlyOpenOrder] = useState([]);
  const [cancelorder, setCancelOrder] = useState([]);
  const [monthlyCancelOrder, setMonthlyCancelOrder] = useState([]);
  const [closeorder, setCloseOrder] = useState([]);
  const [monthlyCloseOrder, setMonthlyCloseOrder] = useState([]);
  const [dispatchorder, setDispatchOrder] = useState([]);
  const [monthlyDispatchOrder, setMonthlyDispatchOrder] = useState([]);
  const [pendingorder, setPendingOrder] = useState([]);
  const [monthlyPendingOrder, setMonthlyPendingOrder] = useState([]);
  const [wondeal, setWondeal] = useState([]);
  const [monthlyWonCount, setMonthlyWonCount] = useState([]);
  const [monthlyTotalCount, setMonthlyTotalCount] = useState([]);
  const [lostdeal, setLostdeal] = useState([]);
  const [monthlyLostCount, setMonthlyLostCount] = useState([]);
  const [pdeal, setPdeal] = useState([]);
  const [monthlyPCount, setMonthlyPCount] = useState([]);
  const [lead, setLead] = useState([]);
  const [monthlyLeadCount, setMonthlyLeadCount] = useState([]); //qualifide
  const [leadnew, setLeadnew] = useState([]);//new
  const [monthlyLeadCountnew, setMonthlyLeadCountnew] = useState([]);
  const [leaddq, setLeaddq] = useState([]);//DISQUALIFIED
  const [monthlyLeadCountdq, setMonthlyLeadCountdq] = useState([]);
  const [leadni, setLeadni] = useState([]);//NOT INTERESTED
  const [monthlyLeadCountni, setMonthlyLeadCountni] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [monthlycustomer, setMonthlyCustomer] = useState([]);//customer growth
  const [timeFilter, setTimeFilter] = useState("week"); // Track active time filter
  const [deals, setDeals] = useState([]); // All deals data
  const [tableFilter, setTableFilter] = useState("all");
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [filteredDeals, setFilteredDeals] = useState([]);
  const [filteredDeal, setFilterdeal] = useState([]);
  const [filteredVisits, setFilteredVisits] = useState([]);
  const [response,setResponse]=useState([]);



  const [chartOptions, setChartOptions] = useState({
    chart: { type: "column" },
    title: { text: "Deals Overview" },
    xAxis: { categories: [], title: { text: "Time Period" } },
    yAxis: { title: { text: "Number of Deals" } },
    series: [],
  });
  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const response = await fetchData("deal");
        if (response?.data) {
          setDeals(response.data);
        }
      } catch (error) {
        console.error("Error fetching deals:", error);
      }
    };

    fetchDeals();
  }, []);
  useEffect(() => {
    const currentDate = new Date();

    const filterDealsByTime = () => {
      if (timeFilter === "week") {
        const lastWeek = new Date();
        lastWeek.setDate(currentDate.getDate() - 7);
        return deals.filter(
          (deal) =>
            new Date(deal.dealdate) >= lastWeek &&
            new Date(deal.dealdate) <= currentDate
        );
      } else if (timeFilter === "month") {
        const currentYear = new Date().getFullYear();
        return deals.filter((deal) => {
          const dealDate = new Date(deal.dealdate);
          return dealDate.getFullYear() === currentYear;
        });
      } else if (timeFilter === "year") {
        const last7Years = new Date();
        last7Years.setFullYear(currentDate.getFullYear() - 7);
        return deals.filter(
          (deal) => new Date(deal.dealdate) >= last7Years && new Date(deal.dealdate) <= currentDate
        );
      } else {
        return deals;
      }
    };

    setFilteredDeals(filterDealsByTime());
  }, [timeFilter, deals]);
  useEffect(() => {
    const updateChart = () => {
      const categories = [];
      const totalData = [];
      const wonData = [];
      const lostData = [];

      if (timeFilter === "week") {
        // Weekly Data: Use last 7 days
        const last7Days = [...Array(7).keys()].map((i) => {
          const date = new Date();
          date.setDate(date.getDate() - i);
          return date.toLocaleDateString("en-US", { weekday: "short" });
        }).reverse();

        categories.push(...last7Days);

        last7Days.forEach((day) => {
          const dayDeals = filteredDeals.filter(
            (deal) => new Date(deal.dealdate).toLocaleDateString("en-US", { weekday: "short" }) === day
          );

          totalData.push(dayDeals.length);
          wonData.push(dayDeals.filter((deal) => deal.dealStatus === "WON").length);
          lostData.push(dayDeals.filter((deal) => deal.dealStatus === "LOST").length);
        });
      } else if (timeFilter === "month") {
        // Monthly Data: Use month names
        const months = Array.from({ length: 12 }, (_, i) => i); // [0, 1, ..., 11]
        categories.push(...months.map((m) => new Date(0, m).toLocaleString("en-US", { month: "short" })));

        months.forEach((month) => {
          const monthDeals = filteredDeals.filter((deal) => {
            const dealDate = new Date(deal.dealdate);
            return dealDate.getMonth() === month;
          })
          totalData.push(monthDeals.length);
          wonData.push(monthDeals.filter((deal) => deal.dealStatus === "WON").length);
          lostData.push(monthDeals.filter((deal) => deal.dealStatus === "LOST").length);
        });
      } else if (timeFilter === "year") {
        // Yearly Data: Last 7 years
        const currentYear = new Date().getFullYear();
        const last7Years = Array.from({ length: 7 }, (_, i) => currentYear - i).reverse();

        categories.push(...last7Years);

        last7Years.forEach((year) => {
          const yearDeals = filteredDeals.filter(
            (deal) => new Date(deal.dealdate).getFullYear() === year
          );

          totalData.push(yearDeals.length);
          wonData.push(yearDeals.filter((deal) => deal.dealStatus === "WON").length);
          lostData.push(yearDeals.filter((deal) => deal.dealStatus === "LOST").length);
        });
      } else if (timeFilter === "all") {
        // All-time Data
        categories.push("All Time");
        totalData.push(filteredDeals.length);
        wonData.push(filteredDeals.filter((deal) => deal.dealStatus === "WON").length);
        lostData.push(filteredDeals.filter((deal) => deal.dealStatus === "LOST").length);
      }

      // Update Chart Options
      setChartOptions({
        chart: { type: "column" },
        title: { text: "Deals Overview" },
        xAxis: {
          categories,
          title: { text: timeFilter === "week" ? "Days" : "Months" },
        },
        yAxis: { title: { text: "Number of Deals" } },
        series: [
          { name: "Total Deals", type: "column", data: totalData, color: '#283747' },
          { name: "Won Deals", type: "line", data: wonData, color: 'green' },
          { name: "Lost Deals", type: "line", data: lostData, color: 'red' },
        ],
      });
    };

    updateChart();
  }, [filteredDeals, timeFilter]);

  const user = useSelector(state => state.user.user);
  const dispatch = useDispatch();
  useEffect(() => {
    // getData();
    geetingdata();
    getpaymentdata();
    // getorderdata();
    // getDeal();
    // getLead();
    getCustomer();
  }, []);
  const [visitRoutes, setVisitRoutes] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => {
    const visitroutes = async () => {
      try {
        const response = await fetchData("visitroute");
        if (response?.data) {
          setVisitRoutes(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    visitroutes();
  }, []);

  const handleRowClick = (route) => {
    console.log('Selected Data:', route);
    setSelectedData(route);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedData(null);
  };

  const handleOk = async () => {
    setConfirmLoading(true);
    console.log('Clicked OK button');
    setIsModalOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setIsModalOpen(false);
  };
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // const getData = async () => {
  //   try {
  //     const response = await fetchData("order");
  //     if (response?.data) {
  //       setOrders(response.data); 
  //       const filteredOrders = filterOrdersByYear(response.data);
  //       const year = new Date().getFullYear();
  //       const monthlyCounts = countOrdersByMonth(filteredOrders, year);
  //       setMonthlyOrderCounts(monthlyCounts);
  //     }
  //     if (response?.data) {
  //       setPendingOrder(response.data);
  //       setOpenOrder(response.data);
  //       setCloseOrder(response.data);
  //       setCancelOrder(response.data);
  //       setDispatchOrder(response.data);

  //       const currentYear = new Date().getFullYear();
  //       const pendingCounts = countPendingOrdersByMonth(response.data, currentYear);
  //       const openCounts = countOpenOrdersByMonth(response.data, currentYear);
  //       const closeCounts = countCloseOrdersByMonth(response.data, currentYear);
  //       const dispatchCounts = countDispatchOrdersByMonth(response.data, currentYear);
  //       const cancelCounts = countCancelOrdersByMonth(response.data, currentYear);

  //       setMonthlyPendingOrder(pendingCounts);
  //       console.log('pendingCounts', pendingCounts);
  //       setMonthlyOpenOrder(openCounts);
  //       console.log('openCounts', openCounts);
  //       setMonthlyCancelOrder(cancelCounts);
  //       console.log('cancelCounts', cancelCounts);
  //       setMonthlyCloseOrder(closeCounts);
  //       console.log('closeCounts', closeCounts);
  //       setMonthlyDispatchOrder(dispatchCounts);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const getCustomer = async () => {
    try {
      const response = await fetchData("customer");
      if (response?.data) {
        setCustomer(response.data);
        const filteredCustomers = filterCustomersByYear(response.data);
        const year = new Date().getFullYear();
        const monthlyCounts = countCustomersByMonth(filteredCustomers, year);
        setMonthlyCustomer(monthlyCounts);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function filterCustomersByYear(customers) {
    const currentDate = new Date();
    const oneYearAgo = new Date(currentDate);
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    return customers.filter(customer => {
      const customerDate = new Date(customer.createdOn);
      return customerDate >= oneYearAgo && customerDate <= currentDate;
    });
  }

  function countCustomersByMonth(customers, year) {
    const monthlyCounts = [];

    for (let month = 0; month < 12; month++) { // Months are 0-based in JavaScript (0 = January)
      const filteredCustomers = customers.filter(customer => {
        const customerDate = new Date(customer.createdOn); // Assuming registrationDate is the date field
        return (
          customerDate.getFullYear() === year &&
          customerDate.getMonth() === month
        );
      });

      monthlyCounts.push({ month: month + 1, totalCustomers: filteredCustomers.length });
    }

    return monthlyCounts;
  }


  // const getLead = async () => {
  //   try {
  //     const response = await fetchData("lead");
  //     if (response?.data) {
  //       setLead(response.data); // Update state with fetched data
  //       setLeadnew(response.data);
  //       setLeaddq(response.data);
  //       setLeadni(response.data);
  //       const filteredLeads = filterLeadsByYear(response.data);
  //       const year = new Date().getFullYear();
  //       const monthlyCounts = countQualifiedLeadsByMonth(filteredLeads, year);
  //       const monthlyCountsnew = countNewLeadsByMonth(filteredLeads, year);
  //       const monthlyCountsdq = countDisQualifiedLeadsByMonth(filteredLeads, year);
  //       const monthlyCountsni = countNotintrestedLeadsByMonth(filteredLeads, year);
  //       setMonthlyLeadCount(monthlyCounts);
  //       setMonthlyLeadCountnew(monthlyCountsnew);
  //       setMonthlyLeadCountdq(monthlyCountsdq);
  //       setMonthlyLeadCountni(monthlyCountsni);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // function filterLeadsByYear(leads) {
  //   const currentDate = new Date();
  //   const oneYearAgo = new Date(currentDate);
  //   oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
  //   return leads.filter(lead => {
  //     const leadDate = new Date(lead.created_at);
  //     return leadDate >= oneYearAgo && leadDate <= currentDate;
  //   });
  // }
  // function countQualifiedLeadsByMonth(leads, year) {
  //   const monthlyCounts = [];

  //   for (let month = 0; month < 12; month++) {
  //     const filteredLeads = leads.filter(lead => {
  //       const leadDate = new Date(lead.updatedOn);
  //       return (
  //         leadDate.getFullYear() === year &&
  //         leadDate.getMonth() === month &&
  //         lead.leadstatus === "QUALIFIED"
  //       );
  //     });

  //     monthlyCounts.push({ month: month + 1, totalQualifiedLeads: filteredLeads.length });
  //   }
  //   return monthlyCounts;
  // }
  // function countDisQualifiedLeadsByMonth(leads, year) {
  //   const monthlyCounts = [];

  //   for (let month = 0; month < 12; month++) {
  //     const filteredLeads = leads.filter(lead => {
  //       const leadDate = new Date(lead.created_at);
  //       return (
  //         leadDate.getFullYear() === year &&
  //         leadDate.getMonth() === month &&
  //         lead.leadstatus === "DISQUALIFIED"
  //       );
  //     });

  //     monthlyCounts.push({ month: month + 1, totalDisQualifiedLeads: filteredLeads.length });
  //   }
  //   return monthlyCounts;
  // }
  // function countNotintrestedLeadsByMonth(leads, year) {
  //   const monthlyCounts = [];

  //   for (let month = 0; month < 12; month++) {
  //     const filteredLeads = leads.filter(lead => {
  //       const leadDate = new Date(lead.created_at);
  //       return (
  //         leadDate.getFullYear() === year &&
  //         leadDate.getMonth() === month &&
  //         lead.leadstatus === "NOT INTERESTED"
  //       );
  //     });

  //     monthlyCounts.push({ month: month + 1, totalNotintrestedQualifiedLeads: filteredLeads.length });
  //   }

  //   return monthlyCounts;
  // }
  // function countNewLeadsByMonth(leads, year) {
  //   const monthlyCounts = [];

  //   for (let month = 0; month < 12; month++) { // Months are 0-based in JavaScript (0 = January)
  //     const filteredLeads = leads.filter(lead => {
  //       const leadDate = new Date(lead.created_at);
  //       return (
  //         leadDate.getFullYear() === year &&
  //         leadDate.getMonth() === month &&
  //         lead.leadstatus === "NEW"
  //       );
  //     });

  //     monthlyCounts.push({ month: month + 1, totalNewQualifiedLeads: filteredLeads.length });
  //   }


  //   return monthlyCounts;
  // }
  // // const getDeal = async () => {
  // //   try {
  // //     const response = await fetchData("deal");
  // //     if (response?.data) {
  // //       setWondeal(response.data);
  // //       setLostdeal(response.data);
  // //       setPdeal(response.data);

  // //       const filteredDeals = filterDealByYear(response.data);
  // //       const year = new Date().getFullYear();
  // //       const wonCounts = countWonByMonth(filteredDeals, year);
  // //       const LostCounts = countLostByMonth(filteredDeals, year);
  // //       const PCounts = countPByMonth(filteredDeals, year);
  // //       const TotalCounts = countTotalByMonth(filteredDeals, year);

  // //       setMonthlyWonCount(wonCounts);
  // //       setMonthlyLostCount(LostCounts);
  // //       setMonthlyPCount(PCounts);
  // //       setMonthlyTotalCount(TotalCounts);
  // //     }
  // //   } catch (error) {
  // //     console.error("Error fetching data:", error);
  // //   }
  // // };
  // function filterDealByYear(deals) {
  //   const currentDate = new Date();
  //   const oneYearAgo = new Date(currentDate);
  //   oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

  //   return deals.filter(deal => {
  //     const dealDate = new Date(deal.dealdate);
  //     return dealDate >= oneYearAgo && dealDate <= currentDate;
  //   });
  // }

  // function countWonByMonth(deals, year) {
  //   const monthlyCounts = [];

  //   for (let month = 0; month < 12; month++) { // Months are 0-based in JavaScript (0 = January)
  //     const filteredDeals = deals.filter(deal => {
  //       const dealDate = new Date(deal.dealdate);
  //       return (
  //         dealDate.getFullYear() === year &&
  //         dealDate.getMonth() === month &&
  //         deal.dealStatus === "WON" // Filter for 'won' dealStatus
  //       );
  //     });

  //     const totalAmountWon = filteredDeals.reduce((sum, deal) => {
  //       return sum + (parseInt(deal.totalAmount, 10) || 0); // Sum up the totalAmount for "won" deals
  //     }, 0);

  //     monthlyCounts.push({ month: month + 1, totalWonDeals: filteredDeals.length, totalAmountWon: totalAmountWon });
  //   }

  //   return monthlyCounts;
  // }

  // function countLostByMonth(deals, year) {
  //   const monthlyCounts = [];

  //   for (let month = 0; month < 12; month++) { // Months are 0-based in JavaScript (0 = January)
  //     const filteredDeals = deals.filter(deal => {
  //       const dealDate = new Date(deal.dealdate);
  //       return (
  //         dealDate.getFullYear() === year &&
  //         dealDate.getMonth() === month &&
  //         deal.dealStatus === "LOST" // Filter for 'lost' dealStatus
  //       );
  //     });

  //     monthlyCounts.push({ month: month + 1, totalLostDeals: filteredDeals.length });
  //   }

  //   return monthlyCounts;
  // }
  // function countTotalByMonth(deals, year) {
  //   const monthlyCounts = [];

  //   for (let month = 0; month < 12; month++) { // Months are 0-based in JavaScript (0 = January)
  //     const filteredDeals = deals.filter(deal => {
  //       const dealDate = new Date(deal.dealdate);
  //       return dealDate.getFullYear() === year && dealDate.getMonth() === month;
  //     });

  //     // Push month and count, defaulting to 0 if no deals are found
  //     monthlyCounts.push({ month: month + 1, totalDeals: filteredDeals.length || 0 });
  //   }

  //   return monthlyCounts;
  // }
  // function countPByMonth(deals, year) {
  //   const monthlyCounts = [];

  //   for (let month = 0; month < 12; month++) {
  //     const filteredDeals = deals.filter(deal => {
  //       const dealDate = new Date(deal.dealdate);
  //       return (
  //         dealDate.getFullYear() === year &&
  //         dealDate.getMonth() === month &&
  //         deal.dealStatus === "in progress"
  //       );
  //     });

  //     monthlyCounts.push({ month: month + 1, totalPDeals: filteredDeals.length });
  //   }

  //   return monthlyCounts;
  // }
  // function filterDeals(deals, filter) {
  //   const currentDate = new Date();

  //   if (filter === "week") {
  //     const startOfWeek = new Date(currentDate);
  //     startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
  //     return deals.filter(deal => {
  //       const dealDate = new Date(deal.dealdate);
  //       return dealDate >= startOfWeek && dealDate <= currentDate;
  //     });
  //   }

  //   if (filter === "month") {
  //     const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  //     return deals.filter(deal => {
  //       const dealDate = new Date(deal.dealdate);
  //       return dealDate >= startOfMonth && dealDate <= currentDate;
  //     });
  //   }

  //   if (filter === "year") {
  //     const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  //     return deals.filter(deal => {
  //       const dealDate = new Date(deal.dealdate);
  //       return dealDate >= startOfYear && dealDate <= currentDate;
  //     });
  //   }

  //   // Default to "all"
  //   return deals;
  // }
  // const getDeal = async () => {
  //   try {
  //     const response = await fetchData("deal");
  //     if (response?.data) {
  //       const allDeals = response.data;

  //       // Filter deals based on the selected time period
  //       const filteredDeals = filterDeals(allDeals, tableFilter);

  //       // Update the filtered deal state
  //       setWondeal(filteredDeals.filter(deal => deal.dealStatus === "WON"));
  //       setLostdeal(filteredDeals.filter(deal => deal.dealStatus === "LOST"));
  //       setPdeal(filteredDeals.filter(deal => deal.dealStatus === "in progress"));

  //       // Update counts for charts
  //       const year = new Date().getFullYear();
  //       setMonthlyWonCount(countWonByMonth(filteredDeals, year));
  //       setMonthlyLostCount(countLostByMonth(filteredDeals, year));
  //       setMonthlyPCount(countPByMonth(filteredDeals, year));
  //       setMonthlyTotalCount(countTotalByMonth(filteredDeals, year));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching deal data:", error);
  //   }
  // };


 
  useEffect(() => {
    async function fetchFilteredData() {
      try {
        const filter= tableFilter;
        const result = await fetchorderdisptchsummary(`dashboard/timewisefilter`,{filter});
        // const result = await response?.json();
  
        if (result?.data) {
          setFilteredLeads(result.data.leads || []);
          setFilterdeal(result.data.deals || []);
          setFilteredVisits(result.data.visits || []);
        }
      } catch (error) {
        console.error('Error fetching filtered data:', error);
      }
    }
  
    fetchFilteredData();
  }, [tableFilter]);
  


  const geetingdata = async () => {
    try {
      const response = await fetchData("expense");
      if (response?.data) {
        setExpenses(response.data);
        const currentYear = new Date().getFullYear();
        const approvedCounts = countApprovedByMonth(response.data, currentYear);
        setMonthlyApprovedCounts(approvedCounts);

      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getpaymentdata = async () => {
    try {
      const response = await fetchData("paymentcollection"); // assuming your endpoint is 'paymentcollection'
      if (response?.data) {
        setPayment(response.data); // Update state with fetched data

        const currentYear = new Date().getFullYear();
        const pendingCounts = countPendingPaymentsByMonth(response.data, currentYear);
        setMonthlyPendingCount(pendingCounts);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // const getorderdata = async () => {
  //   try {
  //     const response = await fetchData("order");
  //     if (response?.data) {
  //       setPendingOrder(response.data);
  //       setOpenOrder(response.data);
  //       setCloseOrder(response.data);
  //       setCancelOrder(response.data);
  //       setDispatchOrder(response.data);

  //       const currentYear = new Date().getFullYear();
  //       const pendingCounts = countPendingOrdersByMonth(response.data, currentYear);
  //       const openCounts = countOpenOrdersByMonth(response.data, currentYear);
  //       const closeCounts = countCloseOrdersByMonth(response.data, currentYear);
  //       const dispatchCounts = countDispatchOrdersByMonth(response.data, currentYear);
  //       const cancelCounts = countCancelOrdersByMonth(response.data, currentYear);

  //       setMonthlyPendingOrder(pendingCounts);
  //       console.log('pendingCounts', pendingCounts);
  //       setMonthlyOpenOrder(openCounts);
  //       console.log('openCounts', openCounts);
  //       setMonthlyCancelOrder(cancelCounts);
  //       console.log('cancelCounts', cancelCounts);
  //       setMonthlyCloseOrder(closeCounts);
  //       console.log('closeCounts', closeCounts);
  //       setMonthlyDispatchOrder(dispatchCounts);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };
  function filterOrdersByYear(orders) {
    const currentDate = new Date();
    const oneYearAgo = new Date(currentDate);
    oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

    return orders.filter(order => {
      const orderDate = new Date(order.orderDate);
      return orderDate >= oneYearAgo && orderDate <= currentDate;
    });
  }

  function filterOrdersByMonth(orders, year, month) {
    return orders.filter(order => {
      const orderDate = new Date(order.orderDate);
      return orderDate.getFullYear() === year && orderDate.getMonth() === month - 1;
    });
  }

  function countOrdersByMonth(orders, year) {
    const monthlyCounts = [];

    for (let month = 1; month <= 12; month++) {
      const filteredOrders = filterOrdersByMonth(orders, year, month);
      monthlyCounts.push({ month: month, totalOrders: filteredOrders.length });
    }

    return monthlyCounts;
  }
  function filterExpensesByMonth(expenses, year, month) {
    return expenses.filter(expense => {
      const expenseDate = new Date(expense.date);
      return expenseDate.getFullYear() === year && expenseDate.getMonth() === month - 1;
    });
  }

  function countApprovedByMonth(expense, year) {
    const monthlyCounts = [];

    for (let month = 1; month <= 12; month++) {
      const filteredExpenses = filterExpensesByMonth(expense, year, month);
      const approvedCount = filteredExpenses.reduce((count, expense) => {
        return count + (parseInt(expense.approved, 10) || 0);
      }, 0);
      monthlyCounts.push({ month: month, approvedCount: approvedCount });
    }
    return monthlyCounts;

  }
  function filterPaymentsByMonth(payments, year, month) {
    return payments.filter((payments) => {
      const paymentDate = new Date(payments.date);
      return paymentDate.getFullYear() === year && paymentDate.getMonth() === month - 1;
    });
  }

  function countPendingPaymentsByMonth(payments, year) {
    const monthlyCounts = [];

    for (let month = 1; month <= 12; month++) {
      const filteredPayments = filterPaymentsByMonth(payments, year, month);

      const pendingCount = filteredPayments.reduce((count, payment) => {
        if (payment.paymentstatus === "pending") { // Check if payment status is "pending"

          return count + (parseInt(payment.amount, 10) || 0); // Sum the amount if status is "pending"
        }
        return count;
      }, 0);

      monthlyCounts.push({ month: month, pendingCount: pendingCount });
    }

    return monthlyCounts;
  }

  function filterPandingOrderByMonth(payments, year, month) {
    return payments.filter((payments) => {
      const paymentDate = new Date(payments.date);
      return paymentDate.getFullYear() === year && paymentDate.getMonth() === month - 1;
    });
  }

  function countPendingOrdersByMonth(orders, year) {
    const monthlyCounts = [];

    for (let month = 1; month <= 12; month++) {
      const filteredOrders = filterOrdersByMonth(orders, year, month);

      // Filter orders with "pending" status
      const pendingOrders = filteredOrders.filter(order => order.orderStatus === "Pending");

      // Count the pending orders
      monthlyCounts.push({ month: month, pendingCount: pendingOrders.length });
    }

    return monthlyCounts;
  }
  function countCancelOrdersByMonth(orders, year) {
    const monthlyCounts = [];

    for (let month = 1; month <= 12; month++) {
      const filteredOrders = filterOrdersByMonth(orders, year, month);

      // Filter orders with "pending" status
      const cancelOrders = filteredOrders.filter(order => order.orderStatus === "Cancel");

      // Count the pending orders
      monthlyCounts.push({ month: month, cancelCount: cancelOrders.length });
    }

    return monthlyCounts;
  }
  function countOpenOrdersByMonth(orders, year) {
    const monthlyCounts = [];

    for (let month = 1; month <= 12; month++) {
      const filteredOrders = filterOrdersByMonth(orders, year, month);

      // Filter orders with "pending" status
      const openOrders = filteredOrders.filter(order => order.orderStatus === "Open");

      // Count the pending orders
      monthlyCounts.push({ month: month, openCount: openOrders.length });
    }

    return monthlyCounts;
  }
  function countCloseOrdersByMonth(orders, year) {
    const monthlyCounts = [];

    for (let month = 1; month <= 12; month++) {
      const filteredOrders = filterOrdersByMonth(orders, year, month);

      // Filter orders with "pending" status
      const closeOrders = filteredOrders.filter(order => order.orderStatus === "Close");

      // Count the pending orders
      monthlyCounts.push({ month: month, closeCount: closeOrders.length });
    }

    return monthlyCounts;
  }
  function countDispatchOrdersByMonth(orders, year) {
    const monthlyCounts = [];

    for (let month = 1; month <= 12; month++) {
      const filteredOrders = filterOrdersByMonth(orders, year, month);

      // Filter orders with "pending" status
      const dispatchOrders = filteredOrders.filter(order => order.orderStatus === "Dispatch");

      // Count the pending orders
      monthlyCounts.push({ month: month, dispatchCount: dispatchOrders.length });
    }

    return monthlyCounts;
  }
  // const { setUser, userRole } = useContext(UserContext);
  // const categories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // const approvedData = monthlyApprovedCounts.map(count => count.approvedCount);
  // const ordercounts = monthlyOrderCounts.map(count => count.totalOrders);
  // const pendingpayment = monthlyPendingCount.map((count) => count.pendingCount);
  const Wondeal = monthlyWonCount.map((count) => count.totalWonDeals);
  const totalWondeals = Wondeal.reduce((acc, current) => acc + current, 0);
  const revenue = monthlyWonCount.map((count) => count.totalAmountWon);
  const totalrevenue = revenue.reduce((acc, current) => acc + current, 0);
  const Lostdeal = monthlyLostCount.map((count) => count.totalLostDeals);
  const totalLostdeals = Lostdeal.reduce((acc, current) => acc + current, 0);
  // const Totaldeal = monthlyTotalCount.map((count) => count.totalTotalDeals);
  const Pdeal = monthlyPCount.map((count) => count.totalPDeals);
  const totalPdeals = Pdeal.reduce((acc, current) => acc + current, 0);
  // const totaldeal = totalWondeals + totalLostdeals + totalPdeals;
  // const totalcustomer = monthlycustomer.map((count) => count.totalCustomers);
  // const porder = monthlyPendingOrder.map((count) => count.pendingCount);
  // const totalporder = porder.reduce((acc, current) => acc + current, 0);
  // dispatch(totalpendingorder(totalporder));
  // const oorder = monthlyOpenOrder.map((count) => count.openCount);
  // const totalorder = oorder.reduce((acc, current) => acc + current, 0);
  // dispatch(totalopenorder(totalorder));
  // const corder = monthlyCancelOrder.map((count) => count.cancelCount);
  // const totalcorder = corder.reduce((acc, current) => acc + current, 0);
  // dispatch(totalcancelorder(totalcorder));
  // const clorder = monthlyCloseOrder.map((count) => count.closeCount);
  // const totalclorder = clorder.reduce((acc, current) => acc + current, 0);
  // dispatch(totalcloseorder(totalclorder));
  // const totalpoccorder = totalporder + totalorder + totalcorder + totalclorder;
  // dispatch(setTotalorder(totalpoccorder))

  const totalQualifiedLeads = monthlyLeadCount.map((count) => count.totalQualifiedLeads);
  const totalqualifiedLeads = totalQualifiedLeads.reduce((acc, current) => acc + current, 0);

  const totalDisQualifiedLeads = monthlyLeadCountdq.map((count) => count.totalDisQualifiedLeads);
  const totaldisQualifiedLeads = totalDisQualifiedLeads.reduce((acc, current) => acc + current, 0);

  const totalNotintrestedQualifiedLeads = monthlyLeadCountni.map((count) => count.totalNotintrestedQualifiedLeads);
  const totalnotintrestedQualifiedLeads = totalNotintrestedQualifiedLeads.reduce((acc, current) => acc + current, 0);

  const totalNewQualifiedLeads = monthlyLeadCountnew.map((count) => count.totalNewQualifiedLeads);
  const totalnewQualifiedLeads = totalNewQualifiedLeads.reduce((acc, current) => acc + current, 0);

  const totalLeads = totalqualifiedLeads + totaldisQualifiedLeads + totalnotintrestedQualifiedLeads + totalnewQualifiedLeads;
  // const percentageQualifiedLeads = 5;
  // const percentageDisQualifiedLeads = 3;
  // const percentageNotInterestedQualifiedLeads = 10;
  // const percentageNewQualifiedLeads = (totalnewQualifiedLeads / totalLeads) * 100;
  // const color = ['#004B95', '#002F5D', '#004B95', '#002F5D', '#004B95', '#002F5D', '#004B95', '#002F5D', '#004B95', '#002F5D', '#004B95', '#002F5D'];
  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }
  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
  }));
  const size = {
    width: 195,
    height: 100,
  };
  const data = [
    { value: 5, label: 'A' },
    { value: 10, label: 'B' },
    { value: 15, label: 'C' },
    { value: 20, label: 'D' },
  ];

  return (
    <>
      <div className='dashboard-page-new-design'>

        <div className='chart-btn'>
          <div className="button-group-alignment">
            <button
              onClick={() => setTableFilter("week")}
              className={tableFilter === "week" ? "active-button" : ""}
            >
              Week
            </button>
            <button
              onClick={() => setTableFilter("month")}
              className={tableFilter === "month" ? "active-button" : ""}
            >
              Month
            </button>
            <button
              onClick={() => setTableFilter("year")}
              className={tableFilter === "year" ? "active-button" : ""}
            >
              Year
            </button>
            <button
              onClick={() => setTableFilter("all")}
              className={tableFilter === "all" ? "active-button" : ""}
            >
              All
            </button>
          </div>

          <div className='chart-grid'>
            <div className='chart-grid-items'>
              <div className='sub-grid'>
                <div className='sub-grid-items'>
                  <div className='icons'> <PieChart
                    series={[{
                      data: [
                        { id: 0, value: filteredLeads.filter(lead => lead.leadstatus === "NEW").length, label: 'New', color: '#008000' },
                        { id: 1, value: filteredLeads.filter(lead => lead.leadstatus === "Not Interested").length, label: 'Not interested', color: '#000' },
                        { id: 2, value: filteredLeads.filter(lead => lead.leadstatus === "Disqualified").length, label: 'Disqualified', color: '#ff0000' },
                        { id: 3, value: filteredLeads.filter(lead => lead.leadstatus === "Qualified").length, label: 'Qualified', color: '#1477e7' },
                      ],
                      innerRadius: 50,
                    }]}
                    {...size} >
                    <PieCenterLabel> {filteredLeads?.length}</PieCenterLabel>
                  </PieChart></div>
                </div>
                <div className='sub-grid-items'>
                  <p>Total Leads</p>
                  <div className='icon-text-grid'>
                    <div className='squre-icon green'></div>
                    <span>New Lead</span>
                  </div>
                  <div className='icon-text-grid'>
                    <div className='squre-icon black'></div>
                    <span>Not interest</span>
                  </div>
                  <div className='icon-text-grid'>
                    <div className='squre-icon red'></div>
                    <span>Disqualified</span>
                  </div>
                  <div className='icon-text-grid'>
                    <div className='squre-icon primary'></div>
                    <span>qualified</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='chart-grid-items'>
              <div className='sub-grid'>
                <div className='sub-grid-items'>
                  <div className='icons'>  <PieChart series={[{ data, innerRadius: 50 }]} {...size}>


                    <PieCenterLabel>10</PieCenterLabel>
                  </PieChart></div>
                </div>
                <div className='sub-grid-items'>
                  <p>Customer</p>
                  <div className='icon-text-grid'>
                    <div className='squre-icon green'></div>
                    <span>Converted</span>
                  </div>
                  <div className='icon-text-grid'>
                    <div className='squre-icon primary'></div>
                    <span>In visit</span>
                  </div>
                  <div className='icon-text-grid'>
                    <div className='squre-icon black'></div>
                    <span>New</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='chart-grid-items'>
              <div className='sub-grid'>
                <div className='sub-grid-items'>
                  <div className='icons'> <PieChart
                    series={[{
                      data: [
                        {
                          id: 0,
                          value: filteredVisits.filter(visit => visit.visit_type === "lead").length,
                          label: 'Lead',
                          color: '#cf27e9'
                        },
                        {
                          id: 1,
                          value: filteredVisits.filter(visit => visit.visit_type === "primary").length,
                          label: 'Primary',
                          color: '#1477e7'
                        },

                      ],
                      innerRadius: 50,
                    }]}
                    {...size}
                  >
                    <PieCenterLabel>
                      {
                        filteredVisits.filter(visit =>
                          visit?.visit_type === "lead" || visit?.visit_type === "primary"
                        ).length
                      }
                    </PieCenterLabel>
                  </PieChart></div>
                </div>
                <div className='sub-grid-items'>
                  <p>Visit</p>
                  <div className='icon-text-grid'>
                    <div className='squre-icon green'></div>
                    <span>Order collection</span>
                  </div>
                  <div className='icon-text-grid'>
                    <div className='squre-icon primary'></div>
                    <span>Primary</span>
                  </div>
                  <div className='icon-text-grid'>
                    <div className='squre-icon purple'></div>
                    <span>Lead</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='chart-grid-items'>
              <div className='sub-grid'>
                <div className='sub-grid-items'>
                  <div className='icons'><PieChart
                    series={[{
                      data: [
                        { id: 0, value: filteredDeal.filter(deal => deal.dealStatus === "WON").length, label: 'Won', color: '#008000' },
                        { id: 1, value: filteredDeal.filter(deal => deal.dealStatus === "LOST").length, label: 'Lost', color: '#ff0000' },
                        { id: 2, value: filteredDeal.filter(deal => deal.dealStatus === "in progress").length, label: 'In progress', color: 'rgba(234, 180, 18, 0.89)' },
                      ],
                      innerRadius: 50,
                    }]} {...size}
                  >
                    <PieCenterLabel>{filteredDeal?.length}</PieCenterLabel>
                  </PieChart></div>
                </div>
                <div className='sub-grid-items'>
                  <p>Deals</p>
                  <div className='icon-text-grid'>
                    <div className='squre-icon green'></div>
                    <span>Won deal</span>
                  </div>
                  <div className='icon-text-grid'>
                    <div className='squre-icon red'></div>
                    <span>Lost deal</span>
                  </div>
                  <div className='icon-text-grid'>
                    <div className='squre-icon yellow'></div>
                    <span>In progress</span>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
        <div className="overview-grid">
          <div className="overview-grid-items">
            <div className="overview-first-card">
              <div className="leds-overview">
                <h2>Deal Overview</h2>
                <div className="button-group-alignment">
                  <button onClick={() => setTimeFilter("week")} className={timeFilter === "week" ? "active-button" : ""}>
                    Week
                  </button>
                  <button onClick={() => setTimeFilter("month")} className={timeFilter === "month" ? "active-button" : ""}>
                    Month
                  </button>
                  <button onClick={() => setTimeFilter("year")} className={timeFilter === "year" ? "active-button" : ""}>
                    Year
                  </button>
                  <button onClick={() => setTimeFilter("all")} className={timeFilter === "all" ? "active-button" : ""}>
                    All
                  </button>
                </div>
              </div>
              <div className="chart-box">
                <div>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    containerProps={{ style: { width: '100%', height: '100%' } }}
                  />
                </div>

              </div>
              <div className='card-bottom'>
                <div className='card-bottom-items'>
                  <h3>{wondeal.length}</h3>
                  <span>Number of Deals</span>
                </div>
                <div className='card-bottom-items'>
                  <h3>{totalWondeals}</h3>
                  <span>Won Deals</span>
                </div>
                <div className='card-bottom-items'>
                  <h3>{totalPdeals}</h3>
                  <span>In Progress</span>
                </div>
                <div className='card-bottom-items'>
                  <h3>₹{totalrevenue}</h3>
                  <span>Revenue</span>
                </div>
              </div>
            </div>
          </div>
          <div className='overview-grid-items'>
            <div className='upcomming-header'>
              <h3>Upcoming Visits</h3>
              <span>View All</span>
            </div>
            <div className='all-card-alignment'>
              {visitRoutes.map((route) => (
                <div
                  className='card-design'
                  key={route.id}
                  onClick={() => handleRowClick(route)}
                >
                  <div>
                    <div className='icon-text'>
                      <i class="fa-solid fa-arrow-right"></i>
                      <p>{route.objective || 'No Objective'}</p>
                    </div>
                    <span style={{ marginLeft: '2rem' }}>{route.userName}</span>
                    <span style={{ marginLeft: '2rem' }}>
                      {route.routes.length > 0
                        ? formatDate(route.routes[0].date)
                        : 'No Route Date'}
                    </span>
                  </div>
                  <div className='two-icon'>

                    <div className='icon-design'>
                      <i
                        class="fa fa-hand-pointer-o"
                        style={{ color: '#1477e7', }}
                      ></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <Modal
              title="Visit Details"
              open={isModalOpen}
              onCancel={handleCancel}
              footer={null}
              bodyStyle={{
                overflowY: 'auto',
                padding: '30px 0 20px 0',
              }}
            >
              {selectedData && (
                <>
                  <div className='visit-details-alignment'>
                    <div className='border-box'>
                      <h3>User:</h3>
                      <p>{selectedData.userName || "No Username"}</p>
                    </div>
                    <div className='border-box'>
                      <h3>Objective</h3>
                      <p>{selectedData.objective || "No Objective"}</p>
                    </div>
                    <div className='border-box'>
                      <h3>Routes</h3>
                      {selectedData.routes.length > 0 ? (
                        selectedData.routes.map((route, index) => (
                          <div
                            key={index} className='routes-border-box'>
                            <p style={{ margin: 0, fontSize: '14px', color: '#000' }}>
                              Date: {formatDate(route.date)}
                            </p>
                            <ul style={{ margin: '4px 0', paddingLeft: '16px' }}>
                              {route.areas.length > 0 ? (
                                route.areas.map((area) => (
                                  <li key={area.id} style={{ fontSize: '14px', color: '#555' }}>
                                    {area.name}
                                  </li>
                                ))
                              ) : (
                                <li style={{ fontSize: '14px', color: '#999' }}>No areas available</li>
                              )}
                            </ul>
                          </div>
                        ))
                      ) : (
                        <p style={{ margin: 0, fontSize: '14px', color: '#999' }}>No Routes Available</p>
                      )}
                    </div>
                    <div className='border-box'>
                      <h3>Priority Customers</h3>
                      {selectedData.priorityCustomer.length > 0 ? (
                        selectedData.priorityCustomer.map((customer) => (
                          <div
                            key={customer.id}
                            className='routes-border-box'
                          >
                            <p style={{ margin: 0, fontSize: '14px', fontWeight: 'bold' }}>
                              <i class="fa-solid fa-user"></i>  {customer.cName}
                            </p>

                          </div>
                        ))
                      ) : (
                        <p style={{ margin: 0, fontSize: '14px', color: '#999' }}>
                          No Priority Customers
                        </p>
                      )}
                    </div>
                  </div>


                </>
              )}
            </Modal>

          </div>

        </div>
        <VisitTable />
        <ActiveOrder response={orders}/>
      </div>
    </>
  );
}

