import React, { useEffect, useState } from 'react';
import './accounttable.scss';
import { Link } from 'react-router-dom';
import { fetchData } from '../../../services/apiService';
import ProductImage from '../../../assets/images/product.svg';
import ViewIcon from '../../../assets/icons/view.svg';
import PenIcon from '../../../assets/icons/pen.svg';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';
import SearchIcon from '../../../assets/icons/search.svg';
import ProfileIcon from '../../../assets/images/profile.svg';
import debounce from 'lodash.debounce';
import ViewIconFill from '../../../shared/icons/viewIcon';
import EditIcon from '../../../shared/icons/editIcon';

export default function Accounttable({ buttonClicked, selectedIds, setSelectedIds, setActivityList }) {
    const [currentPage, setCurrentPage] = useState(1); // Current page state
    const [activityList, setProductList] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [codeTerm, setCodeTerm] = useState("");
    const [nameTerm, setNameTerm] = useState("");
    const [priceTerm, setPriceTerm] = useState("");
    const [emailTerm, setEamilTerm] = useState("");
    const [noTerm, setNoTerm] = useState("");
    const [statusTerm, setStatusTerm] = useState('');
    const [createdbyTerm, setCreatedbyTerm] = useState("");
    const [productData, setProductData] = useState([]);
    const [allactivityType, setallactivityTypeTerm] = useState('');
    const pageSize = 10; // Items per page

    useEffect(() => {
        const filters = {
            cName: nameTerm,
            code: codeTerm,
            addressEmail: emailTerm,
            priceGroup: priceTerm,
            addressContact: noTerm,
            status: statusTerm,
            created_by: createdbyTerm
        };

        fetchProduct(filters, true, currentPage, pageSize)
    }, [currentPage]);



    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleCheckboxChange = (id) => {
        setSelectedIds((prevSelectedIds) => {
            if (prevSelectedIds.includes(id)) {
                return prevSelectedIds.filter((selectedId) => selectedId !== id);
            } else {
                return [...prevSelectedIds, id];
            }
        });
    };


    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedIds(activityList.map((item) => item.id));
        } else {
            setSelectedIds([]);
        }
    };

    const removeEmptyFields = (obj) => {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
        );
    };
    const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
        try {
            const pagination = {
                page: page,
                limit: pageSize,
            };
            const responseProducts = await fetchData('customer', removeEmptyFields(filters), pagination,
                {},
                isSearching,
                globalSearch);

            if (responseProducts?.data) {
                setProductData(responseProducts.data);

                setProductData(responseProducts.data || []);
                setTotalItems(responseProducts?.totalDocuments || 0);
            }

            console.log('setProductData', responseProducts?.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const filters = {
            cName: nameTerm,
            code: codeTerm,
            addressEmail: emailTerm,
            priceGroup: priceTerm,
            addressContact: noTerm,
            status: statusTerm,
            created_by: createdbyTerm,
        };

        const debouncedFetch = debounce(() => {
            setCurrentPage(1);
            fetchProduct(filters, true, currentPage, pageSize);
        }, 500);

        debouncedFetch();

        return () => {
            debouncedFetch.cancel();
        };
    }, [nameTerm, codeTerm, emailTerm, priceTerm, noTerm, statusTerm, createdbyTerm]);

    useEffect(() => {
        const filters = {
            isGlobalSearch: true,
            isSearching: true,
            searchfields: ['cName', 'addressEmail', 'created_by', 'addressContact'],
            searchtext: allactivityType,
        };

        const debouncedFetch = debounce(() => {
            fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
        }, 500);

        debouncedFetch();

        return () => {
            debouncedFetch.cancel();
        };
    }, [allactivityType, currentPage, pageSize]);


    return (
        <>
            <div className=''>
                {/* <div className='account-table-header-alignment'>
                    <div className='search-width'>
                        <div className='searchbar-design'>
                            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
                            <div className='icon-alignment'>
                                <img src={SearchIcon} alt="SearchIcon" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className='account-table-design'>
                    <table>
                        <thead>
                            <style>
                                {`
      .search-input::placeholder {
        font-size: 0.75rem;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 2px;
        text-align: left;
        padding: 3px;
        border-bottom: 2px solid #edf2f9;
      }
    `}
                            </style>
                            <tr>
                                <th><div style={{ width: '100%' }}></div></th>

                                <th style={{ width: '40%' }}><div style={{ textAlign: 'center', width: '100%' }}><input
                                    type="text"
                                    placeholder="Name"
                                    value={nameTerm}
                                    onChange={(e) => setNameTerm(e.target.value)}
                                    className="search-input input-height"
                                    style={{ border: 'none', width: '100%' }}

                                /></div></th>

                                <th><div style={{ textAlign: 'center', width: '100%' }}><input
                                    type="text"
                                    placeholder="Code"
                                    value={codeTerm}
                                    onChange={(e) => setCodeTerm(e.target.value)}
                                    className="search-input input-height"
                                    style={{ border: 'none', width: '100%' }}

                                /></div></th>

                                <th><div style={{ textAlign: 'center', width: '100%' }}><input
                                    type="text"
                                    placeholder="Price Group"
                                    value={priceTerm}
                                    onChange={(e) => setPriceTerm(e.target.value)}
                                    className="search-input input-height"
                                    style={{ border: 'none', width: '100%' }}

                                /></div></th>

                                <th><div style={{ textAlign: 'center', width: '100%' }}><input
                                    type="text"
                                    placeholder="Contact no"
                                    value={noTerm}
                                    onChange={(e) => setNoTerm(e.target.value)}
                                    className="search-input input-height"
                                    style={{ border: 'none', width: '100%' }}

                                /></div></th>
                                <th style={{ width: '10%' }}>
                                    <div>
                                        <select
                                            value={statusTerm === undefined ? "" : statusTerm.toString()}
                                            onChange={(e) => {
                                                const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
                                                setStatusTerm(selectedStatus);
                                            }}
                                            className="w-100 border-0 table-input-background"
                                        >
                                            <option value="">All</option>
                                            <option value="true">Active</option>
                                            <option value="false">Inactive</option>
                                        </select>
                                    </div>
                                </th>

                                <th><div style={{ textAlign: 'center', width: '100%' }}><input
                                    type="text"
                                    placeholder="Created by"
                                    value={createdbyTerm}
                                    onChange={(e) => setCreatedbyTerm(e.target.value)}
                                    className="search-input input-height"
                                    style={{ border: 'none', width: '100%' }}

                                /></div></th>

                                <th><div style={{ width: '100%' }}></div></th>



                            </tr>
                            <tr>
                                {checkAccess('edit_account') && <th>Action</th>}
                                <th>Name</th>
                                <th>Code</th>
                                <th>Price Group</th>
                                <th>Contact no</th>
                                <th>status</th>
                                <th>Created by</th>
                                <th>View Map</th>

                            </tr>
                        </thead>
                        <tbody>
                            {productData && productData.length > 0 ? (
                                productData.map((i) => (
                                    <tr key={i.id}>
                                        {checkAccess('edit_account') && (
                                            <td align="center">
                                                <Link to={`addcustomer/${i?.id}`}>
                                                    <img src={PenIcon} alt="Edit" />
                                                </Link>
                                            </td>
                                        )}
                                        <td>{i.cName || "N/A"}</td>
                                        <td>{i.code || "N/A"}</td>
                                        <td>{i.priceGroup || "N/A"}</td>
                                        <td>{i.addressContact || "N/A"}</td>
                                        <td>
                                            {checkAccess('read_account') ? (
                                                <Link to={{ pathname: `view/${i?.id}` }}>
                                                    <button className={i?.status ? "Active" : "Inactive"}>
                                                        {i?.status ? "Active" : "Inactive"}
                                                    </button>
                                                </Link>
                                            ) : (
                                                <button
                                                    className={i?.status ? "Active" : "Inactive"}
                                                    style={{ pointerEvents: "none", cursor: "default" }}
                                                >
                                                    {i?.status ? "Active" : "Inactive"}
                                                </button>
                                            )}
                                        </td>
                                        <td>{i.created_by || "-"}</td>
                                        <td>
                                            <img
                                                onClick={() => buttonClicked(i.latitude, i.longitude)}
                                                src={ViewIcon}
                                                alt="ViewIcon"
                                            />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={8} style={{ textAlign: "center", fontStyle: "italic", color: "#666" }}>
                                        No data available
                                    </td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                    <div className='pagination-alignment'>
                        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems}
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div> */}
            </div>

            {/* <div className='search-width'>
                <div className='searchbar-design'>
                <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
                    <div className='icon-alignment'>
                        <img src={SearchIcon} alt="SearchIcon" />
                    </div>
                </div>
            </div> */}

            <div className='table-v2-design'>
                <table>
                    <thead>
                        <tr>
                            <th>Costumers</th>
                            <th>Code</th>
                            <th>Price Group</th>
                            <th>Mail</th>
                            <th>Contact No.</th>
                            <th>Status</th>
                            <th>Created By</th>
                            <th>View</th>
                            <th>Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {productData && productData.length > 0 ? (
                            productData.map((i) => (
                                <tr>
                                    <td>
                                        <div className='profile-text-design'>
                                            <img src={ProfileIcon} alt="ProfileIcon" />
                                            <div>
                                                <p>{i.cName || "N/A"}</p>
                                                <span>{i.email}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        {i.code || "N/A"}
                                    </td>
                                    <td>
                                        {i.priceGroup || "N/A"}
                                    </td>
                                    <td>
                                    {i.email}
                                    </td>
                                    <td>
                                        {i.addressContact || "N/A"}
                                    </td>
                                    <td>
                                        {checkAccess('read_account') ? (
                                            <Link to={{ pathname: `view/${i?.id}` }}>
                                                <button className={i?.status ? "active-btn" : "Inactive"}>
                                                    <div className='dot'></div>
                                                    {i?.status ? "Active" : "Inactive"}
                                                </button>
                                            </Link>
                                        ) : (
                                            <button
                                                className={i?.status ? "Active" : "Inactive"}
                                                style={{ pointerEvents: "none", cursor: "default" }}
                                            >
                                                {i?.status ? "Active" : "Inactive"}
                                            </button>
                                        )}
                                    </td>
                                    <td>
                                        {i.created_by || "-"}
                                    </td>
                                    <td>
                                        <div onClick={() => buttonClicked(i.latitude, i.longitude)}>
                                            <ViewIconFill />
                                        </div>
                                    </td>

                                    {checkAccess('edit_account') && (
                                        <td align="center">
                                            <Link to={`addcustomer/${i?.id}`}>
                                                <EditIcon />
                                            </Link>
                                        </td>
                                    )}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={8} style={{ textAlign: "center", fontStyle: "italic", color: "#666" }}>
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Pagination
                    currentPage={currentPage}
                    totalItems={totalItems}
                    pageSize={pageSize}
                    onPageChange={handlePageChange}
                />
            </div>
        </>
    );
}
