import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import InputCustom from "../../../shared/components/input";
import { fetchData, fetchsingleData, patchData, postData } from "../../../services/apiService";
import SelectBox from "../../../shared/components/select";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Input, DatePicker } from "antd";
import moment from 'moment';
import store from '../../../redux/reduxstore';
import dayjs from "dayjs";
import GlobalButton from "../../../shared/globalButton";
import { checkAccess } from "../../../services/checkFeature";
import TotalAmountDisplay from "../formateamount";

import Breadcumbs from "../../../shared/components/breadcumbs";
import CancelIcon from '../../../assets/icons/Cancel.svg';
import StatusBox from "../../addcustomers/statusBox";

const { TextArea } = Input;

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        background: '#fff',
        borderColor: '#f1f1f1',
        minHeight: '40px',
        height: '40px',
        fontSize: '14px',
        boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
        ...provided,
        height: '40px',
        padding: '0 6px',
        fontSize: '14px'
    }),

    input: (provided, state) => ({
        ...provided,
        margin: '0px',
    }),
    indicatorSeparator: state => ({
        display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        height: '40px',
    }),
};
export default function AddEstimate() {
    const navigate = useNavigate();
    const { id } = useParams();
    const company_setting = store.getState()?.auth?.company_setting;

    const isEditMode = window.location.pathname.includes('/add') || false;
    const name = useSelector(state => state.auth.name);
    const userData = store.getState().auth.userData
    const userName = userData.name
    console.log(userData, "---[-")

    const userDataRef = useRef(userData);
    useEffect(() => {
        // Update the ref whenever isExamStarted changes
        userDataRef.current = userData;
    }, [userData]);
    useEffect(() => {
        // Fetch data when the component mounts
        getData();
        getCustomer();
        fetchModule()

    }, []);
    const [productOptions, setProductOptions] = useState([]);
    const [customers, setCustomerOptions] = useState([]);
    const [unitOptions, setUnitOptions] = useState([]);
    const [transportOptions, setTransportOptions] = useState([]);
    const [newNotification, setNewNotification] = useState([]);
    const [newUpdate, setNewUpdate] = useState([]);
    const [usermoduleOptions, setModuleOptions] = useState([]);
    const [linkdropDown, setlinkdropDown] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [formData, setFormData] = useState({
        estimateDate: dayjs(),
        status: true,
        orderProducts: [],
        estimateFor: "",
        totalAmount: 0,
        estimateStatus: "pending",
        yetscreen: true,
        userId : userData.id
    });
    const [errors, setErrors] = useState({});
    const getCustomer = async () => {
        try {
            const response = await fetchData(`customer`);
            if (response?.data) {

                setCustomerOptions(

                    response?.data?.map((i) => {

                        return {
                            label: `[${i?.code}] ${i?.cName}`,
                            key: "customer",
                            value: {
                                id: i?.id,
                                name: `[${i?.code}] ${i?.cName}`,
                                address: `${i?.address} - ${i?.pincode}.`,
                                contact: i?.addressContact,
                            },
                        };
                    })
                );
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    const getData = async () => {
        try {
            const response = await fetchData(`systemtype`, {
                typeCategory: { in: ["unit"] },
                status: true,
            });
            if (response?.data) {
                setUnitOptions(
                    response?.data
                        .filter((i) => i?.typeCategory === "unit")
                        .map((i) => {
                            return {
                                label: i?.typeName,
                                key: "unit",
                                value: i?.id
                            };
                        })
                );
            }
            const responseProducts = await fetchData(`product`);
            if (responseProducts?.data) {
                setProductOptions(
                    responseProducts?.data?.map((i) => {
                        return {
                            ...i,
                            label: `${i?.name}`,
                            key: "name",
                            value: i?.id,
                        };
                    })
                );
            }
            if (id) {
                const response = await fetchsingleData("estimate", id);
                if (response?.data)
                    setFormData(response?.data); // Update state with fetched data
                getdowpDownlist(response?.data?.selesreturnForm?.toLowerCase())
                setProductsForOrder([...response?.data?.orderProducts]);

            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };


    const fetchModule = async () => {
        try {
            const response = await fetchData('module');
            if (response?.data) {
                setModuleOptions(
                    response.data
                        .filter(module =>
                            module?.modulename === 'Lead' || module?.modulename === 'Customer'
                        )
                        .map(module => ({
                            ...module,
                            label: module?.modulename,
                            value: module?.modulename,
                        }))
                );
            }
        } catch (error) {
            console.error('Error fetching modules:', error);
        }
    };

    const [salesreturn, setsalesreturn] = useState([]);
    const [salesreturnList, setsalesreturnList] = useState([]);
    const [selesData, setSlesData] = useState({
        // salesReturnNo: 0, // Default value for salesReturnNo
    });

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = async () => {
        try {
            const response = await fetchData('estimate');
            if (response?.data) {
                const totalDocuments = response.data.length;

                // Set the first sales return data and the list to the state
                setsalesreturn(response.data[0] || {}); // Handle empty data safely
                setsalesreturnList(response.data);

                // Automatically set Sales Return No based on total documents
                const newSalesReturnNo = totalDocuments + 1;

                // Update form data and state
                setSlesData((prevData) => ({
                    ...prevData,
                    // salesReturnNo: newSalesReturnNo,
                }));

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    // salesReturnNo: newSalesReturnNo,
                }));
            } else {
                // Handle no data case
                setSlesData((prevData) => ({
                    ...prevData,
                    // salesReturnNo: 1, // Default to 1 if no documents
                }));

                setFormData((prevFormData) => ({
                    ...prevFormData,
                    // salesReturnNo: 1,
                }));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const getdowpDownlist = async (type) => {
        try {
            const response = await fetchData(type);
            if (response?.data) {
                const modules = await fetchData('module');
                const keyName = (modules?.data ?? []).find(f => f?.modulename?.toLowerCase() === type)?.moduleKey;
                setlinkdropDown(
                    (response?.data ?? []).map((module) => ({
                        ...module,
                        label: module?.[keyName],
                        value: module?.id,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching ' + type, error);
        }
    };
    const handleSelectChange = (event, type) => {
        if (type === 'estimateFor') {
            setFormData({
                ...formData,
                [type]: event.value
            });
            getdowpDownlist(event?.value?.toLowerCase())
            setErrors((prevErrors) => ({
                ...prevErrors,
                estimateFor: "",
            }));
            if (errors.isSubmit) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    estimateFor: formData?.estimateFor ? "" : "Select Lead/Customer.",
                }));
            }
        }
        else {
            let formPayload = {
                ...formData,
                [type]: event.value
            }
            if (type === 'link') {
                formPayload = { ...formPayload, customerName: linkdropDown?.find(d => d?.value === event?.value)?.label }
            }
            setFormData(formPayload);
            setErrors((prevErrors) => ({
                ...prevErrors,
                customerName: "",
            }));
            if (errors.isSubmit) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    customerName: formData?.customerName ? "" : "Select Customer Name",
                }));
            }
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        if (productsForOrder?.length > 0) {
            try {
                if (id) {
                    patchData(`estimate/${id}`, { ...formData, orderProducts: [...productsForOrder], userName })
                        .then((data) => {
                            navigate(-1);
                            console.log("API response:", data);
                        })
                        .catch((error) => {
                            console.error("Error fetching data:", error);
                        });
                } else {
                    postData("estimate", { ...formData, orderProducts: [...productsForOrder], userName })
                        .then((data) => {
                            navigate(-1);
                            console.log("API response:", data);
                        })
                        .catch((error) => {
                            console.error("Error fetching data:", error);
                        });
                }
            } catch (error) {
                console.error("Error saving data:", error);
                // Handle error
            }
        }
    };


    const handleDateChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            selesreturnData: new Date(e)
        }));
    };


    const [productsForOrder, setProductsForOrder] = useState([]);
    const AddProduct = () => {
        const orderProducts = [...productsForOrder];
        orderProducts.push({
            index: orderProducts.length + 1,
            remark: "",
            name: "",
            productId: "",
            unitId: "",
            unitName: "",
            qty: 1,
            unitPrice: 0,
            discount: 0,
            tax: 0,
            subTotal: 0,
            productUrl: '',
            ProductCategory : ''
        });
        setProductsForOrder([...orderProducts]);


    };

    const productSelectChange = (index, event, type) => {
        if (type === 'p') {
            const product_data = productOptions.find(d => d?.value == event?.value);
            const orderProducts = [...productsForOrder];
            orderProducts[index]['productId'] = product_data?.id;
            orderProducts[index]['name'] = product_data?.name;
            orderProducts[index]['ProductCategory'] = product_data?.categoryName;
            orderProducts[index]['unitName'] = product_data?.unitName;
            orderProducts[index]['unitId'] = product_data?.unitId;
            orderProducts[index]['qty'] = Number(product_data?.moq);
            orderProducts[index]['unitPrice'] = Number(Number(parseFloat(product_data?.price)).toFixed(2));
            orderProducts[index]['tax'] = Number(product_data?.tax);
            orderProducts[index]['discount'] = Number(parseFloat(product_data?.discount));
            orderProducts[index]['subTotal'] = (Number(Number(product_data?.price) * Number(product_data?.moq).toFixed(2)));
            orderProducts[index]['productUrl'] = product_data?.productUrl;
            setProductsForOrder([...orderProducts]);
            calculateTotal(orderProducts);

        }

    }

    const handleInputChangeProduct = (e, index) => {
        const { name, value, checked, type } = e.target;
        const orderProducts = [...productsForOrder];
        if (name === 'qty') {
            orderProducts[index][name] = Number(parseFloat(value).toFixed(2));
        }
        if (name === 'unitPrice') {
            orderProducts[index][name] = Number(parseFloat(value).toFixed(2));;
        }
        if (name === 'discount') {
            orderProducts[index][name] = value;
        }
        if (name === 'tax') {
            orderProducts[index][name] = value;
        }
        if (name === 'remark') {
            orderProducts[index][name] = value;
        }

        const price = Number(orderProducts[index]['unitPrice']) * Number(orderProducts[index]['qty']) || 0;
        const discount = (price * Number(orderProducts[index]['discount']) / 100) || 0;
        const tax = ((price - discount) * Number(orderProducts[index]['tax']) / 100) || 0;
        orderProducts[index]['subTotal'] = Number(parseFloat(((price - discount) + tax)).toFixed(2));

        setProductsForOrder([...orderProducts]);
        calculateTotal(orderProducts);

    };
    const calculateTotal = (orderProducts) => {
        const total = orderProducts.reduce((acc, orderProducts) => acc + (orderProducts.subTotal || 0), 0);
        setFormData({ ...formData, totalAmount: total.toFixed(2) });
        console.log(formData.totalAmount);
    }
    const handleRemoveProduct = (indexToRemove, productSubTotal) => {
        const orderProducts = [...productsForOrder];
        orderProducts.splice(indexToRemove, 1);
        setProductsForOrder(orderProducts);
        calculateTotal(orderProducts);
    };
    const handleAddProductClick = () => {
            AddProduct();
    };

    const selectChanged = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [`${e.key}Name`]: e.value?.name,
            [`${e.key}Id`]: e.value?.id,
            [`${e.key}Address`]: e.value?.address,
            [`${e.key}contact`]: e.value?.contact,

        }));
        if (errors.isSubmit);
    };

    console.log("Submit", formData)

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
        if (errors.isSubmit);
    };

    console.log("=====", formData)

    return (

        <div className="add-order-page-design-change">
            {isEditMode && (
                <div className='add-header-design'>
                    <div>
                        <h2>Add estimate</h2>
                        <Breadcumbs activePath="estimate" pageName="Add estimate" />
                    </div>
                    <div className='right-contnet-alignment'>
                        <Link to="/estimat">
                            <button className='light-button'>
                                <img src={CancelIcon} alt="CancelIcon" />
                                Discard
                            </button>
                        </Link>
                        <div onClick={handleSubmit}>
                            <GlobalButton text={id ? 'Update' : 'Save'} />
                        </div>
                    </div>
                </div>
            )}
            <div className="page-main-grid">
                <div>
                    <div className="new-white-box-design">
                        <div className="two-col-grid">
                            <SelectBox
                                label={
                                    <span>
                                        Estimate for <span className="required-star">*</span>
                                    </span>}
                                name="estimateFor"
                                placeholder="Select Estimate for"
                                options={usermoduleOptions}
                                value={usermoduleOptions?.find(i => i?.value === formData.estimateFor)}
                                onChange={(e) => handleSelectChange(e, 'estimateFor')}
                                style={{ width: '100%' }}
                                errors={errors.estimateFor}
                            />
                            <SelectBox
                                name="link"
                                label={
                                    <span>
                                        Linked / Customer <span className="required-star">*</span>
                                    </span>}
                                placeholder={"Linked / " + (formData?.module ? formData?.module : 'Customer')}
                                options={linkdropDown}
                                value={linkdropDown?.find(i => i?.value === formData.link)}
                                onChange={(e) => handleSelectChange(e, 'link')}
                                style={{ width: '100%' }}
                                errors={errors.customerName}
                            />
                            <div>
                                <label className='selesreturnData mb-1'> Date</label>
                                <DatePicker
                                    name="estimateDate"
                                    placeholder='Select Date'
                                    value={dayjs(formData.estimateDate)}
                                    onChange={handleDateChange}
                                    showTime
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="new-white-box-design">
                        {(
                            <div className="">
                                <h2>
                                    Product Details
                                </h2>
                                <div
                                >
                                    <div
                                        className="account-table-design"
                                        style={{ overflow: 'unset' }}
                                    >
                                        <table>
                                            <thead>
                                                <tr style={{ border: '1px solid #eee' }}>
                                                    <th className="width-100" style={{ border: 'none', background: '#f5f5f5' }}>Product</th>
                                                    <th className="width-80" style={{ border: 'none', background: '#f5f5f5' }}>Unit</th>
                                                    <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Quantity</th>
                                                    <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Price (&#8377;)</th>
                                                    <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Discount</th>
                                                    <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Tax</th>
                                                    <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Sub Total (&#8377;)</th>
                                                    <th className="width-10" style={{ border: 'none', background: '#f5f5f5' }}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {productsForOrder.map((i, INDEX) => (
                                                    <tr key={i?.index}>
                                                        <td className="width-auto">
                                                            <SelectBox
                                                                name="name"
                                                                value={productOptions.find((option) => option.value === i.productId)}
                                                                options={productOptions}
                                                                onChange={(e) => productSelectChange(INDEX, e, 'p')}
                                                                placeholder="Product Name"
                                                                type="text"
                                                                styles={{
                                                                    overflow: 'initial',
                                                                    ...customStyles,
                                                                    menu: (provided) => ({
                                                                        ...provided,
                                                                        zIndex: 99999,
                                                                        position: 'absolute',
                                                                    }),
                                                                    control: (provided) => ({
                                                                        ...provided,
                                                                        width: '100%',
                                                                    }),
                                                                }}
                                                            />
                                                            {company_setting?.extra_setting?.remarksInSalesReturnProduct === "Yes" && (
                                                                <TextArea name='remark' value={i?.remark} onChange={(e) => handleInputChangeProduct(e, INDEX)} placeholder='Remark' type='text' rows={2} className="height-set mt-1"></TextArea>

                                                            )}
                                                        </td>
                                                        <td className="width-auto">
                                                            <SelectBox
                                                                name="unit"
                                                                isDisabled={true}
                                                                value={unitOptions.find((option) => option.value === i.unitId)}
                                                                options={unitOptions}
                                                                onChange={(e) => productSelectChange(INDEX, e, 'u')}
                                                                placeholder="Unit Name"
                                                                type="text"
                                                            />
                                                        </td>
                                                        <td className="whitespace-nowrap width-auto">
                                                            <InputCustom
                                                                name="qty"
                                                                value={i?.qty}
                                                                onChange={(e) => handleInputChangeProduct(e, INDEX)}
                                                                type="number"
                                                            />
                                                        </td>
                                                        <td className="width-auto">
                                                            <InputCustom
                                                                name="unitPrice"
                                                                value={i?.unitPrice}
                                                                onChange={(e) => handleInputChangeProduct(e, INDEX)}
                                                                type="number"
                                                                disabled={!checkAccess('edit_sale_quotation_price')}
                                                            />
                                                        </td>
                                                        <td className="width-auto">
                                                            <InputCustom
                                                                name="discount"
                                                                value={i?.discount}
                                                                onChange={(e) => handleInputChangeProduct(e, INDEX)}
                                                                type="number"
                                                                disabled={!checkAccess('edit_sale_quotation_discount')}
                                                            />
                                                        </td>
                                                        <td className="width-auto">
                                                            <InputCustom
                                                                name="tax"
                                                                value={i?.tax}
                                                                onChange={(e) => handleInputChangeProduct(e, INDEX)}
                                                                type="number"
                                                            />
                                                        </td>
                                                        <td className="width-auto">
                                                            <InputCustom
                                                                name="subTotal"
                                                                value={i?.subTotal}
                                                                readOnly={true}
                                                                onChange={(e) => handleInputChangeProduct(e, INDEX)}
                                                                type="number"
                                                            />
                                                        </td>
                                                        <th className="width-auto">
                                                            <div
                                                                onClick={() => {
                                                                    const productToRemove = productsForOrder[INDEX];
                                                                    handleRemoveProduct(INDEX, productToRemove.subTotal);
                                                                }}
                                                                style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                                                            >
                                                                <i className="fas fa-trash-alt"></i>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{ paddingTop: '15px' }}>
                                        <div  onClick={handleAddProductClick}>
                                            <GlobalButton text="Add Product" />
                                        </div>
                                    </div>
                                    <div className="">
                                        <table
                                            className="datatable_main alignTop"
                                            style={{
                                                marginLeft: 'auto',
                                                maxWidth: '40%',
                                                background: '#f5f5f5',
                                                border: '2px solid #eee',
                                            }}
                                        >
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style={{
                                                            textAlign: 'left',
                                                            width: '50%',
                                                            padding: '10px',
                                                        }}
                                                    >
                                                        <b>Total</b>
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: 'right',
                                                            width: '50%',
                                                            padding: '10px',
                                                        }}
                                                    >
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                            <h5 style={{ color: '#28a745', margin: '0' }} >
                                                                <TotalAmountDisplay formData={{ ...formData, orderProducts: [...productsForOrder] }} />
                                                            </h5>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>

                        )}
                    </div>
                </div>
                <div>
                    <StatusBox />
                </div>
            </div>

        </div>
    );
}
