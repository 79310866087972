import { Link } from 'react-router-dom'
import { fetchData } from '../../../services/apiService';
import EditIcon from '../../../shared/icons/editIcon';
import React, { useEffect, useState } from "react";
import Pagination from "../../../shared/components/pagination";
import { checkAccess } from '../../../services/checkFeature';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce';
export default function PricegroupTable({
  setSelectedIds ,
  PriceList ,
  setProductList ,
  setTotalItems ,
  totalItems ,
  selectedIds 
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [Name, setNameTerm] = useState(""); // Initialize as an empty string
  const [Code, setCodeTerm] = useState("");
  const [Discount, setDiscountTerm] = useState("");
  const [Price, setPriceTerm] = useState(""); // Initialize as an empty string
  const [OfferDiscount, setOfferDiscountTrem] = useState("");
  const [productData, setProductData] = useState([]);
  const [Status, setStatusTrem] = useState("");
  const [allactivityType, setallactivityTypeTerm] =useState('');
  useEffect(() => {
    const filters = {
      name: Name,
      code: Code,
      discount: Discount,
      price: Price,
      offerDiscount :OfferDiscount ,
      status: Status
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(PriceList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };
  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData(
        'pricegroup',
        removeEmptyFields(filters), 
        pagination,
        {},
        isSearching,
        globalSearch
      );

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }

      console.log('setProductData', responseProducts?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      name: Name,
      code: Code,
      discount: Discount,
      price: Price,
      offerDiscount: OfferDiscount,
      status: Status,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [Name, Code, Discount, Price, OfferDiscount, Status]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['name', 'code', 'discount', 'price', 'offerDiscount'],
      searchtext: allactivityType,
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);
 

  return (
    <>
    <div className='table-v2-design'>
      <div>
        <table>
          <thead>
            <tr>
              <th>
              <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedIds.length === PriceList.length && PriceList.length > 0}
                />
              </th>
              <th>Name</th>
              <th>Code</th>
              <th>Price</th>
              <th>Discount(%)</th>
              <th>Offer Discount(%)</th>
              <th>Status</th>
              {checkAccess('manage_price_group')&&<th align="center">Action</th>}
            </tr>
          </thead>
          <tbody>
            {productData && productData.length > 0 ? (
              productData.map((item) => (
                <tr key={item.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                 
                  <td>
                    <span className="price-group-text">{item.name || "N/A"}</span>
                  </td>
                  <td>{item.code || "N/A"}</td>
                  <td>{item.price || "N/A"}</td>
                  <td>{item.discount || "N/A"}</td>
                  <td>{item.offerDiscount || "N/A"}</td>
                  <td>
                    <button className={item?.status ? "active-btn" : "active-btn"}>
                      <div className='dot'></div>
                      {item?.status ? "Active" : "Inactive"}
                    </button>
                  </td>
                  {checkAccess('manage_price_group') && (
                    <td>
                      <Link to={{ pathname: `addprice/${item.id}` }}>
                        <EditIcon />
                      </Link>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: "center", fontStyle: "italic", color: "#666" }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
    </div>
    <div className="account-table-page-alignment">
      {/* <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="account-table-design">
        <table>
          <thead>
          <tr>
              <th><div className="tableSize"></div></th>
              <th><div className="tableSize"></div></th>
              <th><input type="text" placeholder="Name" value={Name} onChange={(e) => setNameTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Code" value={Code} onChange={(e) => setCodeTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Price" value={Price} onChange={(e) => setPriceTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Discount" value={Discount} onChange={(e) => setDiscountTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th><input type="text" placeholder="Offer Discount" value={OfferDiscount} onChange={(e) => setOfferDiscountTrem(e.target.value)} className="w-100 border-0 table-input-background" /></th>
             
             
             <th style={{width:'10%'}}>
                <div>
                  <select
                    value={Status === undefined ? "" : Status.toString()} 
                    onChange={(e) => {
                      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
                      setStatusTrem(selectedStatus); 
                    }}
                    className="w-100 border-0 table-input-background"
                  >
                    <option value="">All</option>  
                    <option value="true">Active</option>    
                    <option value="false">Inactive</option>
                  </select>
                </div>
              </th>
            </tr>

            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedIds.length === PriceList.length && PriceList.length > 0}
                />
              </th>
              {checkAccess('manage_price_group')&&<th align="center">Action</th>}
              <th>Name</th>
              <th>Code</th>
              <th>Price</th>
              <th>Discount(%)</th>
              <th>Offer Discount(%)</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {productData && productData.length > 0 ? (
              productData.map((item) => (
                <tr key={item.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                  {checkAccess('manage_price_group') && (
                    <td>
                      <Link to={{ pathname: `addprice/${item.id}` }}>
                        <EditIcon />
                      </Link>
                    </td>
                  )}
                  <td>
                    <span className="price-group-text">{item.name || "N/A"}</span>
                  </td>
                  <td>{item.code || "N/A"}</td>
                  <td>{item.price || "N/A"}</td>
                  <td>{item.discount || "N/A"}</td>
                  <td>{item.offerDiscount || "N/A"}</td>
                  <td>
                    <button className={item?.status ? "Active" : "Inactive"}>
                      {item?.status ? "Active" : "Inactive"}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: "center", fontStyle: "italic", color: "#666" }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="pagination-alignment">
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div> */}
    </div>
    </>
  );
}
