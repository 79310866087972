import React from "react";
import { Box, Typography, Button, Tab, Tabs, } from "@mui/material";
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import AccountTableHeader from "../account/accountTableHeader";
import ActivityDailyReport from "./activityDailyReport";
import MissedCustomerVisitReport from "./missedCustomerVisitReport";
import MissedLeadVisitReport from "./missedLeadVisitReport";
import OrderDailyReport from "./orderDailyReport";
import UserDailyReport from "./userDailyReport";

// Main Component
export default function DailyActivityReport() {
    const [mainTabValue, setMainTabValue] = React.useState(0); // Tracks main tabs like Customer, User, Area, etc.

    const handleMainTabChange = (event, newValue) => {
        setMainTabValue(newValue);
    };

    // Function to render the corresponding component based on selected tab
    const renderTabContent = () => {
        switch (mainTabValue) {
            case 0:
                return <ActivityDailyReport />;
            case 1:
                return <UserDailyReport />;
            case 2:
                return <OrderDailyReport />;
            case 3:
                return <MissedCustomerVisitReport />;
            case 4:
                return <MissedLeadVisitReport />;
            default:
                return <Typography variant="h6">No Data Available</Typography>;
        }
    };

    return (
        <div className="page-px-24">
            <div className="customer-header-alignment pb-4">
                <div>
                    <h2>Daily Activity Report by </h2>
                    <Breadcumbs activePath="Daily Activity Report" pageName="Daily Activity Report" />
                </div>
                <div className="right-contnet-alignment">
                    <GlobalButton text="PRINT" />
                    <GlobalButton text="EXPORT" lightbutton />
                </div>
            </div>
            <AccountTableHeader />
            <Tabs value={mainTabValue} onChange={handleMainTabChange}>
                <Tab label="Activity" />
                <Tab label="Users" />
                <Tab label="Order" />
                <Tab label="Missed Customer Visit" />
                <Tab label="Missed Lead Visit" />
            </Tabs>

            {/* Content Rendering */}
            <div className="pt-4">{renderTabContent()}</div>
        </div>
    );
}
