import React, { useEffect, useState } from 'react';
import './prioritydetails.scss';
import Prioritytable from '../prioritytable';
import { fetchData } from '../../../services/apiService';

export default function Prioritydetails() {
    const [productData, setProductData] = useState([]);
    const [sortOrder, setSortOrder] = useState('');
    const [showNewestFirst, setShowNewestFirst] = useState(false);
    const [showCategoryWise, setShowCategoryWise] = useState(false);

    useEffect(() => {
        fetchData('product')
            .then((response) => {
                console.log('Fetched response:', response);
                const data = Array.isArray(response.data) ? response.data : [];
                setProductData(data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setProductData([]);
            });
    }, []);

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
    };

    const handleNewestFirstChange = () => {
        setShowNewestFirst(!showNewestFirst);
    };

    const handleCategoryWiseChange = () => {
        setShowCategoryWise(!showCategoryWise);
    };

    const sortProductData = () => {
        let sortedData = [...productData];

        if (sortOrder === 'A to Z') {
            sortedData.sort((a, b) => a.name.localeCompare(b.name));
        } else if (sortOrder === 'Z to A') {
            sortedData.sort((a, b) => b.name.localeCompare(a.name));
        } else if (sortOrder === 'Last updated first') {
            sortedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
        } else if (sortOrder === 'Last inserted first') {
            sortedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }

        if (showNewestFirst) {
            sortedData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        }

        if (showCategoryWise) {
            const groupedByCategory = sortedData.reduce((acc, product) => {
                const category = product.category || 'Uncategorized';
                if (!acc[category]) {
                    acc[category] = [];
                }
                acc[category].push(product);
                return acc;
            }, {});

            sortedData = Object.values(groupedByCategory).flat();
        }

        return sortedData;
    };

    const sortedProductData = sortProductData();

    return (
        <div className='priority-details-all-contnet-alignment'>
            <div className='grid'>
                <div className='grid-items' style={{ borderRight: '1px solid #ddd' }}>
                    <div style={{marginTop:25}}>
                        <div className='radion-button'>
                            <input
                                type='checkbox'
                                id='newestFirst'
                                checked={showNewestFirst}
                                onChange={handleNewestFirstChange}
                            />
                            <div>
                                <label>Show newest product first</label>
                            </div>
                        </div>

                        <div className='radion-button'>
                            <input
                                type='checkbox'
                                id='categoryWise'
                                checked={showCategoryWise}
                                onChange={handleCategoryWiseChange}
                            />
                            <div>
                                <label>Show product category wise</label>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop:30}}>
                        <h5>Sorting Options</h5>

                        <div className='radion-button'>
                            <input
                                type='radio'
                                id='sortAtoZ'
                                name='sortOrder'
                                value='A to Z'
                                checked={sortOrder === 'A to Z'}
                                onChange={handleSortOrderChange}
                            />
                            <div>
                                <label>A to Z</label>
                            </div>
                        </div>

                        <div className='radion-button'>
                            <input
                                type='radio'
                                id='sortZtoA'
                                name='sortOrder'
                                value='Z to A'
                                checked={sortOrder === 'Z to A'}
                                onChange={handleSortOrderChange}
                            />
                            <div>
                                <label>Z to A</label>
                            </div>
                        </div>

                        <div className='radion-button'>
                            <input
                                type='radio'
                                id='sortLastUpdated'
                                name='sortOrder'
                                value='Last updated first'
                                checked={sortOrder === 'Last updated first'}
                                onChange={handleSortOrderChange}
                            />
                            <div>
                                <label>Last updated first</label>
                            </div>
                        </div>

                        <div className='radion-button'>
                            <input
                                type='radio'
                                id='sortLastInserted'
                                name='sortOrder'
                                value='Last inserted first'
                                checked={sortOrder === 'Last inserted first'}
                                onChange={handleSortOrderChange}
                            />
                            <div>
                                <label>Last inserted first</label>
                            </div>
                        </div>
                    </div>


                </div>

                <div className='grid-items' style={{ width: '100%', padding: '10px', borderRadius: '5px' }}>
                    <Prioritytable productData={sortedProductData} />
                </div>
            </div>
        </div>
    );
}
