import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import { useSelector } from 'react-redux';

export default function Leaveview() {
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getData();
        }
    }, [id]);

    const [formData, setFormData] = useState({
        status: 'Pending',
    });

    const getData = async () => {
        try {
            const response = await fetchsingleData(`leave`, id);
            if (response?.data) {
                setFormData(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const updateStatus = async (status) => {
        try {
            const updatedData = { ...formData, status };
            const response = await patchData(`leave/${id}`, updatedData);
            if (response) {
                setFormData((prevState) => ({ ...prevState, status }));
                alert(`Status updated to ${status}`);
            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    return (
        <div style={{
            marginTop: '20px',
            background: '#C0C0C0',
            padding: '20px',
            maxWidth: '2500px',
            margin: '0 auto'
        }}>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '20px'
            }}>
                <span style={{
                    fontSize: '18px',
                    fontWeight: 'bold'
                }}>Leave {'>'} List</span>
                <div style={{
                    display: 'flex',
                    gap: '10px'
                }}>
                    <a href="/order" style={{ textDecoration: 'none' }}>
                        <button style={{
                            backgroundColor: '#8CA7C6',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}>
                            Back
                        </button>
                    </a>
                    {/* Approve Button */}
                    <button 
                        onClick={() => updateStatus('Approved')} 
                        style={{
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        Approve
                    </button>
                    {/* Reject Button */}
                    <button 
                        onClick={() => updateStatus('Rejected')} 
                        style={{
                            backgroundColor: '#f44336',
                            color: 'white',
                            padding: '10px 20px',
                            borderRadius: '5px',
                            border: 'none',
                            cursor: 'pointer',
                            height: '30px',
                            fontSize: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        Reject
                    </button>
                </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '2500px', }}>
                <div style={{
                    marginTop: '1rem',
                    padding: '1.5rem',
                    backgroundColor: '#fff',
                    border: '1px solid rgba(30,46,80,.09)',
                    borderRadius: '0.25rem',
                    boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
                    width: '900px',
                    scrollBehavior: 'smooth',
                    height: 'auto',
                    overflowY: 'scroll',
                }}>
                    <>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <h6 style={{ paddingBottom: '5px' }}>Leave from,</h6>
                                <p style={{ fontSize: '0.85rem' }}>
                                    {formData.username}
                                </p>
                            </div>
                        </div>
                        <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                                <div style={{ flex: '1', marginRight: '1rem' }}>
                                    <strong>
                                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Leave Date.</div>
                                    </strong>
                                    {dayjs(formData.date).format('DD-MM-YYYY')}
                                </div>
                                <div style={{ flex: '1', textAlign: 'center' }}>
                                    <strong>
                                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Leave Type</div>
                                    </strong>
                                    {formData.leavetype}
                                </div>
                                <div style={{ flex: '1', textAlign: 'right' }}>
                                    <strong>
                                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Leave Weight</div>
                                    </strong>
                                    {(formData.leaveweight)}
                                </div>
                            </div>
                        </div>
                        <div style={{ borderTop: '1px solid #edf2f9', paddingTop: '1rem', marginTop: '1rem' }}>
                            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                                <div style={{ flex: '1', marginRight: '1rem' }}>
                                    <strong>
                                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Reason:</div>
                                    </strong>
                                    <strong>{formData.customerName}</strong>
                                    <p style={{ fontSize: '0.75rem', marginTop: '0.5rem' }}>
                                        {formData.reason}
                                    </p>
                                </div>
                                <div style={{ flex: '1', textAlign: 'right' }}>
                                    <strong>
                                        <div style={{ fontSize: '0.75rem', marginBottom: '0.5rem' }}>Leave Status</div>
                                    </strong>
                                    {formData.status}
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
}
