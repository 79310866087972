import React, { useState } from 'react'
import Button from '../../shared/components/button';
import './city.scss';
import { Link } from 'react-router-dom';
import CityTable from './citytable';
import { deleteData, filterAll } from '../../services/apiService';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import AccountTableHeader from '../account/accountTableHeader';
export default function City() {

  const [selectedIds, setSelectedIds] = useState([]);
  const [cityList, SetCityList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`city/${id}`)));
      SetCityList(cityList.filter(i => !selectedIds.includes(i?.id)));

      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const handleExport = () => {
    const headers = [
      ["City Name","Country Name","State Name" ,"Status"]

    ];
 
    filterAll("city")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.cityName,
      item.countryName,
      item.stateName,
      item.status ? "Active" : "Inactive", 
      ]);
      exportToExcel('City',headers,datas,'city')
    })
  };

  const [open, setOpen] = useState(false);
const [confirmLoading, setConfirmLoading] = useState(false);

const showPopconfirm = () => {
  setOpen(true);
};

const handleOk = async () => {
  setConfirmLoading(true);

  await handleDelete();
  setOpen(false);
  setConfirmLoading(false);
  
};

const handleCancel = () => {
  console.log('Clicked cancel button');
  setOpen(false);
};

  return (
    <div className='page-24'>
      {/* <div className='page-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>City</span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
          {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
          <Link to="addcity"><Button text='Add City'/></Link>
        </div>
      </div> */}
      <div className="">
        <div className="pb-4 customer-header-alignment">
          <div>
            <h2>City</h2>
            <Breadcumbs activePath="City" pageName="City" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
            <Popconfirm
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            title="Are you sure to delete this record? "
            description="This will lead to delete all records related to this record only!"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
          </Popconfirm>
            )}
            <Link to="addcity">
              <GlobalButton text="Add City" Icon={PlusIcon} />
            </Link>
            <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />
          </div>
        </div>
      </div>

      <div className=''>
        <CityTable
        selectedIds={selectedIds} 
        setSelectedIds={setSelectedIds} 
        cityList={cityList}
        setProductList={SetCityList}
        totalItems={totalItems}
        setTotalItems={setTotalItems}
        />
      </div>
    </div>
  )
}
