import React, { useContext, useState } from 'react';
import './dispatch.scss'; // Update the SCSS file name
import Link from 'antd/es/typography/Link';
import { deleteData, filterAll } from '../../services/apiService';
import { useSelector } from 'react-redux';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg"; // Replace if needed
import ExportIcon from "../../assets/icons/export.svg"; // Replace if needed
import { useNavigate } from 'react-router-dom';
import Dispatchtable from './dispatchtable';
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";

import { checkAccess } from '../../services/checkFeature';

export default function DispatchOrder() {
  const user = useSelector((state) => state.user.user);
  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`dispatchorder/${id}`))); // Update endpoint
      const tempActivityList = activityList.filter(item => !(selectedIds.includes(item.id)))
      setActivityList(tempActivityList);

      setTotalItems(totalItems - selectedIds.length)
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };
  const handleExport = () => {
    const headers = [
      ["Dispatch Code","Customer", "Date","Amount", "Created by", "Status"]

    ];
 
    filterAll("dispatchorder")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.dispatchCode,
      item.customerName,
      item.date,
      item.productDetails?.subTotal,
      item.created_by,
      item.orderStatus,
      ]);
      exportToExcel('Dispatchorder',headers,datas,'dispatchorder')
    })
  };

  return (
    <div className='page-24'>
      <div className="">
        <div className="customer-header-alignment pb-4">
          <div>
            <h2>Dispatch Order</h2> {/* Update title */}
            <Breadcumbs activePath="Dispatch Order" pageName="Dispatch Order" /> {/* Update breadcrumbs */}
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
             <Popconfirm
             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             title="Are you sure to delete this record? "
             description="This will lead to delete all records related to this record only!"
             open={open}
             onConfirm={handleOk}
             okButtonProps={{ loading: confirmLoading }}
             onCancel={handleCancel}
           >
             <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
           </Popconfirm>
            )}
            {checkAccess("add_dispatch")?(<Link onClick={() => navigate("/order/adddispatchorder")}> {/* Update navigation path */}
              <GlobalButton text="Add Dispatch Order" Icon={PlusIcon} /> {/* Update button text */}
            </Link>):(<></>)}
            {checkAccess("export_dispatch")?(<GlobalButton text="Export" lightbutton Icon={ExportIcon} />):(<></>)}
          </div>
        </div>
      </div>
      <Dispatchtable
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        activityList={activityList}
        totalItems={totalItems} 
        setTotalItems={setTotalItems}
        setActivityList={setActivityList}
      />
    </div>
  );
}
