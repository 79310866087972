import React, { useState } from 'react';
import './priority.scss';
import Prioritydetails from './prioritydetails';
import Category from './categorydetails';

export default function Priority() {
  const [activeTab, setActiveTab] = useState('product');

  return (
    <div className="priority-container">
      <div className="priority-header">
        <h2>Priority</h2>
      </div>

      {/* Tab Navigation */}
      <div className="tabs">
        <div
          className={`tab ${activeTab === 'product' ? 'active' : 'inactive'}`}
          onClick={() => setActiveTab('product')}
        >
          Product
        </div>
        <div
          className={`tab ${activeTab === 'category' ? 'active' : 'inactive'}`}
          onClick={() => setActiveTab('category')}
        >
          Category
        </div>
      </div>

      {/* Tab Content */}
      {activeTab === 'product' && <Prioritydetails />}
      {activeTab === 'category' && <Category />}
    </div>
  );
}
