import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom"; // For accessing URL parameters
import { fetchData, fetchsingleData, patchData, postData } from "../../../services/apiService";
import './quoteview.scss';
import ProfileIcon from '../../../assets/icons/profile.webp';
import { Link } from "react-router-dom";
import jsPDF from 'jspdf';
import Quotetable from "../quotetable";
import GlobalButton from "../../../shared/globalButton";
import { useNavigate } from "react-router-dom";
import { Modal } from 'antd';
import Pricegroup from "../../pricegroup";
import { checkAccess } from "../../../services/checkFeature";
export default function Quotationview() {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the ID from the URL
  const [quotation, setQuotation] = useState(null);

  useEffect(() => {
    getData();
  }, [id]);
  const pdfRef = useRef();
  const getData = async () => {
    try {
      const response = await fetchsingleData("quotation", parseInt(id));
      if (response?.data) {
        // const selectedQuotation = response.data.find((q) => q.id === parseInt(id));
        setQuotation(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const updateQuotationStatus = async (newStatus) => {
    try {
      const updatedQuotation = { ...quotation, quotationstatus: newStatus };
      await patchData(`quotation/${id}`, updatedQuotation);
      setQuotation(updatedQuotation); // Update the local state
    } catch (error) {
      console.error(`Error updating status to ${newStatus}:`, error);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRequired, SetisRequired] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
    SetisRequired(true)
  };

  const handleYes = () => {
    convertToOrder()
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    SetisRequired(false)
  };
  const handleGeneratePdf = () => {
    const pdfWidth = 420;
    const pdfHeight = 370;

    const doc = new jsPDF({
      orientation: 'b5',
      unit: 'px',
      format: [pdfWidth, pdfHeight],
    });
    doc.html(pdfRef.current, {
      callback: (doc) => {
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const newWindow = window.open(pdfUrl);
        if (newWindow) {
          newWindow.onload = () => {
            newWindow.print();
          };
        } else {
          console.error('Failed to open new window for printing');
        }
      },
      x: 20,
      y: 20,
      z: 20,
      width: pdfWidth - 20,
      windowWidth: 970,
    });
  };
  if (!quotation) {
    return <p>Loading...</p>;
  };
  function generateOrderNumber() {
    const orderNumber = Math.floor(10000 + Math.random() * 90000);
    const currentDay = new Date().getDate();
    const uniqueOrderNumber = `${orderNumber}${currentDay}`;
    return uniqueOrderNumber;
  }
  const convertToOrder = async () => {
    try {
      if (quotation?.quotationfor === "Lead" && !quotation?.lead_to_customer_id) {
        console.log("Popup state set to true");
       if(!isRequired){
        showModal();
        return;
       }
      }
      let customerName = quotation?.linkname || "";
      let responsecustomer = {};
      if (quotation?.quotationfor === "Lead" && quotation?.link) {
        const leadData = await fetchsingleData('lead', quotation?.link);
        const customerPayload = {
          cName: customerName,
          code: leadData?.data?.code || '',
          priceGroup: leadData?.data?.pricegroup || '',
          outstandingAmount: leadData?.data?.outstandingAmount || 0,
          overdueBillAmount: leadData?.data?.overdueBillAmount || 0,
          email: leadData?.data?.email || '',
          password: leadData?.data?.password || '',
          address: leadData?.data?.address || '',
          title: leadData?.data?.title || '',
          pincode: leadData?.data?.pincode || '',
          countryId: 1,
          countryName: leadData?.data?.country || '',
          stateName: leadData?.data?.state || '',
          stateId: 1,
          city: leadData?.data?.city || '',
          areaId: 1,
          latitude: leadData?.latitude || null,
          longitude: leadData?.longitude || null,
          addressEmail: leadData?.addressEmail || '',
          addressContact: '',
          // pricegroupId: null
        };

        responsecustomer = await postData("customer", customerPayload);
        if (responsecustomer?.data) {
          const quotationRes = await patchData(`quotation/${id}`, {lead_to_customer_id:responsecustomer?.data?.id});
          setQuotation(quotationRes);
        }
        navigate(`/order/add?quotationId=${quotation.id}`, { state: { quotation } });

      }
      else{
        navigate(`/order/add?quotationId=${quotation.id}`, { state: { quotation } });
      }

      // const orderPayload = {
      //   orderNo: generateOrderNumber(),
      //   orderDate: quotation?.quotationDate,
      //   customerName: customerName || "",
      //   customerId: quotation?.customerid || responsecustomer?.data?.id,
      //   customerAddress: quotation?.customeraddress,
      //   customerContact: quotation?.customercontact,
      //   status: quotation?.status,
      //   orderStatus: "Pending",
      //   orderproducts: quotation?.orderproducts?.map((product) => ({
      //     index: product.index,
      //     productName: product.productName,
      //     productId: product.productId,
      //     unitId: product.unitId,
      //     unitName: product.unitName,
      //     qty: product.qty,
      //     unitPrice: product.unitPrice,
      //     discount: product.discount,
      //     tax: product.tax,
      //     subTotal: product.subTotal,
      //     productUrl: product.productUrl,
      //   })),
      //   ordernotification: [],
      //   ordercreatedby: "sales",
      //   totalAmount: quotation?.totalamount,
      // };

      // const response = await postData("order", orderPayload);
      // if (response?.data) {
      //   console.log("Order created successfully:", response.data);
      //   SetisRequired(false)
       
      // }
    } catch (error) {
      console.error("Error converting quotation to order:", error);
    }
  };

  return (
    <div className="" ref={pdfRef}>
      <div className="header bg-white">
        <Link to="/Quotation">
          <button className="back-button">← Quotation #{quotation.quotationNo}</button>
        </Link>
        <div className="actions d-flex">
          <div onClick={handleGeneratePdf} style={{ cursor: 'pointer' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="35" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16">
              <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
              <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1" />
            </svg>
          </div>
          {quotation.quotationstatus === "Pending" &&checkAccess('change_sale_quotation_approval_status')&& (<button className="action-button approve" onClick={() => updateQuotationStatus("Approved")} style={{ borderRadius: '3px' }}>Approve
          </button>)}
          {(quotation.quotationstatus === "Pending" || quotation.quotationstatus === "Approved") &&checkAccess('change_sale_quotation_approval_status')&& (
            <button className="action-button reject" onClick={() => updateQuotationStatus("Rejected")} style={{ borderRadius: '3px' }}>Reject</button>)}

          {id &&checkAccess('change_sale_quotation_approval_status')&& (
            <div className="d-flex justify-content-end">
              <GlobalButton text="Create Order" outline onClick={convertToOrder} />
            </div>
          )}
          <Modal title="Are you sure?" open={isModalOpen} onOk={handleYes} onCancel={handleCancel}>
            <div style={{ width: '10%', height: '40px' }} />
            <div>
              <p>You need to convert this lead {quotation?.linkname} to customer before making order. Do you want to convert it now ?</p>
            </div>
          </Modal>
        </div>
      </div>

      <div style={{ display: 'flex', padding: '1rem', justifyContent: 'space-between' }}>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <img
              src={ProfileIcon}
              alt="ProfileIcon"
              style={{ width: '50px', height: '50px', borderRadius: '50%' }}
            />
            <div style={{ marginLeft: '1rem' }}>
              <h6 style={{ fontWeight: 'bold', margin: 0 }}>SalesEasy</h6>
            </div>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <p style={{ margin: 0, fontSize: '0.80rem' }}>Quotation To,</p>
            <p style={{ fontWeight: 'bold', margin: '0.1rem ', fontSize: '0.80rem' }}>
              {quotation?.linkname} [{quotation?.quotationfor}]
            </p>
            <p style={{ margin: 0, fontSize: '0.80rem' }}>{quotation?.customeraddress}</p>
          </div>
        </div>
        <div>
          <p style={{ fontWeight: 'bold', margin: '0.1rem', fontSize: '0.80rem' }}>
            QUOTATION <span style={{ fontWeight: 'normal' }}>#{quotation?.quotationNo}</span>
          </p>
          <p style={{ display: 'flex', margin: '0.1rem', fontSize: '0.80rem' }}>
            <p>Quotation Date:{' '}</p>
            <p style={{ fontWeight: 'bold', margin: '0.1rem', fontSize: '0.80rem' }}>
              {quotation?.quotationDate
                ? new Date(quotation.quotationDate).toLocaleDateString()
                : 'N/A'}
            </p>
          </p>
          <p style={{ display: 'flex', margin: '0.1rem', fontSize: '0.80rem' }}>
            Quotation Status: <p style={{ fontWeight: 'bold', margin: 0, fontSize: '0.80rem' }}>{quotation?.quotationstatus || 'N/A'}</p>
          </p>
          <p style={{ display: 'flex', margin: '0.1rem', fontSize: '0.80rem' }}>
            Created By: <p style={{ fontWeight: 'bold', margin: 0, fontSize: '0.80rem' }}>{quotation?.created_by || 'N/A'}</p>
          </p>
        </div>
      </div>

      <div className="items-table p-4">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Item</th>
              <th>Unit</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Discount</th>
              <th>Sub Amount</th>
            </tr>
          </thead>
          <tbody>
            {quotation.orderproducts.map((item, index) => (
              <tr key={index}>
                <td>{item.index}</td>
                <td>{item.name}</td>
                <td>{item.unitName}</td>
                <td>{item.qty}</td>
                <td>₹ {item.unitPrice}</td>
                <td>{item.discount} %</td>
                <td>₹ {item.subTotal}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="6" className="text-right">Sub Total</td>
              <td>₹ {quotation.totalamount}</td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="terms-conditions p-4">
        <h6>Terms & Conditions</h6>
      </div>
    </div>
  );
}
