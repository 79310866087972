import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { deleteData } from '../../services/apiService';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import LeadVisitTable from './leadvisittable';
import AccountTableHeader from '../account/accountTableHeader';


export default function LeadVisit() {

  const [leadvisit, SetLeadVisit] = useState([]);


  return (
    <div className='page-24'>
      <div className="">
        <div className="customer-header-alignment pb-4">
          <div>
            <h2>Lead Visit</h2>
            <Breadcumbs activePath="Lead Visit" pageName="Lead Visit" />
          </div>
        </div>
      </div>
      <AccountTableHeader/>
      <div className=''>
        <LeadVisitTable
        leadvisit={leadvisit}
        SetLeadVisit={SetLeadVisit}/>
      </div>
    </div>
  )
}
