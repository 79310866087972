import React, { useEffect, useState } from 'react';
import './activitytypetable.scss';
import { fetchData } from '../../../services/apiService';
import { Link } from 'react-router-dom';
import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';
import { ChecksumAlgorithm } from '@aws-sdk/client-s3';
import { checkAccess } from '../../../services/checkFeature';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce';
export default function Activitytypetable({ selectedIds, setSelectedIds, setActivityList }) {


  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [activityList, setProductList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10; // Items per page
  const [name, setName] = useState("");
  const [activityTemplate, setActivityTemplate] = useState("");
  const [status, setStatus] = useState(undefined);
  const [productData, setProductData] = useState([]);
  const [allactivityType, setallactivityTypeTerm] = useState('');
  useEffect(() => {
    const filters = {
      name: name,
      activityTemplate: activityTemplate,
      status: status
    };

    fetchactvity(filters, true, currentPage, pageSize)
  }, [currentPage]);



  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(activityList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = activityList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };
  const fetchactvity = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('activitytype', removeEmptyFields(filters),
        pagination,
        {},
        isSearching,
        globalSearch
      );
      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      name: name,
      activityTemplate: activityTemplate,
      status: status,
    };

    const debouncedFetch = debounce(() => {
      fetchactvity(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [name, activityTemplate, status, currentPage, pageSize]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['name', 'activityTemplate'],
      searchtext: allactivityType,
    };

    const debouncedSearchFetch = debounce(() => {
      fetchactvity({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedSearchFetch();

    return () => {
      debouncedSearchFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);


  return (
    <div className=''>
      <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className='account-table-design'>
        <table>
          <thead>
            <tr>
              <th><div className="w-100"></div></th>

              <th><div className="w-100"></div></th>

              <th><div><input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-100 border-0 table-input-background"

              /></div></th>

              <th><div ><input
                type="text"
                placeholder=" Activity template"
                value={activityTemplate}
                onChange={(e) => setActivityTemplate(e.target.value)}
                className="w-100 border-0 table-input-background"

              /></div></th>

              {/* <th><div> <input
                type="text"
                placeholder="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                 className="w-100 border-0 table-input-background"

              /></div></th> */}
              <th>

                <div>
                  <select
                    value={status === undefined ? "" : status.toString()} // Default to empty string when status is undefined
                    onChange={(e) => {
                      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
                      console.log("Selected Status:", selectedStatus); // Logs the value as boolean or undefined
                      setStatus(selectedStatus); // Updates the state with true, false, or undefined (for all)
                    }}
                    className="w-100 border-0 table-input-background"
                  >
                    <option value="">All</option>  {/* Default option to show all */}
                    <option value="true">Active</option>     {/* Option for true */}
                    <option value="false">Inactive</option> {/* Option for false */}
                  </select>
                </div>





              </th>

            </tr>
            <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
              {checkAccess('manage_activity_type') && <th>Action</th>}
              <th>Name</th>
              <th>Activity template</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {(productData ?? []).length > 0 ? (
              productData.map((item) => (
                <tr key={item?.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                  {checkAccess('manage_activity_type') && (
                    <td>
                      <Link to={{ pathname: `addEditactivitytype/${item?.id}` }}>
                        <EditIcon />
                      </Link>
                    </td>
                  )}
                  <td>{item?.name}</td>
                  <td>{item?.activityTemplate}</td>
                  <td>
                    <button className={item?.status ? "Active" : "Inactive"}>
                      {item?.status ? "Active" : "Inactive"}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} style={{ textAlign: "center", fontStyle: "italic" }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
        <div className='pagination-alignment'>
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems} // Total items from API response
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}
