import React, { useState, useContext,useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { useSelector } from 'react-redux';
import { fetchorderdisptchsummary } from '../../../services/apiService';
import store from '../../../redux/reduxstore';
import like from '../../../assets/icons/like.svg';
import beer from '../../../assets/icons/beer.svg';
import chat from '../../../assets/icons/chart-simple.png';
import square from '../../../assets/icons/square-plus.svg';
import sugar from '../../../assets/icons/sugar-cubes.png';
import ToogleIcon from '../../../assets/icons/toggle.svg';
import truck from '../../../assets/icons/delivery-truck.svg';
import './sidebar.scss';
import { checkAccess } from '../../../services/checkFeature'
import Dashboard from '../../../routes/dashboard';
import UserIcon from '../../../assets/icons/user-icons.svg';
import SingleLogo from '../../../assets/logo/single-logo.svg';
import TextLogo from '../../../assets/logo/text-logo.svg';
import DashboardIcon from '../../../assets/icons/dashboard.png';
import ActivityIcon from '../../../assets/icons/activity.png';
import CatlogIcon from '../../../assets/icons/catlog.png';
import CustomersIcon from '../../../assets/icons/customers.png';
import QuotationIcon from '../../../assets/icons/Quotation.png';
import DispatchIcon from '../../../assets/icons/Dispatch.png';
import OrderIcon from '../../../assets/icons/order.png';
import SalesIcon from '../../../assets/icons/sales.png';
import EstimateIcon from '../../../assets/icons/Estimate.png';
import InvoiceIcon from '../../../assets/icons/Invoice.png';
import PaymentIcon from '../../../assets/icons/payment.png';
import ExpenseIcon from '../../../assets/icons/Expense.png';
import DealIcon from '../../../assets/icons/deal.png';
import LeadIcon from '../../../assets/icons/lead.png';
import VanIcon from '../../../assets/icons/van.png';
import SubscriptionIcon from '../../../assets/icons/Subscription.png';
import LocationTrackingIcon from '../../../assets/icons/location-tracking.png';
import SettingsIcon from '../../../assets/icons/setting.png';
export default function Sidebar({ onSidebarItemClick }) {
    const [toogle, setToogle] = useState(false);
    const [toogle2, setToogle2] = useState(false);
    const [toogle3, setToogle3] = useState(false);
    const [toogle4, setToogle4] = useState(false);
    const [toogle5, setToogle5] = useState(false);
    const [toogle6, setToogle6] = useState(false);
    const [toogle7, setToogle7] = useState(false);
    const [ sidebarToogle , setSidebarToogle ] = useState(false);
    const [currentdateorder,setCurrentdateorder]=useState([]);
    const location = useLocation(); // Get the current location
    const user = useSelector(state => state.user.user);

    const features = store.getState().auth.feature;
    const company_setting = store.getState()?.auth?.company_setting;

    console.log("features", features)

    const handleItemClick = (title) => {
        setToogle(false)
        onSidebarItemClick(title);
    };
     useEffect(() => {
            getData();
        }, []);
    
        const getData = async () => {
            try {
            
                const response = await fetchorderdisptchsummary('order/currentdateorder');
                if (response?.data) {
                    setCurrentdateorder(response.data || []);
    
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

    const isActive = (path) => {
        return location.pathname === path ? 'active' : ''; 
    };

    return (
        <div className={sidebarToogle ? 'sidebar sidebar-lg' : 'sidebar' }>
        <div className='sidebar-header'>
            <div className='two-logo-alignment'>
            <img src={SingleLogo} alt='SingleLogo'/>
            <img src={TextLogo} alt='TextLogo'/>
            </div>
            <div className='toogle-icons-alignment' onClick={()=> setSidebarToogle(!sidebarToogle)}>
                <img src={ToogleIcon} alt='ToogleIcon'/>
            </div>
        </div>
            <div className='sidebar-body'>
                <Link to="/dashboard" className={`sidebar-menu ${isActive('/dashboard')}`} onClick={() => handleItemClick("Dashboard")} >
                    <img src={DashboardIcon} alt='DashboardIcon'/>
                    <span>Dashboard</span>
                </Link>
                <Link to="/activity" className={`sidebar-menu ${isActive('/activity')}`} onClick={() => handleItemClick("Activity")}>
                    <img src={ActivityIcon} alt='ActivityIcon'/>
                    <span>My Activity</span>
                </Link>

                <div className={`sidebar-menu ${isActive('/priority') || isActive('/product') || isActive('/pricegroup') || isActive('/product-unit') || isActive('/product-category')}`} onClick={() => { setToogle(!toogle); }}>
                    <div className=''>
                        <img src={CatlogIcon} alt="CatlogIcon" />
                    </div>
                    <div className='all-content-alignment'>
                        <span>Catalog</span>
                        <div className={toogle ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
                <div className={toogle ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    <Link to="/product" className={`${isActive('/product')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Product
                        </div>
                    </Link>
                    <Link to="/pricegroup" className={`${isActive('/pricegroup')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Price Group
                        </div>
                    </Link>
                    <Link to="/product-category" className={`${isActive('/product-category')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Category
                        </div>
                    </Link>
                    <Link to="/product-unit" className={`${isActive('/product-unit')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Unit
                        </div>
                    </Link>
                    <Link to="/priority" className={`${isActive('/priority')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Priority
                        </div>
                    </Link>
                </div>

                <Link to="/account" className={`sidebar-menu ${isActive('/account')}`} onClick={() => handleItemClick("Customer")}>
                  <img src={CustomersIcon} alt='CustomersIcon'/>
                    <span>Customers</span>
                </Link>

                {company_setting?.system_config?.enableSaleQuotation === "Yes" && (
                    <Link to="/Quotation" className={`sidebar-menu ${isActive('/Quotation')}`} onClick={() => handleItemClick("Quotation")}>
                        <img src={SalesIcon} alt='SalesIcon'/>
                        <span>Sale Quotation</span>
                    </Link>
                )}
                <Link to="/order" className={`sidebar-menu ${isActive('/order')}`} onClick={() => handleItemClick("Orders")}>
                    <img src={OrderIcon} alt='OrderIcon'/>
                    <span>
                        Order {currentdateorder?.totalOrders ? `(${currentdateorder.totalOrders})` : ''}
                    </span>
                </Link>
                <Link to="/adddispatchorder" className={`sidebar-menu ${isActive('/adddispatchorder')}`} onClick={() => handleItemClick("Orders")}>
                    <div className='svglogo'>
                        <img src={DispatchIcon} alt='DispatchIcon'/>
                    </div>
                    <span>Dispatch</span>
                </Link>
                {company_setting?.system_config?.enableSalesReturn === "Yes" && (
                    <Link to="/salesReturn" className={`sidebar-menu ${isActive('/salesReturn')}`} onClick={() => handleItemClick("salesReturn")}>
                        <img src={CustomersIcon}/>
                        <span>Sales Return</span>
                    </Link>
                )}
                
                    <Link to="/estimat" className={`sidebar-menu ${isActive('/estimat')}`} onClick={() => handleItemClick("estimat")}>
                        <div className='svglogo'>
                       <img src={EstimateIcon} alt='EstimateIcon'/>
                        </div>
                        <span>Estimate</span>
                    </Link>
            

                {company_setting?.system_config?.enableInvoice === "Yes" && (
                    <Link to="/invoice" className={`sidebar-menu ${isActive('/invoice')}`} onClick={() => handleItemClick("Invoice")}>
                       <img src={InvoiceIcon} alt='InvoiceIcon'/>
                        <span>Invoice</span>
                    </Link>
                )}
                <Link to="/payment-collection" className={`sidebar-menu ${isActive('/payment-collection')}`} onClick={() => handleItemClick("Payments")}>
                    {/* <i className="fa-solid fa-cart-shopping"></i> */}
                    <img src={PaymentIcon} alt='PaymentIcon'/>
                    <span>Payment</span>
                </Link>
                <Link to="/expense" className={`sidebar-menu ${isActive('/expense')}`} onClick={() => handleItemClick("Expenses")}>
                    <div className='svglogo size'>
                        <img src={ExpenseIcon} alt="ExpenseIcon" />
                    </div>
                    <span>Expense</span>
                </Link>


                {checkAccess("access_all_deal") ? (<Link to="/deal" className={`sidebar-menu ${isActive('/deal')}`} onClick={() => handleItemClick("Deals")}>
                    {/* <i className="fa-solid fa-cart-shopping"></i> */}
                    <div className='svglogo size'>
                        <img src={DealIcon} alt='DealIcon'/>
                    </div>
                    <span>Deal</span>
                </Link>) : (<></>)}
                <Link to="/lead" className={`sidebar-menu ${isActive('/lead')}`} onClick={() => handleItemClick("Leads")}>
                    <div className='svglogo size'>
                        <img src={LeadIcon} alt="LeadIcon" />
                    </div>
                    <span>Lead</span>
                </Link>


                {checkAccess('manage_location') && <div className={`sidebar-menu ${isActive('/location') || isActive('/current-location') || isActive('/all-location') || isActive('/attendance') || isActive('/leave')}`} onClick={() => { setToogle2(!toogle2); }}>
                    <img src={LocationTrackingIcon} alt='LocationTrackingIcon'/>
                    <div className='all-content-alignment'>
                        <span>Location Tracking</span>
                        <div className={toogle2 ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>}
                <div className={toogle2 ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    <Link to="/location" className={`${isActive('/location')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Dashboard
                        </div>
                    </Link>
                    <Link to="/current-location" className={`${isActive('/current-location')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Current Location
                        </div>
                    </Link>
                    <Link to="/all-location" className={`${isActive('/all-location')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            All Locations
                        </div>
                    </Link>
                    <Link to="/customervisit" className={`${isActive('/customervisit')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Customer Visit
                        </div>
                    </Link>
                    <Link to="/leadvisit" className={`${isActive('/leadvisit')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Lead Visit
                        </div>
                    </Link>
                    <Link to="/maplocator" className={`${isActive('/maplocator')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Map Locator
                        </div>
                    </Link>
                    <Link to="/attendance" className={`${isActive('/attendance')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Attendance
                        </div>
                    </Link>
                    <Link to="/leave" className={`${isActive('/leave')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Leave
                        </div>
                    </Link>
                </div>
                <div className={`sidebar-menu ${isActive('/vehicle') || isActive('/vansales')}`} onClick={() => { setToogle7(!toogle7); }}>
                    <img src={VanIcon} alt='VanIcon'/>
                    <div className='all-content-alignment'>
                        <span>Van sales</span>
                        <div className={toogle7 ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
                <div className={toogle7 ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    <Link to="/vansales" className={`${isActive('/vansales')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            Van Sales
                        </div>
                    </Link>
                    <Link to="/vehicle" className={`${isActive('/vehicle')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            Vehicle
                        </div>
                    </Link>
                </div>

                {checkAccess('all_dispatch_report') ? (<div className={`sidebar-menu ${isActive('/orderwise-report') || isActive('/productwise-report')}`} onClick={() => { setToogle3(!toogle3); }}>
                    <div className='svglogo size'>
                      <img src={CustomersIcon}/>
                    </div>
                    <div className='all-content-alignment'>
                        <span>Pending Dispatch</span>
                        <div className={toogle3 ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>) : (<></>)}
                <div className={toogle3 ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    {checkAccess('orderwise_dispatch_report') ? (<span><Link to="/orderwise-report" className={`${isActive('/orderwise-report')}`}>Orderwise</Link></span>) : (<></>)}
                    {checkAccess('productwise_dispatch_report') ? (<span><Link to="/productwise-report" className={`${isActive('/productwise-report')}`}>Productwise</Link></span>) : (<></>)}
                </div>

                <div className={`sidebar-menu ${isActive('/orderReport')}`} onClick={() => { setToogle4(!toogle4); }}>
                    <div className='svglogo size'>
                        <img src={InvoiceIcon} alt="InvoiceIcon" />
                    </div>
                    <div className='all-content-alignment'>
                        <span>Report</span>
                        <div className={toogle4 ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
                <div className={toogle4 ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    <Link to="/orderReport" className={`${isActive('/orderReport')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Order
                        </div>
                    </Link>
                    <Link to="/salesReport" className={`${isActive('/salesReport')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Sales
                        </div>
                    </Link>
                    <Link to="/paymentReport" className={`${isActive('/paymentReport')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Payment Collection
                        </div>
                    </Link>
                    <Link to="/DealValueReport" className={`${isActive('/DealValueReport')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Deal Value
                        </div>
                    </Link>
                    <Link to="/dealValuesatusReport" className={`${isActive('/dealValuesatusReport')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Deal Status Report
                        </div>
                    </Link>
                    <Link to="/saleQuotationReportReport" className={`${isActive('/saleQuotationReportReport')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Sale Quotation Report
                        </div>
                    </Link>
                    <Link to="/estimateReport" className={`${isActive('/estimateReport')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Estimate Report
                        </div>
                    </Link>
                    {/* <Link to="/dailyActivityReport" className={`${isActive('/dailyActivityReport')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Daily Activity Report
                        </div>
                    </Link> */}
                    <Link to="/deviceReport" className={`${isActive('/deviceReport')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Device  report
                        </div>
                    </Link>
                    
                </div>


                <div className={`sidebar-menu ${isActive('/user-role') || isActive('/user')}`} onClick={() => { setToogle6(!toogle6); }}>
                   <img src={CustomersIcon}/>
                    <div className='all-content-alignment'>
                        <span>User</span>
                        <div className={toogle6 ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>
                <div className={toogle6 ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    <Link to="/user" className={`${isActive('/user')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            User
                        </div>
                    </Link>
                    <Link to="/user-role" className={`${isActive('/user-role')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            User Role
                        </div>
                    </Link>
                </div>
                <div className={`sidebar-menu ${isActive('/activitytype') || isActive('/countryMaster') || isActive('/systemTypeMaster') || isActive('/systemTypeCategory') || isActive('/statetype') || isActive('/city') || isActive('/area') || isActive('/paymenttype') || isActive('/transport') || isActive('/leadstatus') || isActive('/leadsource') || isActive('/leadIndustry') || isActive('/dealpipeline') || isActive('/deallost') || isActive('/visitFeedbacktype') || isActive('/metaLeadCampaign') || isActive('/termsAndCondition') || isActive('/leavetype') || isActive('/location') || isActive('/requestdata')}`} onClick={() => { setToogle5(!toogle5); }}>
                   <img src={SettingsIcon} alt='SettingsIcon'/>
                    <div className='all-content-alignment'>
                        <span>Settings</span>
                        <div className={toogle5 ? 'animation toogle-class' : 'animation'}>
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </div>
                </div>

                <div className={toogle5 ? 'sub-menu-alignment show active' : 'sub-menu-alignment hide'}>
                    <Link to="/activitytype" className={`${isActive('/activitytype')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Activity Type
                        </div>
                    </Link>
                    <Link to="/systemTypeMaster" className={`${isActive('/systemTypeMaster')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            System Category Master
                        </div>
                    </Link>
                    <Link to="/systemTypeCategory" className={`${isActive('/systemTypeCategory')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            System Type Category
                        </div>
                    </Link>
                    <Link to="/countryMaster" className={`${isActive('/countryMaster')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Country
                        </div>
                    </Link>
                    <Link to="/statetype" className={`${isActive('/statetype')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            State
                        </div>
                    </Link>
                    <Link to="/city" className={`${isActive('/city')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            City
                        </div>
                    </Link>
                    <Link to="/area" className={`${isActive('/area')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Area
                        </div>
                    </Link>
                    <Link to="/paymenttype" className={`${isActive('/paymenttype')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Payment Type
                        </div>
                    </Link>
                    <Link to="/transport" className={`${isActive('/transport')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Transport
                        </div>
                    </Link>
                    <Link to="/leadstatus" className={`${isActive('/leadstatus')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Lead Status
                        </div>
                    </Link>
                    <Link to="/leadsource" className={`${isActive('/leadsource')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Lead Source
                        </div>
                    </Link>
                    <Link to="/leadIndustry" className={`${isActive('/leadIndustry')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Lead Industry
                        </div>
                    </Link>
                    <Link to="/dealpipeline" className={`${isActive('/dealpipeline')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Deal Pipeline
                        </div>
                    </Link>
                    <Link to="/deallost" className={`${isActive('/deallost')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Deal Lost
                        </div>
                    </Link>
                    <Link to="/visitFeedbacktype" className={`${isActive('/visitFeedbacktype')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Visit Feedback Type
                        </div>
                    </Link>
                    <Link to="/accountCategory" className={`${isActive('/accountCategory')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Account Category
                        </div>
                    </Link>
                    <Link to="/segment" className={`${isActive('/segment')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Segment
                        </div>
                    </Link>
                    <Link to="/leavetype" className={`${isActive('/leavetype')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Leave Type
                        </div>
                    </Link>
                    <Link to="/metaLeadCampaign" className={`${isActive('/metaLeadCampaign')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Meta Lead Campaign
                        </div>
                    </Link>
                    <Link to="/termsAndCondition" className={`${isActive('/termsAndCondition')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Terms And Condition
                        </div>
                    </Link>
                    <Link to="/location" className={`${isActive('/location')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Location
                        </div>
                    </Link>
                    <Link to="/requestdata" className={`${isActive('/requestdata')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Request Log
                        </div>
                    </Link>
                    
                    <Link to="/expensetype" className={`${isActive('/expensetype')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Expense Type
                        </div>
                    </Link>
                    <Link to="/workprofile" className={`${isActive('/workprofile')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                             Work Profile
                        </div>
                    </Link>
                    <Link to="/companysetting" className={`${isActive('/companysetting')}`}>
                        <div className='d-flex align-items-center gap-2 sub-menu-design'>
                            <img src={UserIcon} alt="UserIcon" />
                            Company Settings
                        </div>
                    </Link>

                </div>
                <Link to="/profile" className={`sidebar-menu ${isActive('/profile')}`} onClick={() => handleItemClick("profile")}>
                    <img src={CustomersIcon}/>
                    <span>Company Profile</span>
                </Link>
                <Link
                    to="/subscription"
                    className={`sidebar-menu ${isActive('/subscription')}`}
                    onClick={() => handleItemClick("subscription")}
                >
                    <img src={SubscriptionIcon} alt='SubscriptionIcon'/>


                    <span>Subscription</span>
                </Link>
                {/* <Link to="/setting" className={`sidebar-menu ${isActive('/setting')}`}>
                    <i className="fa-solid fa-gear"></i>
                    <span>Settings</span>
                </Link> */}
            </div>
        </div>
    )
}
