import React, { useEffect, useState } from 'react';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import './addactivity.scss'
import { fetchData, fetchsingleData, patchData, postData } from '../../../services/apiService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SelectBox from '../../../shared/components/select';
import { Form, DatePicker } from "antd";
import dayjs from 'dayjs';
import GlobalButton from '../../../shared/globalButton';
import Breadcumbs from '../../../shared/components/breadcumbs';
import { error } from 'jquery';
import StatusBox from '../../addcustomers/statusBox';



export default function AddActivity() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [activityOptions, setActivityOptions] = useState([]);
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [userNameOptions, setUserNameOptions] = useState([]);
    const [usermoduleOptions, setModuleOptions] = useState([]);
    const [linkdropDown, setlinkdropDown] = useState([]);
    const [formData, setFormData] = useState({
        activityname: '',
        activitydate: dayjs(),
        username: '',
        module: '',
        linkname: '',
        link_id: '',
        status: true,
        activityvalue: ''

    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (id) {
            getData();
        }
        fetchActivity();
        fetchUser();
        fetchModule();
    }, [id]);

    const fetchActivity = async () => {
        try {
            const response = await fetchData('activitytype');
            if (response?.data) {
                setActivityOptions(
                    response.data.map((activity) => ({
                        label: activity.name,
                        value: activity.name,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching activities:', error);
        }
    };

    const fetchUser = async () => {
        try {
            const response = await fetchData('user');
            if (response?.data) {
                setUserNameOptions(
                    response.data.map((user) => ({
                        label: user.name,
                        value: user.name,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const fetchModule = async () => {
        try {
            const response = await fetchData('module');
            if (response?.data) {
                setModuleOptions(
                    response.data.map((module) => ({
                        ...module,
                        label: module?.modulename,
                        value: module?.modulename,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching modules:', error);
        }
    };
    const getdowpDownlist = async (type) => {
        try {
            const response = await fetchData(type);
            if (response?.data) {
                const modules = await fetchData('module');
                const keyName = (modules?.data ?? []).find(f => f?.modulename?.toLowerCase() === type)?.moduleKey;
                setlinkdropDown(
                    (response?.data ?? []).map((module) => ({
                        label: module?.[keyName],
                        value: module?.id,
                    }))
                );
            }
        } catch (error) {
            console.error('Error fetching ' + type, error);
        }
    };
    const getData = async () => {
        try {
            const response = await fetchsingleData('myactivities', id);
            if (response?.data) {
                setFormData({
                    ...response.data,
                });
            }
            getdowpDownlist(response?.data?.module?.toLowerCase())
        } catch (error) {
            console.error('Error fetching myactivities data:', error);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.activitydate) newErrors.activitydate = 'activitydate is required';
        if (!formData.username) newErrors.username = 'Assign to is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
        if (errors.isSubmit) validateForm(false);
    };

    const handleSelectChange = (event, type) => {
        if (type === 'module') {
            setFormData({
                ...formData,
                [type]: event.value
            });
            getdowpDownlist(event?.value?.toLowerCase())
        }
        else {
            let formPayload = {
                ...formData,
                [type]: event.value
            }
            if (type === 'link_id') {
                formPayload = { ...formPayload, linkname: linkdropDown?.find(d => d?.value === event?.value)?.label }
            }
            setFormData(formPayload);
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();



        try {
            const method = id ? patchData : postData;  // If id exists, use patchData, else use postData
            const url = id ? `myactivities/${id}` : 'myactivities';  // If id exists, target specific activity, else use general endpoint
            console.log("validateForm", validateForm);
            method(url, formData)
                .then((data) => {
                    if (data) navigate(-1);  // Navigate after successful operation
                })
                .catch((error) => {
                    console.error('Error saving data:', error);  // Handle errors
                });
        } catch (error) {
            console.error('Error saving data:', error);  // General catch block for errors
        }

    };


    const handleDateChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            activitydate: new Date(e)
        }));
    };

    return (
        <div className='page-24'>
            {/* <div className="page-header add-news-alignment">
                <span>Activity {'>'} {id ? 'Edit' : 'Create'}</span>
                <div className="two-button-alignment">
                    <div onClick={handleSubmit}>
                        <GlobalButton text={id ? 'Update' : 'Save'} />
                    </div>
                    <Link to="/activity">
                        <GlobalButton text="Discard" outline />
                    </Link>
                </div>
            </div> */}


            <div className='add-header-design'>
                <div>
                    <h2>Add Activity</h2>
                    <Breadcumbs activePath="Dashboard" pageName="Add Activity" />
                </div>
                <div className='right-contnet-alignment'>
                    <Link to="/activity">
                        <button className='light-button'>
                            Discard
                        </button>
                    </Link>
                    { }
                    <button onClick={handleSubmit}>
                        {id ? 'Update' : 'Save'}
                    </button>
                </div>
            </div>
            <div className='page-main-grid'>
                <div>
                    <div className='new-white-box-design'>
                        <div className='two-col-grid'>
                            <SelectBox
                                label="Activity Type"
                                name="activityname"
                                options={activityOptions}
                                value={activityOptions?.find(i => i?.value === formData.activityname)}
                                onChange={(e) => handleSelectChange(e, 'activityname')}
                                style={{ width: '100%' }}
                            />
                            <Input
                                label={(formData?.activityname ? formData?.activityname : 'Activity Value')}
                                placeholder={"Enter " + (formData?.activityname ? formData?.activityname : 'Activity Value')}
                                name='activityvalue'
                                value={formData.activityvalue}
                                onChange={handleInputChange}
                                type='text'
                                required
                                errors={errors.activityvalue}
                            />
                            <div>
                                <label className='activitydate mb-1'>Activity Date <span className="required-star">*</span>
                                </label>
                                <DatePicker
                                    name="activitydate"
                                    placeholder='Select Date'
                                    value={dayjs(formData.activitydate)}
                                    onChange={handleDateChange}
                                    showTime
                                    dateFormat="yyyy-MM-dd HH:mm:ss"
                                    errors={errors.activitydate}

                                />
                            </div>
                            <SelectBox
                                label={
                                    <span>
                                        Assingn to <span className="required-star">*</span>
                                    </span>

                                }

                                name="username"
                                placeholder="Select Assign to"
                                options={userNameOptions}
                                onChange={(e) => handleSelectChange(e, 'username')}
                                value={userNameOptions?.find(i => i?.value === formData?.username)}
                                style={{ width: '100%' }}
                                errors={errors.username}
                            />
                            <SelectBox
                                label="Linked To"
                                name="module"
                                placeholder="Select Linked to"
                                options={usermoduleOptions}
                                value={usermoduleOptions?.find(i => i?.value === formData.module)}
                                onChange={(e) => handleSelectChange(e, 'module')}
                                style={{ width: '100%' }}
                            />
                            <SelectBox
                                name="link_id"
                                label={(formData?.module ? formData?.module : 'Linked')}
                                placeholder={"Select " + (formData?.module ? formData?.module : 'Linked')}
                                options={linkdropDown}
                                value={linkdropDown?.find(i => i?.value === formData.link_id)}
                                onChange={(e) => handleSelectChange(e, 'link_id')}
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                </div>
                <div>
                 
                </div>
            </div>
          
        </div>
    );
}
