import React, { useEffect, useState } from 'react';
import './dealpipelinetable.scss';
import { fetchData } from '../../../services/apiService';
import { Link } from 'react-router-dom';
import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce'; 
export default function Dealpipelinetable({selectedIds,setSelectedIds,setDealPipelineList,dealpipelinelist, setProductList, setTotalItems, totalItems}) {
    const [currentPage, setCurrentPage] = useState(1); // Current page state
    const [name, setName ]= useState("");
    const [status, setStatus] = useState(undefined);
    const [allactivityType, setallactivityTypeTerm] =useState('');
    // const [dealpipelinelist, setProductList] = useState([]);
    const pageSize = 10; // Items per page
    const [productData, setProductData] = useState([]);
    useEffect(() => {
      const filters = {
        name: name,
        status : status
      };
  
      fetchProduct(filters, true, currentPage, pageSize)
    }, [currentPage]);


    const handleCheckboxChange = (id) => {
        setSelectedIds((prevSelectedIds) => {
          if (prevSelectedIds.includes(id)) {
            return prevSelectedIds.filter((selectedId) => selectedId !== id);
          } else {
            return [...prevSelectedIds, id];
          }
        });
      };
     const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedIds  (dealpipelinelist.map((i) => i.id));
        } else {
          setSelectedIds([]);
        }
      };
    
      const handlePageChange = (page) => {
        setCurrentPage(page);
      };
    
      const paginatedData = dealpipelinelist.slice(
        (currentPage - 1) * pageSize,
        currentPage * pageSize
      );

      const removeEmptyFields = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
        );
      };
    
      const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
        try {
          const pagination = {
            page: page,
            limit: pageSize,
          };
          const responseProducts = await fetchData('dealpipeline',  removeEmptyFields(filters), 
          pagination,
          {},
          isSearching,
          globalSearch
        );
    
          if (responseProducts?.data) {
            setProductData(responseProducts.data);
    
            setProductData(responseProducts.data || []);
            setTotalItems(responseProducts?.totalDocuments || 0);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      useEffect(() => {
        const filters = {
          name: name,
          status: status
        };
    
        const debouncedFetch = debounce(() => {
          setCurrentPage(1);
          fetchProduct(filters, true);
        }, 500);
    
        debouncedFetch();
    
        return () => {
          debouncedFetch.cancel();
        };
      }, [name, status]);
    
      useEffect(() => {
        const filters = {
          isGlobalSearch: true,
          isSearching: true,
          searchfields: ['name'],
          searchtext: allactivityType
        };
    
        const debouncedFetch = debounce(() => {
          fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
        }, 500);
    
        debouncedFetch();
    
        return () => {
          debouncedFetch.cancel();
        };
      }, [allactivityType, currentPage, pageSize]);

    return (
        <div>
             <div className='account-table-page-alignment'>
             <div className='account-table-header-alignment'>
          <div className='search-width'>
            <div className='searchbar-design'>
              <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
              <div className='icon-alignment'>
                <img src={SearchIcon} alt="SearchIcon" />
              </div>
            </div>
          </div>
        </div>
          <div className='account-table-design'>
            <table>
              <thead>
                <tr>
                  <th><div className="w-100"></div></th>

                  <th><div className="w-100"></div></th>

                  <th><div><input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-100 border-0 table-input-background"

                  /></div></th>
                  <th>
                    <div>
                      <select
                        value={status === undefined ? "" : status.toString()}
                        onChange={(e) => {
                          const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
                          setStatus(selectedStatus);
                        }}
                        className="w-100 border-0 table-input-background"
                      >
                        <option value="">All</option>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                    </div>
                  </th>



                </tr>
                <tr>
                  <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === dealpipelinelist.length} /></th>
                  <th>Action</th>
                  <th>Name</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {productData && productData.length > 0 ? (
                  productData.map((i) => (
                    <tr key={i?.id}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedIds.includes(i.id)}
                          onChange={() => handleCheckboxChange(i.id)}
                        />
                      </td>
                      <td>
                        <Link to={`adddealpipeline/${i?.id}`}>
                          <EditIcon />
                        </Link>
                      </td>
                      <td>{i?.name}</td>
                      <td>
                        <button className={i?.status ? "Active" : "Inactive"}>
                          {i?.status ? "Active" : "Inactive"}
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: "center", fontStyle: "italic" }}>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>

            </table>
            <div className='pagination-alignment'>
              <Pagination
                currentPage={currentPage}
                totalItems={totalItems} // Total items from API response
                pageSize={pageSize}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
            </div>
        </div>
    );
}
