import React from 'react'
import './threeColumnSection.scss';
import FollowCard from '../followCard';
import FillWhiteButton from '../../../shared/components/fillWhiteButton';
export default function ThreeColumnSection() {
    return (
        <div className='dashboard-three-colum-section'>
            <div className='items'>
                <div className='items-header'>
                    <h3>Follow - Ups</h3>
                </div>
                <FollowCard />
            </div>
            <div className='items'>
                <div className='items-header'>
                    <h3>Follow - Ups</h3>
                    <FillWhiteButton text='Active Leads' />
                </div>
            </div>
            <div className='items'>
                <div className='items-header'>
                    <h3>Conversion Insights</h3>
                </div>
            </div>
        </div>
    )
}
