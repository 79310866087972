import {useState} from 'react'
import './dealpipeline.scss';
import { Link } from 'react-router-dom';
import { deleteData, filterAll } from '../../services/apiService';
import Button from '../../shared/components/button';
import Dealpipelinetable from './dealpipelinetable';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import AccountTableHeader from '../account/accountTableHeader';
export default function Dealpipeline() {
  const [selectedIds,setSelectedIds] =useState([]);
  const [dealpipelinelist,setDealPipelineList]=useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`dealpipeline/${id}`)));
      setDealPipelineList(dealpipelinelist.filter((i) => !selectedIds.includes(i.id)));
      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
      
    }
  };

  const handleExport = () => {
    const headers = [
      ["Name","Status"]

    ];
 
    filterAll("dealpipeline")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.name,
        item.status ? "Active" : "Inactive", 
      ]);
      exportToExcel('Deal pipeline',headers,datas,'dealpipeline')
    })
  };

  const [open, setOpen] = useState(false);
const [confirmLoading, setConfirmLoading] = useState(false);

const showPopconfirm = () => {
  setOpen(true);
};

const handleOk = async () => {
  setConfirmLoading(true);

  await handleDelete();
  setOpen(false);
  setConfirmLoading(false);
  
};

const handleCancel = () => {
  console.log('Clicked cancel button');
  setOpen(false);
};
  return (
    <div className='page-24'>
     {/* <div className='page-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Deal pipeline</span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
          {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
     
        <Link to="adddealpipeline"><Button text='Add dealpipeline'/></Link>
      </div>
      </div> */}
      <div className="">
        <div className="pb-4 customer-header-alignment">
          <div>
            <h2>Deal Pipeline</h2>
            <Breadcumbs activePath="Deal Pipeline" pageName="Deal Pipeline" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
            <Popconfirm
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            title="Are you sure to delete this record? "
            description="This will lead to delete all records related to this record only!"
            open={open}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
          </Popconfirm>
            )}
            <Link to="adddealpipeline">
              <GlobalButton text="Add Deal Pipeline" Icon={PlusIcon} />
            </Link>
            <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />
          </div>
        </div>
      </div>

      <Dealpipelinetable
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      dealpipelinelist={dealpipelinelist}
      setProductList={setDealPipelineList}
      totalItems={totalItems}
      setTotalItems={setTotalItems}

      />
    </div>
  )
}
