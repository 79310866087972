import React, { useEffect, useState, useContext } from 'react';
import Button from '../../shared/components/button';
import { deleteData, filterAll } from '../../services/apiService';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { checkAccess } from '../../services/checkFeature';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import Estimatetable from './estimatetable';
export default function Estimate() {
  const user = useSelector(state => state.user.user);
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`estimate/${id}`)));
      const tempActivityList = activityList.filter(item => !(selectedIds.includes(item.id)))
      setActivityList(tempActivityList);

      setTotalItems(totalItems - selectedIds.length)

      // setActivityList(activityList.filter(item => !selectedIds.includes(item.id)));
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  const handleExport = () => {
    const headers = [
      ["Qutotation No", "Quotation For", "Quotation Date","Total Amount", "Created By", "Status"]
    ];
 
    filterAll("quotation")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.quotation_no,
        item.linkname,
        item.quotationDate,
        item.totalamount,
        item.created_by,
        item.quotationstatus,
      ]);
      exportToExcel('quotation',headers,datas,'quotation')
    })
  };

  return (
    <div className='page-24'>
      <div className="">
        <div className="pb-4 customer-header-alignment">
          <div>
            <h2>Estimate  </h2>
            <Breadcumbs activePath="Estimate" pageName="Estimate" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
             <Popconfirm
             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             title="Are you sure to delete this record? "
             description="This will lead to delete all records related to this record only!"
             open={open}
             onConfirm={handleOk}
             okButtonProps={{ loading: confirmLoading }}
             onCancel={handleCancel}
           >
             <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
           </Popconfirm>
            )}
            {<Link to="add">
              <GlobalButton text="Add Estimate" Icon={PlusIcon} />
            </Link>}
            {<GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />}
          </div>
        </div>
      </div>

      <div >
        <Estimatetable
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          activityList={activityList}
          totalItems={totalItems} 
          setTotalItems={setTotalItems}
          setActivityList={setActivityList}
        />
      </div>
    </div>
  );

}