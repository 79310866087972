import React, { useEffect, useState, useContext } from 'react';
import './order.scss';
import Button from '../../shared/components/button';
import { deleteData, filterAll } from '../../services/apiService';
import { Link } from 'react-router-dom';
import Ordertable from './ordertable';
import { UserContext } from '../../contexts/UserContext';
import { useSelector } from 'react-redux';
import Userrole from '../userrole';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import { checkAccess } from '../../services/checkFeature';
import AccountTableHeader from '../account/accountTableHeader';
import ProcessingIcon from '../../assets/icons/Processing.svg';
import GreenIcon from '../../assets/icons/green-up.svg';
import CommonCard from '../../shared/components/commonCard';
import TabFilterButton from '../../shared/components/TabFilterButton';
export default function Order() {
  const user = useSelector(state => state.user.user);
  const [selectedIds, setSelectedIds] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('Today');
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`order/${id}`)));
      const tempActivityList = activityList.filter(item => !(selectedIds.includes(item.id)))
      setActivityList(tempActivityList);

      setTotalItems(totalItems - selectedIds.length)
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };
  const handleFilterChange = (filterType) => {
    setSelectedFilter(filterType);
    const now = new Date();
    let newStart = null;
    let newEnd = new Date(); 

     if (filterType === 'Past Year') {
      const oneYearAgo = new Date();
      oneYearAgo.setFullYear(now.getFullYear() - 1); 
      newStart = oneYearAgo;
    }
    else if (filterType === 'Past 30Days') {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(now.getDate() - 30);
      newStart = thirtyDaysAgo;
    }
    else if (filterType === 'Past 7Days') {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(now.getDate() - 7);
      newStart = sevenDaysAgo;
    }
    else if (filterType === 'Past 24Hour') {
      const oneDayAgo = new Date();
      oneDayAgo.setDate(now.getDate() - 1);
      newStart = oneDayAgo;
    }

    setStartDate(newStart);
    setEndDate(newEnd);
  };

  const handleExport = () => {
    const headers = [
      ["Order", "Customer", "Date", "Amount", "Created by", "Status"]

    ];

    filterAll("order")
      .then((data) => {
        const datas = (data.data ?? [])?.map((item) => [
          item.orderNo,
          item.customerName,
          item.orderDate,
          item.productDetails?.subTotal,
          item.ordercreatedby,
          item.orderStatus,
        ]);
        exportToExcel('Order', headers, datas, 'order')
      })
  };
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);

  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  {
    return (
      <div className='page-24'>
        {/* <div className='page-header'>
        <span>Order</span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
        {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
        {(<Link to="add"><Button text='Add Order'/></Link>)}
      </div>
      </div> */}
        <div className="">
          <div className="customer-header-alignment pb-4">
            <div>
              <h2>Order</h2>
              <Breadcumbs activePath="Order" pageName="Order" />
            </div>
            <div className="right-contnet-alignment">
              {selectedIds.length > 0 && (
                <Popconfirm
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  title="Are you sure to delete this record? "
                  description="This will lead to delete all records related to this record only!"
                  open={open}
                  onConfirm={handleOk}
                  okButtonProps={{ loading: confirmLoading }}
                  onCancel={handleCancel}
                >
                  <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
                </Popconfirm>
              )}
              {checkAccess("add_order") ? (<Link to="add">
                <GlobalButton text="Add Order" Icon={PlusIcon} />
              </Link>) : (<></>)}
              {checkAccess("export_order") ? (<GlobalButton text="Export" lightbutton Icon={ExportIcon} />) : (<></>)}
            </div>
          </div>
        </div>
        <CommonCard startDate={startDate} endDate={endDate}  filterType={selectedFilter}/>
        <TabFilterButton onFilterChange={handleFilterChange} activeFilter={selectedFilter} />

        <div>
          <Ordertable
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            activityList={activityList}
            totalItems={totalItems}
            setTotalItems={setTotalItems}
            setActivityList={setActivityList}

          /> </div>
      </div>
    )
  }
}
