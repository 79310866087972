import {useState} from 'react'
import { deleteData, filterAll } from '../../services/apiService';
import './leadIndustry.scss';
import Button from '../../shared/components/button';
import LeadIndustrytable from './leadIndustrytable';
import { Link } from 'react-router-dom';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import { checkAccess } from '../../services/checkFeature';
import AccountTableHeader from '../account/accountTableHeader';

export default function LeadIndustry() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [paymenttypeList, setleadindustryList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`leadIndustry/${id}`)));
      setleadindustryList(paymenttypeList.filter((i) => !selectedIds.includes(i.id)));
      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);
      
    }
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  
  const handleExport = () => {
    const headers = [
      ["Name","Status"]

    ];
 
    filterAll("leadIndustry")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.name,
        item.status ? "Active" : "Inactive", 
      ]);
      exportToExcel('Lead Industry',headers,datas,'leadIndustry')
    })
  };

  return (
    <div className='page-24'>
       {/* <div className='page-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>LeadIndustry</span>
        <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
          {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
        <Link to="addEditleadindustry"><Button text='Add Lead Industry'/></Link>
      </div></div> */}
      <div className="">
        <div className="customer-header-alignment pb-4 ">
          <div>
            <h2>Lead Industry</h2>
            <Breadcumbs activePath="Lead Industry" pageName="Lead Industry" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
              <Popconfirm
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              title="Are you sure to delete this record? "
              description="This will lead to delete all records related to this record only!"
              open={open}
              onConfirm={handleOk}
              okButtonProps={{ loading: confirmLoading }}
              onCancel={handleCancel}
            >
              <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
            </Popconfirm>
            )}
            {checkAccess('manage_lead_industry')&&<Link to="addEditleadindustry">
              <GlobalButton text="Add Lead Industry" Icon={PlusIcon} />
            </Link>}
            {checkAccess('manage_lead_industry')&&<GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />}
          </div>
        </div>
      </div>

      <LeadIndustrytable
      selectedIds={selectedIds}
      setSelectedIds={setSelectedIds}
      paymenttypeList={paymenttypeList}
      setProductList={setleadindustryList}
      totalItems={totalItems}
      setTotalItems={setTotalItems}

      
      />
    </div>
  )
}
