import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchData } from "../../../services/apiService";
import moment from "moment";
import { useSelector } from "react-redux";
import SelectBox from "../../../shared/components/select";
import { DatePicker, Space } from "antd";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import Pagination from "../../../shared/components/pagination";
import EditIcon from "../../../shared/icons/editIcon";
import { checkAccess } from "../../../services/checkFeature";
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export default function Dispatchtable({
  selectedIds,
  setSelectedIds,
  totalItems,
  setTotalItems,
  activityList,
  setActivityList,
}) {
  const user = useSelector((state) => state.user.user);
  const [customers, setCustomerOptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Customer Name filter
  const [statusTerm, setStatusTerm] = useState(""); // Order Status filter
  const [orderTerm, setOrderTerm] = useState(""); // Order# filter
  const [amountTerm, setAmountTerm] = useState(""); // Amount filter
  const [createdByTerm, setCreatedByTerm] = useState(""); // Created By filter
  const [selectedDates, setSelectedDates] = useState([]); // Date Range filter
  const [dispatchOrder, setDispatchOrder] = useState([]);
  const [productData, setProductData] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]); // Filtered list
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [dispatchId, setDispatchid] = useState(""); // Search term for dispatch ID
  const { RangePicker } = DatePicker;
  const [allactivityType, setallactivityTypeTerm] =useState('');
  useEffect(() => {
    const filters = {
      customerName: searchTerm,
      id: dispatchId,
      orderStatus: statusTerm,
      orderNo: orderTerm,
      subTotal :amountTerm ,
      created_by:createdByTerm,
      // orderDate :selectedDates
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  useEffect(() => {
    getCustomer();
  }, [searchTerm, statusTerm, orderTerm, amountTerm, createdByTerm, selectedDates, dispatchId, activityList]);

  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const response = await fetchData("dispatchorder", requestBody, pagination);
      if (response?.data) {
        setActivityList(response.data || []);
        setTotalItems(response?.totalDocuments || 0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getCustomer = async () => {
    try {
      const response = await fetchData("customer");
      if (response?.data) {
        setCustomerOptions(
          response.data.map((customer) => ({
            label: `[${customer.code}] ${customer.cName}`,
            value: `[${customer.code}] ${customer.cName}`,
            
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    }
  };

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };
  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
  
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('dispatchorder', removeEmptyFields(filters),  pagination,
      {},
      isSearching,
      globalSearch);

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }

      console.log('setProductData', responseProducts?.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      customerName: searchTerm,
      id: dispatchId,
      orderStatus: statusTerm,
      orderNo: orderTerm,
      subTotal: amountTerm,
      created_by: createdByTerm,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [searchTerm, dispatchId, statusTerm, createdByTerm, amountTerm, selectedDates, orderTerm]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['customerName', 'orderStatus', 'created_by'],
      searchtext: allactivityType,
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prev) =>
      prev.includes(id) ? prev.filter((selectedId) => selectedId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = (e) => {
    setSelectedIds(e.target.checked ? filteredOrders.map((item) => item.id) : []);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [errors, setErrors] = useState({});
  const getStatusClass = (status) => {
    switch (status) {
      case "Close":
        return "Closee";
      case "Cancelled":
        return "Cancelled";
      case "Pending":
        return "Pending";
      case "Open":
        return "Open";
      default:
        return "";
    }
  };

  return (
    <div className="account-table-page-alignment">
            <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div>
      <div className="account-table-design">
        <table>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedIds.length === filteredOrders.length}
                     className="w-100 border-0 table-input-background"
                />
              </th>
              {checkAccess("edit_dispatch") && <th>Action</th>}
              <th>
                <input
                  type="text"
                  placeholder="Dispatch"
                  value={dispatchId}
                  onChange={(e) => setDispatchid(e.target.value)}
                     className="w-100 border-0 table-input-background"
                />
              </th>
              <th><input type="text" placeholder="Customer Name" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>

              <th>
                <Space direction="vertical">
                  <RangePicker
                    presets={[
                      { label: "Last 7 Days", value: [dayjs().subtract(7, "day"), dayjs()] },
                      { label: "Last 30 Days", value: [dayjs().subtract(30, "day"), dayjs()] },
                    ]}
                    onChange={(dates) => setSelectedDates(dates || [])}
                    placeholder={[" Start Date", " End Date"]}
                  />
                </Space>
              </th>
              <th>
                <input
                  type="text"
                  placeholder="Amount"
                  value={amountTerm}
                  onChange={(e) => setAmountTerm(e.target.value)}
                     className="w-100 border-0 table-input-background"
                />
              </th>
              <th>
                <input
                  type="text"
                  placeholder="Created By"
                  value={createdByTerm}
                  onChange={(e) => setCreatedByTerm(e.target.value)}
                     className="w-100 border-0 table-input-background"
                />
              </th>
             
            </tr>
          </thead>
          <tbody>
            {productData && productData.length > 0 ? (
              productData.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                  {checkAccess("edit_dispatch") && (
                    <td>
                      <Link to={`/order/adddispatchorder/${item.id}`}>
                        <EditIcon />
                      </Link>
                    </td>
                  )}
                  <td>
                    <Link to={{ pathname: `view/${item?.id}` }}>
                      <button className="status">{item.dispatchNo}</button>
                    </Link>
                  </td>
                  <td>{item.customerName || "N/A"}</td>
                  <td>{moment(item.date).format("DD-MM-YYYY") || "N/A"}</td>
                  <td>{item.total || "N/A"}</td>
                  <td>{item.created_by || "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} style={{ textAlign: 'center', fontStyle: 'italic', color: '#666' }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
        <Pagination
          totalItems={totalItems}
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
}
