import React, { useState, useEffect, useRef } from "react";
import "./addorder.scss";
import { Link } from "react-router-dom";
import Button from "../../../shared/components/button";
import InputCustom from "../../../shared/components/input";
import { fetchData, fetchsingleData, patchData, postData } from "../../../services/apiService";
import SelectBox from "../../../shared/components/select";
import Toggle from "../../../shared/components/switch";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form, Input } from "antd";
import moment from 'moment';
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { control } from "leaflet";
import GlobalButton from "../../../shared/globalButton";
import EditIcon from "../../../shared/icons/editIcon";
import { checkAccess } from "../../../services/checkFeature";
import store from "../../../redux/reduxstore";
import { notify } from "../../toastr/Toastr";
import Breadcumbs from "../../../shared/components/breadcumbs";
import CancelIcon from '../../../assets/icons/Cancel.svg';




const { TextArea } = Input;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#f1f1f1',
    minHeight: '40px',
    height: '40px',
    fontSize: '14px',
    boxShadow: state.isFocused ? null : null,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    height: '40px',
    padding: '0 6px',
    fontSize: '14px'
  }),

  input: (provided, state) => ({
    ...provided,
    margin: '0px',
  }),
  indicatorSeparator: state => ({
    display: 'none',
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '40px',
  }),
};
export default function AddOrder() {
  const navigate = useNavigate();
  const { id } = useParams();
  const company_setting = store.getState()?.auth?.company_setting;
  const isEditMode = window.location.pathname.includes('/add') || false;
  const name = useSelector(state => state.auth.name);
  const userData = store.getState().auth.userData
  const userName = userData.name
  const userId = userData.id


  const userDataRef = useRef(userData);
  useEffect(() => {
    // Update the ref whenever isExamStarted changes
    userDataRef.current = userData;
  }, [userData]);
  const [segmentOptions, setsegmentOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([])
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [discountType, setDiscountType] = useState([
    { label: "%", value: "%",name:"%" },
    { label: "₹", value: "₹",name:"₹" },
  ]);
  const [billingaddress,setBillingaddress]=useState(false);
  useEffect(() => {
    getData();
    getCustomer();
    fetchCountry();
    fetchState();
    fetchCity();
    fetchArea();




  }, []);
  const [productOptions, setProductOptions] = useState([]);
  const [customers, setCustomerOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [transportOptions, setTransportOptions] = useState([]);
  const [newNotification, setNewNotification] = useState([]);
  const [newUpdate, setNewUpdate] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const [formData, setFormData] = useState({
    // orderNo: "",
    orderDate: new Date(),
    customerName: "",
    customerId: "",
    customerAddress: "",
    customerContact: "",
    status: true,
    orderStatus: 'Pending',
    orderproducts: [],
    ordernotification: [],
    ordercreatedby: '',
    orderupdate: '',
    areaName: '',
    orderupdatedby: '',
    totalAmount: 0,
    city: "",
    cityId: null,
    countryId: null,
    countryName: "",
    stateId: null,
    stateName: "",
    userName: userData.name,
    userId: userData.id,
    yetscreen: true,
  });
  const [editarea, setEditarea] = useState({
    customerAddress: formData.customerAddress,
    customerContact: formData.customerContact,
    pincode: formData.pincode,
    city: formData.city,
    cityId: formData.cityId,
    countryId: formData.countryId,
    countryName: formData.countryName,
    stateId: formData.stateId,
    stateName: formData.stateName,
    areaName: formData.areaName,
    areaId: formData.areaId,
  });
  const [shippingarea,setShippingarea]=useState({
    shippingcustomerAddress:formData.shippingcustomerAddress,
    shippingcustomerContact:formData.shippingcustomerContact,
    shippingcity:formData.shippingcity,
    shippingcityId:formData.shippingcityId,
    shippingcountryName:formData.shippingcountryName,
    shippingcountryId:formData.shippingcountryId,
    shippingstateName:formData.shippingstateName,
    shippingstateId:formData.shippingstateId,
    shippingPincode:formData.shippingpincode,
    
  })
  const [dispatchFormData, setDispatchFormData] = useState({
    dispatchNo: "",
    dispatchDate: moment(),
    dispatchCode: "",
    invoiceNo: "",
    invoiceDate: moment(),
    transportId: "",
    transportName: "",
    billingAddress: "",
    dispatchSatatus: 'Pending',
    lrNo: '',
    lrDate: moment(),
    orderproducts: [],
    ordernotification: [],
    ordercreatedby: '',
    orderupdatedby: '',
    totalAmount:0,
    userName: userData.name,
    userId: userData.id,


  });

  const [errors, setErrors] = useState({});
  const getCustomer = async () => {
    try {
      const response = await fetchData(`customer`,{status:true});
      if (response?.data) {
        setCustomerOptions(
          response?.data?.map((i) => {
            const lable=i?.code?`[${i?.code}] ${i?.cName}`:i?.cName;
            return {
              label: lable,
              key: "customer",
              value: {
                id: i?.id,
                name: `[${i?.code}] ${i?.cName}`,
                address: `${i?.address} - ${i?.pincode}.`,
                contact: i?.addressContact,
                pincode: i?.pincode,
                city: i?.city,
                cityId: i?.cityId,
                countryId: i?.countryId,
                countryName: i?.countryName,
                areaName: i?.areaName,
                areaId: i?.areaId,
                stateId: i?.stateId,
                stateName: i?.stateName,
                shippingAddress: i?.shippingAddress,
                shippingPincode: i?.shippingpincode,
                shippingcountryName: i?.shippingcountryName,
                shippingcountryId: i?.shippingcountryId,
                shippingstateId: i?.shippingstateId,
                shippingstateName: i?.shippingstateName,
                shippingaddressEmail: i?.shippingaddressEmail,
                shippingaddressContact: i?.shippingaddressContact,
                shippingcityName:i?.shippingcityName,
                shippingcityId:i?.shippingcityId,

              },
            };
          })
        );
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchCountry = async () => {
    try {
      const response = await fetchData("country");

      if (response?.data) {
        setCountryOptions(
          response?.data.map((i) => {
            return {
              label: i?.countryName,
              key: "country",
              value: { countryId: i?.id, countryName: i?.countryName },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchState = async (countyId) => {
    try {
      const response = await fetchData("state", { countryId: countyId });

      if (response?.data) {
        setStateOptions(
          response?.data.map((i) => {
            return {
              label: i?.stateName,
              key: "state",
              value: { stateId: i?.id, stateName: i?.stateName },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchCity = async (stateId) => {
    try {
      const response = await fetchData("city", { stateId: stateId });

      if (response?.data) {
        setCityOptions(
          response?.data.map((i) => {
            return {
              label: i?.cityName,
              key: "city",
              value: { cityId: i?.id, city: i?.cityName },
            };
          })
        );
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchArea = async (cityId) => {
    try {
      const response = await fetchData("area", { cityId: cityId });

      if (response?.data) {
        setAreaOptions(
          response?.data.map((i) => {
            return {
              label: i?.areaName,
              key: "area",
              value: { areaId: i?.id, areaName: i?.areaName },
            };
          })
        );
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getData = async () => {
    try {
      // Fetch unit options
      const response = await fetchData(`systemtype`, {
        typeCategory: { in: ["unit"] },
        status: true,
      });
      if (response?.data) {
        setUnitOptions(
          response?.data
            .filter((i) => i?.typeCategory === "unit")
            .map((i) => {
              return {
                label: i?.typeName,
                key: "unit",
                value: { id: i?.systemtypeId, name: i?.typeName },
              };
            })
        );
      }

    
  
      const responseProducts = await fetchData(`product`,{status:true});
      if (responseProducts?.data) {
        setProductOptions(
          responseProducts?.data?.map((i) => {
            return {
              label: `${i?.productCode ? `[${i.productCode}] ` : ''}${i?.name}`,
              key: "product",
              value: {
                productId: i?.id,
                productName: i?.name,
                unitPrice: i?.price,
                unitId: i?.unitId,
                unitName: i?.unitName,
                moq: i?.moq,
                tax: i?.tax,
                discount: i?.discount,
                productUrl: i?.productUrl,
                productCode: i?.hsn_code,
                quantity: 1,
                remark: i?.remark,
                priceTax: i?.priceTax,
                offerDiscountCount: i?.offerDiscountCount,
                additionalDiscount: i?.additionalDiscount,
                discounttype:i?.discountType,
                offerDiscounttype:i?.offerDiscountType,
                additionalDiscounttype:i?.additionalDiscountType,
                
                
              },
            };
          })
        );
      }

      // Fetch and patch order data
      if (id) {
        const response = await fetchsingleData("order", id);
        console.log(response, "response")
        if (response?.data) {
          const updatedorderproducts = response.data.orderproducts.map((product) => {
            return {
              ...product,
              total: calculatetotal(product), // Recalculate total for each product
            };
          });

          setFormData(response.data);
          setProductsForOrder([...updatedorderproducts]);
        }
      }
      const quotationId = new URLSearchParams(window.location.search).get("quotationId");
      if (quotationId) {
        getQuotationData(quotationId); // Fetch and pre-fill quotation data
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getQuotationData = async (quotationId) => {
    try {
      const response = await fetchsingleData("quotation", quotationId);
      if (response?.data) {
        const quotationData = response.data;
        let customerName = quotationData?.linkname || "";
        setFormData((prevState) => ({
          ...prevState,
          orderDate: quotationData?.quotationDate,
          customerName: customerName || "",
          customerId: quotationData?.lead_to_customer_id || quotationData?.link,
          customerAddress: quotationData?.customeraddress,
          city: quotationData?.city,
          cityId: quotationData?.cityId,
          countryId: quotationData?.countryId,
          countryName: quotationData?.countryName,
          stateId: quotationData?.stateId,
          stateName: quotationData?.stateName,
          customerContact: quotationData?.customercontact,
          status: quotationData?.status,
          orderStatus: "Pending",
          ordernotification: [],
          ordercreatedby: "sales",
          totalAmount: quotationData?.totalamount,
        }));
        const orderproducts = (quotationData?.orderproducts ?? [])?.map((product) => ({
          index: product.index,
          name: product.name ?? '',
          productId: product.productId,
          id: product.productId,
          unitId: product.unitId,
          unitName: product.unitName,
          quantity: product.qty,
          unitPrice: product.unitPrice,
          discount: product.discount,
          tax: product.tax,
          total: product?.subTotal,
          productUrl: product.productUrl,
          remark: product.remark
        }))
        setProductsForOrder([...orderproducts])
        // calculateTotal(quotationData.orderproducts);
      }
    } catch (error) {
      console.error("Error fetching quotation data:", error);
    }
  };


  const getTransportData = async () => {
    try {
      if (id) {
        const response = await fetchData(`trasport`, {
          status: true,
        });
        if (response?.data) {
          setTransportOptions(
            response?.data?.map((i) => {
              return {
                label: i?.name,
                key: "name",
                value: { id: i?.trasportId, name: i?.name },
              };
            })
          );
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleSubmit = (e) => {
    const currentDateTime = dayjs().format('DD-MM-YYYY,HH:mm:ss');
    // const updatedNotification = { date: currentDateTime, user: name };
    // setNewNotification(prevNotifications => [...prevNotifications, updatedNotification]);
    // setNewUpdate(prevNotifications => [...prevNotifications, updatedNotification]);
    e.preventDefault();
    if (validationCheck() && productsForOrder?.length > 0) {
      try {
        if (id) {
          patchData(`order/${id}`, { ...formData, orderproducts: [...productsForOrder], orderupdatedby: name, orderupdate: currentDateTime })
            .then((data) => {
              if (data) {
                // notify.success('Order No ' + data?.data.orderNo + ' Order updated Successfully!');
                notify.success(`Order No ${data?.data.orderNo} has been updated successfully!`);

                navigate(`/order`);
              }
            })
            .catch((error) => {
              notify.error('Error while saving order');
              console.error("Error fetching data:", error);
            });
        } else {
          postData("order", { ...formData, orderproducts: [...productsForOrder], ordercreatedby: name, })
            .then((data) => {
              if (data) {
                notify.success(`Order No ${data?.data.orderNo} has been saved successfully!`);

                navigate(`/order`);
              }
            })
            .catch((error) => {
              notify.error('Error while saving order');
              console.error("Error fetching data:", error);
            });
        }
      } catch (error) {
        console.error("Error saving data:", error);
        // Handle error
      }
    }
  };
  const handleEvent = (e, type) => {
    e.preventDefault();
    if (type) {
      try {
        if (id) {
          patchData(`order/${id}`, { orderId: id, orderStatus: type, dispatchs: [] })
            .then((data) => {
              navigate(-1);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        }
      } catch (error) {
        console.error("Error saving data:", error);

      }
    }
  };
  const selectChanged = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [`${e.key}Name`]: e.value?.name,
      [`${e.key}Id`]: e.value?.id,

    }));
    if (e.key === "customer") {
      setFormData((prevState) => ({
        ...prevState,
        customerAddress: e.value?.address,
        customerContact: e.value?.phone,
        city: e.value?.city,
        pincode: e.value?.pincode,
        cityId: e.value?.cityId,
        countryId: e.value?.countryId,
        countryName: e.value?.countryName,
        areaName: e.value?.areaName,
        areaId: e.value?.areaId,
        stateId: e.value?.stateId,
        stateName: e.value?.stateName,
        shippingcustomerAddress: e.value?.shippingAddress,
        shippingcustomerContact: e.value?.shippingaddressContact,
        shippingcity: e.value?.shippingcityName,
        shippingcityId:e.value?.shippingcityId,
        shippingcountryName: e.value?.shippingcountryName,
        shippingcountryId:e.value?.shippingcountryId,
        shippingstateName:e.value?.shippingstateName,
        shippingstateId:e.value?.shippingstateId,
        shippingPincode:e.value?.shippingpincode,
      }));

    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      customerName: "",
    }));

    if (errors.isSubmit) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        customerName: formData?.customerName ? "" : "Please select customer",
      }));
    }

  };

  const selectChangedDispatch = (e) => {
    setDispatchFormData((prevState) => ({
      ...prevState,
      [`${e.key}Name`]: e.value?.name,
      [`${e.key}Id`]: e.value?.id,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
    if (errors.isSubmit) validationCheck();
  };
  const editArea = (e) => {
    const { name, value, checked, type } = e.target;
    setEditarea((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

  };
  const editshippingarea = (e) => {
    const { name, value, checked, type } = e.target;
    setShippingarea((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

  };

  const handleDateChange = (date) => {
    if (date) {
      setFormData((prevState) => ({
        ...prevState,
        orderDate: date.toISOString(), // Converts a valid date to an ISO string
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        orderDate: null, // Explicitly set as null if no valid date is selected
      }));
    }
  };


  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!formData.customerName.trim()) {
      validationErrors.customerName = "Please select customer";
    }
    if (!formData.orderDate) {
      validationErrors.orderDate = "Select Order Date";
    }
    if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

    setErrors(validationErrors);
    return Object.keys(validationErrors).filter((i) => i !== "isSubmit")
      .length === 0
      ? true
      : false;
  };

  const [productsForOrder, setProductsForOrder] = useState([]);
  const AddProduct = () => {
    const orderproducts = [...productsForOrder];
    const newProduct = {
      remark: "",
      productName: "",
      productId: "",
      unitId: "",
      unitName: "",
      quantity: 1,
      unitPrice: 0,
      discount: 0,
      tax: 0,
      productUrl: '',
      productStatus: 'dispatchPending',
      productCode: '',
      offerDiscount: 0,
      additionalDiscount: 0,
      priceTax: '',
      taxcharges: 0,
      discounttype:'',
      offerDiscounttype:'',
      additionalDiscounttype:'',



    };

    // Calculate initial total for new product
    newProduct.total = calculatetotal(newProduct);

    orderproducts.push(newProduct);
    setProductsForOrder([...orderproducts]);
  };
  // const calculatetotal = (product) => {
  //   const unitPrice = Number(product.unitPrice) * Number(product.quantity) || 0;
  //   const discount = (unitPrice * Number(product.discount)) / 100 || 0;
  //   const tax = ((unitPrice - discount) * Number(product.tax)) / 100 || 0;
  //   return Number(((unitPrice - discount) + tax).toFixed(2));
  // };

  // const productSelectChange = (index, event, type) => {
  //   const orderproducts = [...productsForOrder];
  //   if (type === "p") {
  //     const product = orderproducts[index];
  //     product.productId = event.value.productId;
  //     product.productName = event.value.productName;
  //     product.unitName = event.value.unitName;
  //     product.unitId = event.value.unitId;
  //     product.quantity = 1;
  //     product.unitPrice = Number(Number(parseFloat(event.value.unitPrice)).toFixed(2));
  //     product.tax = Number(event.value.tax);
  //     product.discount = 0;
  //     product.productUrl = event.value.productUrl;
  //     product.productCode = event.value.productCode;

  //     // Recalculate total
  //     product.total = calculatetotal(product);

  //     setProductsForOrder([...orderproducts]);
  //     calculateTotal(orderproducts);
  //   }
  // };

  const calculatetotal = (product) => {

    let price = product.unitPrice * product.quantity;
    if (product.discount) {
      if (product.discounttype === "%") {
          const discount = (price * product.discount) / 100;
          price -= discount;
      } else {
          price -= product.discount; 
      }
  }
  if (product.offerDiscount) {
      if (product.offerDiscounttype === "%") {
          const offerDiscount = (price * product.offerDiscount) / 100;
          price -= offerDiscount;
      } else {
          price -= product.offerDiscount; 
      }
  }
  if (product.additionalDiscount) {
      if (product.additionalDiscounttype === "%") {
          const additionalDiscount = (price * product.additionalDiscount) / 100;
          price -= additionalDiscount;
      } else {
          price -= product.additionalDiscount; 
      }
  }

    const taxcharges = product.tax ? Number(((price * product.tax) / 100).toFixed(2)) : 0;


    if (product.tax) {
      const taxAmount = (price * product.tax) / 100;
      price += taxAmount;

    }
    product.taxcharges = taxcharges;


    return Number(price.toFixed(2));
  };

  // const productSelectChange = (index, event, type) => {
  //   const orderproducts = [...productsForOrder];
  //   if (type === "p") {
  //     const product = orderproducts[index];
  //     product.productId = event.value.productId;
  //     product.productName = event.value.productName;
  //     product.unit = event.value.unitName;
  //     product.unitId = event.value.unitId;
  //     product.quantity = 1;
  //     product.priceTax=event.value.priceTax
  //     product.unitPrice = Number(parseFloat(event.value.unitPrice).toFixed(2));
  //     product.tax = Number(event.value.tax);
  //     product.discount = Number(parseFloat(event.value.discount));
  //     product.offerDiscount = Number(event.value.offerDiscountCount) || 0;
  //     product.additionalDiscount = Number(event.value.additionalDiscount)||0;
  //     product.productUrl = event.value.productUrl;
  //     product.productCode = event.value.productCode;
  //     product.moq=event.value.moq;
  //     product.remark=event.value.remark;


  //     // Recalculate total
  //     product.total = calculatetotal(product);

  //     setProductsForOrder([...orderproducts]);
  //     calculateTotal(orderproducts);
  //   }
  // };

  const productSelectChange = (index, event, type) => {
    const orderproducts = [...productsForOrder];
    if (type === "p") {
      const product = orderproducts[index];

      product.productId = event.value.productId;
      product.productName = event.value.productName;
      product.unitName = event.value.unitName;
      product.unitId = event.value.unitId;
      product.quantity = 1;
      product.priceTax = event.value.priceTax;
      product.discount = Number(parseFloat(event.value.discount));
      product.offerDiscount = Number(event.value.offerDiscountCount) || 0;
      product.additionalDiscount = Number(event.value.additionalDiscount) || 0;
      product.productUrl = event.value.productUrl;
      product.productCode = event.value.productCode;
      product.moq = event.value.moq;
      product.remark = event.value.remark;
      product.discounttype=event.value.discounttype;
      product.additionalDiscounttype=event.value.additionalDiscounttype;
      product.offerDiscounttype=event.value.offerDiscounttype;
      if (event.value.priceTax === "Inclusive") {
        const taxRate = Number(event.value.tax) / 100;
        product.unitPrice = Number((event.value.unitPrice + (event.value.unitPrice * taxRate)).toFixed(2));
        product.tax = 0;
      } else if (event.value.priceTax === "exclusive") {
        product.unitPrice = Number(parseFloat(event.value.unitPrice).toFixed(2));
        product.tax = Number(event.value.tax);
      }


      product.total = calculatetotal(product);

      setProductsForOrder([...orderproducts]);
      calculateTotal(orderproducts);
    }
  };


  const handleInputChangeProduct = (e, index, isSelectBox = false) => {
    let name, value;
  
    if (isSelectBox) {
      name = e.name;
      value = e.value;
    } else {
      ({ name, value } = e.target);
    }
    
  
    const orderproducts = [...productsForOrder];
  
    if (name === "quantity") {
      const enteredQuantity = value;
      const moq = orderproducts[index]?.moq || 0;
      orderproducts[index][name] = enteredQuantity;
      if (enteredQuantity > moq) {
        orderproducts[index][name] = moq;
        notify.error(`Quantity cannot exceed the MOQ ${moq}`);
      }
    } else if (
      name === "discount" ||
      name === "offerDiscount" ||
      name === "additionalDiscount"
    ) {
      const discountTypeKey =
        name === "discount"
          ? "discounttype"
          : name === "offerDiscount"
          ? "offerDiscounttype"
          : "additionalDiscounttype";
  
      const selectedDiscountType = orderproducts[index]?.[discountTypeKey];
  
      if (selectedDiscountType === "₹") {
        if (value > orderproducts[index].unitPrice) {
          value = orderproducts[index].unitPrice;
          notify.error(
            `${
              name === "discount"
                ? "Discount"
                : name === "offerDiscount"
                ? "Offer Discount"
                : "Additional Discount"
            } cannot exceed the product price of ₹${orderproducts[index].unitPrice}`
          );
        }
      } else if (selectedDiscountType === "%") {
        if (value > 100) {
          value = 100;
          notify.error(
            `${
              name === "discount"
                ? "Discount"
                : name === "offerDiscount"
                ? "Offer Discount"
                : "Additional Discount"
            } cannot exceed 100%`
          );
        }
      }
  
      orderproducts[index][name] = Number(parseFloat(value).toFixed(2));
    } else if (
      name === "discounttype" ||
      name === "offerDiscounttype" ||
      name === "additionalDiscounttype"
    ) {
      const discountKey =
        name === "discounttype"
          ? "discount"
          : name === "offerDiscounttype"
          ? "offerDiscount"
          : "additionalDiscount";
      const currentDiscountValue = orderproducts[index]?.[discountKey] || 0;
  
      if (value === "₹" && currentDiscountValue > orderproducts[index].unitPrice) {
        orderproducts[index][discountKey] = 0;
        notify.error(
          `${
            name === "discounttype"
              ? "Discount"
              : name === "offerDiscounttype"
              ? "Offer Discount"
              : "Additional Discount"
          } value reset to ₹0 as it exceeds the product price`
        );
      }
      else if(value === "%"&&currentDiscountValue>100) {
        orderproducts[index][discountKey] = 0;
        notify.error( `${
          name === "discounttype"
            ? "Discount"
            : name === "offerDiscounttype"
            ? "Offer Discount"
            : "Additional Discount"
        } value reset to ₹0 as it exceeds the product price`);
      }
  
      orderproducts[index][name] = value;
    } else {
      orderproducts[index][name] =
        name === "unitPrice" ||
        name === "tax" ||
        name === "offerDiscount" ||
        name === "additionalDiscount" ||
        name === "discount"
          ? Number(parseFloat(value).toFixed(2))
          : value;
    }
    orderproducts[index].total = calculatetotal(orderproducts[index]);
    console.log(orderproducts[index], "orderproducts[index]");
    setProductsForOrder([...orderproducts]);
    calculateTotal(orderproducts);
  };
  
  


  // const handleInputChangeProduct = (e, index) => {
  //   const { name, value } = e.target;
  //   const orderproducts = [...productsForOrder];
  //   console.log(orderproducts);

  //   orderproducts[index][name] = name === "quantity" || name === "unitPrice" || name === "tax" || name === "offerDiscount" || name === "additionalDiscount"
  //     ? Number(parseFloat(value).toFixed(2))
  //     : value;


  //   orderproducts[index].total = calculatetotal(orderproducts[index]);

  //   setProductsForOrder([...orderproducts]);
  //   calculateTotal(orderproducts);
  // };

  const calculateTotal = (orderproducts) => {
    const total = orderproducts.reduce((acc, orderproducts) => acc + (orderproducts.total || 0), 0);
    setFormData({ ...formData, totalAmount: total.toFixed(2) });
  }



  const handleRemoveProduct = (indexToRemove, producttotal) => {
    const orderproducts = [...productsForOrder];
    orderproducts.splice(indexToRemove, 1);
    setProductsForOrder(orderproducts);
    calculateTotal(orderproducts);
  };
  const handleDispatch = (e, type) => {
    if (type === 'Add') {
      setAddDispatch(!addDispatch);
      setFormData({ ...formData, dispatchs: [{ ...dispatchFormData }] });
      getTransportData();
    }
  }
  const handleAddProductClick = () => {
    if (formData.customerId) {
      AddProduct();
    }
  };
  const handleAddClick = () => {
    setDrawerOpen(true);
    setBillingaddress(true);

  };
  const handleAddshippingclick=()=>{
    setDrawerOpen(true);

  };
  const closeDrawer = () => {
    setDrawerOpen(false);
    setBillingaddress(false);
  };
  const editAddress = () => {

    const updatedFormData = {
      ...formData,
      customerAddress: editarea.customerAddress?.trim(),
      pincode: editarea.pincode?.trim(),
      customerContact: editarea.customerContact?.trim(),
      countryName: countryOptions?.find(
        (i) => i.value.countryId === editarea.countryId
      )?.label,
      stateName: stateOptions.find(
        (i) => i.value.stateId === editarea.stateId
      )?.label,
      city: cityOptions.find(
        (i) => i.value.cityId === editarea.cityId
      )?.label,
    };
    setFormData(updatedFormData);
    closeDrawer();
    notify.success(`Address has been updated successfully!`);

    // Optionally, reset formData if needed
    // setFormData({}); // Reset form data
  };
  const editShippingaddress = () => {

    const updatedFormData = {
      ...formData,
      shippingcustomerAddress: shippingarea.shippingcustomerAddress?.trim(),
      shippingPincode: shippingarea.shippingPincode?.trim(),
      shippingcustomerContact: shippingarea.shippingcustomerContact?.trim(),
      shippingcountryName: countryOptions.find(
        (i) => i.value.countryId === shippingarea.shippingcountryId
      )?.label,
      shippingstateName: stateOptions.find(
        (i) => i.value.stateId === shippingarea.shippingstateId
      )?.label,
      shippingcity: cityOptions.find(
        (i) => i.value.cityId === shippingarea.shippingcityId
      )?.label,
    };
    setFormData(updatedFormData);
    closeDrawer();
    notify.success(`Shipping Address has been updated successfully!`);
  };



  const [addDispatch, setAddDispatch] = useState(false);


  return (
    <div className="add-order-page-design-change">
      {isEditMode && (
        // <div className="page-header add-news-alignment">
        //   <span>Order {`>`} {id ? 'Edit' : 'Create'}</span>
        //   <div className="two-button-alignment">
        //     <div onClick={handleSubmit}>
        //       <GlobalButton text={id ? 'Update' : 'Save'} />
        //     </div>
        //     <Link to="/order"><GlobalButton text='Discard' outline /></Link>

        //   </div>
        // </div>
        <div className='add-header-design'>
          <div>
            <h2>Add Order</h2>
            <Breadcumbs activePath="Quotation" pageName="Add Order" />
          </div>
          <div className='right-contnet-alignment'>
            <Link to="/order">
              <button className='light-button'>
                <img src={CancelIcon} alt="CancelIcon" />
                Discard
              </button>
            </Link>
            {/* <button onClick={handleSubmit}>
                        Save
                    </button> */}
            <div onClick={handleSubmit}>
              <GlobalButton text={id ? 'Update' : 'Save'} />
            </div>
          </div>
        </div>
      )}
      {!isEditMode && (
        <div className="page-header add-news-alignment">
          <span>Order</span>
          {formData?.orderStatus === "Pending" && (
            <div className="two-button-alignment">
              <div
                onClick={(e) => {
                  handleEvent(e, "open");
                }}
              >
                <GlobalButton text="Open Order" />
              </div>


              <GlobalButton text="Cancel Order" cancel />

            </div>
          )}
          {formData?.orderStatus === "open" && (
            <div className="two-button-alignment">
              <div
                onClick={(e) => {
                  handleDispatch(e, "Add");
                }}
              >
                <GlobalButton text="Add" />
              </div>
              <div
                onClick={(e) => {
                  handleEvent(e, "Pending");
                }}
              >
                <GlobalButton text="Pending Order" outline />
              </div>
              <GlobalButton text="Close Order" outline />
              <GlobalButton text="Cancel Order" cancel />
            </div>
          )}
          {formData?.orderStatus === "cancelled" && (
            <div className="two-button-alignment">
              <div
                onClick={(e) => {
                  handleEvent(e, "pending");
                }}
              >
                <GlobalButton text="Pending Order" outline />
              </div>
              <GlobalButton text="Close Order" outline />
              <GlobalButton text="Cancel Order" cancel />
            </div>
          )}
        </div>
      )}
      <div>
        <div style={{ margin: '10px -5px 10px 3px', padding: '40px 20px' }}>
          <h2 style={{ marginTop: '-30px', paddingBottom: '20px' }}>Customer Details</h2>
          <div className='grid' style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '70%' }}>
            {
              id ? (
                <div className="bottom-alignment">
                  <InputCustom
                    label="Order No"
                    name="orderNo"
                    value={formData.orderNo}
                    onChange={handleInputChange}
                    placeholder="#orderNo"
                    // type="text"
                    disabled
                  />
                </div>
              ) : (<div className="bottom-alignment">
                <InputCustom
                  label="Order No"
                  name="orderNo"
                  placeholder="NULL"
                  disabled
                  style={{
                    color: 'lightgray',
                    cursor: 'not-allowed',
                    backgroundColor: '#eae8ea',
                  }}
                />
              </div>)
            }
            <div className="bottom-alignment select-change-design-change">
              <SelectBox
                label={
                  <span>
                    Customer<span className="required-star">*</span>
                  </span>}
                styles={customStyles}
                name="customer"
                value={customers?.find(
                  (option) => option.value.id === formData.customerId
                )}
                options={customers}
                onChange={selectChanged}
                placeholder="Customer Name"
                type="text"
                required={true}
                errors={errors.customerName}
              />
            </div>
            <div style={{ marginTop: '1rem' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker label={
                  <span>
                    Date<span className="required-star">*</span>
                  </span>}
                  name='orderDate' value={dayjs(formData.orderDate)} onChange={handleDateChange} format="DD/MM/YYYY" />
              </LocalizationProvider>
            </div>


          </div>
          <div></div>
          <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px' }}>
            {formData?.customerAddress && (
              <div style={{ width: '48%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ marginTop: '15px', fontSize: '16px', fontWeight: 'bold' }}>Billing Details</h2>
                  <b style={{ cursor: 'pointer' }} onClick={() => handleAddClick()}>
                    <EditIcon />
                  </b>

                </div>
                <hr />
                <textarea
                  rows={3}
                  value={`${formData?.customerAddress ? `${formData.customerAddress}, ` : ''}${formData?.areaName ? `${formData.areaName}, ` : ''}${formData?.cityName ? `${formData.cityName}, ` : ''}${formData?.stateName ? `${formData.stateName}, ` : ''}${formData?.countryName ? `${formData.countryName}` : ''}${formData?.pincode ? `-${formData.pincode}\n` : ''}${formData?.customerContact || ''}`
                  }
                  style={{
                    width: '100%',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '10px',
                    fontSize: '14px',
                    resize: 'none',
                  }}
                  readOnly
                />
              </div>
            )}
            {formData?.shippingcustomerAddress && (
              <div style={{ width: '48%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ marginTop: '15px', fontSize: '16px', fontWeight: 'bold' }}>Shipping Details</h2>
                  <b style={{ cursor: 'pointer' }} onClick={() => handleAddshippingclick()}>
                    <EditIcon />
                  </b>
                  
                </div>
                <hr />
                <textarea
                  rows={3}
                  value={`${formData?.shippingcustomerAddress ? `${formData.shippingcustomerAddress}, ` : ''}${formData?.shippingarea ? `${formData.shippingarea}, ` : ''}${formData?.shippingcityName ? `${formData.shippingcityName}, ` : ''}${formData?.shippingstateName ? `${formData.shippingstateName}, ` : ''}${formData?.shippingcountryName ? `${formData.shippingcountryName}` : ''}${formData?.shippingPincode ? `-${formData.shippingPincode}\n` : ''}${formData?.shippingcustomerContact || ''}`
                  }
                  style={{
                    width: '100%',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    padding: '10px',
                    fontSize: '14px',
                    resize: 'none',
                  }}
                  readOnly
                />
              </div>
            )}
          </div>


        </div>

        <div style={{ padding: '40px 20px' }}>
          <div className="product-details-header-alignment">
            <h2 style={{
              zIndex: 1111,
            }}>Product Details</h2>

          </div>
          <div className="order-table" style={{
            zIndex: -1,
            position: '',
            top: 'auto',
            //bottom: '100%',
          }}>
            <div className="table-design"
              style={{ overflow: "unset" }}
            >
              <table >
                <thead>
                  <tr style={{ border: '1px solid #eee' }}>
                    <th className="width-100" style={{ zIndex: -1, border: 'none', background: '#f5f5f5' }}>Product</th>
                    <th className="width-80" style={{ border: 'none', background: '#f5f5f5' }}>Unit</th>
                    <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Quantity</th>
                    <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Price (&#8377;)</th>
                    <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Discount</th>
                    <th className="width-50" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Tax</th>
                    <th className="width-100" style={{ border: 'none', background: '#f5f5f5', textAlign: 'right' }}>Sub Total (&#8377;)</th>
                    <th className="width-10" style={{ border: 'none', background: '#f5f5f5' }}></th>
                  </tr>
                </thead>
                <tbody>
                  {productsForOrder.map((i, INDEX) => {
                    return (
                      <tr key={INDEX}>
                        <td className="width-auto">
                          <SelectBox
                            name="product"
                            value={productOptions.find(
                              (option) => option.value.productId === i.productId
                            )}
                            options={productOptions}
                            onChange={(e) =>
                              productSelectChange(INDEX, e, "p")
                            }
                            placeholder="Product Name"
                            type="text"

                            styles={{
                              overflow: "initial",
                              ...customStyles,
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 99999,
                                position: 'absolute'



                              }),
                              control: (provided) => ({
                                ...provided,
                                width: '100%'



                              })
                            }}
                          />
                          {company_setting?.extra_setting?.remarksInOrderProduct === "Yes" && (
                            <TextArea name='remark' value={i?.remark} onChange={(e) =>
                              handleInputChangeProduct(e, INDEX)
                            } placeholder='Remark' type='text' rows={2} className="height-set mt-1"></TextArea>

                          )}
                        </td>
                        <td className="width-auto" style={{ textOverflow: 'ellipsis', }}>
                          <SelectBox
                            name="unit"

                            value={unitOptions.find(
                              (option) => option.value.name === i?.unitName
                            )}
                            options={unitOptions}
                            onChange={(e) =>
                              productSelectChange(INDEX, e, "u")
                            }
                            placeholder="Unit Name"
                            type="text"

                          />
                        </td>
                        <td className="whitesapce-nowrap width-auto">
                          <InputCustom
                            name="quantity"
                            value={i?.quantity}
                            onChange={(e) =>
                              handleInputChangeProduct(e, INDEX)
                            }
                            type="number"
                          />
                        </td>
                        <td className="width-auto">
                          <InputCustom
                            name="unitPrice"
                            value={i?.unitPrice}
                            onChange={(e) => handleInputChangeProduct(e, INDEX)}
                            type="number"
                            disabled={!checkAccess('edit_price')}
                          />
                          <div>{i?.priceTax}</div>
                        </td>

                        <td className="width-auto">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <InputCustom
                            name="discount"
                            value={i?.discount}
                            onChange={(e) => handleInputChangeProduct(e, INDEX)}
                            type="number"
                            style={{ marginRight: "8px", width: "100px" }}
                            disabled={!checkAccess('edit_discount')}
                          />
                           
                            <SelectBox
                          style={{width:'30px'}}
                              name="discounttype"
                              value={discountType.find(
                                (option) => option.value === i.discounttype
                              )}
                              options={discountType}
                              onChange={(e) =>
                                handleInputChangeProduct(
                                  { name: "discounttype", value: e.value },
                                  INDEX,
                                  true 
                                )
                              }
                              placeholder="%"
                              type="text"
                            />
                             </div>
       
<div>
                           
                            <label className="discount-label">Offer Discount</label>
                            <div style={{ display: "flex", alignItems: "center" }}>
                            <InputCustom
                              name="offerDiscount"
                              value={i?.offerDiscount}
                              onChange={(e) => handleInputChangeProduct(e, INDEX)}
                              type="number"
                              style={{ marginRight: "8px", width: "100px" }}
                              disabled={!checkAccess('edit_discount')}
                            />
                             <SelectBox
                          style={{width:'30px'}}
                              name="offerDiscounttype"
                              value={discountType.find(
                                (option) => option.value === i.offerDiscounttype
                              )}
                              options={discountType}
                              onChange={(e) =>
                                handleInputChangeProduct(
                                  { name: "offerDiscounttype", value: e.value },
                                  INDEX,
                                  true 
                                )
                              }
                              placeholder="%"
                              type="text"
                            />
                            </div>

                            <div className="base-price-text">(On Base Price)</div>
                          </div>
                          <div>
                            <label className="discount-label">Additional Discount</label>
                            <div style={{ display: "flex", alignItems: "center" }}>
                            <InputCustom
                              name="additionalDiscount"
                              value={i?.additionalDiscount}
                              onChange={(e) => handleInputChangeProduct(e, INDEX)}
                              type="number"
                              style={{ marginRight: "8px", width: "100px" }}
                              disabled={!checkAccess('edit_discount')}
                            />
                             <SelectBox
                          style={{width:'30px'}}
                              name="additionalDiscounttype"
                              value={discountType.find(
                                (option) => option.value === i.additionalDiscounttype
                              )}
                              options={discountType}
                              onChange={(e) =>
                                handleInputChangeProduct(
                                  { name: "additionalDiscounttype", value: e.value },
                                  INDEX,
                                  true 
                                )
                              }
                              placeholder="%"
                              type="text"
                            />
                            </div>

                            <div className="base-price-text">(On Base Price)</div>
                          </div>
                        </td>
                        <td className="width-auto">
                          <InputCustom
                            name="tax"
                            value={i?.tax}
                            onChange={(e) =>
                              handleInputChangeProduct(e, INDEX)
                            }
                            type="number"
                            disabled={!checkAccess('edit_tax')}
                          />
                        </td>
                        <td className="width-auto">
                          <InputCustom
                            name="total"
                            value={i?.total}
                            readOnly={true}
                            onChange={(e) =>
                              handleInputChangeProduct(e, INDEX)
                            }
                            type="number"
                          />
                          {i.taxcharges > 0 && (
                            <div style={{ fontSize: '12px', color: '#888' }}>
                              Tax Applied: ₹{i.taxcharges} ({i.tax}%)
                            </div>
                          )}
                        </td>
                        <th className="width-auto vartical-center">
                          <div
                            onClick={() => {
                              const productToRemove = productsForOrder[INDEX]; // Assuming INDEX is the current index of the product
                              handleRemoveProduct(INDEX, productToRemove.total);
                            }}
                            style={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}
                          >
                            <i className="fas fa-trash-alt" ></i>
                          </div>
                        </th>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* <div style={{ paddingTop: '15px', }}>  {isEditMode && (
              <div onClick={handleAddProductClick}>
                <GlobalButton text="Add Product" />
              </div>
            )}</div> */}
            <div style={{ paddingTop: '15px' }}>
              {isEditMode && (
                <GlobalButton text="Add Product" onClick={handleAddProductClick} />
              )}
            </div>
            {/* <div> <table>
                <tr>
                  <th className="width-50">Sub Total</th>
                  <th></th>
                  <th className="width-50">Total</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Megha</td>
                  <td>19</td>
                  <td>Female</td>
                </tr>
                <tr>
                  <td>Subham</td>
                  <td>25</td>
                  <td>Male</td>
                </tr>
              </table></div> */}
            <div className="rounded-circl"> <table className="datatable_main alignTop rounded-circl " style={{
              marginLeft: '70%',
              maxWidth: '30%',
              background: '#f5f5f5',
              border: '2px #eee'
            }}>
              <tbody>

                <tr></tr>
                <tr>
                  <td style={{ textAlign: 'left', width: '1%', marginRight: '10px', padding: '5px' }}><b>Total</b></td>
                  <td></td>
                  <td style={{ textAlign: 'right', padding: '5px' }}><b> <i className="fa fa-inr"></i>{formData.totalAmount}</b></td>
                </tr>
              </tbody>
            </table></div>
          </div>
        </div>
      </div>
      <div className={isDrawerOpen ? 'map-locator-modal-md width1 show' : 'map-locator-modal-md width1 hide'}>
        <div className='header-modal'>
          <div className='close' onClick={closeDrawer}>
            &times;
          </div>
          <div>
            
            {billingaddress?(<div onClick={editAddress}>
              <GlobalButton text={'Update'} />
            </div>):(<div onClick={editShippingaddress}>
              <GlobalButton text={'Update'} />
            </div>)}
          </div>
        </div>
        {billingaddress?(<div className='maplocator-body'>
          <div className='allcard'>
            
            <div className='bottom-alignment'>
  <label>
    Address 
  </label>
  <textarea
    name="customerAddress"
    value={editarea.customerAddress}
    onChange={editArea}
    placeholder="Address"
    rows="3"
  ></textarea>
</div>
            <div className='bottom-alignment'>
          
              <Input label='Pincode' name='pincode' value={editarea.pincode} onChange={editArea} placeholder='Pincode' type='text' />
            </div>
            <div className='bottom-alignment'>
              <SelectBox
                label={
                  <span>
                    Country 
                  </span>
                }
                name="countryName"
                value={countryOptions.find(
                  (i) => i.value.countryId === editarea.countryId
                )}
                options={countryOptions}
                placeholder="Select Country"
                onChange={(selectedOption) => {
                  fetchState(selectedOption?.value?.countryId);

                  setEditarea((prevState) => ({

                    ...prevState,
                    countryId: Number(selectedOption?.value?.countryId),
                    countryName: selectedOption?.value?.countryName,
                  }));
                }}


              />
            </div>
            <div className='bottom-alignment'>
              <SelectBox
                label={
                  <span>
                    State 
                  </span>
                }
                name="stateName"
                value={stateOptions.find(
                  (i) => i.value.stateId === editarea.stateId
                )}
                options={stateOptions}
                placeholder="Select State"
                onChange={(selectedOption) => {
                  fetchCity(selectedOption?.value?.stateId);

                  setEditarea((prevState) => ({
                    ...prevState,
                    stateId: Number(selectedOption?.value?.stateId),
                    stateName: selectedOption?.value?.stateName,
                  }));
                }}

              />

              {/* {errors.stateId && <p className="error">{errors.stateId}</p>} */}
            </div>
            <div className='bottom-alignment'>
              <SelectBox
                label={
                  <span>
                    City 
                  </span>
                }
                name="city"
                value={cityOptions.find(
                  (i) => i.value.cityId === editarea.cityId
                )}
                options={cityOptions}
                placeholder="Select City"
                onChange={(selectedOption) => {
                  fetchArea(selectedOption?.value?.cityId);

                  setEditarea((prevState) => ({
                    ...prevState,
                    cityId: Number(selectedOption?.value?.cityId),
                    city: selectedOption?.value?.city,
                  }));
                }}

              />
            </div>
            <div className='bottom-alignment'>
              <SelectBox
                label={
                  <span>
                    Area
                  </span>
                }
                name="areaName"
                value={areaOptions.find(
                  (i) => i.value.areaId === editarea.areaId
                )}
                options={areaOptions}
                placeholder="Select Area"
                onChange={(selectedOption) => {
                  fetchState(selectedOption?.value?.areaId);

                  setEditarea((prevState) => ({

                    ...prevState,
                    areaId: Number(selectedOption?.value?.areaId),
                    areaName: selectedOption?.value?.areaName,
                  }));
                }}


              />
            </div>
            <div className='bottom-alignment'>
              <Input label={
                <span>
                  Contact No 
                </span>
              } name='customerContact' value={editarea.customerContact} onChange={editArea} placeholder='Contact No.' type='text' />

            </div>
          </div>
        </div>):(<div className='maplocator-body'>
          <div className='allcard'>
            
            <div className='bottom-alignment'>
  <label>
    Shipping Address 
  </label>
  <textarea
    name="shippingcustomerAddress"
    value={shippingarea.shippingcustomerAddress}
    onChange={editshippingarea}
    placeholder="Address"
    rows="3"
  ></textarea>
</div>
            <div className='bottom-alignment'>
              <Input label='Shipping Pincode' name='shippingPincode' value={shippingarea.shippingPincode} onChange={editshippingarea} placeholder='Pincode' type='text' />
            </div>
            <div className='bottom-alignment'>
              <SelectBox
                label={
                  <span>
                    Shipping Country 
                  </span>
                }
                name="shippingcountryName"
                value={countryOptions.find(
                  (i) => i.value.countryId === shippingarea.shippingcountryId
                )}
                options={countryOptions}
                placeholder="Select Country"
                onChange={(selectedOption) => {
                  fetchState(selectedOption?.value?.countryId);

                  setShippingarea((prevState) => ({

                    ...prevState,
                    shippingcountryId: Number(selectedOption?.value?.countryId),
                    shippingcountryName: selectedOption?.value?.countryName,
                  }));
                }}


              />
            </div>
            <div className='bottom-alignment'>
              <SelectBox
                label={
                  <span>
                    Shipping State 
                  </span>
                }
                name="shippingstateName"
                value={stateOptions.find(
                  (i) => i.value.stateId === shippingarea.shippingstateId
                )}
                options={stateOptions}
                placeholder="Select State"
                onChange={(selectedOption) => {
                  fetchCity(selectedOption?.value?.stateId);

                  setEditarea((prevState) => ({
                    ...prevState,
                    shippingstateId: Number(selectedOption?.value?.stateId),
                    shippingstateName: selectedOption?.value?.stateName,
                  }));
                }}

              />

              {/* {errors.stateId && <p className="error">{errors.stateId}</p>} */}
            </div>
            <div className='bottom-alignment'>
              <SelectBox
                label={
                  <span>
                    Shipping City 
                  </span>
                }
                name="shippingcity"
                value={cityOptions.find(
                  (i) => i.value.cityId === shippingarea.shippingcityId
                )}
                options={cityOptions}
                placeholder="Select City"
                onChange={(selectedOption) => {
                  fetchArea(selectedOption?.value?.cityId);

                  setEditarea((prevState) => ({
                    ...prevState,
                    shippingcityId: Number(selectedOption?.value?.cityId),
                    shippingcity: selectedOption?.value?.city,
                  }));
                }}

              />
            </div>
            {/* <div className='bottom-alignment'>
              <SelectBox
                label={
                  <span>
                   Shipping Area<span className="required-star">*</span>
                  </span>
                }
                name="shippingareaname"
                value={areaOptions.find(
                  (i) => i.value.areaId === shippingarea.shippingareaId
                )}
                options={areaOptions}
                placeholder="Select Area"
                onChange={(selectedOption) => {
                  fetchState(selectedOption?.value?.areaId);

                  setEditarea((prevState) => ({

                    ...prevState,
                    shippingareaId: Number(selectedOption?.value?.areaId),
                    shippingareaName: selectedOption?.value?.areaName,
                  }));
                }}


              />
            </div> */}
            <div className='bottom-alignment'>
              <Input label={
                <span>
                  Shipping ContactNo <span className="required-star">*</span>
                </span>
              } name='shippingcustomerContact' value={shippingarea.shippingcustomerContact} onChange={editshippingarea} placeholder='Contact No.' type='text' />

            </div>
          </div>
        </div>)}
      </div>
      {formData?.orderStatus === "Open" && formData?.dispatchs?.length >= 0 && !addDispatch && (
        <div className="order-table">
          <div className="table-design">
            <table>
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Dispatch #</th>
                  <th>Date</th>
                  <th>Invoice No</th>
                  <th>Invoice Date</th>
                  <th>Transport</th>
                  <th>Created By</th>
                </tr>
              </thead>
              <tbody>
                {formData?.dispatchs?.map((i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <EditIcon />
                      </td>
                      <td className="whitesapce-nowrap">{i?.orderNo}</td>
                      <td>
                        <span className="price-group-text">
                          {i?.customerName}
                        </span>
                      </td>
                      <td className="whitesapce-nowrap">
                        {moment(i?.orderDate).format("MMMM Do YYYY, h:mm:ss a")}
                      </td>
                      <td>
                        {i?.orderproducts
                          ?.reduce((accumulator, currentValue) => {
                            return accumulator + Number(currentValue.total);
                          }, 0)
                          .toFixed(2)}
                      </td>
                      <td>SYSTEM</td>
                      <td>
                        <Link to={{ pathname: `view/${i?.orderId}` }}>
                          <button className={i?.orderStatus}>
                            {i?.orderStatus}
                          </button>
                        </Link>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {
        formData?.orderStatus === 'Open' && addDispatch && (
          <div className="add-news-form-design">
            <h2>Basic Details</h2>
            <div className="grid">
              <div className="bottom-alignment">
                <InputCustom
                  label="Dispatch No."
                  name="dispatchNo"
                  value={dispatchFormData.dispatchNo}
                  onChange={handleInputChange}
                  placeholder="#dispatchNo"
                  type="text"
                />
              </div>
              <div className="bottom-alignment grid-column-auto">
                <Form layout="vertical" autoComplete="off">
                  <Form.Item
                    name="dispatchDate"
                    label="Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      label="Order Date"
                      name="dispatchDate"
                      onChange={handleDateChange}
                      defaultValue={dispatchFormData.dispatchDate}
                      errors={errors.dispatchDate}
                    />
                  </Form.Item>
                </Form>
              </div>
              <div className="bottom-alignment">
                <InputCustom
                  label="Dispatch code"
                  name="dispatchCode"
                  value={dispatchFormData.dispatchCode}
                  onChange={handleInputChange}
                  placeholder="#dispatchCode"
                  type="text"
                />
              </div>
              <div className="bottom-alignment">
                <InputCustom
                  label="Invoice No."
                  name="invoiceNo"
                  value={dispatchFormData.invoiceNo}
                  onChange={handleInputChange}
                  placeholder="#invoiceNo"
                  type="text"
                />
              </div>
              <div className="bottom-alignment grid-column-auto">
                <Form layout="vertical" autoComplete="off">
                  <Form.Item
                    name="invoiceDate"
                    label="Date"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      label="Invoice Date"
                      name="invoiceDate"
                      onChange={handleDateChange}
                      defaultValue={dispatchFormData.invoiceDate}
                      errors={errors.invoiceDate}
                    />
                  </Form.Item>
                </Form>
              </div>
              <div className="bottom-alignment select-change-design-change">
                <SelectBox
                  label="Transport"
                  styles={customStyles}
                  name="transport"
                  options={transportOptions}
                  onChange={selectChangedDispatch}
                  type="text"
                  required={true}
                />
              </div>
              {formData?.customerAddress && (
                <div className="bottom-alignment">
                  <Form layout="vertical" autoComplete="off">
                    <Form.Item name="billingAddress" label="Billing Address">
                      <TextArea
                        rows={3}
                        defaultValue={`${formData?.customerAddress}\n${formData?.customerContact}`}
                        disabled
                      />
                    </Form.Item>
                  </Form>
                </div>
              )}
            </div>
          </div>
        )
      }
    </div>
  );
}
