import React, { useState,useEffect } from 'react';
import { fetchData } from '../../../services/apiService';
import { Link } from 'react-router-dom';
import Pagination from '../../../shared/components/pagination';
import EditIcon from '../../../shared/icons/editIcon';
import './systemtypetable.scss';
import debounce from 'lodash.debounce';
import SearchIcon from '../../../assets/icons/search.svg';
export default function Systemtypetable({ selectedIds, setSelectedIds,activityList,totalItems,setTotalItems,setActivityList }) {

  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const [ProductList, setProductList] = useState([]);
  const [status, setStatus] = useState(undefined);
  const [categoryName, setCategoryName] = useState("");
  const pageSize = 10; // Items per page
  const [productData, setProductData] = useState([]);
  const [allactivityType, setallactivityTypeTerm] =useState('');

  useEffect(() => {
    const filters = {
      categoryName: categoryName,
      status : status
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  useEffect(() => {
    setProductList(activityList)
  }, [activityList])

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(ProductList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };

  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const responseProducts = await fetchData('systemcategory', removeEmptyFields(filters), 
      pagination,
      {},
      isSearching,
      globalSearch
    );

      if (responseProducts?.data) {
        setProductData(responseProducts.data);

        setProductData(responseProducts.data || []);
        setTotalItems(responseProducts?.totalDocuments || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const filters = {
      categoryName: categoryName,
      status: status
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [categoryName, status]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['categoryName'],
      searchtext: allactivityType
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);

  return (
    <div className=''>
                            <div className='account-table-header-alignment'>
                    <div className='search-width'>
                        <div className='searchbar-design'>
                            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
                            <div className='icon-alignment'>
                                <img src={SearchIcon} alt="SearchIcon" />
                            </div>
                        </div>
                    </div>
                </div>
      <div className='account-table-design'>
        <table>
          <thead>
            <tr>
              <th><div></div></th>
              
              <th><div></div></th>

              <th><div><input
                type="text"
                placeholder="Category Name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                className="w-100 border-0 table-input-background"

              /></div></th>

              {/* <th><div> <input
                type="text"
                placeholder="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="w-100 border-0 table-input-background"

              /></div></th> */}

<th>

<div>
  <select
    value={status === undefined ? "" : status.toString()} 
    onChange={(e) => {
      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
      setStatus(selectedStatus); 
    }}
    className="w-100 border-0 table-input-background"
  >
    <option value="">All</option>
    <option value="true">Active</option> 
    <option value="false">Inactive</option>
  </select>
</div>





</th>
              

            </tr>
            <tr>
              <th><input type='checkbox' onChange={handleSelectAll} checked={selectedIds.length === activityList.length} /></th>
              <th>Action</th>
              <th>Category Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {(productData ?? []).length > 0 ? (
              productData.map((item) => (
                <tr key={item?.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                  <td>
                    <Link to={{ pathname: `addEditSystemTypeMaster/${item?.id}` }}>
                      <EditIcon />
                    </Link>
                  </td>
                  <td>{item?.categoryName}</td>
                  <td>
                    <button className={item?.status ? "Active" : "Inactive"}>
                      {item?.status ? "Active" : "Inactive"}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center", fontStyle: "italic" }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>

        </table>
        <div className='pagination-alignment'>
        <Pagination
                            currentPage={currentPage}
                            totalItems={totalItems} // Total items from API response
                            pageSize={pageSize}
                            onPageChange={handlePageChange}
                        />
        </div>
      </div>
    </div>
  );
}
