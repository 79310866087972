import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

export default function PaymentPieComponent({ customerList }) {
    const [chartData, setChartData] = useState([]);
    const [visibleData, setVisibleData] = useState([]); 
    const [dataType, setDataType] = useState("Confirmed"); 
    const [currentPage, setCurrentPage] = useState(1); 
    const itemsPerPage = 10; 

    useEffect(() => {
        formatChartData(dataType);
    }, [dataType, customerList]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setVisibleData(chartData.slice(startIndex, endIndex));
    }, [chartData, currentPage]);

    const formatChartData = (type) => {
        const fieldMapping = {
            Confirmed: "confirmed_amount",
            Cancelled: "cancelled_amount",
            Pending: "pending_amount",
            TotalAmount: "total_payment_amount",
        };

        const selectedField = fieldMapping[type];
        const formattedData = customerList
            .filter((item) => item?.customer) // Filter out null customers
            .map((item) => ({
                name: item?.customer?.name || "Unknown Customer",
                y: item[selectedField] || 0,
            }));

        setChartData(formattedData);
        setCurrentPage(1);
    };

    const handleChange = (event) => {
        setDataType(event.target.value);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const options = {
        chart: { type: "pie" },
        title: { text: `Customer Data by ${dataType}` },
        tooltip: { pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>" },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: {point.percentage:.1f}%",
                },
            },
        },
        series: [{
            name: `${dataType} Count`,
            colorByPoint: true,
            data: visibleData,
        }],
    };

    const totalPages = Math.ceil(chartData.length / itemsPerPage);
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(currentPage * itemsPerPage, chartData.length);

    return (
        <Box p={4}>
            <FormControl fullWidth margin="normal">
                <InputLabel id="data-type-label">Select Data Type</InputLabel>
                <Select
                    labelId="data-type-label"
                    value={dataType}
                    onChange={handleChange}
                >
                    <MenuItem value="Confirmed">Confirmed</MenuItem>
                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="TotalAmount">Total Amount</MenuItem>
                </Select>
            </FormControl>
            <HighchartsReact highcharts={Highcharts} options={options} />
            <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    Previous
                </Button>
                <Typography>
                    Showing {rangeStart} - {rangeEnd} of {chartData.length}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </Box>
        </Box>
    );
}
