import React, { useState } from "react";
import "./termsandcondition.scss";

export default function TermsAndCondition() {
  const [moduleMessage] = useState(
    "You have not selected any module. Go to settings and check the module."
  );
  const [termsMessage] = useState(
    "You have not added any terms to this module."
  );

  return (
    <div className="page-px-24">
    <div className="terms-container">
 <div>
           <h2>Terms & Conditions </h2>
         </div>

      <div className="terms-content">

        <div className="section">
          <h2 className="section-title">Module</h2>
          <p className="section-message">{moduleMessage}</p>
        </div>

        <div className="section">
          <h2 className="section-title">Terms & Conditions</h2>
          <p className="section-message">{termsMessage}</p>
        </div>
      </div>
    </div>
    </div>
  );
}
