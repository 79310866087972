import React, { useState, useEffect } from 'react';
import './productcategorytable.scss';
import { fetchData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link } from 'react-router-dom';
import EditIcon from '../../../shared/icons/editIcon';
import Pagination from '../../../shared/components/pagination';
import { checkAccess } from '../../../services/checkFeature';
import SearchIcon from '../../../assets/icons/search.svg';
import debounce from 'lodash.debounce';
export default function ProductCategoryTable({
  categoryList,
  setSelectedIds,
  setProductList,
  setTotalItems,
  totalItems,
  selectedIds,
}) {
  const [productList, setProduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const pageSize = 10;
  const [productData, setProductData] = useState([]);
  const [Category, setCategoryTerm] = useState("");
  const [Status, setStatusTrem] = useState("");
  const [allactivityType, setallactivityTypeTerm] =useState('');
    useEffect(() => {
    const filters = {
      typeName: Category,
      status: Status
    };

    fetchProduct(filters, true, currentPage, pageSize)
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedIds(categoryList.map((item) => item.id));
    } else {
      setSelectedIds([]);
    }
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => value !== undefined && value !== null && value !== "")
    );
  };
  const fetchProduct = async (filters, isSearching = false, page = 1, pageSize = 10, globalSearch = {}) => {
    try {
      const pagination = {
        page: page,
        limit: pageSize,
      };
  
      const filterParams = {
        ...removeEmptyFields(filters), 
        typeCategory: 'category',      
      };
  
      const responseProducts = await fetchData(
        'systemtype',
        removeEmptyFields(filters), 
        // filterParams,
        pagination,
        {},
        isSearching,
        globalSearch
      );
  
      if (responseProducts?.data) {
        setProductData(responseProducts.data || []); // Update product data
        setTotalItems(responseProducts?.totalDocuments || 0); // Update total item count
      } else {
        setProductData([]); // Clear product data if no response
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  useEffect(() => {
    const filters = {
      typeName: Category,
      status: Status,
    };

    const debouncedFetch = debounce(() => {
      setCurrentPage(1);
      fetchProduct(filters, true, currentPage, pageSize);
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [Category, Status]);

  useEffect(() => {
    const filters = {
      isGlobalSearch: true,
      isSearching: true,
      searchfields: ['typeName'],
      searchtext: allactivityType,
    };

    const debouncedFetch = debounce(() => {
      fetchProduct({}, true, currentPage, pageSize, allactivityType ? filters : {});
    }, 500);

    debouncedFetch();

    return () => {
      debouncedFetch.cancel();
    };
  }, [allactivityType, currentPage, pageSize]);

  return (
    <div className="">
            {/* <div className='account-table-header-alignment'>
        <div className='search-width'>
          <div className='searchbar-design'>
            <input type='text' placeholder='Search' value={allactivityType} onChange={(e) => setallactivityTypeTerm(e.target.value)} />
            <div className='icon-alignment'>
              <img src={SearchIcon} alt="SearchIcon" />
            </div>
          </div>
        </div>
      </div> */}
      <div className='table-v2-design'>
        <div>
          <table>
            <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={
                    selectedIds.length === categoryList.length &&
                    categoryList.length > 0
                  }
                />
              </th>
            
              <th>Category</th>
              <th>Status</th>
              {checkAccess('manage_product_category') && <th>Action</th>}
            </tr>
            </thead>
            <tbody>
            {productData && productData.length > 0 ? (
              productData.map((item) => (
                <tr key={item.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                  <td>{item?.typeName || "N/A"}</td>
                  <td>
                    <button className={item?.status ? "active-btn" : "active-btn in-active-btn"}>
                      <div className='dot'></div>
                      {item?.status ? "Active" : "Inactive"}
                    </button>
                  </td>
                  {checkAccess('manage_product_category') && (
                    <td>
                      <Link to={{ pathname: `edit/${item?.id}` }}>
                        <EditIcon />
                      </Link>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center", fontStyle: "italic", color: "#666" }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems} 
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
      {/* <div className="account-table-design">
        <table>
          <thead>
          <tr>
              <th className='cradsize'><div className="tableSize"></div></th>
             
              <th className='crad'><div className="tableSize"></div></th>
              <th><input type="text" placeholder="Category" value={Category} onChange={(e) => setCategoryTerm(e.target.value)} className="w-100 border-0 table-input-background" /></th>
              <th style={{width:'10%'}}>
                <div>
                  <select
                    value={Status === undefined ? "" : Status.toString()}
                    onChange={(e) => {
                      const selectedStatus = e.target.value === "true" ? true : e.target.value === "false" ? false : undefined;
                      setStatusTrem(selectedStatus); 
                    }}
                    className="w-100 border-0 table-input-background"
                  >
                    <option value="">All</option>  
                    <option value="true">Active</option>     
                    <option value="false">Inactive</option> 
                  </select>
                </div>
              </th>
            </tr>

            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={
                    selectedIds.length === categoryList.length &&
                    categoryList.length > 0
                  }
                />
              </th>
              {checkAccess('manage_product_category') && <th>Action</th>}
              <th>Category</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {productData && productData.length > 0 ? (
              productData.map((item) => (
                <tr key={item.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedIds.includes(item.id)}
                      onChange={() => handleCheckboxChange(item.id)}
                    />
                  </td>
                  {checkAccess('manage_product_category') && (
                    <td>
                      <Link to={{ pathname: `edit/${item?.id}` }}>
                        <EditIcon />
                      </Link>
                    </td>
                  )}
                  <td>{item?.typeName || "N/A"}</td>
                  <td>
                    <button className={item?.status ? "Active" : "Inactive"}>
                      {item?.status ? "Active" : "Inactive"}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={4} style={{ textAlign: "center", fontStyle: "italic", color: "#666" }}>
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="pagination-alignment">
          <Pagination
            currentPage={currentPage}
            totalItems={totalItems} 
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div> */}
    </div>
  );
}
