import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Attendancetable from './attendancetable';
import AttendanceWithLeave from './attendancewithleave';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import UserDailySummary from './userdailysummary';

export default function Attendance() {
  const [activeComponent, setActiveComponent] = useState('Attendancetable'); 

  const renderComponent = () => {
    switch (activeComponent) {
      case 'Attendancetable':
        return <Attendancetable />;
      case 'AttendanceWithLeave':
        return <AttendanceWithLeave />;
      case 'DailyUserSummary':
        return <UserDailySummary></UserDailySummary>;
      default:
        return <Attendancetable />;
    }
  };

  const buttonStyle = (isActive) => ({
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '16px', 
    padding: '10px 15px',
    cursor: 'pointer',
    color: isActive ? '#007bff' : '#000', 
    borderBottom: isActive ? '2px solid #007bff' : '2px solid transparent', 
    fontWeight: isActive ? 'bold' : 'normal',
    transition: 'all 0.3s ease' 
  });

  return (
    <div className="page-24">
      <div className="">
        <div className="pb-4 customer-header-alignment" style={{ display: 'unset' }} >
          <div>
            <h2>Attendance</h2>
            <Breadcumbs activePath="Attendance" pageName="User Attendance" />
          </div>
          <div className="actions-container" style={{
            borderBottom: '1px solid #d3d3d3', 
            paddingBottom: '10px',
            margin: '10px 0',
            display: 'flex',
            gap: '10px',
            alignItems: 'center'
          }}>
            <button
              style={buttonStyle(activeComponent === 'Attendancetable')}
              onClick={() => setActiveComponent('Attendancetable')}
            >
              Punch in
            </button>
            <button
              style={buttonStyle(activeComponent === 'AttendanceWithLeave')}
              onClick={() => setActiveComponent('AttendanceWithLeave')}
            >
              Users
            </button>
            <button
              style={buttonStyle(activeComponent === 'DailyUserSummary')}
              onClick={() => setActiveComponent('DailyUserSummary')}
            >
              Days
            </button>
          </div>
        </div>
      </div>
      <div>
        {renderComponent()}
      </div>
    </div>
  );
}
