import { useEffect, useState, useRef } from 'react';
import { fetchCustomData,fetchData} from '../../../services/apiService';
import ViewIcon from '../../../assets/icons/view.svg';
import Pagination from '../../../shared/components/pagination';
import { Modal } from 'antd';
import mapboxgl from 'mapbox-gl';
import SelectBox from '../../../shared/components/select';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { time } from 'highcharts';


const MAPBOX_TOKEN = 'pk.eyJ1IjoicnV0dmlrbSIsImEiOiJjbHh2b3Zjcmkwd28zMm5zZ3BzYWJ4NXlkIn0.SnKNDTgcIO2Ar6dGfnXZbQ';

export default function AllLocationTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [allLocationList, setAllLocationList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [modalTitle, setModalTitle] = useState(''); 
  const [searchuser, setSearchUser] = useState("");
  const [user, setUser] = useState([]);
  const [userrole, setUserrole] = useState([]);
  const [searchrole, setSearchrole] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [searchtime,setSearchtime]=useState("");
  const [distance,setDistance]=useState("");
  const[duration,setDuration]=useState("");
  const[battery,setBattery]=useState("");
  const[status,setStatus]=useState("");
  const [filteredAllLocationList, setFilteredAllLocationList] = useState([]);

  const { RangePicker } = DatePicker;


  const pageSize = 10;
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    getData(currentPage, pageSize);
    getUser();
    getUserrole();
  }, [currentPage]);
  useEffect(() => {
    filterAllLocationList(); // Apply filtering whenever inputs or data change
  }, [searchuser, searchrole, selectedDates, searchtime, distance, duration, battery, status, allLocationList]);
  const getUserrole = async () => {
    try {
      const response = await fetchData(`userrole`);
      if (response?.data) {
        setUserrole(
          response?.data?.map((i) => {
            return {
              label: `${i?.role}`,
              key: "userrole",
              value: {
                id: i?.id,
                name: `${i?.role}`,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getUser = async () => {
    try {
      const response = await fetchData(`user`);
      if (response?.data) {
        setUser(
          response?.data?.map((i) => {
            return {
              label: `${i?.name}`,
              key: "user",
              value: {
                id: i?.id,
                name: `${i?.name}`,
              },
            };
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getData = async (page, pageSize) => {
    try {
      const requestBody = {};
      const pagination = {
        page: page,
        limit: pageSize,
      };
      const response = await fetchCustomData('location_tracking/All-user', requestBody, pagination);
      if (response?.data) {
        setAllLocationList(response.data || []);
        setTotalItems(response?.totalDocuments || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const openMapModal = (latitude, longitude, userName, date, time) => {
    setLat(latitude);
    setLong(longitude);
    setModalTitle(`${userName} - ${date} ${time}`); 
    setIsModalOpen(true);
  };
  

  const handleModalClose = () => {
    setIsModalOpen(false);
    setLat(null);
    setLong(null);
    if (mapRef.current) {
      mapRef.current.remove(); 
      mapRef.current = null;
    }
  };

  const initializeMap = () => {
    if (!mapContainerRef.current || lat === null || long === null) return;
    if (mapRef.current) {
      mapRef.current.remove();
      mapRef.current = null;
    }

  
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      center: [long, lat], 
      zoom: 12,
      style: 'mapbox://styles/mapbox/streets-v11',
      accessToken: MAPBOX_TOKEN,
    });

    new mapboxgl.Marker()
      .setLngLat([long, lat]) 
      .addTo(mapRef.current);
    setTimeout(() => {
      if (mapRef.current) mapRef.current.resize();
    }, 0);
  };

  useEffect(() => {
    if (isModalOpen && lat !== null && long !== null) {
      initializeMap();
    }
  }, [isModalOpen, lat, long]);
  console.log(allLocationList);
  
 const rangePresets = [
  {
    label: 'Last 7 Days',
    value: [dayjs().subtract(7, 'day'), dayjs()],
  },
  {
    label: 'Last 14 Days',
    value: [dayjs().subtract(14, 'day'), dayjs()],
  },
  {
    label: 'Last 30 Days',
    value: [dayjs().subtract(30, 'day'), dayjs()],
  },
  {
    label: 'Last 90 Days',
    value: [dayjs().subtract(90, 'day'), dayjs()],
  },
];
const onRangeChange = (dates) => {
  setSelectedDates(dates); // Update the state with the selected date range
};
const filterAllLocationList = () => {
  const hasFilters =
    searchuser || searchrole || selectedDates.length || searchtime || distance || duration || battery || status;

  if (!hasFilters) {
    // No filters applied, show all data
    setFilteredAllLocationList(allLocationList);
    setTotalItems(allLocationList.length);
    return;
  }

  const filtered = allLocationList.filter((item) => {
    const matchesUser = searchuser
      ? item.userName?.toLowerCase().includes(searchuser.toLowerCase())
      : true;

    const matchesRole = searchrole
      ? item.userRole?.toLowerCase().includes(searchrole.toLowerCase())
      : true;

    const matchesDateRange =
      selectedDates && selectedDates.length === 2
        ? dayjs(item.date).isSameOrAfter(selectedDates[0], 'day') &&
          dayjs(item.date).isSameOrBefore(selectedDates[1], 'day')
        : true;

    const matchesTime = searchtime
      ? item.time?.toLowerCase().includes(searchtime.toLowerCase())
      : true;

    const matchesDistance = distance
      ? String(item.incrementalDistance || '').includes(distance)
      : true;

    const matchesDuration = duration
      ? String(item.incrementalTime || '').includes(duration)
      : true;

    const matchesBattery = battery
      ? String(item.battery_level || '').includes(battery)
      : true;

    const matchesStatus = status
      ? item.visit_type?.toLowerCase().includes(status.toLowerCase())
      : true;

    return (
      matchesUser &&
      matchesRole &&
      matchesDateRange &&
      matchesTime &&
      matchesDistance &&
      matchesDuration &&
      matchesBattery &&
      matchesStatus
    );
  });
  setFilteredAllLocationList(filtered);
  setTotalItems(filtered.length);
  const totalFilteredPages = Math.ceil(filtered.length / pageSize);
  if (currentPage > totalFilteredPages) {
    setCurrentPage(1); 
  }
};



const paginatedData = filteredAllLocationList.slice(
  (currentPage - 1) * pageSize,
  currentPage * pageSize
);
  return (
    <div className="account-table-page-alignment">
      <div className="account-table-design">
        <table>
          <thead>
            <tr>
              <th><div >
                              <SelectBox
                                value={
                                  user?.find((option) => option.label === searchuser) || null
                                }
                                options={user || []}
                                onChange={(selectedOption) =>
                                  setSearchUser(selectedOption?.label || "")
                                }
                                placeholder="User"
                                type="text"
                                required={true}
                                styles={{
                                  menu: (provided) => ({
                                    ...provided,
                                    zIndex: 9999,
                                    maxHeight: '400px',
                                    overflowY: 'auto',
                                    border: 'none',
                                  }),
                                  control: (provided) => ({
                                    ...provided,
                                    minWidth: '100%',
                                    border: 'none',
                                    marginTop: '-8px',
                                    maxHeight: '25px',
                                    textAlign: 'left',
                                  }),
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                    border: 'none',
                                  }),
                                }}
                                menuPortalTarget={document.body}
                                isClearable={true}
                                components={{
                                  ClearIndicator: (props) => (
                                    <div
                                      {...props.innerProps}
                                      style={{
                                        cursor: 'pointer',
                                        padding: '5px',
                                        color: 'gray',
                                      }}
                                    >
                                      &#x2715;
                                    </div>
                                  ),
                                }}
                              />
                            </div></th>
                              <th><div >
                                            <SelectBox
                                              value={
                                                userrole?.find((option) => option.label === searchrole) || null
                                              }
                                              options={userrole || []}
                                              onChange={(selectedOption) =>
                                                setSearchrole(selectedOption?.label || "")
                                              }
                                              placeholder="User"
                                              type="text"
                                              required={true}
                                              styles={{
                                                menu: (provided) => ({
                                                  ...provided,
                                                  zIndex: 9999,
                                                  maxHeight: '400px',
                                                  overflowY: 'auto',
                                                  border: 'none',
                                                }),
                                                control: (provided) => ({
                                                  ...provided,
                                                  minWidth: '100%',
                                                  border: 'none',
                                                  marginTop: '-8px',
                                                  maxHeight: '25px',
                                                  textAlign: 'left',
                                                }),
                                                menuPortal: (base) => ({
                                                  ...base,
                                                  zIndex: 9999,
                                                  border: 'none',
                                                }),
                                              }}
                                              menuPortalTarget={document.body}
                                              isClearable={true}
                                              components={{
                                                ClearIndicator: (props) => (
                                                  <div
                                                    {...props.innerProps}
                                                    style={{
                                                      cursor: 'pointer',
                                                      padding: '5px',
                                                      color: 'gray',
                                                    }}
                                                  >
                                                    &#x2715;
                                                  </div>
                                                ),
                                              }}
                                            />
                                          </div></th>
                                          <th><div style={{ width: '100%' }}>
                <Space direction="vertical" size={12}>
                  <RangePicker presets={rangePresets} onChange={onRangeChange} placeholder={[' Start Date', ' End Date']} className="my-custom-class" />
                </Space></div> </th>
                <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Time"
                value={searchtime}
                onChange={(e) => setSearchtime(e.target.value)}
                className="search-input input-height"
                style={{ border: 'none', width: '100%' }}

              /></div></th>
                <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Distance"
                value={distance}
                onChange={(e) => setDistance(e.target.value)}
                className="search-input input-height"
                style={{ border: 'none', width: '100%' }}

              /></div></th>
               <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Duration"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                className="search-input input-height"
                style={{ border: 'none', width: '100%' }}

              /></div></th>
              <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Battery Level"
                value={battery}
                onChange={(e) => setBattery(e.target.value)}
                className="search-input input-height"
                style={{ border: 'none', width: '100%' }}

              /></div></th>
               <th><div style={{ width: '100%' }}><input
                type="text"
                placeholder="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="search-input input-height"
                style={{ border: 'none', width: '100%' }}

              /></div></th>
              <th><div style={{ width: '100%' }}></div></th>
            </tr>
            <tr>
              <th>User</th>
              <th>User Role</th>
              <th>Date</th>
              <th>Time</th>
              <th>Distance</th>
              <th>Duration</th>
              <th>Battery Level</th>
              <th>Status</th>
              <th>View Map</th>
            </tr>
          </thead>
          <tbody>
            {paginatedData?.slice().reverse().map((item, index) => (
              <tr key={index}>
                <td>{item?.userName}</td>
                <td>{item?.userRole}</td>
                <td>
                  {item?.date} 
                </td>
                <td>{item?.time}</td>
                <td>{item?.incrementalDistance} Km</td>
                <td>{item?.incrementalTime}</td>
                <td>{item?.battery_level ? `${item.battery_level}%` : ''}</td>
                <td className="status">{item?.visit_type}</td>
                <td>
                  <img
                    src={ViewIcon}
                    alt="View Map"
                    onClick={() => openMapModal(item.latitude, item.longitude,item.userName,item.date,item.time)}
                    style={{ cursor: 'pointer' }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-alignment">
        <Pagination
  currentPage={currentPage}
  totalItems={filteredAllLocationList.length} 
  pageSize={pageSize}
  onPageChange={handlePageChange}
/>
        </div>
      </div>
      <Modal
        title={modalTitle}
        open={isModalOpen}
        onOk={handleModalClose}
        onCancel={handleModalClose}
        width={800}
        style={{ top: 20 }}
        bodyStyle={{ padding: 0 }}
      >
        <div
          ref={mapContainerRef}
          style={{ width: '100%', height: '400px' }}
        />
      </Modal>
    </div>
  );
}
