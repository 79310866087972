import React, { useState } from 'react'
import { deleteData } from '../../services/apiService';
import './pricegroup.scss';
import Button from '../../shared/components/button';
import PricegroupTable from './pricegroupTable';
import { Link } from 'react-router-dom';
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from '../../services/globalService';
import { filterAll } from '../../services/apiService';
import { checkAccess } from '../../services/checkFeature';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import AccountTableHeader from '../account/accountTableHeader';
import PageSubHeader from '../../shared/components/pageSubHeader';
import PrimaryButton from '../../shared/components/primaryButton';
import CaretIcon from '../../shared/icons/caretIcon';
export default function Pricegroup() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [PriceList, setpricelist] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const handleExport = () => {
    const headers = [
      ["Name", "Code", "Price", "Discount", "Offer Discount", "Status"]
    ];

    filterAll("pricegroup")
      .then((data) => {
        const datas = (data.data ?? [])?.map((item) => [
          item.name,
          item.code,
          item.price,
          item.discount,
          item.offerdiscount,
          item.status ? "Active" : "Inactive",
        ]);
        exportToExcel('Pricegroup', headers, datas, 'pricegroup')
      })
  };
  const handleDelete = async () => {
    try {
      await Promise.all(selectedIds.map((id) => deleteData(`pricegroup/${id}`)));
      setpricelist(PriceList.filter((i) => !selectedIds.includes(i.id)));

      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error('Error deleting data:', error);

    }
  };
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    setConfirmLoading(true);

    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);

  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  return (
    <>
      <div className="design-v2-header">
        <div className="design-v2-left">
          <h2>
            Price Group
          </h2>
          <div className="design-breadcumb">
            <span>Catalog</span>
            <CaretIcon />
            <span>Price Group</span>

          </div>
        </div>
        <div className="design-v2-right">
          {checkAccess('manage_price_group') && <Link to="addprice">
            <PrimaryButton text="Add Price Group" />
          </Link>}

        </div>
      </div>
      <PageSubHeader />
      <div className='design-v2-left-right-alignment'>
      <PricegroupTable
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            PriceList={PriceList}
            setProductList={setpricelist}
            totalItems={totalItems}
            setTotalItems={setTotalItems} />
            </div>
      <div className='page-24'>
        {/* <div className="">
          <div className="pb-4 customer-header-alignment">
            <div>
              <h2>Price Group</h2>
              <Breadcumbs activePath="Catalog" pageName="Price Group" />
            </div>
            <div className="right-contnet-alignment">
              {selectedIds.length > 0 && (
                <Popconfirm
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  title="Are you sure to delete this record? "
                  description="This will lead to delete all records related to this record only!"
                  open={open}
                  onConfirm={handleOk}
                  okButtonProps={{ loading: confirmLoading }}
                  onCancel={handleCancel}
                >
                  <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
                </Popconfirm>
              )}
              {checkAccess('manage_price_group') && <Link to="addprice">
                <GlobalButton text="Add Price Group" Icon={PlusIcon} />
              </Link>}
            </div>
          </div>
        </div> */}
        {/* <div className=''>
          <PricegroupTable
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            PriceList={PriceList}
            setProductList={setpricelist}
            totalItems={totalItems}
            setTotalItems={setTotalItems} />
        </div> */}
      </div>
    </>
  )
}
