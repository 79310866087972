import React, { useState, useEffect } from 'react';
import 'moment/locale/it.js';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import SelectBox from '../../../shared/components/select';
import { fetchsingleData, fetchData, patchData, postData, uploadDocument } from '../../../services/apiService';
import UploadImage from '../../../shared/components/uploadImage';
// import { deleteDocument } from '../../../services/s3bucket';


import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Leadstatus from '../../leadstatus';
import dayjs from 'dayjs';
import GlobalButton from '../../../shared/globalButton';



export default function AddEditProfile() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [leadSource, setLeadSource] = useState([]);
    const [LeadIndustry, setLeadIndustry] = useState([]);
    const [pricegroup, setPriceGroup] = useState([]);
    const [newNotification, setNewNotification] = useState([]);

    const [formData, setFormData] = useState({
        name: '',
        website: '',
        contactNo: '',
        personName: '',
        fax: '',
        email: '',
        address: '',
        pincode: '',
        // country : '',
        // state : '',
        // city: '',
        GST: '',
        PAN: '',
        PlaceofSupply: '',
        TAN: '',
        document_id: '',
        password: "",
        confirmPassword: "",
        salesUsers: "",
        companyName: ""




    });
    const [TurnOver, setTurnOver] = useState([]);
    const [errors, setErrors] = useState({});
    const options = [
        { value: '5 Lakh+', label: '5 lakh+' },
        { value: '3 to 5 Lakh', label: '3 to 5 Lakh' },
        { value: '1.5 to 3 Lakh', label: '1.5 to 3 Lakh' },
        { value: '1.5 lakh and above', label: '1.5 lakh and above' },

    ];


    useEffect(() => {
        getData();
        setTurnOver(options);

    }, []);

    const getData = async () => {
        try {
            const response = await fetchData(`leadSource`);
            if (response?.data) {
                const formattedleadsource = response.data.map(i => ({
                    label: i?.name,
                    key: 'name',
                    value: { name: i?.name, }
                }));
                setLeadSource(formattedleadsource);
            }
            const responsee = await fetchData(`leadIndustry`);
            if (responsee?.data) {
                const leadindustry = responsee.data.map(i => ({
                    label: i?.name,
                    key: 'leadindustry',
                    value: { name: i?.name }
                }));
                setLeadIndustry(leadindustry);
            }
            const responseee = await fetchData(`pricegroup`);
            if (responseee?.data) {
                const pricegroup = responseee.data.map(i => ({
                    label: i?.name,
                    key: 'pricegroup',
                    value: { name: i?.name }
                }));
                setPriceGroup(pricegroup);
            }
            // 
            if (id) {
                const response = await fetchsingleData("companyprofile", id);
                if (response?.data) {
                    const fetchedData = response.data;

                    setFormData(fetchedData); // Update state with fetched data
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validationCheck()) {
            try {
                if (id) {
                    patchData(`companyprofile/${id}`, formData).then(data => {
                        navigate(-1);
                        console.log('API response:', data);
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    });
                } else {
                    postData('companyprofile', formData).then(data => {
                        navigate(-1);
                        console.log('API response:', data);
                    }).catch(error => {
                        console.error('Error fetching data:', error);
                    });

                }
            } catch (error) {
                console.error('Error saving data:', error);
                // Handle error
            }
        }
    };

    const selectChanged = (e) => {
        setFormData(prevState => ({
            ...prevState,

            // ['turnover']: e.value,
        }));
        if (errors.isSubmit) validationCheck();

        if (e.key === "country") {
            setFormData(prevState => ({
                ...prevState,
                ...e.value
            }));
            fetchState(e.value.countryId)
        } else if (e.key === "state") {
            setFormData(prevState => ({
                ...prevState,
                ...e.value
            }));
            fetchArea(e.value.stateId)

        }
        else {
            setFormData(prevState => ({
                ...prevState,
                ...e.value
            }));
        }
    };
    const selectindustryChanged = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [`leadindustry`]: e.value?.name,


        }));
        if (errors.isSubmit) validationCheck();
    };

    const selectCustChanged = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [`leadsource`]: e?.value?.name,

        }));
        if (errors.isSubmit) validationCheck();
    };
    const SelectChanged = (e) => {
        setFormData(prevState => ({
            ...prevState,
            [`pricegroup`]: e?.value?.name,

        }));
        if (errors.isSubmit) validationCheck();
    };


    const toggleSwitch = (checked) => {
        setFormData({ ...formData, status: checked });
    };

    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (errors.isSubmit) validationCheck();
    };
    const [countryOptions, setCountryOptions] = useState([])
    const [sateOptions, sateCategory] = useState([])
    const [areaOptions, areCategory] = useState([])
    const [priceOptions, priceCategory] = useState([])
    const fetchCountry = async () => {
        try {
            const response = await fetchData("country");

            if (response?.data) {
                setCountryOptions(response?.data.map(i => {
                    return {
                        label: i?.countryName, key: 'country', value: { countryId: i?.id, countryName: i?.countryName }
                    }
                }));
            }
            if (id) {
                const response = await fetchsingleData("country", id);
                if (response?.data)
                    setFormData(response?.data); // Update state with fetched data
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const fetchState = async (id) => {
        try {
            const response = await fetchData("state", { countryId: id });

            if (response?.data) {
                sateCategory(response?.data.map(i => {
                    return {
                        label: i?.stateName, key: 'state', value: { stateId: i?.id, stateName: i?.stateName }
                    }
                }));
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const fetchArea = async (id) => {
        try {
            const response = await fetchData("area", { stateId: id });
            console.log('arra', response.data);
            if (response?.data) {

                areCategory(response?.data.map(i => {
                    return {
                        label: i?.cityName, key: 'area', value: { areaId: i?.id, areaName: i?.cityName }
                    }
                }));
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {

        fetchCountry()
        fetchState()
        fetchArea()
    }, [])

    // const handleDateChange = (date) => {
    //     setFormData(prevState => ({
    //         ...prevState,
    //         date
    //     }));
    //     if (errors.isSubmit) validationCheck();
    // };

    const validationCheck = (isSubmit) => {
        const validationErrors = {};
        if (!formData.name) {
            validationErrors.name = "Enter Registered Name";
        }
        if (!formData.contactNo) {
            validationErrors.contactNo = "Enter Registered Contact No ";

        }
        if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

        setErrors(validationErrors);
        return (
            Object.keys(validationErrors).filter((i) => i !== "isSubmit").length === 0
        );
    };

    const handleFileChange = async (e, id) => {
        const file = e;
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('name', file.name);
        try {
            const status = await uploadDocument(`document/uploadfile`, formData);
            if (status?.data?.id) {
                // if (formData?.productUrl) {
                //     await deleteDocument(formData?.productUrl);
                // }
                setFormData(prevFormData => ({
                    ...prevFormData,
                    profileUrl: `${status?.data?.id}`,
                    document_id: status?.data?.id
                }));
            }
        } catch (error) {
            console.error("Error uploading the file:", error);
        }
    };

    return (
        <div>
            <div className='page-header add-news-alignment'>
                <strong> <span>Profile {'>'} Create</span></strong>
                <div className='two-button-alignment'>
                    <div onClick={handleSubmit} ><GlobalButton text="Save" /></div>
                    <Link to="/profile"> <GlobalButton text="Discard" outline /></Link>
                </div>
            </div>
            <div>
                <h5 style={{ marginTop: '12px', paddingLeft: '10px' }}>Profile Info</h5>
                <hr style={{ marginTop: '0.5px' }} />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ width: '50%', marginLeft: "25px" }}>
                        <h2>Profile Image</h2>
                        <div className='grid'>
                            <div className='bottom-alignment'>
                                <UploadImage action={(e) => handleFileChange(e, formData.id)} value={formData.profileUrl} />
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
                            <div style={{ width: '100%', paddingLeft: '20px' }}>
                                <Input
                                    label={
                                        <span>
                                            Name<span className="required-star">*</span>
                                        </span>}
                                    name='name'
                                    placeholder='Name'
                                    type='text'
                                    value={formData.name} onChange={handleInputChange}
                                    errors={errors.name}
                                    required
                                />
                            </div>
                            <div style={{ paddingLeft: '20px', marginTop: '10px' }}>
                                <Input
                                label={
                                    <span>
                                      Registered Contact No<span className="required-star">*</span>
                                     </span> }
                                    name='contactNo'
                                    value={formData.contactNo} onChange={handleInputChange}
                                    placeholder='Registered Contact No'
                                    type='number'
                                    errors={errors.contactNo}
                                    required />
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
                            <div style={{ paddingLeft: '20px', width: '100%' }}>
                                <Input
                                    label='Website'
                                    name='website'
                                    value={formData.website} onChange={handleInputChange}
                                    placeholder='Website'
                                    type='text'
                                    errors={errors.website}
                                    required
                                />
                            </div>
                            <div style={{ paddingLeft: '20px', marginTop: '10px' }}>
                                <Input
                                    label='Fax'
                                    name='fax'
                                    placeholder='Fax'
                                    type='number'
                                    value={formData.fax} onChange={handleInputChange}
                                    errors={errors.fax}
                                    required />
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between' }}>
                            <div style={{ paddingLeft: '20px', width: '100%' }}>
                                <Input
                                    label='Registered Email'
                                    name='email'
                                    placeholder='Registered Email'
                                    type='text'
                                    value={formData.email} onChange={handleInputChange}
                                    errors={errors.email}
                                    required
                                />
                            </div>
                            <div style={{ paddingLeft: '20px', marginTop: '10px' }}>
                                <Input
                                    label='Contact Person Name'
                                    name='personName'
                                    placeholder='Contact Person Name'
                                    type='text'
                                    value={formData.personName} onChange={handleInputChange}
                                    errors={errors.personName}
                                    required />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div>
                <div className='page-header add-news-alignment' style={{ marginTop: "20px" }}>
                    <strong> <span>Address Details</span></strong>
                </div>
                <div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ display: 'flex', width: '30%', }}>
                                <div style={{ width: '100%', paddingLeft: '20px', marginTop: "20px" }}>
                                    <Input
                                        label='Address'
                                        name='address'
                                        placeholder='Address'
                                        type='Address'
                                        value={formData.address} onChange={handleInputChange}
                                        errors={errors.address}
                                        required
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '30%' }}>
                                <div style={{ width: '100%', paddingLeft: '20px', marginTop: "20px" }}>
                                    <Input
                                        label='Pincode'
                                        name='pincode'
                                        placeholder='Pincode'
                                        type='number'
                                        value={formData.pincode} onChange={handleInputChange}
                                        errors={errors.pincode}
                                        required
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '30%' }}>
                                <div style={{ width: '100%', paddingLeft: '20px', marginTop: "20px" }}>
                                    <SelectBox
                                        label='Country'
                                        name='country'
                                        value={countryOptions.find(i => i.value.id === formData.countryId)} options={countryOptions} onChange={selectChanged}
                                        placeholder='Select Country'
                                        required
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '30%' }}>
                                <div style={{ width: '100%', paddingLeft: '20px', marginTop: "20px" }}>
                                    <SelectBox
                                        label='State'
                                        name='state'
                                        value={sateOptions.find(i => i.value.id === formData.stateId)} options={sateOptions} onChange={selectChanged}
                                        placeholder='Select State'
                                        required
                                    />
                                </div>
                            </div>
                        </div>



                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', width: '25%', }}>
                            <div style={{ width: '100%', paddingLeft: '20px', marginTop: "20px" }}>
                                <SelectBox
                                    label='City'
                                    name='city'
                                    value={areaOptions.find(i => i.value.id === formData.areaId)} options={areaOptions} onChange={selectChanged}
                                    placeholder='Select City'
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className='page-header add-news-alignment' style={{ marginTop: "80px" }}>
                    <strong> <span>Statutory Details</span></strong>
                </div>
                <div>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <div style={{ display: 'flex', width: '30%', }}>
                                <div style={{ width: '100%', paddingLeft: '20px', marginTop: "20px" }}>
                                    <Input
                                        label='GST'
                                        name='GST'
                                        value={formData.GST} onChange={handleInputChange}
                                        placeholder='GST'
                                        type='text'
                                        errors={errors.GST}
                                        required
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '30%' }}>
                                <div style={{ width: '100%', paddingLeft: '20px', marginTop: "20px" }}>
                                    <Input
                                        label='Place Of Supply'
                                        name='PlaceofSupply'
                                        placeholder='Place Of Supply'
                                        type='text'
                                        value={formData.PlaceofSupply} onChange={handleInputChange}
                                        errors={errors.PlaceofSupply}
                                        required
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '30%' }}>
                                <div style={{ width: '100%', paddingLeft: '20px', marginTop: "20px" }}>
                                    <Input
                                        label='PAN'
                                        name='PAN'
                                        value={formData.PAN} onChange={handleInputChange}
                                        placeholder='PAN'
                                        type='text'
                                        errors={errors.PAN}
                                        required
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', width: '30%' }}>
                                <div style={{ width: '100%', paddingLeft: '20px', marginTop: "20px" }}>
                                    <Input
                                        label='TAN'
                                        name='TAN'
                                        value={formData.TAN} onChange={handleInputChange}
                                        placeholder='TAN'
                                        type='text'
                                        errors={errors.TAN}
                                        required
                                    />
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div >


    );
}
