import React, { useState, useEffect } from 'react';
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchsingleData, fetchData, patchData, postData, uploadDocument, filterAll } from '../../../services/apiService';
import SelectBox from '../../../shared/components/select';
import Toggle from '../../../shared/components/switch';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import UploadImage from '../../../shared/components/uploadImage';
import Breadcumbs from '../../../shared/components/breadcumbs';
import StatusBox from '../../addcustomers/statusBox';
import CancelIcon from '../../../assets/icons/Cancel.svg';
import { Link } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import store from '../../../redux/reduxstore';
import GlobalButton from "../../../shared/globalButton";
import { notify } from '../../toastr/Toastr';
import PlusIcon from '../../../assets/icons/plus.svg';
import { TextField, MenuItem, Box, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import './addeditproduct.scss'
import dayjs from 'dayjs';
import { Flex } from 'antd';
import Modal from "../../../shared/components/model";

export default function AddEditProduct() {
  const navigate = useNavigate();
  const { id } = useParams();
  const company_setting = store.getState()?.auth?.company_setting;
  const [isEditable, setIsEditable] = useState(true); // To toggle between edit and view modes
  const [categoryOptions, setCategoryOptions] = useState([]);
  const location = useLocation();
  const [priceOptions, priceCategory] = useState([])
  const isVisible = /^\/product\/view\/\d+$/.test(location.pathname);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unitOptions, setUnitOptions] = useState([]);
  const [timelines, setTimelines] = useState([]);
  const [isChecked, setIsChecked] = useState(false);



  const [formData, setFormData] = useState({
    name: '',
    hsn_code: '',
    productCode: '',
    categoryId: 0,
    isCheckedNew: true,
    categoryName: '',
    remarks: '',
    price: 0,
    discount: 0,
    offerDiscountCount: 0,
    additionalDiscountType: '',
    offerDiscountType: '',
    additionalDiscount: 0,
    discountType: "",
    tax: 0,
    unitId: 0,
    unitName: '',
    moq: 0,
    gstNumber: '',
    location: '',
    descriptions: [],
    mrpPrice: 0,
    productUrl: '',
    description: '',
    stock: 0,
    status: true,
    mrpPriceDetail: 0,
    priceGroup: [],
    applyOfferDiscount: "basePrice",
    priceTax: "Inclusive", 
    applyAdditionalDiscount: "discountPrice",
  });
  console.log(formData, "formDataformDatachjwbh");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (window.location.pathname.includes('/view')) {
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }
    getData();
    getPrice();
  }, []);

  useEffect(() => {

    const getTimeline = async () => {
      const requestBody = {
        "reference_type": "product",
        reference_id: id
      }

      const responsetimeline = await fetchData("timeline", requestBody);
      console.log(responsetimeline);
      if (responsetimeline?.data) {
        setTimelines(responsetimeline.data);
      }
    }
    getTimeline();
  }, [id]);

  const [selectedTax, setSelectedTax] = useState("exclusive");
  const [discountType, setDiscountType] = useState("");
  const [offerDiscountType, setOfferDiscountType] = useState("");
  const [additionalDiscountType, setAdditionalDiscountType] = useState("");
  const [selectedDiscount, setSelectedDiscount] = useState("basePrice");
  const [selectedDiscountAdditional, setSelectedDiscountAdditional] = useState("discountPrice");

    const handleChangeTax = (event) => {
      const { value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        priceTax: value, // Update the priceTax in the formData
      }));
    };
    const handleChangeDiscount = (event) => {
      const { value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        applyOfferDiscount: value, // Update applyOfferDiscount in the formData
      }));
    };

    const handleChangeDiscountAdditional = (event) => {
      const { value } = event.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        applyAdditionalDiscount: value, // Update applyAdditionalDiscount in formData
      }));
    };

    const handleChangeStatus = (event, groupIndex) => {
      const { value } = event.target;

      let updatedPriceGroups = [...priceGroups];
      updatedPriceGroups[groupIndex].status = value;
      setPriceGroups(updatedPriceGroups);
    };


    const handleChangeAdditionalDiscount = (event, groupIndex) => {
      const { value } = event.target;
      let updatedPriceGroups = [...priceGroups];
      updatedPriceGroups[groupIndex].additionalDiscountType = value;
      setPriceGroups(updatedPriceGroups);      
      console.log(groupIndex, "groupIndex");
      console.log(value, "value");
      console.log(updatedPriceGroups, "updatedPriceGroups");
      console.log(priceGroups, "priceGroups");
    };
    

  const getData = async () => {
    try {
      const response = await fetchData(`systemtype`, { typeCategory: { in: ['unit', 'category'] }, status: true });

      if (response?.data) {
        setCategoryOptions(
          response?.data
            .filter((i) => i?.typeCategory === 'category')
            .map((i) => ({
              label: i?.typeName,
              key: 'category',
              value: { id: i?.id, name: i?.typeName }
            }))
        );
        setUnitOptions(
          response?.data
            .filter((i) => i?.typeCategory === 'unit')
            .map((i) => ({
              label: i?.typeName,
              key: 'unit',
              value: { id: i?.id, name: i?.typeName }
            }))
        );
      }
      if (id) {
        if (id) {
          try {
            const response = await fetchsingleData("product", id); // Replace with your actual API call method
    
            if (response?.data) {
              setFormData(response.data); 
    
              const priceGroupsData = response.data.priceGroup?.map((group) => ({
                id: group.id || "",
                code: group.code || "",
                name: group.name || "",
                unit: group.unit || "",
                orgId: group.orgId || "",
                price: group.price || "",
                status: group.status || "",
                discount: group.discount || "",
                offerDiscount: group.offerDiscount || "",
                offerDiscountType: group.offerDiscountType || "",
                additionalDiscount: group.additionalDiscount || "",
                additionalDiscountType: group.additionalDiscountType || "",
                created_by: group.created_by || "",
                created_at: group.created_at || "",
                updatedat: group.updatedat || "",
              }));

    
              setPriceGroups(priceGroupsData || []); // Update state with transformed data
              console.log(priceGroups, "priceGroupsDatapriceGroupsData");
              
            }
          } catch (error) {
            console.error("Error fetching product data:", error);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    if (!isEditable) return; // Prevent changes in view mode
    if (value < 0) return; // Prevent negative values
    setFormData((prev) => ({ ...prev, [name]: value }));
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
    if (errors.isSubmit) validationCheck();
  };
  
  const getPrice = async (page, pageSize) => {
    try {
      const response = await fetchData("pricegroup");

      if (response?.data) {
        priceCategory(response?.data.map(i => ({
          label: i?.name,
          key: 'pricegroup',
          value: { pricegroupId: i?.id, pricegroupName: i?.price }
        })));

        setPriceGroups(response.data); // Assuming `setPriceGroups` is a function that sets the state
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Product name is required';
    if (!formData.hsn_code) newErrors.hsn_code = 'HSN Code is required';
    if (!formData.productCode) newErrors.productCode = " product code is required"
    if (!formData.unitId) newErrors.unitId = 'Product Unit is required';
    if (!formData.moq) newErrors.moq = 'MOQ is required';
    if (!formData.location.trim()) newErrors.location = 'Location is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [descriptionData, setDescriptionData] = useState({
    Title: '',
    Description: '',
  });

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setDescriptionData({
      Title: '',
      Description: '',
    });
  };

  const handleSaveExpense = () => {
    setFormData((prev) => ({
      ...prev,
      descriptions: [...prev.descriptions, descriptionData],
    }));
    handleCloseModal();
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEditable) return; // Prevent form submission in view mode
    if (!validateForm()) return;
    const key = company_setting?.extra_setting?.productUniqueKey === "Name" ? 'name' : 'hsn_code';
    console.log(formData, "formDataformDatachjwbh");
    try {
      if (id) {
        const payload = {
          [key]: formData[key],
          // "id": { $ne: id }
        }
        const isExist = await filterAll('product', payload);
        if (isExist?.data?.length > 1) {
          notify.info(`Product ${company_setting?.extra_setting?.productUniqueKey} cannot be duplicated`);
          return;
        }
        patchData(`product/${id}`, formData).then(data => {
          if (data) {
            notify.success('Product updated successfully!');
            navigate(-1);
          }
        })
          .catch(error => {
            notify.error('Error while saving Product');
            console.error('Error fetching data:', error);
          });


      } else {
        const payload = {
          [key]: formData[key]
        }
        const isExist = await filterAll('product', payload);
        if (isExist?.data?.length) {
          notify.info(`Product ${company_setting?.extra_setting?.productUniqueKey} cannot be duplicated`);
          return;
        }
        postData('product', formData)
          .then((data) => {
            if (data) {
              notify.success('Product saved successfully!');
              navigate(-1);
            }
          })
          .catch((error) => {
            notify.error('Error while saving Product');
            console.error('Error fetching data:', error);
          });
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }

  };

  const selectChanged = (e) => {
    if (!isEditable) return; // Prevent changes in view mode
    setFormData((prevState) => ({
      ...prevState,
      [`${e.key}Name`]: e.value?.name,
      [`${e.key}Id`]: e.value?.id
    }));
    if (errors.isSubmit) validationCheck();
  };

  const toggleSwitch = (checked) => {
    if (!isEditable) return; // Prevent toggle in view mode
    setFormData({ ...formData, status: checked });
  };


  const handleChange = (event) => {
    setSelectedTax(event.target.value);
  };

  const discountOptions = ['%', '₹'];


  useEffect(() => {
    handleInputChange({ target: { name: 'discountType', value: discountType } });
  }, [discountType]);


  useEffect(() => {
    handleInputChange({ target: { name: 'additionalDiscountType', value: additionalDiscountType } });
  }, [additionalDiscountType]);

  const handleCheckboxChange = (e) => {
    setFormData({
      ...formData,
      isCheckedNew: e.target.checked,
    });
  };


  const [priceGroups, setPriceGroups] = useState([
    { name: '', unit: '', price: '', priceTax: 'exclusive', discount: '', offerDiscount: '', offerDiscountType: '', additionalDiscount: '', additionalDiscountType: '', status: '' }

  ]);

  console.log(priceGroups, "priceGroups");
  const handleChangeOfferDiscountType = (event, index) => {
    const updatedPriceGroups = [...priceGroups];
    updatedPriceGroups[index].offerDiscountType = event.target.value; 
    setPriceGroups(updatedPriceGroups); 
    console.log(index, "index");
    console.log(updatedPriceGroups, "updatedPriceGroups");
    
    
  };

  const handlePriceGroupChange = (index, field, value) => {
    if (field === 'additionalDiscount' && (isNaN(value) || value < 0)) {
      return; // Validation for additional discount
    }

    setPriceGroups((prevGroups) => {
      const updatedGroups = prevGroups.map((group, i) =>
        i === index ? { ...group, [field]: value } : group
      );
      setFormData({
        ...formData,
        priceGroup: updatedGroups,
      });
      return updatedGroups;
    });
  };
  

  const handleFileChange = async (e) => {
    if (!isEditable) return; // Prevent file upload in view mode
    const file = e;
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('name', file.name);
    try {
      const status = await uploadDocument(`document/uploadfile`, formData);
      if (status?.data?.id) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          productUrl: `${status?.data?.id}`
        }));
      }
    } catch (error) {
      console.error('Error uploading the file:', error);
    }
  };

  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!(formData.price > 0)) {
      validationErrors.price = 'Please enter product price';
    }
    if (formData.discount < 0 || formData.discount > 100) {
      validationErrors.discount = 'Discount should be in percentage(%)';
    }

    if (formData.tax < 0 || formData.tax > 100) {
      validationErrors.tax = 'Tax should be in percentage(%)';
    }
    if (!formData.unitId) {
      validationErrors.unit = 'Select Product Unit';
    }
    if (!formData.location.trim()) {
      validationErrors.location = 'Please enter location';
    }
    if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

    setErrors(validationErrors);
    return Object.keys(validationErrors).filter((i) => i !== 'isSubmit').length === 0;
  };

  return (
    <div className="page-24">

      <div className="add-header-design">
        <div>
          <h2>Add Product</h2>
          <Breadcumbs activePath="Catalog" pageName="Add Product" />
        </div>
        {isEditable && (<div className='right-contnet-alignment'>
          <Link to="/product">
            <button className='light-button'>
              <img src={CancelIcon} alt="CancelIcon" />
              Cancel
            </button></Link>
          <button className={formData.name && formData.hsn_code && formData.unitName && formData.moq && formData.location ? "success-button" : ""} onClick={handleSubmit}>
            Save
          </button>
        </div>)}

      </div>
      <div className="page-main-grid">
        <div>


          <div className="new-white-box-design">
            <h2>Basic Details</h2>
            <div className="three-col-grid">

              <div>
                <Input
                  label={<span>Product Name <span className="required-star">*</span></span>}
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Product Name"
                  type="text"
                  disabled={!isEditable}
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>

              <div>
                <Input
                  label={<span>Code <span className="required-star">*</span></span>}
                  name="productCode"
                  value={formData.productCode}
                  onChange={handleInputChange}
                  placeholder="Product Code"
                  disabled={!isEditable}
                />
                {errors.productCode && <span className="error">{errors.productCode}</span>}
              </div>

              <div>
                <SelectBox
                  label="Category"
                  name="category"
                  value={categoryOptions.find((i) => i.value.id === formData.categoryId)}
                  options={categoryOptions}
                  onChange={selectChanged}
                  placeholder="Select Product Category"
                  disabled={!isEditable}
                />
              </div>

              <div>
                <Input
                  label={<span>HSN Code</span>}
                  name="hsn_code"
                  value={formData.hsn_code}
                  onChange={handleInputChange}
                  placeholder="HSN Code"
                  disabled={!isEditable}
                />
                {errors.hsn_code && <span className="error">{errors.hsn_code}</span>}
              </div>

              {company_setting?.extra_setting?.remarksInProduct === "Yes" && (
                <div className="input">
                  <label className="label">Remark</label>
                  <TextArea
                    rows={2}
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleInputChange}
                    placeholder="Product Remark..."
                    disabled={!isEditable}
                  />
                </div>
              )}

              <div className="toggle-container">
                <Toggle
                  checked={formData.status}
                  onChange={toggleSwitch}
                  disabled={!isEditable}
                />
                <span className="toggle-container">{formData.status ? "Active" : "Inactive"}</span>
              </div>

              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={formData.isCheckedNew}
                    onChange={handleCheckboxChange}
                  />
                  Is New
                </label>
              </div>

            </div>
          </div>

          <div className="new-white-box-design">
            <h2>Price Details</h2>
            <div className="three-col-grid">
              <TextField
                label="Price"
                name="price"
                value={formData.price}
                onChange={handleInputChange}
                placeholder="Price"
                type="number"
                disabled={!isEditable}
                fullWidth
              />

              <div className="price-tax-container">
                <FormControl component="fieldset">
                  <label className="price-tax-label">Price Tax</label>
                  <RadioGroup
                    row
                    name="priceTax"
                    value={formData.priceTax} // Bind to formData.priceTax
                    onChange={handleChangeTax}
                  >
                    <FormControlLabel
                      value="exclusive"
                      control={<Radio />}
                      label="Exclusive"
                    />
                    <FormControlLabel
                      value="inclusive"
                      control={<Radio />}
                      label="Inclusive"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <Box display="flex" alignItems="center">
                <TextField
                  label="Discount"
                  name="discount"
                  value={formData.discount}
                  onChange={handleInputChange}
                  placeholder="Discount"
                  type="number"
                  disabled={!isEditable}
                  size="small"
                  fullWidth
                />
                <TextField
                  select
                  name="discountType"
                  value={discountType}
                  onChange={(e) => setDiscountType(e.target.value)}
                  disabled={!isEditable}
                  size="small"
                  sx={{ width: 80, marginRight: 1 }}
                >
                  {discountOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <Box display="flex" alignItems="center">
                <TextField
                  label="Offer Discount"
                  name="offerDiscountCount"
                  value={formData.offerDiscountCount}
                  onChange={handleInputChange}
                  placeholder="Offer Discount"
                  type="number"
                  disabled={!isEditable}
                  size="small"
                  fullWidth
                />
                <TextField
                  select
                  name="offerDiscountType"
                  value={offerDiscountType}
                  onChange={(e) => setOfferDiscountType(e.target.value)}
                  disabled={!isEditable}
                  size="small"
                  sx={{ width: 80, marginRight: 1 }}
                >
                  {discountOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <div className="price-tax-container">
                <FormControl component="fieldset">
                  <label className="price-tax-label">Apply Offer Discount On</label>
                  <RadioGroup
                    row
                    name="applyOfferDiscount"
                    value={formData.applyOfferDiscount} // Bind to formData.applyOfferDiscount
                    onChange={handleChangeDiscount}
                  >
                    <FormControlLabel
                      value="basePrice"
                      control={<Radio />}
                      label="Base Price"
                    />
                    <FormControlLabel
                      value="discountPrice"
                      control={<Radio />}
                      label="Discount Price"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <Box display="flex" alignItems="center">
                <TextField
                  label="Additional Discount"
                  name="additionalDiscount"
                  value={formData.additionalDiscount}
                  onChange={handleInputChange}
                  placeholder="Additional Discount"
                  type="number"
                  disabled={!isEditable}
                  size="small"
                  fullWidth
                />
                <TextField
                  select
                  name="additionalDiscountType"
                  value={additionalDiscountType}
                  onChange={(e) => setAdditionalDiscountType(e.target.value)}
                  disabled={!isEditable}
                  size="small"
                  sx={{ width: 80, marginRight: 1 }}
                >
                  {discountOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>

              <div className="price-tax-container">
                <FormControl component="fieldset">
                  <label className="price-tax-label">Apply Additional Discount On</label>
                  <RadioGroup
                    row
                    name="applyAdditionalDiscount"
                    value={formData.applyAdditionalDiscount} // Bind to formData.applyAdditionalDiscount
                    onChange={handleChangeDiscountAdditional}
                  >
                    <FormControlLabel
                      value="basePrice"
                      control={<Radio />}
                      label="Base Price"
                    />
                    <FormControlLabel
                      value="discountPrice"
                      control={<Radio />}
                      label="Discount Price"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <TextField
                label="Tax (%)"
                name="tax"
                value={formData.tax}
                onChange={handleInputChange}
                placeholder="Tax"
                type="number"
                disabled={!isEditable}
                fullWidth
              />
              {company_setting?.extra_setting?.enableMRP === "Yes" && (
                <TextField
                  label="MRP"
                  name="mrpPriceDetail"
                  value={formData.mrpPriceDetail}
                  onChange={handleInputChange}
                  placeholder="Product MRP"
                  type="number"
                  disabled={!isEditable}
                  fullWidth
                />
              )}
            </div>
          </div>

          <div className="new-white-box-design">
            <h2>Stock Details</h2>
            <div className="three-col-grid">
              <div><SelectBox
                label={
                  <span>
                    Basic Unit <span className="required-star">*</span>
                  </span>
                } name="unit" value={unitOptions.find((i) => i.value.id === formData.unitId)} options={unitOptions} onChange={selectChanged} placeholder="Basic Unit" disabled={!isEditable} />{errors.unit && <span className="error">{errors.unit}</span>}</div>
              <Input label="Stock" name="stock" value={formData.stock} onChange={handleInputChange} placeholder="Stock" type="number" disabled={!isEditable} />
            </div>
          </div>

          <div className="new-white-box-design">
            <h2>Other Details</h2>
            <div className="three-col-grid">
              <div><Input
                label={
                  <span>
                    MOQ <span className="required-star">*</span>
                  </span>
                } name="moq" value={formData.moq} onChange={handleInputChange} placeholder="MOQ" disabled={!isEditable} />{errors.moq && <span className="error">{errors.moq}</span>}</div>

              <Input label="GST Number" name="gstNumber" value={formData.gstNumber} onChange={handleInputChange} placeholder="GST Number" type="text" disabled={!isEditable} />
              <Input label="MRP" name="mrpPrice" value={formData.mrpPrice} onChange={handleInputChange} placeholder="MRP Price" type="text" disabled={!isEditable} />
              <div><Input
                label={
                  <span>
                    Location <span className="required-star">*</span>
                  </span>
                } name="location" value={formData.location} onChange={handleInputChange} placeholder="Location" type="text" disabled={!isEditable} />{errors.location && <span className="error">{errors.location}</span>}</div>
            </div>
          </div>

          <div className="new-white-box-design">
            <h2>Product Image</h2>
            <div className="upload-img">
              <UploadImage action={(e) => handleFileChange(e)} value={formData.productUrl} disabled={!isEditable}></UploadImage>
            </div>
          </div>

          <div className="new-white-box-design">
            <h2>Description List</h2>
            <div>
              <table>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(formData.descriptions) &&
                    formData.descriptions.map((expense, index) => (
                      <tr key={index}>
                        <td>{expense.Title}</td> 
                        <td>{expense.Description}</td> 
                      </tr>
                    ))}
                </tbody>

              </table>
            </div>
            <div style={{ marginTop: '5px' }}>
              <GlobalButton text="Add Description" Icon={PlusIcon} onClick={handleOpenModal} />
            </div>

            {isModalOpen && (
              <Modal title="Add Description" isOpen={isModalOpen} onClose={handleCloseModal} onSave={handleSaveExpense}>
                <div className="mainBox">
                  <div style={{ alignItems: 'center', display: 'flex' }}>
                    <Input
                      label="Title"
                      name="Title"
                      value={descriptionData.Title}
                      placeholder="Title"
                      type="text"
                      onChange={(e) => setDescriptionData({ ...descriptionData, Title: e.target.value })}
                    />
                  </div>

                  <div style={{ alignItems: 'center', display: 'flex' }}>
                    <Input
                      label="Description"
                      name="Description"
                      value={descriptionData.Description}
                      placeholder="Description"
                      type="text"
                      onChange={(e) => setDescriptionData({ ...descriptionData, Description: e.target.value })}
                    />
                  </div>
                </div>
              </Modal>
            )}
          </div>



          <div className="new-white-box-design">
            <h2>Price Group Details</h2>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Default Unit</th>
                  <th>Price</th>
                  <th>Discount (%)</th>
                  <th>Offer Discount (%)</th>
                  <th>Additional Discount (%)</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {priceGroups?.map((group, index) => (
                  <tr key={index}>
                    <td>
                      <TextField
                        name="name"
                        disabled
                        value={group.name}
                        onChange={(e) => handlePriceGroupChange(index, 'name', e.target.value)}
                        placeholder="Name"
                        size="small"
                      />
                    </td>
                    <td style={{ width: '50px' }}>
                      <TextField
                        style={{ width: '100px' }}
                        select
                        name="unit"
                        value={group.unit}
                        onChange={(e) => handlePriceGroupChange(index, 'unit', e.target.value)}
                        size="small"
                      >
                        {unitOptions
                          .filter((option) => option.value.id === formData.unitId || !formData.unitId)
                          .map((option) => (
                            <MenuItem key={option.value.id} value={option.value.id}>
                              {option.label}
                            </MenuItem>
                          ))}
                      </TextField>
                    </td>
                    <td>
                      <TextField
                        name="price"
                        value={group.price}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value >= 0 || value === '') {
                            handlePriceGroupChange(index, 'price', value);
                          }
                        }}
                        placeholder="Price"
                        size="small"
                        type="number"
                      />
                    </td>
                    <td>
                      <TextField
                        name="discount"
                        value={group.discount}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value >= 0 || value === '') {
                            handlePriceGroupChange(index, 'discount', value);
                          }
                        }}
                        placeholder="Discount (%)"
                        size="small"
                        type="number"
                      />
                    </td>
                    <td>
                      <TextField
                        name="offerDiscount"
                        value={group.offerDiscount}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value >= 0 || value === '') {
                            handlePriceGroupChange(index, 'offerDiscount', value);
                          }
                        }}
                        placeholder="Offer Discount (%)"
                        size="small"
                        type="number"
                      />
                      <div className="price-tax-container">
                          <RadioGroup
                            row
                            name="offerDiscountType"
                            value={group.offerDiscountType } 
                            onChange={(event) => handleChangeOfferDiscountType(event, index)}
                          >
                            <FormControlLabel value="basePrice" control={<Radio />} label="Base Price" />
                            <FormControlLabel value="discountPrice" control={<Radio />} label="Discount Price" />
                          </RadioGroup>
                      </div>
                    </td>
                    <td>
                      <TextField
                        name="additionalDiscount"
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value >= 0 || value === '') {
                            handlePriceGroupChange(index, 'additionalDiscount', value);
                          }
                        }}
                        placeholder="Additional Discount"
                        size="small"
                        type="number"
                      />
                      <div className="price-tax-container">
                        {/* <FormControl component="fieldset"> */}
                          <RadioGroup
                            row
                            name="additionalDiscountType"
                            value={group.additionalDiscountType} 
                            onChange={(event) => handleChangeAdditionalDiscount(event, index)}
                          >
                            <FormControlLabel value="basePrice" control={<Radio />} label="Base Price" />
                            <FormControlLabel value="discountPrice" control={<Radio />} label="Discount Price" />
                          </RadioGroup>
                        {/* </FormControl> */}
                      </div>
                    </td>
                    <td>
                      <div className="price-tax-container">
                        {/* <FormControl component="fieldset" key={index}> */}
                          <RadioGroup
                            row
                            name="status"
                            value={group.status}
                            onChange={(event) => handleChangeStatus(event, index)}
                          >
                            <FormControlLabel value="active" control={<Radio />} label="Active" />
                            <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
                          </RadioGroup>
                        {/* </FormControl> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>






        </div>
        <div>
          {/* <StatusBox /> */}
          {isVisible && (

            <div style={{
              // marginTop: '5px',
              padding: '1.5rem',
              backgroundColor: '',
              border: '1px solid rgba(30,46,80,.09)',
              borderRadius: '0.25rem',
              boxShadow: '0 20px 27px 0 rgba(0, 0, 0, 0.05)',
              maxWidth: '100%',
              marginLeft: '5px',
              width: '400px',
              scrollBehavior: 'smooth',
              height: '100%',
              overflowY: 'auto'
            }}>
              <div style={{ marginTop: '-8px', paddingBottom: '15px', fontWeight: 'bold', fontSize: '20px' }}><strong>Timeline</strong></div>
              {timelines?.slice().reverse().map((i, index) => (
                <div key={i.id || index} style={{ display: "flex", paddingLeft: "3px", paddingBottom: "15px" }}>
                  <div style={{ paddingRight: "5px" }}>➨</div>
                  <div style={{ fontSize: "14px" }}>
                    <strong>{i.message}</strong> On <strong>{dayjs(i.timestamp).format('DD-MM-YYYY, HH:mm:ss')}</strong>
                  </div>
                </div>
              ))}
            </div>
          )}

        </div>

      </div>
    </div>
  );
}
