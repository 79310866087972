import React, { useEffect, useState } from 'react'
import Button from '../../../shared/components/button';
import Input from '../../../shared/components/input';
import { fetchsingleData, patchData, postData } from '../../../services/apiService';
import Toggle from '../../../shared/components/switch';
import { Link, useNavigate, useParams } from 'react-router-dom/dist';
import GlobalButton from '../../../shared/globalButton';
import Breadcumbs from '../../../shared/components/breadcumbs';

export default function AddEditactivitytype() {
    const navigate = useNavigate();
    const { id } = useParams();
    useEffect(() => {
        // Fetch data when the component mounts
        if (id) {
            getData();
        }
    }, []);
    const [formData, setFormData] = useState({
        name: '',
        activityTemplate: '',
        areaType:'',
        status: "true"
    });
    const [errors, setErrors] = useState({});
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data:", formData);
        if (validationCheck()) {
            try {
                if (id) {
                    patchData(`activitytype/${id}`, formData).then(data => {
                        if (data) navigate(-1);
                    })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });
                } else {
                    postData('activitytype', formData)
                        .then(data => {
                            if (data) navigate(-1);
                        })
                        .catch(error => {
                            console.error('Error fetching data:', error);
                        });

                }
            } catch (error) {
                console.error('Error saving data:', error);
            }
        }
    };
    const validationCheck = (isSubmit) => {
        const validationErrors = {};
        if (!formData?.name?.trim()) {
            validationErrors.name = 'This field is required';
        }
        if (isSubmit || errors.isSubmit)
            validationErrors.isSubmit = true;
        setErrors(validationErrors);
        return (Object.keys(validationErrors).filter(i => i !== 'isSubmit').length === 0) ? true : false
    }
    const toggleSwitch = (checked) => {
        setFormData({ ...formData, status: !!checked });
    };
    const handleInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
        if (errors.isSubmit)
            validationCheck()
    };
    const getData = async () => {
        try {
            const response = await fetchsingleData("activitytype", id);
            console.log("Fetched data:", response);
            if (response?.data)
                setFormData(response?.data); // Update state with fetched data
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    return (
        <div className='page-24'>
            {/* <div className='page-header add-news-alignment'>
                <span>Activity {`>`} {id ? 'Edit' : 'Create'}</span>
                <div className='two-button-alignment'>
                    <div onClick={handleSubmit}><GlobalButton text={id ? 'Update' : 'Save'} /></div>
                    <Link to="/activitytype"><GlobalButton text='Discard' outline /></Link>
                </div>
            </div> */}
             <div className='add-header-design'>
                            <div>
                                <h2>Add Activity</h2>
                                <Breadcumbs activePath="Activity Type" pageName="Add Activity" />
                            </div>
                            <div className='right-contnet-alignment'>
                                <Link to="/activity">
                                    <button className='light-button'>
                                        Discard
                                    </button>
                                </Link>
                                {}
                                <button onClick={handleSubmit}>
                                    {id ? 'Update' : 'Save'}
                                </button>
                            </div>
                        </div>
            <div >
                <div className='add-news-form-design'>
                    <div className='grid'>
                        <div className='bottom-alignment'>
                            <Input label={
                                <span>
                                    Name<span className="required-star">*</span>
                                </span>}
                                name='name' value={formData.name} onChange={handleInputChange} placeholder='Enter Activity Name..' type='text' required errors={errors.name} />
                        </div>
                        <div className='bottom-alignment'>
                            <Input label='Activity Template' name='activityTemplate' value={formData.activityTemplate} onChange={handleInputChange} placeholder='Enter Activity Template..' type='text' />
                        </div>
                        <div className='bottom-alignment'>
                            <Input label='Area Type' name='areaType' value={formData.areaType} onChange={handleInputChange} placeholder='Enter Area Type..' type='text' />
                        </div>
                        <div className='bottom-alignment'>
                            <Toggle label='Status' checked={formData.status} onChange={toggleSwitch} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
