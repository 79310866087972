import React, { useEffect, useState } from 'react';
import Map, { Marker, Popup, Source, Layer } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { fetchCustomData, fetchData, fetchCustomDataId, filterAll, fetchLivelocation, fetchalluservisitdistance } from '../../services/apiService';
import Breadcumbs from '../../shared/components/breadcumbs';
import './location.scss';
import moment from "moment";
import SelectBox from '../../shared/components/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'

const MAPBOX_TOKEN = 'pk.eyJ1IjoicnV0dmlrbSIsImEiOiJjbHh2b3Zjcmkwd28zMm5zZ3BzYWJ4NXlkIn0.SnKNDTgcIO2Ar6dGfnXZbQ'; // Replace with your Mapbox token

const MapComponent = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true); // Manage sidebar state
  const [users, setUserData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [visits, setVisits] = useState(0);
  const [totalDistance, setTotalDistance] = useState(0);
  const [searchuser, setSearchUser] = useState(''); 
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [selectedUserId, setSelectedUserId] = useState('');
  
  
  // const handleSelectChange = (e) => {
  //   const userId = e.target.value;
  //   setSelectedUserId(userId);
  //   if (userId === '') {
  //     setFilteredUsers(users);
  //   } else {
  //     const filtered = users.filter((user) => String(user.id) === userId);
  //     console.log(filtered, "filtered");
  //     setFilteredUsers(filtered);
  //   }
  // };

  const handleSelectChange = (selectedOption) => {
    setSearchUser(selectedOption); 
    setFilteredUsers(
      selectedOption
        ? users.filter((user) => user.id === selectedOption.value)
        : users
    ); // Filter users
  };
  const selectBoxOptions = users.map((user) => ({
    label: `${user.name}`,
    value: user.id,
  }));

  useEffect(() => {
    getData();
  }, []);

  // const formatDate = (dateString) => {
  //   const options = {
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: "2-digit",
  //     hour12: true,
  //     timeZone: "Asia/Kolkata" 
  //   };
  //   return new Date(dateString).toLocaleString("en-IN", options);
  // };
  // function formatVisitStart(visitStart) {
  //   return moment(visitStart).format("D-M-YYYY h:mm A");
  // }
  // const formatDateTime = (isoString) => {
  //   if (!isoString) return 'N/A';
  //   const date = new Date(isoString);
  //   return date.toLocaleString('en-IN', {
  //     timeZone: 'Asia/Kolkata',
  //     year: 'numeric',
  //     month: 'long',
  //     day: 'numeric',
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     second: '2-digit',
  //     hour12: true,
  //   });
  // };
  // function convertUTCToIST(utcDateString) {
  //   const utcDate = new Date(utcDateString);
  //   const istOffset = 5.5 * 60 * 60 * 1000; 
  //   const istTime = new Date(utcDate.getTime() + istOffset);
  //   return istTime;
  // }
  function formatISTTime(timestamp) {
      const dateObj = new Date(timestamp);
      const day = String(dateObj.getUTCDate()).padStart(2, '0');
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); 
      const year = dateObj.getUTCFullYear();
  
      let hours = dateObj.getUTCHours();
      const minutes = String(dateObj.getUTCMinutes()).padStart(2, '0');
      const seconds = String(dateObj.getUTCSeconds()).padStart(2, '0');
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12; 
      return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds} ${ampm}`;

}


  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  };

  const calculateTimeDifference = (start, end) => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const diffMs = endTime - startTime;
    const diffSecs = Math.floor(diffMs / 1000);
    const hours = Math.floor(diffSecs / 3600);
    const minutes = Math.floor((diffSecs % 3600) / 60);
    const seconds = diffSecs % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  };

  const getData = async () => {
    try {
      const response = await fetchalluservisitdistance('location_tracking/UserwithAttendance');
      if (typeof response?.data === 'object') {
        const userData = response.data.map((i) => ({ ...i }));
        setUserData(userData);
        setFilteredUsers(userData);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchOrderData = async (selectedUser) => {
    // if (!selectedUser) return;

    try {
      const currentDate = selectedUser.visit_start;
      const data = { salesperson_id: selectedUser.user_id, orderDate: currentDate };

      const response = await filterAll("order", data);
      if (response) {
        setOrders(response.data || 0);
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };
  useEffect(() => {
    if (modalOpen && selectedUser?.user_id) {
      fetchOrderData(selectedUser);
    }
  }, [modalOpen, selectedUser]);
  console.log(orders, "order");

  useEffect(() => {
    if (selectedUser?.locations) {
      const filteredVisits = selectedUser.locations.filter((i) => {
        return i?.visit_type !== "regular_tracked_point";
      });
      setVisits(filteredVisits.length);

    }
  }, [selectedUser]);
  console.log("selecteduser", selectedUser);

  const getLocationsData = async (user) => {
    try {
      const response = await fetchLivelocation('location_tracking/current-user', user?.id);
      if (typeof response?.data === 'object') {
        const { visit_locations, ...restData } = response.data;
        setSelectedUser({
          ...user,
          ...restData,
          locations: visit_locations?.filter((i) => {
            if (i?.latitude !== null && i?.longitude !== null) {
              return {
                ...i,
                time: i?.date,
              };
            }
          })
        });

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Define colors based on visit_type
  const getColorBasedOnVisitType = (visitType) => {
    switch (visitType) {
      case 'regular_tracked_point':
        return 'blue';
      case 'primary':
        return 'green';
      case 'lead':
        return 'orange';
      default:
        return 'gray';
    }
  };



  const [filterVisitType, setFilterVisitType] = useState({
    lead: true,
    primary: true,
    regular_tracked_point: true,
  });

  const handleFilterChange = (field, isChecked) => {
    setFilterVisitType((prev) => ({
      ...prev,
      [field]: isChecked,
    }));
  };

  const visitTypeToShow = (loc) => {
    return filterVisitType[loc?.visit_type];
  }
  const totalDistancecover = (lat1, lon1, lat2, lon2) => {
    const toRadians = (degree) => (degree * Math.PI) / 180;

    const R = 6371;
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };
  useEffect(() => {
    if (selectedUser?.locations) {
      const validVisits = selectedUser.locations.filter(
        (location) => location.visit_type !== "regular_tracked_point"
      );
      setVisits(validVisits.length);
      let distance = 0;
      for (let i = 0; i < selectedUser.locations.length - 1; i++) {
        const current = selectedUser.locations[i];
        const next = selectedUser.locations[i + 1];

        if (
          current.latitude &&
          current.longitude &&
          next.latitude &&
          next.longitude
        ) {
          distance += totalDistancecover(
            current.latitude,
            current.longitude,
            next.latitude,
            next.longitude
          );
        }
      }
      setTotalDistance(distance);
    }
  }, [selectedUser]);
  console.log(selectedUser, "selecteduser");
  return (
    <>
      <div className="location-page-header-alignment">
        <div className="location-header-alignment">
          <Breadcumbs activePath="Location Tracking" pageName="Location" />
          <div className="right-all-contnet-alignment">
            <div className="checkbox-text">
              <input
                type="checkbox"
                checked={filterVisitType.lead}
                style={{ accentColor: "#dc7633" }}
                onChange={(e) =>
                  handleFilterChange("lead", e.target.checked)
                }
              />
              <span>Lead visit</span>
            </div>
            <div className="checkbox-text">
              <input
                type="checkbox"
                checked={filterVisitType.primary}
                style={{ accentColor: "green" }}
                onChange={(e) =>
                  handleFilterChange("primary", e.target.checked)
                }
              />
              <span>Primary visit</span>
            </div>
            <div className="checkbox-text">
              <input
                type="checkbox"
                checked={filterVisitType.regular_tracked_point}
                style={{ accentColor: "blue" }}
                onChange={(e) =>
                  handleFilterChange("regular_tracked_point", e.target.checked)
                }
              />
              <span>Regular tracked points</span>
            </div>
            <div className="checkbox-text">
              <div className="dot"></div>
              <span>First & Last Tracked Points</span>
            </div>
          </div>
        </div>
        <div className="location-grid">
          <div className="location-grid-items">
            <div className="user-list">
              <h3>User List</h3>

              {/* <select
                value={selectedUserId}
                onChange={handleSelectChange}
                style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
              >
                <option value="">Select a user...</option>
                {users.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </select> */}
              <SelectBox
                   value={selectBoxOptions.find((option) => option.value === searchuser?.value) || null}
                   options={selectBoxOptions}
                   onChange={handleSelectChange}
                   placeholder="Select a user..."
                  type="text"
                  required={true}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                      maxHeight: '400px',
                      overflowY: 'auto',
                      border: 'none',
                    }),
                    control: (provided) => ({
                      ...provided,
                      minWidth: '100%',
                      border: 'none',
                      marginTop: '-8px',
                      maxHeight: '25px',
                      textAlign: 'left',
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      border: 'none',
                    }),
                  }}
                  menuPortalTarget={document.body}
                  isClearable={true}
                  components={{
                    ClearIndicator: (props) => (
                      <div
                        {...props.innerProps}
                        style={{
                          cursor: 'pointer',
                          padding: '5px',
                          color: 'gray',
                        }}
                      >
                        &#x2715;
                      </div>
                    ),
                  }}
                />

              {filteredUsers.map((user) => (
                <a
                  key={user.id}
                  className="name-dot-grid"
                  style={selectedUser?.id == user?.id ? { 'background-color': '#99a3a4 ' } : {}}
                  onClick={() => getLocationsData(user)}
                >
                  <div className={user.attendanceStatus === 'Present' ? 'dot-green' : 'dot-red'}></div>
                  <div>
                    <p>{user.name}</p>
                  </div>
                </a>
              ))}
            </div>
          </div>
          <div className="location-grid-items">
            <div style={styles.mapContainer}>
              <Map
                initialViewState={{
                  latitude: 21.1702,
                  longitude: 72.8311,
                  zoom: 13,
                }}
                style={{ width: "100%", height: "100%" }} // Full height map
                mapStyle="mapbox://styles/mapbox/streets-v11"
                mapboxAccessToken={MAPBOX_TOKEN}
              >
                {selectedUser && (
                  <>
                    {/* Draw route connecting the points */}
                    <Source
                      id="route"
                      type="geojson"
                      data={{
                        type: "Feature",
                        geometry: {
                          type: "LineString",
                          coordinates: selectedUser.locations.map((loc) => [
                            loc.longitude,
                            loc.latitude,
                          ]),
                        },
                      }}
                    >
                      {/* <Layer
                        id="routeLayer"
                        type="line"
                        paint={{
                          "line-color": "#0f53ff",
                          "line-width": 4,
                        }}
                      /> */}
                      <Layer
  id="bikeRouteLayer"
  type="line"
  paint={{
    "line-color": "#0f53ff",  // Bright blue for bike path
    "line-width": 4,          // Thickness of the line
    "line-dasharray": [1, 2], // Dashed line pattern: 1px dash, 2px gap
    "line-opacity": 0.9,      // Slight transparency for a softer look
  }}
  layout={{
    "line-cap": "round",      // Rounded line ends for smooth aesthetics
    "line-join": "round",     // Smooth corners for curves in the route
  }}
/>
                    </Source>

                    {/* Show markers with sequential numbers */}
                    {selectedUser?.locations?.filter((loc) => {
                      return visitTypeToShow(loc)
                    })?.map((loc, index, array) => {
                      const isFirst = index === 0; 
                      const isLast = index === array.length - 1;
                      const markerColor = isFirst || isLast ? "red" : getColorBasedOnVisitType(loc?.visit_type);
                      return (
                        <Marker
                          key={index}
                          latitude={loc.latitude}
                          longitude={loc.longitude}
                          anchor="bottom"
                          onClick={() => setSelectedPoint(loc)} // Set selected point when clicked
                        >
                          <div
                            style={{ position: "relative", cursor: "pointer" }}
                          >
                            {/* <img
                              src="https://upload.wikimedia.org/wikipedia/commons/e/ed/Map_pin_icon.svg"
                              alt={`Location Pin ${index}`}
                              style={{ width: "30px", height: "auto" }}
                            /> */}
                            <i style={{fontSize:'35px',color:'#ea4335'}}><FontAwesomeIcon icon={faLocationDot} /></i>
                            <div
                              style={{
                                position: "absolute",
                                top: "-20px",
                                left: "50%",
                                transform: "translateX(-50%)",
                                color: "white",
                                backgroundColor: markerColor, // Set marker color dynamically
                                padding: "2px 5px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                            >
                              {index + 1}
                            </div>
                          </div>
                        </Marker>
                      );
                    })}
                  </>
                )}


                {selectedPoint && (
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(28, 19, 19, 0.5)", // Semi-transparent background
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 1000,
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "30px",
                        borderRadius: "10px",
                        width: "80%",
                        maxWidth: "600px",
                        position: "relative",
                      }}
                    >
                      {/* Close Button */}
                      <button
                        onClick={() => setSelectedPoint(null)}
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          backgroundColor: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                        }}
                      >
                        &times;
                      </button>

                      {/* Modal Content */}
                      <div>

                        {selectedPoint.visit_type === "primary" && (
                          <>
                            <h4>Customer Details</h4>
                            <p>
                              <strong>Name:</strong> {selectedPoint.customerName}
                            </p>
                            <p>
                              <strong>Address:</strong> {selectedPoint.address}
                            </p>
                            <p>
                              <strong>Date:</strong> {formatISTTime(selectedPoint.visit_start)}
                            </p>
                            <p>
                              <strong>Time Taken:</strong>{" "}
                              {calculateTimeDifference(selectedPoint.visit_start, selectedPoint.visit_end)}
                            </p>
                          </>
                        )}

                        {selectedPoint.visit_type === "lead" && (
                          <>
                            <h4>LeadDetails</h4>
                            <p>
                              <strong>Lead Name:</strong> {selectedPoint.leadName || "N/A"}
                            </p>
                            <p>
                              <strong>Address:</strong> {selectedPoint.address || "N/A"}
                            </p>
                            <p>
                              <strong>Date</strong> {formatISTTime(selectedPoint.visit_start) || "N/A"}
                            </p>
                            <p>
                              <strong>Time Taken:</strong>{" "}
                              {calculateTimeDifference(selectedPoint.visit_start, selectedPoint.visit_end)}
                            </p>
                          </>
                        )}

                        {selectedPoint.visit_type !== "primary" &&
                          selectedPoint.visit_type !== "lead" && (
                            <p>
                              <strong>Date:</strong> {formatISTTime(selectedPoint.visit_start) || "N/A"}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                )}

              </Map>
            </div>
          </div>
          <div
            className={modalOpen ? "menu-open menu-open-change" : "menu-open"}
            onClick={() => setModalOpen(!modalOpen)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
            </svg>
          </div>
        </div >
      </div >
      <div
        className={modalOpen ? "location-modal show" : "location-modal hide"}
      >
        <div className="sticky-card">
          <div className="location-modal-header">
            <div className="header-grid">
              <img src="https://app.koops.in/application/assets/images/user-thumbnail.png" />
              <div>
                <p>{selectedUser?.name}</p>
                <span>
                  {selectedUser?.punchInTime
                    ? `Punch In at: ${formatDateTime(selectedUser.punchInTime)}`
                    : "NA"}
                </span>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <i class="fas fa-battery-full" style={{ fontSize: "16px", color: "white" }}></i>
                  <span style={{ fontSize: "14px" }}>
                    {selectedUser?.battery_percent || 0}%
                  </span>

                </div>
                <span> <p><strong>{totalDistance.toFixed(2)}</strong> km</p></span>
              </div>

            </div>

            <div className="header-four-col">
              <div className="items">
                <p>VISIT</p>
                <span>{visits}</span>
              </div>
              <div className="items">
                <p>CUSTOMERS</p>
                <span>{selectedUser?.customers}</span>
              </div>
              <div className="items">
                <p>LEAD</p>
                <span>{selectedUser?.leads}</span>
              </div>
              <div className="items">
                <p>ORDERS</p>
                <span>{selectedUser?.orders}</span>
              </div>
            </div>
          </div>
          <div className="sub-header-alignment">
            <div className="title-sub-text-alignment">
              <p>Expense</p>
            </div>
            <div className="all-text-alignment">
              <div>
                <p>TOTAL</p>
                <span>₹{selectedUser?.total}</span>
              </div>
              <div>
                <p>APPROVED</p>
                <span>₹{selectedUser?.approved}</span>
              </div>
              <div>
                <p>PENDING</p>
                <span>₹{selectedUser?.pending}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="all-card-box">
          {selectedUser && isSidebarExpanded && (
            <>
              <h3>{selectedUser.name}'s Journey</h3>
              <div style={{ margin: "20px 0" }}>
                {selectedUser?.locations?.filter((i) => {
                  return i?.visit_type !== 'regular_tracked_point'
                }).map((loc, index) => {
                  const visitStartFormatted = formatDateTime(loc.visit_start);
                  const visitEndFormatted = formatDateTime(loc.visit_end);
                  const timeTaken = calculateTimeDifference(loc.visit_start, loc.visit_end);

                  return (
                    <div className="new-grid" key={index}>
                      <div className="line"></div>
                      <div className="bottom-spacing">
                        <h3 className='starting-time'>{formatISTTime(loc.visit_start)}</h3>
                        <div className="white-box">
  <p>
    {loc.visit_type === "primary"
      ? `${loc.customerName} [Customer]`
      : `${loc.leadName} [Lead]`} 
    <span className="time-taken">{timeTaken}</span>
  </p>
</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

// Styles for the layout
const styles = {
  mapContainer: {
    flex: 1,
    height: 'calc(100vh - 160px)', // Full screen height for the map
  },
  leftSidebar: {
    width: '250px',
    height: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '10px',
    overflowY: 'auto',
  },
  rightSidebar: {
    height: '100vh',
    backgroundColor: '#f4f4f4',
    padding: '10px',
    overflowY: 'auto',
    borderLeft: '2px solid #ccc',
    transition: 'width 0.3s ease', // Smooth transition for the sidebar width
  },
  userItem: {
    padding: '10px',
    cursor: 'pointer',
    marginBottom: '10px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  toggleButton: {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '5px',
    marginBottom: '10px',
    width: '100%',
  },
  journeyList: {
    paddingTop: '10px',
  },
  journeyItem: {
    marginBottom: '15px',
    padding: '10px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
};

export default MapComponent;
