import React, { useEffect, useState } from "react";
import Button from "../../../shared/components/button";
import Input from "../../../shared/components/input";
import {
  fetchsingleData,
  fetchData,
  patchData,
  postData,
  deleteData,
} from "../../../services/apiService";
import Toggle from "../../../shared/components/switch";
import { Link, useNavigate, useParams } from "react-router-dom/dist";
import SelectBox from "../../../shared/components/select";
import GlobalButton from "../../../shared/globalButton";
import Breadcumbs from "../../../shared/components/breadcumbs";
import PlusIcon from '../../../assets/icons/plus.svg';
import CancelIcon from '../../../assets/icons/Cancel.svg';
import StatusBox from "../../addcustomers/statusBox";
import store from "../../../redux/reduxstore";
import { notify } from "../../toastr/Toastr";
export default function AddVehicle() {
  const navigate = useNavigate();
  const { id } = useParams();
  const company_setting = store.getState()?.auth?.company_setting;
  useEffect(() => {
    if (id) {
      getData();
    }
  }, []);

  const [formData, setFormData] = useState({
   vehiclenumber:'',
    status: true,
    vehiclename:'',
    
  });
 
  const [errors, setErrors] = useState({});
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validationCheck()) {
      try {
        if (id) {
          patchData(`vehicle/${id}`, formData)
            .then((data) => {
              notify.success('Vehicle updated successfully!');
              if (data) navigate(-1);
            })
            .catch((error) => {
              notify.error('Error while saving Vehicle');
              console.error("Error fetching data:", error);
            });
        } else {
          postData("vehicle", formData)
            .then((data) => {
                if(data){
              notify.success('Vehicle saved successfully!');}
              else{
                notify.error('vehicle not saved');
              }
              if (data) navigate(-1);
            })
            .catch((error) => {
              notify.error('Error while saving Vehicle');
              console.error("Error fetching data:", error);
            });
        }
      } catch (error) {
        console.error("Error saving data:", error);
      }
    }
  };
  const validateField = (name, value) => {
    switch (name) {
        case "vehiclenumber":
            return value.trim() === "" ? "Vehicle number is required." : "";
    
            case "vehiclename":
                return value.trim() === "" ? "Vehicle name is required." : "";
       
        default:
            return "";
    }
};

  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!formData?.vehiclename) {
        validationErrors.vehiclename = "Please enter a Vehicle Name.";
      }
    if (!formData?.vehiclenumber) {
      validationErrors.vehiclenumber = "Please enter a Vehicle Number.";
    }
    if (isSubmit || errors.isSubmit) {
      validationErrors.isSubmit = true;
    }
    setErrors(validationErrors);
    console.log("Validation Errors:", validationErrors); // Debug validation errors
    return (
      Object.keys(validationErrors).filter((i) => i !== "isSubmit").length === 0
    );
  };

  const toggleSwitch = (checked) => {
    setFormData({ ...formData, status: checked });
  };

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
    setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "", 
    }));
    if (errors.isSubmit) {
        const fieldError = validateField(name, value);
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: fieldError, 
        }));
    }
  };

  const handleDelete = async () => {
    try {
      await deleteData(`vehilce/${id}`);
      navigate(-1);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const getData = async () => {
    try {
      const response = await fetchsingleData("vehicle", id);
      if (response?.data) setFormData(response?.data); 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  return (
    <div className="page-24">
      <div className='add-header-design'>
        <div>
          <h2>Add Vehicle</h2>
          <Breadcumbs activePath="Dashboard" pageName="Add Vehicle" />
        </div>
        <div className='right-contnet-alignment'>
          <Link to="/vehicle">
            <button className='light-button'>
              <img src={CancelIcon} alt="CancelIcon" />
              Discard
            </button>
          </Link>
          <button onClick={handleSubmit}>
            {id ? "Update" : "Save"}
          </button>
        </div>
      </div>

      <div className="page-main-grid">
        <div>
          <div className="new-white-box-design">
            <div className="two-col-grid">
            <Input
                label={
                  <span>
                    Vehicle Name<span className="required-star">*</span>
                  </span>}

                name="vehiclename"
                value={formData.vehiclename}
                onChange={handleInputChange}
                placeholder="Enter Vehicle Name"
                type="text"
                required
                errors={errors.vehiclename}
              />
               <Input
                label={
                  <span>
                    Vehicle Number<span className="required-star">*</span>
                  </span>}

                name="vehiclenumber"
                value={formData.vehiclenumber}
                onChange={handleInputChange}
                placeholder="Enter Vehicle Number"
                type="text"
                required
                errors={errors.vehiclenumber}
              />
             
              <div className="d-flex align-items-end">
                <Toggle
                  label="Status"
                  checked={formData.status}
                  onChange={toggleSwitch}
                />
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  );
}
