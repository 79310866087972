import { useState } from "react";
import { deleteData, filterAll } from "../../services/apiService";
import Button from "../../shared/components/button";
import Expensetypetable from "./expenstypetable";
import { Link } from "react-router-dom";
import Breadcumbs from "../../shared/components/breadcumbs";
import GlobalButton from "../../shared/globalButton";
import PlusIcon from "../../assets/icons/plus.svg";
import ExportIcon from "../../assets/icons/export.svg";
import * as XLSX from "xlsx";
import { exportToExcel } from "../../services/globalService";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Popconfirm } from "antd";
import AccountTableHeader from "../account/accountTableHeader";

export default function Expensetype() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [expensetypeList, setexpensetypeList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const handleDelete = async () => {
    try {
      await Promise.all(
        selectedIds.map((id) => deleteData(`expensetype/${id}`))
      );
      setexpensetypeList(
        expensetypeList.filter((i) => !selectedIds.includes(i.id))
      );
      setTotalItems(totalItems - selectedIds.length)
      setSelectedIds([]);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  
  const showPopconfirm = () => {
    setOpen(true);
  };
  
  const handleOk = async () => {
    setConfirmLoading(true);
  
    await handleDelete();
    setOpen(false);
    setConfirmLoading(false);
    
  };
  
  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };
  
  const handleExport = () => {
    const headers = [
      ["Name","Amount","Allowance Type","	Is per Km","Status"]


    ];
 
    filterAll("expensetype")
    .then((data) => {
      const datas = (data.data??[])?.map((item) => [
        item.name,
        item.amount ? item.amount : item.rate,
        item.allowancetype,
        item.isperkm,
        item.status ? "Active" : "Inactive",  
      ]);
      exportToExcel('Expense Type',headers,datas,'expensetype')
    })
  };

  return (
    <div className="page-px-24">
      {/* <div className='page-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>Expense Type</span>
                <div className='button-group' style={{ display: 'flex', gap: '10px' }}>
                    {selectedIds.length > 0 && <Button text='Delete Selected' onClick={handleDelete} />}
                    <Link to="/expensetype/addexpensetype"><Button text='Add Expense Type' /></Link>
                </div></div> */}
      <div className="">
        <div className="customer-header-alignment pb-4">
          <div>
            <h2>Expense Type</h2>
            <Breadcumbs activePath="Expense Type" pageName="Expense Type" />
          </div>
          <div className="right-contnet-alignment">
            {selectedIds.length > 0 && (
             <Popconfirm
             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
             title="Are you sure to delete this record? "
             description="This will lead to delete all records related to this record only!"
             open={open}
             onConfirm={handleOk}
             okButtonProps={{ loading: confirmLoading }}
             onCancel={handleCancel}
           >
             <GlobalButton text="Delete Selected" onClick={showPopconfirm} />
           </Popconfirm>
            )}
            <Link to="addexpensetype">
              <GlobalButton text="Add Expense Type" Icon={PlusIcon} />
            </Link>
            <GlobalButton text="Export" lightbutton Icon={ExportIcon} onClick={handleExport} />
          </div>
        </div>
      </div>
      <AccountTableHeader/>
      <Expensetypetable
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        expensetypeList={expensetypeList}
        setProductList={setexpensetypeList}
        totalItems={totalItems}
        setTotalItems={setTotalItems}
      />
    </div>
  );
}
