import {
  Typography,
  Tabs,
  Tab,
  TextField,
  FormControl,
} from "@mui/material";

import SelectBox from '../../../shared/components/select';
import { fetchData, postData, fetchsingleData } from "../../../services/apiService";
import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import GlobalButton from "../../../shared/globalButton";
import PieuserComponent from "./PieComponentt";
import BaruserComponent from "./BarComponent";
import CustomerUserTableComponent from "./CustomerProductTable";
import CustomerAreaTableComponent from "./CustomerProductTable";
import BarAreaComponent from "./BarComponent";
import PieareaComponent from "./PieComponentt";
import CustomerProductTableComponent from "./CustomerProductTable";
import BarProductComponent from "./BarComponent";
import PieProductComponent from "./PieComponentt";
import SalesBarProductComponent from "./BarComponent";
import SalesPieProductComponent from "./PieComponentt";
import SalesTableCustomerProductTableComponent from "./CustomerProductTable";
import StatussPieProductComponent from "./PieComponentt";
import SatusTableCustomerProductTableComponent from "./CustomerProductTable";
import StatusBarProductComponent from "./BarComponent";

export default function StatusComponent() {
  const [tabValue, setTabValue] = useState(0);
  const [userNameOptions, setUserNameOptions] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [formData, setFormData] = useState([]);
  const { id } = useParams();
  const [customers, setCustomerOptions] = useState([]);
  const [categoryOptions, setcategoryOptions] = useState([]);
  const [priceGroupOptions, setPriceGroupOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);
  const [accountcategoryOptions, setaccountcategoryOptions] = useState([]);
  const [segmentOptions, setsegmentOptions] = useState([]);
  const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
  };
  const [errors, setErrors] = useState({});
  const customStyles = {
      control: (provided) => ({
          ...provided,
          borderColor: '#ced4da',  // Add your custom border color
          borderRadius: '4px',      // Rounded corners
          padding: '0.375rem 0.75rem',  // Padding inside the control
          boxShadow: 'none',        // Remove any box shadow
          '&:hover': {
              borderColor: '#80bdff', // Border color on hover
          },
      }),
      dropdownIndicator: (provided) => ({
          ...provided,
          color: '#495057',  // Custom color for the dropdown indicator (arrow)
      }),
      menu: (provided) => ({
          ...provided,
          borderRadius: '4px',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',  // Shadow for the dropdown menu
      }),
      option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#e9ecef' : null,
          color: state.isSelected ? 'white' : '#495057',
          padding: '10px 20px',
      }),
  };


  const [mainTabValue, setMainTabValue] = React.useState(0); // Tracks main tabs like Customer, User, Area, etc.

  const handleMainTabChange = (event, newValue) => {
      setMainTabValue(newValue);
  };
  useEffect(() => {
      getData();
  }, []);
  const [customerList, setCustomerList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);


  const getData = async () => {
      try {
          const requestBody = {};
          const response = await postData("payment/status", requestBody); // Assuming postData is defined
          console.log("====", response)
          if (response?.data) {
              setCustomerList(response.data || []);
              setTotalItems(response?.totalDocuments || 0);

              // Calculate total amount by summing up the total_amount values
              const totalAmount = response.data.reduce((sum, item) => sum + (item.total_amount || 0), 0);
              setTotalAmount(totalAmount);  // Assuming you have a state setter for totalAmount
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
  };


  // Function to render the corresponding component based on selected tab
  const renderTabContent = () => {
      switch (mainTabValue) {
          case 0:
              return <SatusTableCustomerProductTableComponent customerList={customerList} setCustomerList={setCustomerList} />;
          case 1:
              return <StatusBarProductComponent customerList={customerList} setCustomerList={setCustomerList} />;
          case 2:
              return <StatussPieProductComponent customerList={customerList} setCustomerList={setCustomerList} />;
          default:
              return <Typography variant="h6">No Data Available</Typography>;
      }
  };

  const [countryOptions, setCountryOptions] = useState([])
  const [sateOptions, sateCategory] = useState([])
  const [areaOptions, areCategory] = useState([])

  const fetchCountry = async () => {
      try {
          const response = await fetchData("country");

          if (response?.data) {
              setCountryOptions(response?.data.map(i => {
                  return {
                      label: i?.countryName, key: 'country', value: { countryId: i?.id, countryName: i?.countryName }
                  }
              }));
          }
          if (id) {
              const response = await fetchsingleData("country", id);
              if (response?.data)
                  setFormData(response?.data); // Update state with fetched data
          }

      } catch (error) {
          console.error('Error fetching data:', error);
      }
  }

  const fetchState = async (id) => {
      try {
          const response = await fetchData("state", { countryId: id });

          if (response?.data) {
              sateCategory(response?.data.map(i => {
                  return {
                      label: i?.stateName, key: 'state', value: { stateId: i?.id, stateName: i?.stateName }
                  }
              }));
          }

      } catch (error) {
          console.error('Error fetching data:', error);
      }
  }

  const fetchArea = async (id) => {
      try {
          const response = await fetchData("area", { stateId: id });
          console.log('arra', response.data);
          if (response?.data) {

              areCategory(response?.data.map(i => {
                  return {
                      label: i?.cityName, key: 'area', value: { areaId: i?.id, areaName: i?.cityName }
                  }
              }));
          }

      } catch (error) {
          console.error('Error fetching data:', error);
      }
  }


  const fetchproduct = async (id) => {
      try {
          const responseProducts = await fetchData("paymenttype");
          console.log('arra', responseProducts.data);
          if (responseProducts?.data) {

              setProductOptions(
                  responseProducts?.data?.map((i) => {
                      return {
                          ...i,
                          label: `${i?.name}`,
                          key: "name",
                          value: i?.id,
                      };
                  })
              );
          }

      } catch (error) {
          console.error('Error fetching data:', error);
      }
  }

  useEffect(() => {

      fetchCountry()
      fetchState()
      fetchGroup();
      fetchArea()
      getCustomer()
      fetchCategory();
      fetchproduct();
      fetchUser()
      fetchaccountcategory()
      fetchasegment()
      // handleApply()
  }, [])

  const selectChanged = (e) => {



      // If the selected key is "country", update formData and fetch states
      if (e.key === "country") {
          setFormData(prevState => ({
              ...prevState,
              ...e.value
          }));
          fetchState(e.value.countryId);
      } else if (e.key === "state") {
          setFormData(prevState => ({
              ...prevState,
              ...e.value
          }));
          fetchArea(e.value.stateId);
      } else {
          setFormData(prevState => ({
              ...prevState,
              ...e.value
          }));
      }

  };

  const selectChangedcustomer = (e) => {
      //  Assuming e is an array of selected options for a multi-select
      const selectedValues = e.map(option => option.value.id); // Get ids from selected options

      // Update formData with the selected customer ids
      setFormData(prevState => ({
          ...prevState,
          customerId: selectedValues,
      }));
  }


  const getCustomer = async () => {
      try {
          const response = await fetchData(`customer`);
          if (response?.data) {

              setCustomerOptions(

                  response?.data?.map((i) => {

                      return {
                          label: `${i?.cName}`,
                          key: "customer",
                          value: {
                              id: i?.id,
                              name: ` ${i?.cName}`,
                              address: `${i?.address} - ${i?.pincode}.`,
                              contact: i?.addressContact,
                          },
                      };

                  })
              );
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
  };

  const fetchCategory = async () => {
      try {
          const response = await fetchData("systemtype", {
              typeCategory: "category",
          });

          if (response?.data) {
              setcategoryOptions(
                  response?.data.map((i) => {
                      return {
                          label: i?.typeName,
                          key: "area",
                          value: { productCategoryId: i?.id, productCategory: i?.typeName },
                      };
                  })
              );
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
  };

  const fetchGroup = async () => {
      try {
          const response = await fetchData("pricegroup");

          if (response?.data) {
              setPriceGroupOptions(
                  response?.data.map((i) => {
                      return {
                          label: i?.name,
                          key: "pricegroup",
                          value: { priceGroupId: i?.id, priceGroupName: i?.name },
                      };
                  })
              );
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      }
  };

  const fetchUser = async () => {
      try {
          const response = await fetchData('user');
          if (response?.data) {
              setUserNameOptions(
                  response.data.map((user) => ({
                      label: user.name,
                      value: user.name,
                  }))
              );
          }
      } catch (error) {
          console.error('Error fetching users:', error);
      }
  };

  const fetchaccountcategory = async () => {
      try {
          const response = await fetchData('accountcategory');
          if (response?.data) {
              setaccountcategoryOptions(
                  response.data.map((item) => ({
                      label: item.name, // The label to display in the dropdown
                      value: item.id,   // The unique identifier for the option
                      color: item.color, // Optional additional property
                  }))
              );
          }
      } catch (error) {
          console.error('Error fetching account categories:', error);
      }
  };

  const fetchasegment = async () => {
      try {
          const response = await fetchData('segment');
          if (response?.data) {
              setsegmentOptions(
                  response.data.map((item) => ({
                      label: item.name, // Text shown in the dropdown
                      value: item.id,   // Unique identifier
                  }))
              );
          }
      } catch (error) {
          console.error('Error fetching users:', error);
      }
  };


  const selectChangeduser = (selectedOptions) => {
      const selectedValues = selectedOptions.map(option => option.value);
      setFormData(prevData => ({
          ...prevData,
          username: selectedValues,
      }));
  };


  const selectaccountcategory = (selectedOptions) => {
      const selectedValues = selectedOptions.map(option => option.value); // Map selected options to their values
      setFormData(prevData => ({
          ...prevData,
          name: selectedValues, // Update the `name` field in formData
      }));
  };


  const selectacsegment = (selectedOptions) => {
      const selectedValues = selectedOptions.map(option => option.value);
      setFormData(prevData => ({
          ...prevData,
          name: selectedValues,
      }));
  };


  const selectChangedprice = (selectedOptions) => {
      const selectedValues = selectedOptions.map(option => option.value.priceGroupId);
      setFormData(prevData => ({
          ...prevData,
          priceGroupId: selectedValues,
      }));
  };

  const selectChangedproduct = (selectedOptions) => {
      // Assuming selectedOptions is an array of selected product objects
      const selectedValues = selectedOptions.map(option => option.value);
      setFormData({ ...formData, productId: selectedValues });
  };

  const selectChangedcat = (selectedOptions) => {
      // Assuming selectedOptions is an array of selected category objects
      const selectedValues = selectedOptions.map(option => option.value);
      setFormData({ ...formData, productCategoryId: selectedValues });
  };



  const clearFilters = () => {
      const initialData = {
          period: "",
          fromDate: "", // Clear date fields
          toDate: "",
          countryId: "",
          stateId: "",
          areaId: "",
          customerId: [], // Clear multi-selection arrays
          customerName: [],
          username: "",
          priceGroupId: "",
          segment: "",
          productCategoryId: "",
          productId: "",
          countryName: "",
          stateName: "",
          areaName: "",
          name: "",
          address: "",
          contact: "",
          productCategory: "",
      };
      handleApply();
      // setFormData(defaultData);
      setFormData(initialData); // Reset the form data to initial state
      setErrors({}); // Clear any validation errors if present
  };
  const handleApply = async () => {
      // Ensure all fields are properly set or defaulted
      const formattedData = {
          period: formData.period || "", // Ensure period is set
          fromDate: formData.fromDate ? new Date(formData.fromDate).toISOString().split('T')[0] : "", // Format date if exists
          toDate: formData.toDate ? new Date(formData.toDate).toISOString().split('T')[0] : "", // Format date if exists
          countryId: formData.countryId || "", // Ensure countryId is set
          stateId: formData.stateId || "", // Ensure stateId is set
          areaId: formData.areaId || "", // Ensure areaId is set
          customerId: Array.isArray(formData.customerId)
              ? formData.customerId.map(customer => customer?.id || customer) // Safely map over customerId
              : [], // Default to an empty array
          username: Array.isArray(formData.username)
              ? formData.username.map(user => user?.id || user) // Safely map over username
              : [], // Default to an empty array
          priceGroupId: Array.isArray(formData.priceGroupId)
              ? formData.priceGroupId.map(priceGroup => priceGroup?.id || priceGroup) // Safely map over priceGroupId
              : [], // Default to an empty array
          segment: Array.isArray(formData.name)
              ? formData.name.map(segment => ({
                  segmentId: segment?.id || "",
                  segmentName: segment?.name || "",
              }))
              : [], // Default to an empty array
          customerCategory: Array.isArray(formData?.id) && formData.id.length > 0
              ? formData.id.map((category) => ({
                  customerCategoryId: category?.id || "",
              }))
              : [], // Default to an empty array
          productCategories: Array.isArray(formData.productCategoryId)
              ? formData.productCategoryId.map(category => ({
                  productCategoryId: category?.productCategoryId || category?.id || "",
                  productCategory: category?.productCategory || "",
              }))
              : [], // Default to an empty array
          productId: Array.isArray(formData.productId)
              ? formData.productId.map(product => product?.id || product) // Safely map over productId
              : [], // Default to an empty array
          countryName: formData.countryName || "", // Ensure countryName is set
          stateName: formData.stateName || "", // Ensure stateName is set
          areaName: formData.areaName || "", // Ensure areaName is set
          productCategory: formData.productCategory || "", // Ensure productCategory is set
      };

      console.log("Formatted Payload:", formattedData);

      // API call
      try {
          const response = await postData("payment/status", formattedData);

          setCustomerList(response?.data);

          if (response?.data) {
              console.log("API Response:", response.data);
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      }

  };

  return (
    <div className="">
        <div className="customer-page-main-grid">
            <div>
                <div className=" new-white-box-design">
                       <h2>
                        Total amount: ₹ {new Intl.NumberFormat('en-IN').format(totalAmount)}
                       </h2>
                        <div>
                            <Tabs value={mainTabValue} onChange={handleMainTabChange}>
                                <Tab label="TABLE" />
                                <Tab label="BAR" />
                                <Tab label="PIE" />
                            </Tabs>
                            <div className="pt-3">{renderTabContent()}</div>
                        </div>
                </div>
            </div>
            <div>
                <div className=" new-white-box-design">
                    <div className="pb-3">
                        <FormControl style={{ width: '100%' }}>
                            <TextField
                                type="date"
                                label="From"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                variant="outlined"
                                name="fromDate"
                            />
                        </FormControl>
                    </div>
                    <div className="pb-3">
                        <FormControl style={{ width: '100%' }}>
                            <TextField
                                type="date"
                                label="To"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                variant="outlined"
                                name="toDate"
                            />
                        </FormControl>
                    </div>
                    <div className="pb-3">
                        <SelectBox
                            label="Country"
                            name="countryId"
                            value={countryOptions.find(i => i.value.id === formData.countryId)}
                            options={countryOptions}
                            onChange={selectChanged}
                            placeholder="Select Country"
                            required
                        />
                    </div>
                    <div className="pb-3">
                        <SelectBox
                            label="State"
                            name="stateId"
                            value={sateOptions.find(i => i.value.id === formData.stateId)}
                            options={sateOptions}
                            onChange={selectChanged}
                            placeholder="Select State"
                            required
                        />
                    </div>
                    <div className="pb-3">
                        <SelectBox
                            label="City"
                            name="areaId"
                            value={areaOptions.find(i => i.value.id === formData.areaId)}
                            options={areaOptions}
                            onChange={selectChanged}
                            placeholder="Select City"
                            required
                            errors={errors.customerName}
                        />
                    </div>
                    <div className="pb-3">
                        <SelectBox
                            label="Customer"
                            name="customerId"
                            value={customers?.filter(option => (formData.customerId || []).includes(option.value.id))} // This ensures selected values are shown
                            options={customers}
                            onChange={selectChangedcustomer}  // Ensure this is correct
                            placeholder="Customer Name"
                            required
                            errors={errors.customerName}
                            isMulti={true} // Enables multi-selection
                        />
                    </div>
                    <div className="pb-3">
                        <SelectBox
                            label="Created By"
                            name="username"
                            value={userNameOptions?.filter(i => Array.isArray(formData?.username) && formData?.username?.includes(i?.value))}
                            options={userNameOptions}
                            onChange={selectChangeduser}
                            placeholder="Created By"
                            required
                            isMulti
                            errors={errors.username}
                        />
                    </div>
                    <div className="pb-3">
                        <SelectBox
                            size="small"
                            variant="outlined"
                            label="Customer Category"
                            name="Customer Category"
                            value={accountcategoryOptions?.filter(i => Array.isArray(formData?.name) && formData?.name?.includes(i?.value))}
                            options={accountcategoryOptions}
                            onChange={selectaccountcategory}
                            isMulti
                            errors={errors.name}
                        />
                    </div>
                    <div className="pb-3">
                        <SelectBox
                            label="payment Type"
                            name="paymenttype"
                            value={productOptions.filter(option => Array.isArray(formData.productId) && formData.productId.includes(option.value))}
                            options={productOptions}
                            onChange={selectChangedproduct}
                            placeholder="payment Type"
                            required
                            errors={errors.customerName}
                            isMulti // This makes it a multi-select
                        />
                    </div>
                    <div className="d-flex align-items-center gap-3 justify-content-center">
                        <div onClick={handleApply}>
                        <GlobalButton text="APPLY"/>
                        </div>
                        <div onClick={clearFilters}>
                        <GlobalButton text="Clear Filters"/>
                        </div>

                    </div>
                        {/* <InputLabel shrink>Period</InputLabel> */}
                        {/* <div className="col-md-12">
                            <FormControl>
                                <InputLabel>Period</InputLabel>
                                <Select
                                    name="period"
                                    value={formData.period}
                                    onChange={selectChanged}
                                >
                                    <MenuItem value="Last 180 days">Last 180 days</MenuItem>
                                    <MenuItem value="Custom">Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </div> */}

                        {/* Buttons with display flex */}
                       
                </div>
            </div>

        </div>
    </div>
);
}
