import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  fetchData,
  postData,
  fetchsingleData,
} from "../../services/apiService";
import "./login.scss";
import Input from "../../shared/components/input";
import { NavLink } from "react-router-dom";
import { signinUser } from "../../services/cognitoService";
import Button from "../../shared/components/button";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { login as loginAction, setUsername } from "../../redux/auth";
import { setUser } from "../../redux/rba";
import { notify } from "../toastr/Toastr";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login = useSelector((state) => state.auth.isAuthenticated);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [isInactive, setIsInactive] = useState(false);

  const validationCheck = (isSubmit) => {
    const validationErrors = {};
    if (!formData.username.trim()) {
      validationErrors.username = "Please enter username";
    }
    if (!formData.password.trim()) {
      validationErrors.password = "Please enter password";
    }
    if (isSubmit || errors.isSubmit) validationErrors.isSubmit = true;

    setErrors(validationErrors);
    return (
      Object.keys(validationErrors).filter((i) => i !== "isSubmit").length === 0
    );
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (errors.isSubmit) validationCheck();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validationCheck(true)) {
      const username = formData?.username;
      const user = {
        email: username,
        password: formData?.password,
      };
      console.log("user", user);

      try {
        const data = await postData("auth/login", user);
        console.log("data", data);

        if (!data.user.status) {
          setIsInactive(true);
          message.error(
            "This user is inactive. Please contact the administrator."
          );
          return;
        }

        setIsInactive(false);

        dispatch(loginAction(data));
        const response = await fetchsingleData("userrole", data.user.roleId);
        const company_setting = await fetchsingleData(
          "company_setting",
          data?.user?.orgId
        );
        dispatch(
          loginAction({
            ...data,
            feature: response.data.features,
            company_setting: company_setting?.data,
            orgId: response?.data?.orgId,
          })
        );
        notify.success("You are successfully logged in.");
        navigate("/dashboard");
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Please Enter Correct username and Password");
      }
    }
  };

  return (
    <div>
      <div className="login-banner">
        <div className="box">
          <div className="header">
            <h1>Login</h1>
          </div>
          <div className="body">
            <div className="alignment">
              <label>Username</label>
              <div className="input-container">
                <Input
                  placeholder="Enter Username"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="alignment">
              <label>Password</label>
              <div className="input-container">
                <Input
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Enter Password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <i
                  className={
                    passwordVisible
                      ? "fa-regular fa-eye-slash"
                      : "fa-regular fa-eye"
                  }
                  onClick={togglePasswordVisibility}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                />
              </div>
            </div>
            <div className="link">
              <NavLink to="/forgot-password">Forgot Password?</NavLink>
            </div>
          </div>
          <div className="button" onClick={handleSubmit}>
            <Button text="Login" />
          </div>
        </div>
      </div>
    </div>
  );
}
